import React from "react";

import { Layout, Divider, Progress, Button, Space } from "antd";

import Base from "../Base";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

class FinanceHome extends Base {
  /* lifcyle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      noneCheckData: null,
      loadingNoneCheck: false,

      checkedData: null,
      loadingCheckedData: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(
        this.i18n.t("financeHome.loadingData"),
        Base.Message.loading
      );
      this.loadNoneCheckData();
      this.loadCheckedData();
    }
  }

  /* render methods */

  render() {
    return <Layout id="route-finance-home">{this._renderBillBrief()}</Layout>;
  }

  _renderBillBrief() {
    return (
      <div className="bill-brief">
        {this._renderBillBriefHeader()}
        {this._renderBillBriefList(true)}
        {this._renderBillBriefList()}
      </div>
    );
  }

  _renderBillBriefHeader() {
    return (
      <div className="bill-brief-header">
        <span>{this.i18n.t("financeHome.headerTitle")}</span>
        <Button
          style={{
            boxShadow: "none",
            borderWidth: 0,
            backgroundColor: "transparent",
          }}
          onClick={this.onBillBriefAllClick}
        >
          {`${this.i18n.t("financeHome.headerAllBills")} >`}
        </Button>
      </div>
    );
  }

  _renderBillBriefList(unsettled = false) {
    let className = "bill-brief-list";
    if (unsettled) {
      className += " unsettled";
    }

    return (
      <div className={className}>
        {this._renderBillBriefListTitle(unsettled)}
        {this._renderBillBriefListHeader()}
        {this._renderBillBriefListGroups(unsettled)}
      </div>
    );
  }

  _renderBillBriefListTitle(unsettled) {
    let title = this.i18n.t("financeHome.listTitle");
    if (unsettled) {
      title = this.i18n.t("financeHome.listTitleUnsettled");
    }

    return (
      <div className="list-title">
        <span>{title}</span>
      </div>
    );
  }

  _renderBillBriefListHeader() {
    return (
      <div className="finance-bill-list-header">
        <div className="date">{this.i18n.t("financeHome.listHeaderDate")}</div>
        <div className="sum">
          {this.i18n.t("financeHome.listHeaderSum")}

        </div>
        <div className="progress"></div>
        <div className="actions">
          {this.i18n.t("financeHome.listHeaderActions")}
        </div>
        <div className="details">
          {this.i18n.t("financeHome.listHeaderDetails")}
        </div>
      </div>
    );
  }

  _renderBillBriefListGroups(unsettled) {
    let data = null;
    if (unsettled) {
      data = this.state.noneCheckData;
    } else {
      data = this.state.checkedData;
    }

    if (data) {
      let groups = null;
      if (unsettled) {
        groups = data ? [data.record] : null;
      } else {
        let records = data.records;
        groups = records ? records.data : null;
      }

      if (groups && groups.length > 0) {
        return (
          <div className="list-items">
            {groups.map((group, index) => {
              let className = "finance-bill-list-group";
              if (unsettled) {
                className += " unsettled";
              }

              return (
                <div key={`list-group-${index}`} className={className}>
                  {this._renderBillBriefListGroupItems(group, index, unsettled)}
                  {this._renderBillBriefListGroupDetails(
                    group,
                    index,
                    unsettled
                  )}
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div className="list-items">
            <div className="list-message">
              {this.i18n.t("financeHome.loadingEmpty")}
            </div>
          </div>
        );
      }
    } else {
      let isLoading =
        (unsettled && this.state.loadingNoneCheck) ||
        (!unsettled && this.state.loadingCheckedData);
      if (isLoading) {
        return (
          <div className="list-items">
            <div className="list-message">
              {this.i18n.t("financeHome.loadingData")}
            </div>
          </div>
        );
      }

      return (
        <div className="list-items">
          <div className="list-message">
            {this.i18n.t("financeHome.loadingEmpty")}
          </div>
        </div>
      );
    }
  }

  _renderBillBriefListGroupItems(group, index, unsettled) {
    let dailyRecords = group.daily_records;
    if (dailyRecords && dailyRecords.length > 0) {
      return (
        <div className="group-items">
          {dailyRecords.map((itm, idx) => {
            const date = this.moment(itm.checkout_date);

            return (
              <div key={`group-${index}-item-${idx}`} className="group-item">
                <div className="date">{date.format("YYYY-MM-DD")}</div>
                <div className="sum">
                  <span className="unit">￥</span>
                  <span className="total">
                    {this.util.getFormattedPrice(itm.total_merchant_income)}
                    {itm.succeeded_amount && (
                      <span className="succeeded">
                        ({this.i18n.t('financeOrderDetail.detailTotal')}:{this.util.getFormattedPrice(itm.succeeded_amount)})
                      </span>
                    )}
                  </span>
                </div>
                <div className="progress">
                  <Progress
                    percent={this.getPricePercent(
                      itm.total_merchant_income,
                      unsettled
                    )}
                    showInfo={false}
                  />
                </div>
                <Space direction="horizontal" className="actions">
                  <Button
                    style={{
                      borderWidth: 0,
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    onClick={() => this.onDailyBillView(group, itm, unsettled)}
                  >
                    {this.i18n.t("financeHome.listItemActionDetails")}
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    style={{
                      borderWidth: 0,
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    onClick={() =>
                      this.onDailyBillDownload(group, itm, unsettled)
                    }
                  >
                    {this.i18n.t("financeHome.listItemActionDownload")}
                  </Button>
                </Space>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div className="group-items"></div>;
    }
  }

  _renderBillBriefListGroupDetails(group, index, unsettled) {
    let detailInfo = this.i18n.t("financeHome.listGroupInfo");
    if (unsettled) {
      detailInfo = this.i18n.t("financeHome.listGroupInfoUnsettled", {
        sign_date: group.sign_date,
      });
    }

    return (
      <div className="group-details">
        <span className="time">
          {this.i18n.t("financeHome.listGroupTime", {
            start_date: group.start_date,
            end_date: group.end_date,
          })}
        </span>
        <span className="sum">
          <span className="unit">￥</span>
          <span className="total">
            {this.util.getFormattedPrice(group.total_merchant_income)}
          </span>
        </span>
        <Button
          className="info"
          onClick={() => this.onGroupBillInfoClick(group, unsettled)}
          style={{ borderWidth: 0, boxShadow: "none" }}
        >
          {detailInfo}
        </Button>
      </div>
    );
  }

  /* user methods */

  onBillBriefAllClick = () => {
    this.props.router.push({
      pathname: "/finance/check",
    });
  };

  onDailyBillView = (group, item, unsettled) => {
    this.props.router.push({
      pathname: "/finance/check-daily",
      state: {
        data: {
          ...item,
          sign_date: group.sign_date,
          is_signed: !unsettled,
        },
      },
    });
  };

  onDailyBillDownload = (group, item, unsettled) => {
    this.props.router.push({
      pathname: "/finance/download",
      query: {
        timeType: "custom",
        timeRange: `${this.moment(group.start_date).format(
          "YYYY-MM-DD"
        )}~${this.moment(group.end_date).format("YYYY-MM-DD")}`,
      },
    });
  };

  onGroupBillInfoClick = (group, unsettled) => {
    if (unsettled) return;

    this.props.router.push({
      pathname: "/finance/account",
    });
  };

  /* server methods */

  async loadNoneCheckData() {
    if (this.state.loadingNoneCheck) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.nonCheckout;
    let apiConfig = {
      token: token,
    };

    this.setState({ loadingNoneCheck: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ noneCheckData: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingNoneCheck: false });
    }
  }

  async loadCheckedData() {
    if (this.state.loadingCheckedData) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.checkout;
    let apiConfig = {
      token: token,
      query: {
        with_record_details: 1,
        page: 1,
        count: 1,
      },
    };

    this.setState({ loadingCheckedData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ checkedData: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingCheckedData: false });
    }
  }

  /* custom methods */

  getPricePercent(price = 0, unsettled) {
    let max = 6000;

    let data = null;
    if (unsettled) {
      data = this.state.noneCheckData;
    } else {
      data = this.state.checkedData;
    }
    if (data && data.max_income_amount) {
      max = data.max_income_amount / 100;
    }

    price = this.util.getPrice(price);
    let ratio = (price / max) * 100;

    if (ratio >= 100) {
      ratio = 100;
    }

    if (ratio < 1) {
      ratio = 1;
    }

    return ratio;
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
}, {})(FinanceHome);
