import React from "react";
import { Space, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Locale } from "../../utils";

const i18n = Locale.getInstance();

function FormSubmitBtn({ weappId, downloadUrl, onGenerateWeapp, loading }) {
  return (
    <Space direction="horizontal" size={20}>
      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        size="large"
      >
        {i18n.t("sales.submit")}
      </Button>
      {weappId && (
        <Button
          disabled={loading}
          loading={loading}
          type="default"
          size="large"
          onClick={onGenerateWeapp}
        >
          {i18n.t("sales.generate")}
        </Button>
      )}
      {downloadUrl && (
        <Button icon={<DownloadOutlined />} size="large" href={downloadUrl}>
          {i18n.t("financeHome.listItemActionDownload")}
        </Button>
      )}
    </Space>
  );
}

export default FormSubmitBtn;
