import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Row,
  List,
  Divider,
  Input,
  message,
  Avatar,
  Button,
  InputNumber,
  Space,
} from "antd";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { ApiManager } from "../../utils";
import Base from "../Base";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const base = Base.getInstance();

const { TextArea } = Input;

function omit(obj, arr) {
  return Object.keys(obj)
    .filter((k) => !arr.includes(k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {}); //eslint-disable-line
}

function AgentListForm({ visible, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState({ data: [] });
  const [selectedUserList, setSelectedUserList] = useState({});
  const user = useSelector((state) => getUserInfo(state));
  const onSearchUsers = useRef();

  onSearchUsers.current = async (page = 1, keyword = "") => {
    const { token } = user;
    if (token) {
      try {
        setLoading(true);
        const apiConfig = {
          token,
          query: {
            page,
            "filter[mobile]": keyword,
            "filter[status]": 1,
          },
        };
        const res = await api.proxy.list.run(apiConfig);
        if (res.result) {
          setUsersList(res.data.users);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  function onDeleteUser(userId) {
    const others = omit(selectedUserList, [`${userId}`]);
    setSelectedUserList(others);
  }

  function onSelectUser(item) {
    setSelectedUserList({
      ...selectedUserList,
      [item.id]: { ...item, count: 1, comment: "" },
    });
  }

  function checkIfItemSelected(itemId) {
    const currentKeys = Object.keys(selectedUserList);
    return currentKeys.findIndex((key) => key === `${itemId}`) !== -1;
  }

  useEffect(() => {
    onSearchUsers.current();
  }, [onSearchUsers]);

  async function onFinish(data) {
    if (user.token) {
      const apiUrl = api.proxy.transfer;
      try {
        setLoading(true);
        const res = await Promise.all(
          data.map((d) => {
            const apiConfig = {
              data: { ...d, amount: Number(d.amount) * 100 },
              token: user.token,
            };
            return apiUrl.run(apiConfig);
          })
        );
        if (Array.isArray(res) && res.every((i) => i.result)) {
          base.showMessage(
            i18n.t("financeAccount.listItemStatusSuccess"),
            Base.Message.success
          );
          onCancel();
        } else {
          base.showMessage(res[0].message, Base.Message.error);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setSelectedUserList({});
        onCancel();
      }}
      title={i18n.t("sales.coupons.send")}
      width={1020}
      bodyStyle={{ height: 600, overflowY: "scroll" }}
      okText={i18n.t("base.modal.okText")}
      cancelText={i18n.t("base.modal.cancelText")}
      onOk={() => {
        const data = Object.keys(selectedUserList).map((key) => {
          return {
            user_id: key,
            amount: selectedUserList[key].count,
            comment: selectedUserList[key].comment,
          };
        });
        onFinish(data);
      }}
      okButtonProps={{ loading, disabled: loading }}
    >
      <Row align="top">
        <List
          loading={loading}
          style={{ width: "49%", height: "100%" }}
          header={
            <Input.Search
              enterButton
              onSearch={(keyword) => onSearchUsers.current(1, keyword)}
            />
          }
          pagination={{
            current: usersList.current_page,
            onChange: (page) => onSearchUsers.current(page),
            total: usersList.total,
            showSizeChanger: false,
            showQuickJumper: true,
          }}
          dataSource={usersList.data}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  disabled={checkIfItemSelected(item.id)}
                  onClick={() => onSelectUser(item)}
                >
                  {checkIfItemSelected(item.id)
                    ? i18n.t("sales.coupons.selected")
                    : i18n.t("sales.coupons.select")}
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar shape="circle" size="large" src={item.avatar} />
                }
                title={item.nickname}
                description={item.mobile}
              />
            </List.Item>
          )}
        />
        <Divider type="vertical" style={{ height: 500 }} />
        <List
          style={{ width: "49%" }}
          dataSource={Object.keys(selectedUserList)}
          renderItem={(key) => {
            const item = selectedUserList[key];
            return (
              <List.Item
                actions={[
                  <Button
                    key="delete"
                    danger
                    onClick={() => onDeleteUser(item.id)}
                  >
                    {i18n.t("sales.activity.delete")}
                  </Button>,
                ]}
              >
                <Space direction="vertical">
                  <List.Item.Meta
                    avatar={
                      <Avatar shape="circle" size="large" src={item.avatar} />
                    }
                    title={item.nickname}
                    description={item.mobile}
                  />
                  <InputNumber
                    min={0}
                    formatter={(i) => `¥${i}`}
                    value={item.count}
                    onChange={(value) => {
                      setSelectedUserList({
                        ...selectedUserList,
                        [item.id]: { ...item, count: value },
                      });
                    }}
                  />
                  <TextArea
                    rows={2}
                    placeholder={i18n.t("transfer.reason")}
                    value={item.comment}
                    onChange={(e) => {
                      setSelectedUserList({
                        ...selectedUserList,
                        [item.id]: { ...item, comment: e.target.value },
                      });
                    }}
                  />
                </Space>
              </List.Item>
            );
          }}
        />
      </Row>
    </Modal>
  );
}

export default AgentListForm;
