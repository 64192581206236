import React, { useState, useCallback, useEffect, useRef } from "react";
import { Table, message, Tag, Avatar, Button, DatePicker, Input } from "antd";
import { connect } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { ApiManager, Locale, Util } from "../../utils";
import Base from "../Base";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();
const base = Base.getInstance();

function CommissionWithdrawRequestList({ user, router }) {
  const thisSorter = useRef();
  const thisFilters = useRef();

  const [list, setList] = useState({ data: [], current_page: 1 });
  const [range, setRange] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchList = useCallback(
    async (page = 1) => {
      const { token } = user;
      if (token) {
        setLoading(true);
        try {
          const apiUrl = api.commission.withdrawRequestList;
          const apiConfig = {
            token,
            query: {
              page,
              keyword,
              count: 10,
            },
          };

          if (thisSorter.current && thisSorter.current.field) {
            const order = thisSorter.current.order.split("end")[0];
            apiConfig.query[
              `filter[sort]`
            ] = `${thisSorter.current.field}@${order}`;
          }
          if (thisFilters && thisFilters.current) {
            for (let key in thisFilters.current) {
              if (thisFilters.current[key]) {
                apiConfig.query[`filter[${key}]`] = thisFilters.current[
                  key
                ].join(",");
              }
            }
          }
          if (range && range.length > 0) {
            apiConfig.query["filter[start_date]"] = range[0].format(
              "YYYY-MM-DD"
            );
            apiConfig.query["filter[end_date]"] = range[1].format("YYYY-MM-DD");
          }
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setList(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [user, range, keyword]
  );
  function onWithdrawListItemDetailClick(item) {
    router.push({
      pathname: "/finance/withdraw-commission-list",
      query: {
        id: item.id,
      },
      state: {
        data: {
          ...item,
        },
      },
    });
  }
  function onSubmit(id, type = "confirm") {
    base.showModal(
      i18n.t("base.modal.confirmTitle"),
      Base.Modal.confirm,
      async () => {
        let apiUrl = api.commission.acceptWithdrawRequest;
        if (type === "reject") {
          apiUrl = api.commission.rejectWithdrawRequest;
        }
        console.log(
          "🚀 ~ file: CommissionWithdrawRequestList.jsx ~ line 97 ~ apiUrl",
          apiUrl
        );
        const { token } = user;
        if (apiUrl && token) {
          const apiConfig = {
            token,
            path: {
              id,
            },
          };
          try {
            setLoading(true);
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              fetchList(list.current_page);
            } else {
              message.warning(res.message);
            }
          } catch (error) {
            setLoading(false);
            base.handleApiError(apiUrl, error);
          }
        }
      }
    );
  }

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  function getStatusColor(recordStatus) {
    let color;
    switch (recordStatus) {
      case 2:
        color = "green";
        break;
      case 4:
        color = "blue";
        break;
      default:
        color = "red";
        break;
    }
    return color;
  }
  function accept(item) {
    if (item.status !== 0) return null;
    return (
      <Button type="link" onClick={() => onSubmit(item.id, "confirm")}>
        {i18n.t("commissionList.confirm")}
      </Button>
    );
  }
  function reject(item) {
    if (item.status !== 0) return null;
    return (
      <Button type="link" onClick={() => onSubmit(item.id, "reject")}>
        {i18n.t("commissionList.reject")}
      </Button>
    );
  }
  function go2Detail(item) {
    return (
      <Button
        type="link"
        onClick={() => onWithdrawListItemDetailClick(item, "confirm")}
      >
        {i18n.t("financeAccount.listItemActionDetails")}
      </Button>
    );
  }
  return (
    <>
      <Input.Group compact style={{ marginBottom: 16 }}>
        <DatePicker.RangePicker
          defaultValue={range}
          format="YYYY-MM-DD"
          allowClear
          onChange={(dates) => setRange(dates)}
          suffixIcon={<CaretDownOutlined />}
        />
        <Search
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={() => fetchList()}
          placeholder={i18n.t("users.searchPlace")}
          style={{ width: 300 }}
          enterButton
          allowClear
        />
      </Input.Group>
      <Table
        loading={loading}
        dataSource={list.data}
        rowKey={(record) => `${record.id}`}
        onChange={(pagination, filters, sorter) => {
          thisSorter.current = sorter;
          thisFilters.current = filters;
          fetchList(pagination.current);
        }}
        pagination={{
          current: list.current_page,
          total: list.total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        columns={[
          {
            title: "id",
            key: "id",
            dataIndex: "id",
          },
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => {
              const { user } = record;
              return (
                <Avatar
                  size="large"
                  shape="circle"
                  src={user.avatar}
                  alt={user.nickname}
                />
              );
            },
          },
          {
            title: i18n.t("users.name"),
            key: "nickname",
            render: (text, record) => record.user.nickname,
          },
          {
            title: i18n.t("commissionList.channel"),
            key: "type",
            align: "center",
            filters: list.types
              ? Object.keys(list.types).map((k) => ({
                  text: list.types[k],
                  value: k,
                }))
              : [],

            render: (text, record) => {
              return <Tag color="purple">{record.type_label}</Tag>;
            },
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            align: "center",
            render: (text, record) => record.user.mobile,
          },
          {
            title: i18n.t("commissionList.comm"),
            key: "received_amount",
            dataIndex: "received_amount",
            sorter: true,
            render: (text, record) => {
              return util.getFormattedPrice(record.received_amount, "¥");
            },
          },
          {
            title: i18n.t("commissionList.status"),
            key: "status",
            align: "center",
            filters: list.status
              ? list.status.map((i, idx) => ({
                  text: i,
                  value: idx,
                }))
              : [],
            render: (text, record) => {
              if (record.user_id) {
                return (
                  <Tag color={getStatusColor(record.status)}>
                    {record.status_label}
                  </Tag>
                );
              }
              return null;
            },
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "created_at",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("users.actions"),
            key: "actions",
            align: "center",
            render: (text, record) => {
              return (
                <>
                  {go2Detail(record)}
                  {accept(record)}
                  {reject(record)}
                </>
              );
            },
          },
        ]}
      />
    </>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  CommissionWithdrawRequestList
);
