import React from "react";

import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Radio,
  Checkbox,
  Input,
  Button,
  Pagination,
  DatePicker,
  Table,
  Avatar,
  Divider,
  Tag,
} from "antd";

import Base from "../Base";
import ProxyEditModal from "../../components/proxy/ProxyEditModal";
import TopupInput from "../balance/TopupInput";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import moment from "moment";

const { RangePicker } = DatePicker;

class ProxyList extends Base {
  /* lifecycle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      searchFromParent: false,
      mobile: this.getDefaultMobile(),
      tmpMobile: this.getDefaultMobile(),
      level: null,
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(5),

      ownerValue: -1,
      owners: this.getOwners(),

      proxyStatus: -1,
      allStatus: this.getProxyStatus(),

      levels: null,
      data: null,
      loadingData: false,
      addingProxy: false,
      updatingProxy: false,
      deletingProxy: false,

      showEditModal: false,
      editModalProxy: null,
      range: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(this.i18n.t("proxy.loadingData"), Base.Message.loading);
      this.loadProxyList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      prevState.mobile !== this.state.mobile ||
      (prevState.level && prevState.level.id !== this.state.level.id) ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.currentPageSize !== this.state.currentPageSize ||
      prevState.ownerValue !== this.state.ownerValue ||
      prevState.proxyStatus !== this.state.proxyStatus ||
      prevState.searchFromParent !== this.state.searchFromParent ||
      prevState.range !== this.state.range
    ) {
      this.showMessage(this.i18n.t("proxy.updatingData"), Base.Message.loading);
      this.loadProxyList();

      this.setRouterQuery({
        mobile: this.state.mobile,
        level: this.state.level.id,
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
        range: this.state.range
          ? this.state.range.map(
              (d) => `${d.year()}-${d.month() + 1}-${d.date()}`
            )
          : [],
      });
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-proxy-list">
        {this._renderFilters()}
        {this._renderListTitle()}
        {this._renderListPagination()}
        {this._renderListItems()}
        {this._renderListPagination()}
        {this._renderModals()}
      </Layout>
    );
  }

  _renderFilters() {
    return (
      <Row className="filter-container">
        <Col span={16}>
          {this._renderFiltersMobile()}
          {this._renderFiltersLevel()}
          {this._renderFiltersOwner()}
          {this._renderFiltersProxyStatus()}
          {this._renderFiltersDate()}
        </Col>
        <Col span={8}>
          <Button type="primary" onClick={this.onProxyAddClick}>
            {this.i18n.t("proxy.addProxy")}
          </Button>
        </Col>
      </Row>
    );
  }

  _renderFiltersMobile() {
    let inputSuffix = <span />;
    if (this.state.mobile) {
      inputSuffix = (
        <CloseCircleOutlined onClick={this.handleFilterMobileClear} />
      );
    }

    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("proxy.filterMobile")}</span>
        <Input
          placeholder={this.i18n.t("proxy.filterMobileHint")}
          suffix={inputSuffix}
          value={this.state.tmpMobile}
          onChange={this.handleFilterMobileChange}
          onPressEnter={this.handleFilterMobileEnter}
        />
        <span className="label">
          <Checkbox
            checked={this.state.searchFromParent}
            onChange={this.onCheckChange}
          />
          <span style={{ margin: "5px" }}>
            {this.i18n.t("proxy.searchFromParent")}
          </span>
        </span>
      </div>
    );
  }

  _renderFiltersLevel() {
    let levels = this.state.levels;

    if (!levels || levels.length <= 0) {
      return null;
    }

    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("proxy.filterLevel")}</span>
        <Radio.Group
          value={`${this.state.level.id}`}
          onChange={this.handleFilterLevelChange}
        >
          {levels.map((item, index) => {
            return (
              <Radio key={`level-${index}`} value={`${item.id}`}>
                {this.i18n.getPropNameByLocale(item, "name")}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersOwner() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("proxy.filterOwner")}</span>
        <Radio.Group
          value={this.state.ownerValue}
          onChange={this.handleFilterOwnerChange}
        >
          {this.getOwners().map((item, index) => {
            return (
              <Radio key={`level-${index}`} value={item.value}>
                {item.name}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersProxyStatus() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("proxy.filterStatus")}</span>
        <Radio.Group
          value={this.state.proxyStatus}
          onChange={this.handleFilterStatusChange}
        >
          {this.getProxyStatus().map((item, index) => {
            return (
              <Radio key={`level-${index}`} value={item.value}>
                {item.name}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersDate() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("proxy.registerDate")}</span>
        <RangePicker onChange={this.onChangeRange} value={this.state.range} />
      </div>
    );
  }

  _renderListTitle() {
    let data = this.state.data;
    let users = data ? data.users : null;
    let count = users ? users.total : 0;

    return (
      <div className="list-title">
        {this.i18n.t("proxy.listTitle", { count: count })}
      </div>
    );
  }

  _renderListItems() {
    let data = this.state.data;
    const { user } = this.props;
    if (data) {
      let users = data ? data.users : null;
      let usersData = users ? users.data : null;
      if (usersData && usersData.length > 0) {
        let spinning =
          this.state.loadingData ||
          this.state.addingProxy ||
          this.state.updatingProxy ||
          this.state.deletingProxy;

        return (
          <Table
            dataSource={usersData}
            loading={spinning}
            rowKey={(record) => `${record.id}`}
            pagination={false}
            columns={[
              {
                title: this.i18n.t("users.avatar"),
                key: "avatar",
                dataIndex: "avatar",
                render: (text, record) => (
                  <Avatar size="large" src={record.avatar} />
                ),
              },
              {
                title: this.i18n.t("users.name"),
                key: "nickname",
                width: 100,
                render: (text, record, index) => {
                  return (
                    <span
                      style={{
                        display: "block",
                        width: "80px",
                      }}
                    >
                      {record.nickname}
                    </span>
                  );
                },
                dataIndex: "nickname",
                align: "center",
              },
              {
                title: this.i18n.t("proxy.listItemMobile"),
                key: "mobile",
                dataIndex: "mobile",
                render: (text, record, index) => {
                  return record.mobile;
                },
                align: "center",
              },
              {
                title: this.i18n.t("proxy.listItemLevel"),
                key: "level",
                dataIndex: "level",
                align: "center",
                render: (text, record) => this.getProxyLevel(record),
              },
              {
                title: this.i18n.t("proxy.listItemPercent"),
                key: "commission_percent",
                dataIndex: "commission_percent",
                render: (text, record) => `${record.commission_percent}%`,
              },
              {
                title: this.i18n.t("proxy.listItemParent"),
                key: "parent_mobile",
                dataIndex: "parent_mobile",
                align: "center",
                render: (text, record) =>
                  record.parent_mobile ||
                  this.i18n.t("proxy.listItemParentNone"),
              },
              {
                title: this.i18n.t("proxy.listProxyTeam"),
                key: "teamCount",
                dataIndex: "team_count",
                align: "center",
                responsive: ["xxl"],
                render: (text, record) =>
                  record.team_count || this.i18n.t("proxy.listItemParentNone"),
              },
              {
                title: this.i18n.t("proxy.listProxyClient"),
                key: "clientCount",
                dataIndex: "client_count",
                align: "center",
                responsive: ["xxl"],
                render: (text, record) =>
                  record.client_count ||
                  this.i18n.t("proxy.listItemParentNone"),
              },
              {
                title: this.i18n.t("proxy.listProxyOrders"),
                key: "ordersCount",
                dataIndex: "orders_count",
                align: "center",
                responsive: ["xxl"],
                render: (text, record) =>
                  record.orders_count ||
                  this.i18n.t("proxy.listItemParentNone"),
              },
              {
                title: this.i18n.t("proxy.filterStatus"),
                key: "status",
                align: "center",
                render: (text, record) => {
                  if (record.status === 1) {
                    return (
                      <Tag color="green">{this.i18n.t("proxy.reviewed")}</Tag>
                    );
                  }
                  return (
                    <Tag color="red">{this.i18n.t("proxy.reviewing")}</Tag>
                  );
                },
              },
              {
                title: this.i18n.t("proxy.registerDate"),
                key: "registerDate",
                align: "center",
                responsive: ["xl"],
                render: (text, record) =>
                  Array.isArray(record.level_change_log) &&
                  record.level_change_log.length > 0
                    ? record.level_change_log[0].change_at
                    : this.i18n.t("proxy.listItemParentNone"),
              },
              {
                title: this.i18n.t("users.actions"),
                key: "actions",
                align: "center",
                render: (text, item) => (
                  <div className="card-item-actions">
                    <Button
                      type="link"
                      onClick={() => this.onProxyItemEditClick(item)}
                    >
                      <EditOutlined />
                      {this.i18n.t("proxy.listItemEdit")}
                    </Button>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      onClick={() => this.onProxyItemDeleteClick(item)}
                      danger
                    >
                      <DeleteOutlined />
                      {this.i18n.t("proxy.listItemDelete")}
                    </Button>
                    <Divider type="vertical" />
                    <TopupInput
                      userId={item.user_id}
                      fetchList={() => this.loadProxyList()}
                      isUserList={user.merchant && user.merchant.has_agents}
                    />
                  </div>
                ),
              },
            ]}
          />
        );
      } else {
        return (
          <div className="list-message">{this.i18n.t("proxy.listNoData")}</div>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <div className="list-message">{this.i18n.t("proxy.loadingData")}</div>
        );
      }

      return (
        <div className="list-message">{this.i18n.t("proxy.listNoData")}</div>
      );
    }
  }

  _renderListPagination() {
    let data = this.state.data;
    let users = data ? data.users : null;
    if (users && users.data && users.data.length > 0) {
      return (
        <div className="list-pagination">
          <Pagination
            total={users.total}
            current={this.state.currentPage}
            showSizeChanger={true}
            pageSizeOptions={["12", "24", "36"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
            showQuickJumper
          />
        </div>
      );
    } else {
      return null;
    }
  }

  _renderModals() {
    let items = [];

    items.push(this._renderEditModal());

    return items;
  }

  _renderEditModal() {
    if (!this.state.showEditModal) return null;

    let levels = Object.assign([], this.state.levels);
    if (!levels || levels.length <= 0) {
      return null;
    }

    let confirmLoading = this.state.addingProxy || this.state.updatingProxy;

    return (
      <ProxyEditModal
        key="proxy-edit-modal"
        levels={levels}
        proxy={this.state.editModalProxy}
        onCancel={this.handleEditModalCancel}
        onConfirm={this.handleEditModalConfirm}
        confirmLoading={confirmLoading}
      />
    );
  }

  /* user methods */

  handleFilterMobileChange = (e) => {
    let value = e.target.value;

    this.setState({ tmpMobile: value });
  };

  handleFilterMobileEnter = () => {
    if (this.state.mobile === this.state.tmpMobile) return;
    this.setState({ mobile: this.state.tmpMobile, currentPage: 1 });
  };

  handleFilterMobileClear = () => {
    this.setState({ tmpMobile: "", mobile: "", currentPage: 1 });
  };

  handleFilterLevelChange = (e) => {
    let levels = this.state.levels;
    if (!levels || levels.length <= 0) return;

    let level = levels.find((item) => `${item.id}` === e.target.value);
    this.setState({ level: level, currentPage: 1 });
  };

  handleFilterOwnerChange = (e) => {
    const ownerValue = this.getOwners().find(
      (item) => item.value === e.target.value
    );
    this.setState({ ownerValue: ownerValue.value, currentPage: 1 });
  };

  handleFilterStatusChange = (e) => {
    const proxyStatus = this.getProxyStatus().find(
      (item) => item.value === e.target.value
    );
    this.setState({ proxyStatus: proxyStatus.value, currentPage: 1 });
  };
  onCheckChange = (e) => {
    console.log(e.target, "fromagent");
    this.setState({ searchFromParent: e.target.checked });
  };
  onProxyAddClick = () => {
    if (!this.state.levels) return;

    this.setState({
      showEditModal: true,
      editModalProxy: null,
    });
  };

  onProxyItemEditClick = (item) => {
    if (!this.state.levels) return;

    this.setState({
      showEditModal: true,
      editModalProxy: item,
    });
  };

  onProxyItemDeleteClick = (item) => {
    if (!item) return;

    this.showModal(this.i18n.t("proxy.deleteHint"), Base.Modal.confirm, () => {
      this.showMessage(this.i18n.t("proxy.deleting"), Base.Message.loading);
      this.deleteProxy(item);
    });
  };

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  handleEditModalCancel = () => {
    this.setState({
      showEditModal: false,
      editModalProxy: null,
    });
  };

  handleEditModalConfirm = (values, update = false) => {
    if (!values) return;

    if (update) {
      this.showMessage(this.i18n.t("proxy.updating"), Base.Message.loading);
      this.updateProxy(values);
    } else {
      this.showMessage(this.i18n.t("proxy.adding"), Base.Message.loading);
      this.addProxy(values);
    }
  };

  onChangeRange = (date) => {
    this.setState({ range: date });
  };

  /* server methods */

  async loadProxyList() {
    if (this.state.loadingData) return;

    let token = this.getUserToken();
    let user = this.getUser();
    if (!token) return;

    let { range } = this.state;
    if (range < 2) {
      range = [0, 0];
    } else {
      range = range.map((d) => `${d.year()}-${d.month()}-${d.date()}`);
    }
    let api = this.api.proxy.list;
    let apiConfig = {
      token,
      query: {
        "filter[mobile]": this.state.mobile,
        "filter[parent]": this.state.searchFromParent,
        "filter[level]": this.getApiLevelAPi(),
        "filter[group_owner]": this.state.ownerValue,
        "filter[status]": this.state.proxyStatus,
        "filter[start_date]": range[0],
        "filter[end_date]": range[1],
        page: this.state.currentPage,
        count: this.state.currentPageSize,
        merchant_id: user.merchant.id,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        let levels = this.getAllLevels(res.data.levels);
        let level = this.getDefaultLevel(levels);
        let range = this.getDefaultRange();
        this.setState({ data: res.data, levels, level, range });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ loadingData: false });
    }
  }

  addProxy = async (values) => {
    if (!values) return;

    if (!values) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.proxy.add;
    let apiConfig = {
      token: token,
      data: values,
    };

    this.setState({ addingProxy: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.setState({
          showEditModal: false,
          editModalProxy: null,
        });

        this.showMessage(
          this.i18n.t("proxy.updatingData"),
          Base.Message.loading
        );
        this.loadProxyList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ addingProxy: false });
    }
  };

  updateProxy = async (values) => {
    if (!values) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.proxy.update;
    let apiConfig = {
      token: token,
      path: {
        proxyId: values.proxyId,
      },
      data: values,
    };

    this.setState({ updatingProxy: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.setState({
          showEditModal: false,
          editModalProxy: null,
        });

        this.showMessage(
          this.i18n.t("proxy.updatingData"),
          Base.Message.loading
        );
        this.loadProxyList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ updatingProxy: false });
    }
  };

  deleteProxy = async (proxy) => {
    if (this.state.deletingProxy) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.proxy.delete;
    let apiConfig = {
      token: token,
      path: {
        proxyId: proxy.id,
      },
    };

    this.setState({ deletingProxy: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.showMessage(
          this.i18n.t("proxy.updatingData"),
          Base.Message.loading
        );
        this.loadProxyList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ deletingProxy: false });
    }
  };

  /* custom methods */

  getOwners() {
    return [
      { name: this.i18n.t("proxy.all"), value: -1 },
      { name: this.i18n.t("proxy.owner"), value: 1 },
      { name: this.i18n.t("proxy.notOwner"), value: 0 },
    ];
  }

  getProxyStatus() {
    return [
      { name: this.i18n.t("proxy.all"), value: -1 },
      { name: this.i18n.t("proxy.reviewing"), value: 0 },
      { name: this.i18n.t("proxy.reviewed"), value: 1 },
    ];
  }

  getDefaultMobile() {
    let mobile = "";

    let queryMobile = this.getFromRouterQuery("mobile");
    if (queryMobile) {
      mobile = queryMobile;
    }

    return mobile;
  }

  getApiLevelAPi() {
    let level = this.state.level;
    if (level) {
      return level.id;
    }

    let queryLevel = this.getFromRouterQuery("level");
    if (queryLevel) {
      return queryLevel;
    }

    return -1;
  }

  getAllLevels(arr) {
    let levels = this.state.levels;

    if (!levels) {
      levels = [
        {
          commission_percent: 0,
          id: -1,
          parent_commission_percent: 0,
          ug_name: "ھەمىىسى",
          zh_name: "全部",
        },
      ].concat(arr);
    }

    return levels;
  }

  getDefaultLevel(arr) {
    let level = this.state.level;

    if (!level) {
      let queryLevel = this.getFromRouterQuery("level");
      if (queryLevel) {
        level = arr.find((item) => `${item.id}` === queryLevel);
      }

      if (!level) {
        level = arr[0];
      }
    }

    return level;
  }

  getDefaultRange() {
    let range = this.state.range;

    if (!range || range.length) {
      const rangeLevel = this.getFromRouterQuery("range");
      if (rangeLevel) {
        range = rangeLevel.map((date) => moment(date));
      }
    }

    return range;
  }

  getProxyLevel(proxy) {
    let levels;
    if (this.state.levels) {
      levels = Object.assign([], this.state.levels);
      levels.shift();
    }

    if (proxy && levels) {
      let level = levels.find((item) => item.id === proxy.level);
      if (level) {
        return this.i18n.getPropNameByLocale(level, "name");
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(ProxyList);
