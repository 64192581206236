export default {
  list: {
    name: "coupons list api",
    version: 6,
    url: "coupon",
    method: "GET",
  },
  add: {
    name: "add coupons api",
    version: 6,
    url: "coupon",
    method: "POST",
  },
  update: {
    name: "update coupons api",
    version: 6,
    url: "coupon/%{id}",
    method: "PUT",
  },
  updateStatus: {
    name: "update coupons api",
    version: 6,
    url: "coupon/%{id}/update-status",
    method: "PUT",
  },
  delete: {
    name: "delete coupons api",
    version: 6,
    url: "coupon/%{id}",
    method: "DELETE",
  },
  send: {
    name: "send coupons api",
    version: 6,
    url: "coupon/%{id}/distribute",
    method: "POST",
  },
  users: {
    name: "get coupons user api",
    version: 6,
    url: "users",
    method: "GET",
  },
  distributedList: {
    name: "get coupons distributed list api",
    version: 6,
    url: "coupon/%{id}/distributed-list",
    method: "GET",
  },
};
