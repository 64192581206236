import I18n from "react-rn-localize";

import { Logger } from "../";

import zhCN from "./zh-CN";
import ugCN from "./ug-CN";

class Locale {
  static Locales = {
    zh: { locale: "zh", name: "selectLang.zh", rtl: false },
    ug: { locale: "ug", name: "selectLang.ug", rtl: true },
  };

  static initialized = false;
  static async initialize() {
    Logger.getInstance().log(`util ${Locale.name} initializing ...`);

    I18n.setTranslations({ zh: zhCN, ug: ugCN });
    let defaultLocale = Locale._getWindowLocale();
    I18n.setLocale(defaultLocale);
    Locale.initialized = true;

    Logger.getInstance().log(
      `util ${Locale.name} initialized with ${defaultLocale}`
    );
  }

  static _getWindowLocale() {
    let locale = Locale.Locales.zh.locale;

    if (window.locale) {
      if (window.locale.indexOf("ug") !== -1) {
        locale = Locale.Locales.ug.locale;
      }
    }

    return locale;
  }

  static locale = null;
  static getInstance() {
    if (!Locale.locale) Locale.locale = new Locale();
    return Locale.locale;
  }

  static getLocale() {
    return I18n.getLocale();
  }

  constructor() {
    if (!Locale.initialized) {
      Locale.initialize();
    }
  }

  t(key, replacements = {}) {
    return I18n.t(key, replacements);
  }

  getPropNameByLocale(obj, prop) {
    if (!obj) return null;

    let locale = Locale.getLocale();

    let value = obj[prop];
    if (value) {
      if (locale === Locale.Locales.zh.locale && obj[`zh_${prop}`]) {
        value = obj[`zh_${prop}`];
      }
    } else {
      value = obj[`ug_${prop}`];
      if (locale === Locale.Locales.zh.locale && obj[`zh_${prop}`]) {
        value = obj[`zh_${prop}`];
      }
    }
    return value;
  }

  getThemeNameByLocale(obj, prop) {
    if (!obj) return null;
    if (obj[prop].zh === null) {
      obj[prop].zh = "";
    }
    if (obj[prop].ug === null) {
      obj[prop].ug = "";
    }

    let locale = Locale.getLocale();

    let value = obj[prop];
    if (value) {
      if (locale === Locale.Locales?.zh.locale && obj[`${prop}`]?.zh) {
        value = obj[`${prop}`]?.zh;
      }
    } else {
      value = obj[`${prop}`]?.ug;
      if (locale === Locale.Locales?.zh.locale && obj[`${prop}`]?.zh) {
        value = obj[`${prop}`]?.zh;
      }
    }
    return value;
  }

  isLocaleRTL() {
    let locale = Locale.getLocale();
    if (locale) {
      if (locale === Locale.Locales.ug.locale) return true;
    }
    return false;
  }

  getCurrentLocale() {
    let locale = Locale.Locales.zh;

    let localeKey = Locale.getLocale();
    if (localeKey === Locale.Locales.ug.locale) {
      locale = Locale.Locales.ug;
    }

    return locale;
  }

  getAvailableLocales() {
    let arr = [];

    let keys = Object.keys(Locale.Locales);
    let currentKey = Locale.getLocale();
    for (let i = 0; i < keys.length; i++) {
      let locale = Locale.Locales[keys[i]];
      if (locale.locale !== currentKey) {
        arr.push(locale);
      }
    }

    return arr;
  }
}

export default Locale;
