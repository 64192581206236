import React, { useState, useEffect, useRef } from "react";
import { Table, message, Avatar, Button, Tag, Space, Radio } from "antd";
import { useSelector } from "react-redux";
import { ApiManager, Locale } from "../../../utils";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import EditGiftForm from "./EditGiftForm";
import Base from "../../Base";
import { FILTER_STATUS } from "../../../constants";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const base = Base.getInstance();

function GiftList() {
  const getListData = useRef();
  const [listData, setListData] = useState({ data: [] });
  const [currentGift, setCurrentGift] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(FILTER_STATUS.ALL.value);
  const user = useSelector((state) => getUserInfo(state));

  getListData.current = async (page = 1) => {
    const apiUrl = api.gift.list;
    const { token } = user;
    const apiConfig = {
      token,
      query: { "filter[status]": filterStatus, page, count: 10 },
    };
    if (token) {
      setLoading(true);
      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setListData(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const onClickAdd = () => {
    setCurrentGift({ id: -1 });
  };

  const onClickCancel = () => {
    setCurrentGift(null);
  };

  const onDeleteGift = (id) => {
    base.showModal(
      i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const { token } = user;
        if (token) {
          const apiUrl = api.gift.delete;
          const apiConfig = {
            token,
            path: { id },
          };
          try {
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              getListData.current();
            } else {
              base.showMessage(res.message);
            }
          } catch (error) {
            base.handleApiError(api, error);
          }
        }
      }
    );
  };

  useEffect(() => {
    getListData.current();
  }, [filterStatus]);

  const columns = [
    {
      title: i18n.t("shows.type.image"),
      key: "image",
      render: (text, record) => (
        <Avatar
          shape="square"
          size="large"
          src={i18n.getPropNameByLocale(record, "image")}
        />
      ),
    },
    {
      title: i18n.t("sales.gift.gift"),
      key: "name",
      render: (text, record) => i18n.getPropNameByLocale(record, "name"),
    },
    {
      title: i18n.t("sales.gift.type"),
      key: "type",
      render: (text, record) => {
        if (record.type === 0) {
          return (
            <Tag color="blue">
              {i18n.t("sales.gift.order")}￥{record.order_amount / 100}
            </Tag>
          );
        }
        return <Tag color="green">{i18n.t("sales.gift.product")}</Tag>;
      },
    },
    {
      title: i18n.t("financeHome.listHeaderDate"),
      key: "date",
      align: "center",
      render: (text, record) => {
        return `${record.start_time}~${record.end_time}`;
      },
    },
    {
      title: i18n.t("users.actions"),
      key: "actions",
      align: "center",
      render: (text, record) => {
        return (
          <Space direction="horizontal">
            <Button type="link" onClick={() => setCurrentGift(record)}>
              {i18n.t("product.productItem.actionEdit")}
            </Button>
            <Button type="link" danger onClick={() => onDeleteGift(record.id)}>
              {i18n.t("product.productItem.actionDelete")}
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <Space direction="horizontal">
        <Button
          type="primary"
          style={{ marginBottom: 18 }}
          onClick={onClickAdd}
        >
          {i18n.t("sales.gift.add")}
        </Button>
        <Radio.Group
          style={{ marginRight: 20, marginLeft: 20, marginBottom: 18 }}
          onChange={(e) => {
            setFilterStatus(e.target.value);
          }}
          value={filterStatus}
        >
          <Radio value={FILTER_STATUS.ALL.value}>
            {FILTER_STATUS.ALL.name}
          </Radio>
          <Radio value={FILTER_STATUS.OPENED.value}>
            {FILTER_STATUS.OPENED.name}
          </Radio>
          <Radio value={FILTER_STATUS.CLOSED.value}>
            {FILTER_STATUS.CLOSED.name}
          </Radio>
        </Radio.Group>
      </Space>
      <Table
        loading={loading}
        dataSource={listData.data}
        rowKey={(record) => record.id}
        columns={columns}
        pagination={{
          current: listData.current_page,
          onChange: (page) => getListData.current(page),
          total: listData.total,
          pageSize: 10,
          showQuickJumper: true,
        }}
      />
      <EditGiftForm
        currentGift={currentGift}
        onCancel={onClickCancel}
        onGetList={getListData.current}
      />
    </>
  );
}

export default GiftList;
