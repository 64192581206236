import React from "react";
import { message, Modal } from "antd";

import { ApiManager, Locale, Logger, Moment, Util } from "../utils";
import { JD_ID, GROUPON_ID, SUPERMARKET_ID } from "../constants";

class Base extends React.Component {
  /* lifecycle methods */

  static getInstance() {
    if (!Base.base) Base.base = new Base();
    return Base.base;
  }

  constructor(props) {
    super(props);

    this.logger = Logger.getInstance();
    this.i18n = Locale.getInstance();
    this.api = ApiManager.getInstance();
    this.moment = Moment.getInstance();
    this.util = Util.getInstance();

    this.state = {
      previewImages: null,
      previewImageIndex: 0,
      isImg: true,
    };
  }

  componentDidMount() {
    this.mounted = true;

    message.config({
      top: 56,
      duration: 2,
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.mounted = false;
  }

  /* message methods */

  static Message = {
    success: "sucess",
    error: "error",
    info: "info",
    warning: "warning",
    loading: "loading",
  };

  showMessage(content = "", type = Base.Message.info) {
    switch (type) {
      case Base.Message.success: {
        message.success(content);
        break;
      }
      case Base.Message.error: {
        message.error(content);
        break;
      }
      case Base.Message.info: {
        message.info(content);
        break;
      }
      case Base.Message.warning: {
        message.warning(content);
        break;
      }
      case Base.Message.loading: {
        message.loading(content);
        break;
      }
      default: {
        message.info(content);
        break;
      }
    }
  }

  /* modal methods */

  static Modal = {
    success: "sucess",
    error: "error",
    info: "info",
    warning: "warning",
    confirm: "confirm",
  };

  showModal(title, type = Base.Modal.info, onOk, custom = {}) {
    this.hideModal();

    switch (type) {
      case Base.Modal.success: {
        let config = {
          title: title || this.i18n.t("base.modal.successTitle"),
          okText: this.i18n.t("base.modal.knowText"),
          onOk: onOk,
        };

        this.modal = Modal.success({ ...config, ...custom });

        break;
      }
      case Base.Modal.error: {
        let config = {
          title: title || this.i18n.t("base.modal.errorTitle"),
          okText: this.i18n.t("base.modal.knowText"),
          onOk: onOk,
        };

        this.modal = Modal.error({ ...config, ...custom });

        break;
      }
      case Base.Modal.warning: {
        let config = {
          title: title || this.i18n.t("base.modal.warningTitle"),
          okText: this.i18n.t("base.modal.knowText"),
          onOk: onOk,
        };

        this.modal = Modal.warning({ ...config, ...custom });

        break;
      }
      case Base.Modal.confirm: {
        let config = {
          title: title || this.i18n.t("base.modal.confirmTitle"),
          okText: this.i18n.t("base.modal.okText"),
          cancelText: this.i18n.t("base.modal.cancelText"),
          onOk: onOk,
        };

        this.modal = Modal.confirm({ ...config, ...custom });

        break;
      }
      case Base.Modal.info:
      default: {
        let config = {
          title: title || this.i18n.t("base.modal.infoTitle"),
          okText: this.i18n.t("base.modal.knowText"),
          onOk: onOk,
        };

        this.modal = Modal.info({ ...config, ...custom });

        break;
      }
    }
  }

  hideModal() {
    if (this.modal) this.modal.destroy();
  }

  renderImagePreviewModal() {
    let images = this.state.previewImages;

    if (images) {
      let image = images[this.state.previewImageIndex];
      return (
        <Modal
          className="route-base-image-preview-modal"
          key="route-base-image-preview-modal"
          title={false}
          footer={null}
          visible={true}
          closable={false}
          onCancel={this.onImagePreviewModalCancel}
        >
          {this.state.isImg ? (
            <img alt="" src={image.url || image.thumbUrl} />
          ) : (
            <video controls src={image.url || image.thumbUrl} />
          )}
        </Modal>
      );
    }

    return null;
  }

  onImagePreviewModalCancel = () => {
    this.setState({ previewImages: null, previewImageIndex: 0 });
  };

  /* router methods */

  goToLogin() {
    if (this.props && this.props.router) {
      this.props.router.replace({
        pathname: "login",
        state: {
          from: this.props.location,
        },
      });
    }
  }

  setRouterQuery(queries = {}) {
    let route = this.props.location;
    route.query = { ...route.query, ...queries };
    this.props.router.replace(route);
  }

  getFromRouterQuery(key) {
    let query = this.props.location.query;
    return query ? query[`${key}`] : null;
  }

  getFromRouterState(key) {
    let state = this.props.location.state;
    return state ? state[key] : null;
  }

  static QueryValueType = {
    String: "string",
    Integer: "integer",
    Boolean: "boolean",
  };

  getRouterQueryValueByKey(key = "", valueType = Base.QueryValueType.String) {
    let value = this.getFromRouterQuery(key);
    if (value) {
      switch (valueType) {
        case Base.QueryValueType.Integer: {
          value = parseInt(value, 10);
          break;
        }
        case Base.QueryValueType.Boolean: {
          value = value === "true";
          break;
        }
        case Base.QueryValueType.String:
        default: {
          break;
        }
      }
    }
    return value;
  }

  getRouterQueryItemByKey(arr = [], key = "", defaultValue = 0) {
    let item = this.getFromRouterQuery(key);
    if (item) {
      item = arr.find((itm) => itm.key === item);
      if (!item) item = arr[defaultValue];
    } else {
      item = arr[defaultValue];
    }
    return item ? item : {};
  }

  getRouterQueryTimeRange() {
    let rangeTime = null;
    let queryTime = this.getFromRouterQuery("timeRange");
    if (queryTime) {
      let timeArr = queryTime.split("~");
      if (timeArr[1]) {
        let startTime = this.moment(timeArr[0]);
        let endTime = this.moment(timeArr[1]);
        if (
          startTime.isValid() &&
          endTime.isValid() &&
          startTime.diff(endTime) <= 0
        ) {
          rangeTime = [startTime, endTime];
        }
      }
    }
    return rangeTime;
  }

  getRouterQueryPage() {
    let page = this.getFromRouterQuery("page");
    if (page && !isNaN(parseInt(page, 10))) {
      page = parseInt(page, 10);
    } else {
      page = 1;
    }
    return page;
  }

  getRouterQueryKeyword() {
    let keyword = this.getFromRouterQuery("keyword");
    if (!keyword) {
      return "";
    }
    return keyword;
  }

  getRouterQuerySearchType() {
    let type = this.getFromRouterQuery("searchType");
    if (!type) {
      return "name";
    }
    return type;
  }

  getRouterQueryStartPrice() {
    const price = this.getFromRouterQuery("price_start");
    if (!price) {
      return "";
    }
    return price;
  }

  getRouterQueryEndPrice() {
    const price = this.getFromRouterQuery("price_end");
    if (!price) {
      return "";
    }
    return price;
  }

  getRouterQueryPageSize(defaultValue = this.isJdShop() ? 20 : 10) {
    let pageSize = this.getFromRouterQuery("pageSize");
    if (pageSize && !isNaN(parseInt(pageSize, 10))) {
      pageSize = parseInt(pageSize, 10);
    } else {
      pageSize = defaultValue;
    }
    return pageSize;
  }

  isRouterLbsRelated() {
    const routes = ["/delivery"];
    let pathname = this.props.location.pathname;
    return routes.some((item) => pathname.startsWith(item));
  }

  isRouterProxyRelated() {
    const routes = ["/proxy", "/balance"];
    let pathname = this.props.location.pathname;
    return routes.some((item) => pathname.startsWith(item));
  }

  isRouterWeappRelated() {
    const routes = [
      "/mini-program",
      "/users",
      "/shows",
      "/manage-ads/splash",
      "/manage-ads/home-ad",
      "/live",
    ];
    let pathname = this.props.location.pathname;
    return routes.some((item) => pathname.startsWith(item));
  }

  isRouterALRelated() {
    const routes = ["/article"];
    let pathname = this.props.location.pathname;
    return routes.some((item) => pathname.startsWith(item));
  }

  isRouterLotteryRelated() {
    const routes = ["/lottery/list", "/red-packet"];
    let pathname = this.props.location.pathname;
    return routes.some((item) => pathname.startsWith(item));
  }

  isRouterMembersRelated() {
    let pathname = this.props.location.pathname;
    return pathname.includes("members");
  }

  isRouteWarehouseRelated(pathname = null) {
    const routes = ["/order", "/product", "/review", "/setting"];

    if (!pathname) {
      pathname = this.props.location.pathname;
    }

    return routes.some((item) => pathname.startsWith(item));
  }

  /* redux methods */

  getUser() {
    return this.props.user;
  }

  getUserToken() {
    let user = this.getUser();
    return user ? user.token : null;
  }

  getUserRefreshToken() {
    let user = this.getUser();
    return user ? user.refresh_token : null;
  }

  getMerchantBusinessStatus() {
    let user = this.getUser();
    let merchant = user ? user.merchant : null;
    return merchant ? merchant.status : 0;
  }

  isMerchantInBusinuess() {
    return this.getMerchantBusinessStatus() === 1;
  }
  merchantHasCommission() {
    if (
      this.isBBShopMerchant() ||
      this.isAdminWithDabazaShop() ||
      (this.isThirdPartyShopHasWeapp() && this.shopHasAgents())
    ) {
      return true;
    }
    return false;
  }

  isBBShopMerchant(merchant = null) {
    let user = this.getUser();
    merchant = user ? user.merchant : null;
    if (merchant) {
      return [SUPERMARKET_ID, JD_ID, GROUPON_ID].includes(merchant.id);
    }
    return false;
  }

  isArticleEnabled() {
    const user = this.getUser();
    return user && user.merchant && user.merchant.enabled_article;
  }

  isLotteryEnabled() {
    const user = this.getUser();
    return user && user.merchant && user.merchant.enabled_lottery;
  }

  isMembersEnabled() {
    const { settings } = this.props;
    return settings && settings.enable_membership;
  }

  isDabazaShop() {
    const user = this.getUser();
    return (
      (user.merchant && user.merchant.is_dabaza_shop) ||
      user.merchant.type === 14
    );
  }

  isSoleLotteryWeapp() {
    const user = this.getUser();
    return (
      user &&
      user.merchant &&
      user.merchant.enabled_lottery &&
      user.merchant.type === 15
    );
  }

  isEnabledSpecifiedUsers() {
    const user = this.getUser();
    return user.merchant && user.merchant.enabled_specified_users;
  }

  isThirdPartyShop() {
    const user = this.getUser();
    return [12, 13, 15].includes(user.merchant.type);
  }

  isThirdPartyShopHasWeapp() {
    const user = this.getUser();
    return this.isThirdPartyShop() && user.merchant.has_weapp;
  }

  isLbsShop() {
    const user = this.getUser();
    return user.merchant && user.merchant.is_lbs;
  }

  shopHasAgents() {
    const user = this.getUser();
    return user.merchant && user.merchant.has_agents;
  }

  showExtraContent() {
    const user = this.getUser();
    return user.admin && this.isThirdPartyShop();
  }

  isAdminWithDabazaShop() {
    const user = this.getUser();
    return user.admin && this.isDabazaShop();
  }

  isJdShop() {
    const user = this.getUser();
    return user && user.merchant.id === JD_ID;
  }

  /* api methods */

  handleApiError(api, err) {
    if (!api || !err) return;

    this.logger.error(api.name, api, err);

    switch (err.status) {
      case 401: {
        this.onApi401Error(api, err);
        break;
      }
      case 408: {
        this.onApi408Error(api, err);
        break;
      }
      case 422: {
        this.onApi422Error(api, err);
        break;
      }
      case 429: {
        this.onApi429Error(api, err);
        break;
      }
      case 500: {
        this.onApi500Error(api, err);
        break;
      }
      case 503: {
        this.onApi503Error(api, err);
        break;
      }
      default: {
        this.onApiUknownError(api, err);
        break;
      }
    }
  }

  onApi401Error(api, err) {
    if (!api || !err) return;
    this.showModal(this.i18n.t("base.api.err401"), Base.Modal.warning, () => {
      this.goToLogin();
    });
  }

  onApi408Error(api, err) {
    if (!api || !err) return;

    this.showModal(this.i18n.t("base.api.err408"), Base.Modal.error);
  }

  onApi422Error(api, err) {
    if (!api || !err) return;

    let errors = err.data ? err.data : null;
    if (errors && errors.data) {
      errors = errors.data;
    }
    if (!errors) errors = err.data ? err.data.errors : null;

    let keys = errors ? Object.keys(errors) : null;
    if (keys && keys.length > 0) {
      let arr = [];
      for (let i = 0; i < keys.length; i++) {
        let value = errors[`${keys[i]}`];
        arr.push(value.toString());
      }

      let modalContent = (
        <div>
          {arr.map((item, index) => {
            return <h4 key={`err-${index}`}>{`${index + 1}. ${item}`}</h4>;
          })}
        </div>
      );
      this.showModal(
        this.i18n.t("base.api.err422Title"),
        Base.Modal.warning,
        () => {},
        {
          content: modalContent,
        }
      );
    } else {
      this.showModal(this.i18n.t("base.api.err422Empty"), Base.Modal.warning);
    }
  }

  onApi429Error(api, err) {
    if (!api || !err) return;

    this.showModal(this.i18n.t("base.api.err429"));
  }

  onApi500Error(api, err) {
    if (!api || !err) return;

    this.showModal(this.i18n.t("base.api.err500"));
  }

  onApi503Error(api, err) {
    if (!api || !err) return;

    this.showModal(this.i18n.t("base.api.err503"));
  }

  onApiUknownError(api, err) {
    if (!api || !err) return;

    this.showMessage(this.i18n.t("base.api.errUnknown"), Base.Message.error);
  }
}

export default Base;
