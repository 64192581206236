import React, { useEffect } from "react";

import {
  Modal,
  Input,
  Form,
  Upload,
  message,
  Button,
  InputNumber,
  Switch,
} from "antd";

import {
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Locale, Util } from "../../../utils";
import LinkSelector from "./LinkSelector";
import TranslateBtn from "../../../components/product/TranslateBtn";

const i18n = Locale.getInstance();
const util = Util.getInstance();

function onBeforeUpload(file) {
  if (file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error(i18n.t("productEdit.uploadImageSizeWarning"));
    }
    return isLt1M;
  }
  return false;
}

const EditAdForm = ({
  adData,
  onCancel,
  onFinish,
  onUploadImg,
  loading,
  type,
  tip,
  hasWeapp,
  isBBShopMerchant,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (adData && Object.keys(adData).length > 0) {
      const {
        zh_title,
        ug_title,
        order,
        data,
        zh_image,
        ug_image,
        status,
      } = adData;
      form.setFieldsValue({
        zh_title,
        ug_title,
        order: !Number.isNaN(Number(order)) ? Number(order) : 0,
        status: status === 1,
        zh_image: util.getDefaultImages(zh_image),
        ug_image: util.getDefaultImages(ug_image),
      });
      if (data && data.length > 0) {
        const [emptyString, prefix, type] = data.split("#");
        form.setFieldsValue({
          prefix: prefix ? `#${prefix}#` : "#show#",
          suffix: type,
          type,
        });
      }
    }
    return () => {
      form.resetFields();
    };
  }, [adData, form]);

  return (
    <Modal
      width={600}
      title={i18n.t("sales.ad.title")}
      visible={!!adData}
      onCancel={onCancel}
      footer={null}
      forceRender
    >
      <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.zhTitle")}
              name="zh_title"
              rules={[
                { required: true, message: i18n.t("sales.zhTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_title")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_title: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.ugTitle")}
              name="ug_title"
              rules={[
                { required: true, message: i18n.t("sales.ugTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_title")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_title: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item label={i18n.t("sales.ad.order")} name="order">
          <InputNumber
            style={{ width: "100%" }}
            max={99999}
            placeholder={i18n.t("sales.ad.orderPlace")}
          />
        </Form.Item>
        {[
          { label: i18n.t("sales.ad.zhImg"), name: "zh_image" },
          { label: i18n.t("sales.ad.ugImg"), name: "ug_image" },
        ].map((item) => (
          <Form.Item
            key={item.label}
            rules={[{ required: true, message: i18n.t("sales.ad.imgWarning") }]}
            label={item.label}
            name={item.name}
            valuePropName="fileList"
            getValueFromEvent={(e) => util.normFile4Img(e)}
            extra={i18n.t(`sales.ad.${tip}Tip`)}
          >
            <Upload
              accept="image/*"
              multiple={false}
              listType="picture"
              beforeUpload={onBeforeUpload}
              customRequest={onUploadImg}
            >
              <Button>
                <UploadOutlined /> {i18n.t("sales.upload")}
              </Button>
            </Upload>
          </Form.Item>
        ))}
        <LinkSelector
          type={type}
          hasWeapp={hasWeapp}
          isBBShopMerchant={isBBShopMerchant}
          onSelect={(value) => {
            form.setFieldsValue({ type: `${value}` });
          }}
        />
        <Form.Item
          name="status"
          label={i18n.t("sales.status")}
          valuePropName="checked"
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAdForm;
