export default {
  list: {
    name: "wechat-live list api",
    version: 6,
    url: "wechat-live",
    method: "GET",
  },
  url: {
    name: "wechat-live media url api",
    version: 6,
    url: "wechat-live/%{id}/url",
    method: "GET",
  },
  sync: {
    name: "sync wechat-live api",
    version: 6,
    url: "wechat-live-sync",
    method: "POST",
  },
  update: {
    name: "update wechat-live api",
    version: 6,
    url: "wechat-live/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete wechat-live api",
    version: 6,
    url: "wechat-live/%{id}",
    method: "DELETE",
  },
};
