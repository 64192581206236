export default {
  list: {
    name: "deliveryman list api",
    version: 6,
    url: "deliveryman",
    method: "GET",
  },
  add: {
    name: "add deliveryman api",
    version: 6,
    url: "deliveryman",
    method: "POST",
  },
  update: {
    name: "update deliveryman api",
    version: 6,
    url: "deliveryman/%{id}",
    method: "PUT",
  },
  bind: {
    name: "bind deliveryman api",
    version: 6,
    url: "bind-deliveryman/%{id}",
    method: "POST",
  },
  delete: {
    name: "delete deliveryman api",
    version: 6,
    url: "deliveryman/%{id}",
    method: "DELETE",
  },
};
