export const ACTIONS = {
  SET_MERCHANT_SETTINGS: "SET_MERCHANT_SETTINGS",
};

export const setMerchantSettings = (settings) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.SET_MERCHANT_SETTINGS,
    settings: settings,
  });
};
