export default {
	checkout: {
		name: 'checkout list api',
		version: 4,
		url: 'record',
		method: 'GET',
	},
	nonCheckout: {
		name: 'none checkout list api',
		version: 4,
		url: 'non-checkout-record',
		method: 'GET',
	},
	dailyCheckout: {
		name: 'daily checkout list api',
		version: 4,
		url: 'daily-record/%{id}',
		method: 'GET',
	},
	checkoutShow: {
		name: 'checkout show api',
		version: 4,
		url: 'record/%{id}',
		method: 'GET',
	},
	createExel: {
		name: 'create bill exel api',
		version: 4,
		url: 'bill',
		method: 'POST',
	},
	listExel: {
		name: 'list bill exel api',
		version: 4,
		url: 'bill',
		method: 'GET',
	},
	deleteExel: {
		name: 'delete bill exel api',
		version: 4,
		url: 'bill/%{id}',
		method: 'DELETE',
	},
}