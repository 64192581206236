export default {
    getMerchantInfo: {
        name: "get merchant info api",
        version: 6,
        url: "info",
        method: "GET",
    },
    addMerchantInfo: {
        name: "add merchant info api",
        version: 6,
        url: "info",
        method: "POST",
    },
    updateMerchantInfo: {
        name: "update merchant info api",
        version: 6,
        url: "info/%{id}",
        method: "PUT",
    },
    getMerchantAccountInfo: {
        name: "get merchant account info api",
        version: 6,
        url: "account/info",
        method: "GET",
    },
    updateMerchantAccountInfo: {
        name: "update merchant account info api",
        version: 6,
        url: "account/info/%{id}",
        method: "PUT",
    },

    getMerchantBasicInfo: {
        name: "get merchant basic info api",
        version: 6,
        url: "basic",
        method: "GET",
    },
    updateMerchantBasicInfo: {
        name: "update merchant basic info api",
        version: 6,
        url: "basic/%{id}",
        method: "PUT",

    },

}