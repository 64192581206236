import React from "react";

import { LeftOutlined, PlusOutlined } from "@ant-design/icons";

import { Layout, Divider, Button, Modal } from "antd";

import Base from "../Base";

import TicketEdit from "../../components/promotion/PromotionShopTicketEdit";

class PromotionShopTicket extends Base {
  /* lifecycle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      showEditModal: false,
      editModalTitle: "",
    };
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-promotion-shop-ticket">
        {this._renderHeader()}
        {this._renderContent()}
        {this._renderEditModal()}
      </Layout>
    );
  }

  _renderHeader() {
    return (
      <div className="header-wrap">
        <Button
          style={{ backgroundColor: "transparent", borderWidth: 0 }}
          className="back"
          onClick={this.onHeaderBackClick}
        >
          <LeftOutlined />
          返回
        </Button>
        <Divider type="vertical" />
        <span className="title">店内领券活动设置</span>
      </div>
    );
  }

  _renderContent() {
    return (
      <div className="content-wrap">
        {this._renderContentMain()}
        {this._renderContentDivider()}
        {this._renderContentExtra()}
        {this._renderContentBlank()}
      </div>
    );
  }

  _renderContentMain() {
    return (
      <div className="content-main">
        {this._renderContentMainAdvise()}
        {this._renderContentMainTickets()}
        {this._renderContentMainAction()}
      </div>
    );
  }

  _renderContentMainAdvise() {
    return (
      <div className="content-main-advise">
        <span className="title">活动设置建议</span>
        <span className="text">
          在开始之前，推荐先学习 React 和 ES2015，并正确安装和配置了 Node.js
          v6.5 或以上。 官方指南假设你已了解关于 HTML、CSS 和 JavaScript
          的中级知识，并且已经完全掌握了 React
          全家桶的正确开发方式。如果你刚开始学习前端或者 React，将 UI
          框架作为你的第一步可能不是最好的主意。
        </span>
      </div>
    );
  }

  _renderContentMainTickets() {
    return (
      <div className="content-main-tickets">
        {[1, 2].map((item, index) => {
          return (
            <div key={`ticket-item-${index}`} className="ticket-item">
              <div className="ticket-header">
                <span className="title">{`优惠券${index + 1}`}</span>
                <div className="actions">
                  <Button onClick={this.onTicketDistributeClick}>发放</Button>
                  <Button onClick={this.onTicketEditClick}>编辑</Button>
                  <Button onClick={this.onTicketDeleteClick}>删除</Button>
                </div>
              </div>
              <div className="ticket-content">
                <div className="main">
                  <div className="price">
                    <span className="unit">￥</span>
                    <span className="amount">15</span>
                  </div>
                  <div className="condition">满58可用</div>
                </div>
                <Divider type="vertical" />
                <div className="extra">
                  <div className="prop">新、老客可领</div>
                  <div className="prop">7天内有效</div>
                  <div className="prop">数量1000张</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  _renderContentMainAction() {
    return (
      <div className="content-main-action">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={this.onTicketAddClick}
        >
          添加优惠券
        </Button>
      </div>
    );
  }

  _renderContentDivider() {
    return <div className="content-divider"></div>;
  }

  _renderContentExtra() {
    return (
      <div className="content-extra">
        <p className="title">【店内领券】活动设置必读</p>
        <p className="question">1.什么是【店内领券】活动?</p>
        <p className="answer">
          我们使用 GitHub Issues 来做 bug 追踪。 如果你想要你发现的 bug
          被快速解决，最好的办法就是通过我们提供的 issue 小助手 来提
          issue。并且能使用这个 模板 来提供重现。在你报告一个 bug 之前，
          请先确保已经搜索过已有的 issue 和阅读了我们的 常见问题。
        </p>
        <p className="question">1.什么是【店内领券】活动?</p>
        <p className="answer">
          我们使用 GitHub Issues 来做 bug 追踪。 如果你想要你发现的 bug
          被快速解决，最好的办法就是通过我们提供的 issue 小助手 来提
          issue。并且能使用这个 模板 来提供重现。
        </p>
        <p className="error">
          *重要：在你报告一个 bug 之前，请先确保已经搜索过已有的 issue
          和阅读了我们的 常见问题。
        </p>
      </div>
    );
  }

  _renderContentBlank() {
    return <div className="content-blank"></div>;
  }

  _renderEditModal() {
    return (
      <Modal
        className="route-promotion-shop-ticket-edit-modal"
        visible={this.state.showEditModal}
        title={this.state.editModalTitle}
        onOk={this.onEditModalConfirm}
        onCancel={this.onEditModalCancel}
      >
        <TicketEdit />
      </Modal>
    );
  }

  /* user methods */

  onHeaderBackClick = () => {
    this.props.router.goBack();
  };

  onTicketDistributeClick = () => {};

  onTicketEditClick = () => {
    this.setState({ showEditModal: true, editModalTitle: "编辑优惠券1" });
  };

  onTicketDeleteClick = () => {};

  onTicketAddClick = () => {
    this.setState({ showEditModal: true, editModalTitle: "新建优惠券" });
  };

  onEditModalConfirm = () => {
    this.setState({ showEditModal: false, editModalTitle: null });
  };

  onEditModalCancel = () => {
    this.setState({ showEditModal: false, editModalTitle: null });
  };
}

export default PromotionShopTicket;
