import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Switch,
  message,
  Upload,
  Radio,
  InputNumber,
  Button,
  Avatar,
  List,
  DatePicker,
} from "antd";
import moment from "dayjs";
import { useForm } from "antd/lib/form/Form";
import { Util, Locale, ApiManager } from "../../../utils";
import { UploadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import ProductSelectList from "./ProductSelectList";

import Base from "../../Base";
import TranslateBtn from "../../../components/product/TranslateBtn";

const util = Util.getInstance();
const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const base = Base.getInstance();

function onBeforeUpload(file) {
  if (file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error(i18n.t("productEdit.uploadImageSizeWarning"));
    }
    return isLt1M;
  }
  return false;
}

function EditGiftForm({ currentGift, onCancel, onGetList, user }) {
  const [form] = useForm();
  const [products, setProducts] = useState({ items: [], byId: {} });
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [giftProducts, setGiftProducts] = useState({ items: [], byId: {} });

  const getFormattedDateString = (date) => {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  };

  const onUploadImg = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");

    const { token } = user;
    if (!token) onError("no user token");

    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "gift-pic",
    };

    try {
      const res = await api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  const onResetSelectedProducts = () => {
    setProducts({ items: [], byId: {} });
  };

  const onResetSelectedGiftProducts = () => {
    setGiftProducts({ items: [], byId: {} });
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        const { token } = user;
        if (token) {
          let apiUrl = api.gift.add;
          const {
            zh_image,
            ug_image,
            order_amount,
            status,
            range,
            ...otherValues
          } = values;
          let apiConfig = {
            token,
            data: {
              ...otherValues,
              status: status ? 1 : 0,
              start_time: getFormattedDateString(range[0]),
              end_time: getFormattedDateString(range[1]),
              gift_product_ids: giftProducts.items,
            },
          };

          if (values.type === "1" && products.items.length > 0) {
            apiConfig.data.products_ids = products.items;
          }
          if (zh_image[0] && zh_image[0].uid !== -1 && zh_image[0].response) {
            apiConfig.data.zh_image = zh_image[0].response.path;
          }
          if (ug_image[0] && ug_image[0].uid !== -1 && ug_image[0].response) {
            apiConfig.data.ug_image = ug_image[0].response.path;
          }
          if (order_amount) {
            apiConfig.data.order_amount = order_amount * 100;
          }

          if (currentGift.id !== -1) {
            apiUrl = api.gift.update;
            apiConfig.path = { id: currentGift.id };
          }

          try {
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              base.showMessage(res.message, Base.Message.success);
              onCancel();
              if (products.items.length > 0) {
                onResetSelectedProducts();
              }
              if (giftProducts.items.length > 0) {
                onResetSelectedGiftProducts();
              }
              onGetList();
            } else {
              base.showModal(res.message, Base.Modal.warning);
            }
          } catch (error) {
            base.handleApiError(apiUrl, error);
          }
        }
      })
      .catch((err) => {
        console.log("onFinish -> err", err);
      });
  };

  const unselectProduct = (productId) => {
    const otherById = util.omit(products.byId, [productId]);
    const otherItems = products.items.filter((id) => id !== productId);
    setProducts({
      items: otherItems,
      byId: otherById,
    });
  };

  const unselectGiftProduct = (productId) => {
    const otherById = util.omit(giftProducts.byId, [productId]);
    const otherItems = giftProducts.items.filter((id) => id !== productId);
    setGiftProducts({
      items: otherItems,
      byId: otherById,
    });
  };

  const getCurrentProducts = () => {
    if (showSelectModal === "product") {
      return products;
    }
    if (showSelectModal === "gift") {
      return giftProducts;
    }
  };

  const getCurrentSetProducts = () => {
    if (showSelectModal === "product") {
      return setProducts;
    }
    if (showSelectModal === "gift") {
      return setGiftProducts;
    }
  };

  useEffect(() => {
    if (!currentGift || currentGift.id === -1) {
      form.resetFields();
      onResetSelectedProducts();
      onResetSelectedGiftProducts();
    } else if (currentGift.id) {
      form.setFieldsValue({
        zh_name: currentGift.zh_name,
        ug_name: currentGift.ug_name,
        range: [moment(currentGift.start_time), moment(currentGift.end_time)],
        zh_image: util.getDefaultImages(currentGift.zh_image),
        ug_image: util.getDefaultImages(currentGift.ug_image),
        type: `${currentGift.type}`,
        order_amount:
          currentGift && currentGift.order_amount
            ? currentGift.order_amount / 100
            : 0,
        order: currentGift.order,
        status: currentGift.status === 1,
      });
      if (
        Array.isArray(currentGift.products) &&
        currentGift.products.length > 0
      ) {
        const byId = {};
        currentGift.products.forEach((p) => {
          byId[p.id] = p;
        });
        setProducts({
          items: currentGift.products.map((p) => p.id),
          byId,
        });
      }
      if (
        Array.isArray(currentGift.gift_products) &&
        currentGift.gift_products.length > 0
      ) {
        const byId = {};
        currentGift.gift_products.forEach((p) => {
          byId[p.id] = p;
        });
        setGiftProducts({
          items: currentGift.gift_products.map((p) => p.id),
          byId,
        });
      }
    }
  }, [currentGift, form]);

  return (
    <Modal
      title={
        currentGift && currentGift.id === -1
          ? i18n.t("sales.gift.add")
          : i18n.t("sales.gift.edit")
      }
      visible={currentGift}
      okText={i18n.t("base.modal.okText")}
      cancelText={i18n.t("base.modal.cancelText")}
      onCancel={onCancel}
      onOk={onFinish}
      width={600}
      bodyStyle={{ maxHeight: "600px", overflowY: "scroll" }}
    >
      <Form form={form} labelCol={{ span: 5 }}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.zhTitle")}
              name="zh_name"
              rules={[
                { required: true, message: i18n.t("sales.zhTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_name: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.ugTitle")}
              name="ug_name"
              rules={[
                { required: true, message: i18n.t("sales.ugTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_name: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label={i18n.t("sales.activity.time")}
          name="range"
          rules={[
            { required: true, message: i18n.t("sales.gift.rangeWarning") },
          ]}
        >
          <DatePicker.RangePicker
            showTime
            disabled={currentGift && currentGift.id !== -1}
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
          />
        </Form.Item>
        {[
          { label: i18n.t("sales.ad.zhImg"), name: "zh_image" },
          { label: i18n.t("sales.ad.ugImg"), name: "ug_image" },
        ].map((item) => (
          <Form.Item noStyle shouldUpdate key={item.label}>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  rules={[
                    { required: true, message: i18n.t("sales.ad.imgWarning") },
                  ]}
                  label={item.label}
                  name={item.name}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => util.normFile4Img(e)}
                >
                  <Upload
                    accept="image/*"
                    multiple={false}
                    listType="picture-card"
                    beforeUpload={onBeforeUpload}
                    customRequest={onUploadImg}
                  >
                    {(!getFieldValue(item.name) ||
                      !getFieldValue(item.name).length) && (
                      <span>
                        <UploadOutlined /> {i18n.t("sales.upload")}
                      </span>
                    )}
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
        ))}
        <Form.Item
          name="type"
          label={i18n.t("sales.gift.type")}
          rules={[
            { required: true, message: i18n.t("sales.gift.typeWarning") },
          ]}
          getValueFromEvent={(e) => {
            if (e.target.value === "0" && products.items.length > 0) {
              onResetSelectedProducts();
            }
            return e.target.value;
          }}
        >
          <Radio.Group disabled={currentGift && currentGift.id !== -1}>
            <Radio value="0">{i18n.t("sales.gift.order")}</Radio>
            <Radio value="1">{i18n.t("sales.gift.product")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue("type") === "0") {
              return (
                <Form.Item
                  name="order_amount"
                  disabled={currentGift && currentGift.id !== -1}
                  label={i18n.t("sales.gift.orderAmount")}
                >
                  <InputNumber formatter={(value) => `￥ ${value}`} />
                </Form.Item>
              );
            }
            return (
              <Form.Item label={i18n.t("sales.gift.product")}>
                <Button
                  type="default"
                  disabled={currentGift && currentGift.id !== -1}
                  onClick={() => {
                    setShowSelectModal("product");
                  }}
                >
                  {i18n.t("sales.coupons.select")}
                </Button>
                {products.items.length > 0 && (
                  <List
                    dataSource={products.items}
                    header={
                      <Button
                        danger
                        size="small"
                        type="link"
                        disabled={currentGift && currentGift.id !== -1}
                        onClick={onResetSelectedProducts}
                      >
                        {i18n.t("sales.gift.deleteAll")}
                      </Button>
                    }
                    renderItem={(productId) => (
                      <List.Item
                        actions={[
                          <Button
                            danger
                            size="small"
                            type="link"
                            disabled={currentGift && currentGift.id !== -1}
                            onClick={() => unselectProduct(productId)}
                          >
                            {i18n.t("product.productItem.actionDelete")}
                          </Button>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              shape="square"
                              size="large"
                              src={products.byId[productId].images[0]}
                            />
                          }
                          description={i18n.getPropNameByLocale(
                            products.byId[productId],
                            "name"
                          )}
                        />
                      </List.Item>
                    )}
                  />
                )}
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item label={i18n.t("sales.gift.gift")}>
          <Button
            type="default"
            disabled={currentGift && currentGift.id !== -1}
            onClick={() => {
              setShowSelectModal("gift");
            }}
          >
            {i18n.t("sales.coupons.select")}
          </Button>
          {giftProducts.items.length > 0 && (
            <List
              dataSource={giftProducts.items}
              header={
                <Button
                  danger
                  size="small"
                  type="link"
                  disabled={currentGift && currentGift.id !== -1}
                  onClick={onResetSelectedGiftProducts}
                >
                  {i18n.t("sales.gift.deleteAll")}
                </Button>
              }
              renderItem={(productId) => (
                <List.Item
                  actions={[
                    <Button
                      danger
                      size="small"
                      type="link"
                      disabled={currentGift && currentGift.id !== -1}
                      onClick={() => unselectGiftProduct(productId)}
                    >
                      {i18n.t("product.productItem.actionDelete")}
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        shape="square"
                        size="large"
                        src={giftProducts.byId[productId].images[0].url}
                      />
                    }
                    description={i18n.getPropNameByLocale(
                      giftProducts.byId[productId],
                      "name"
                    )}
                  />
                </List.Item>
              )}
            />
          )}
        </Form.Item>
        <Form.Item label={i18n.t("showsEdit.orderLabel")} name="order">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label={i18n.t("proxy.filterStatus")}
          name="status"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
      <ProductSelectList
        products={getCurrentProducts()}
        onSelect={getCurrentSetProducts()}
        visible={showSelectModal}
        onCancel={() => {
          setShowSelectModal(false);
        }}
      />
    </Modal>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(EditGiftForm);
