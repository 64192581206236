import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Table, message, Tag, Avatar, Space, Typography } from "antd";
import Search from "antd/lib/input/Search";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { ApiManager, Locale, Util } from "../../utils";
import TopupInput from "./TopupInput";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();
const { Text } = Typography;

function BalanceTopupList({ user }) {
  const thisSorter = useRef();
  const thisFilters = useRef();

  const [list, setList] = useState({ data: [], current_page: 1 });
  const [loading, setLoading] = useState(false);

  const fetchList = useCallback(
    async (page = 1, keyword = "") => {
      const { token } = user;
      if (token) {
        setLoading(true);
        try {
          const apiUrl = api.balance.topupList;
          const apiConfig = {
            token,
            query: {
              page,
              keyword,
              count: 10,
            },
          };
          if (thisSorter.current && thisSorter.current.field) {
            const order = thisSorter.current.order.split("end")[0];
            apiConfig.query[
              `filter[sort]`
            ] = `${thisSorter.current.field}@${order}`;
          }
          if (thisFilters && thisFilters.current) {
            for (let key in thisFilters.current) {
              if (thisFilters.current[key]) {
                apiConfig.query[`filter[${key}]`] = thisFilters.current[
                  key
                ].join(",");
              }
            }
          }
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setList(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <>
      <Search
        onSearch={(keyword) => fetchList(1, keyword)}
        placeholder={i18n.t("users.searchPlace")}
        style={{ width: 300, marginBottom: 16 }}
        enterButton
      />
      <Table
        loading={loading}
        dataSource={list.data}
        rowKey={(record) => `${record.id}`}
        onChange={(pagination, filters, sorter) => {
          thisSorter.current = sorter;
          thisFilters.current = filters;
          fetchList(pagination.current, "", sorter);
        }}
        pagination={{
          current: list.current_page,
          total: list.total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        columns={[
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => (
              <Avatar
                size="large"
                shape="circle"
                src={record.avatar}
                alt={record.nickname}
              />
            ),
          },
          {
            title: i18n.t("users.name"),
            dataIndex: "nickname",
            key: "nickname",
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            dataIndex: "mobile",
            align: "center",
          },
          {
            title: i18n.t("users.topup"),
            key: "amount",
            dataIndex: "amount",
            align: "center",
            sorter: true,
            render: (text, record) => {
              if (record.type === 2) {
                return (
                  <Tag color="red">
                    -{util.getFormattedPrice(record.amount, "¥")}
                  </Tag>
                );
              }
              return (
                <Tag color="green">
                  +{util.getFormattedPrice(record.amount, "¥")}
                </Tag>
              );
            },
          },
          {
            title: i18n.t("users.currentBalance"),
            key: "balance",
            dataIndex: "balance",
            render: (text, record) => {
              return util.getFormattedPrice(record.balance, "¥");
            },
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "created_at",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("users.operator"),
            key: "operator",
            align: "center",
            render: (text, record) => {
              if (record.operator) {
                return (
                  <Space direction="vertical">
                    <Avatar src={record.operator.avatar} size="small" />
                    <Text>{record.operator.nickname}</Text>
                  </Space>
                );
              }
              return null;
            },
          },
          {
            title: i18n.t("users.actions"),
            key: "actions",
            align: "center",
            render: (text, record) => {
              if (record.user_id) {
                return (
                  <TopupInput
                    userId={record.user_id}
                    fetchList={fetchList}
                    historyId={record.id}
                    type={record.type}
                    status={record.status}
                  />
                );
              }
              return null;
            },
          },
        ]}
      />
    </>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(BalanceTopupList);
