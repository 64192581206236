import { Table, Tag, Avatar, DatePicker, Input, Button, Space } from "antd";
import { CaretDownOutlined, TransactionOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApiManager, Locale, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import AgentListForm from "./AgentListForm";
import Base from "../Base";

const i18n = Locale.getInstance();
const util = Util.getInstance();
const base = Base.getInstance();
const api = ApiManager.getInstance();

const { Search } = Input;

function SendMoneyList() {
  const thisSorter = useRef();
  const thisFilters = useRef();
  const getData = useRef();
  const [listData, setListData] = useState({ data: [], current_page: 1 });
  const [range, setRange] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => getUserInfo(state));

  getData.current = async (page = 1) => {
    if (user.token) {
      const apiUrl = api.proxy.transferList;
      const apiConfig = {
        token: user.token,
        query: {
          page,
          keyword,
          count: 10,
        },
      };
      if (thisSorter.current && thisSorter.current.field) {
        const order = thisSorter.current.order.split("end")[0];
        apiConfig.query[
          `filter[sort]`
        ] = `${thisSorter.current.field}@${order}`;
      }
      if (thisFilters && thisFilters.current) {
        for (let key in thisFilters.current) {
          if (thisFilters.current[key]) {
            apiConfig.query[`filter[${key}]`] = thisFilters.current[key].join(
              ","
            );
          }
        }
      }
      if (range && range.length > 0) {
        apiConfig.query["filter[start_date]"] = range[0].format("YYYY-MM-DD");
        apiConfig.query["filter[end_date]"] = range[1].format("YYYY-MM-DD");
      }
      setLoading(true);
      const res = await apiUrl.run(apiConfig);
      try {
        if (res.result) {
          setListData(res.data);
        } else {
          base.showMessage(res.message);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData.current();
  }, [getData]);

  function getStatusColor(recordStatus) {
    let color;
    switch (recordStatus) {
      case 1:
        color = "green";
        break;
      case 4:
        color = "blue";
        break;
      default:
        color = "red";
        break;
    }
    return color;
  }

  return (
    <>
      <Space direction="horizontal" style={{ marginBottom: 16 }}>
        <Input.Group compact>
          <DatePicker.RangePicker
            defaultValue={range}
            format="YYYY-MM-DD"
            allowClear
            onChange={(dates) => setRange(dates)}
            suffixIcon={<CaretDownOutlined />}
          />
          <Search
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onSearch={() => getData.current()}
            placeholder={i18n.t("users.searchPlace")}
            style={{ width: 300 }}
            enterButton
            allowClear
          />
        </Input.Group>
        <Button
          type="primary"
          icon={<TransactionOutlined />}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {i18n.t("transfer.new")}
        </Button>
      </Space>
      <Table
        loading={loading}
        dataSource={listData.data}
        rowKey={(record) => `${record.id}`}
        onChange={(pagination, filters, sorter) => {
          thisSorter.current = sorter;
          thisFilters.current = filters;
          getData.current(pagination.current);
        }}
        pagination={{
          current: listData.current_page,
          total: listData.total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        columns={[
          {
            title: "id",
            key: "id",
            dataIndex: "id",
          },
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => {
              const { user } = record;
              return (
                <Avatar
                  size="large"
                  shape="circle"
                  src={user.avatar}
                  alt={user.nickname}
                />
              );
            },
          },
          {
            title: i18n.t("users.name"),
            key: "nickname",
            render: (text, record) => record.user.nickname,
          },
          {
            title: "",
            key: "channel",
            align: "center",
            render: (text, record) => {
              return <Tag color="purple">{record.desc}</Tag>;
            },
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            align: "center",
            render: (text, record) => record.user.mobile,
          },
          {
            title: i18n.t("commissionList.comm"),
            key: "amount",
            dataIndex: "amount",
            sorter: true,
            render: (text, record) => {
              return util.getFormattedPrice(record.withdrawal_amount, "¥");
            },
          },
          {
            title: i18n.t("commissionList.status"),
            key: "status",
            align: "center",
            filters: listData.status
              ? listData.status.map((i, idx) => ({
                  text: i,
                  value: idx,
                }))
              : [],
            render: (text, record) => {
              if (record.user_id) {
                return (
                  <Tag color={getStatusColor(record.status)}>
                    {record.status_label}
                  </Tag>
                );
              }
              return null;
            },
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "payment_time",
            align: "center",
            sorter: true,
          },
        ]}
      />
      <AgentListForm visible={showModal} onCancel={() => setShowModal(false)} />
    </>
  );
}

export default SendMoneyList;
