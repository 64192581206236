export default {
	list: {
		name: 'review list api',
		version: 4,
		url: 'review',
		method: 'GET',
	},
	reply: {
		name: 'review reply api',
		version: 4,
		url: 'review/%{reviewId}/reply',
		method: 'POST',
	},
}