import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Form,
  message,
  Button,
  Space,
  Upload,
  Input,
  Switch,
  InputNumber,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { ApiManager, Locale, Request, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";

const i18n = Locale.getInstance();
const util = Util.getInstance();
const api = ApiManager.getInstance();

function EditCard({ router }) {
  const user = useSelector((state) => getUserInfo(state));
  const settings = useSelector((state) => getMerchantSettings(state));
  const editCard = useRef();
  const [form] = useForm();
  const {
    location: { state },
  } = router;
  editCard.current = async (values) => {
    if (user.token) {
      try {
        let url = "/member/api/v6/merchant/member-card";
        let method = Request.post;
        const {
          zh_card_image,
          ug_card_image,
          status,
          ug_description,
          zh_description,
          price,
          discount_percent,
          ...otherValues
        } = values;
        let payload = {
          ...otherValues,
          status: status ? 1 : 0,
          price: price * 100,
          discount_percent,
          zh_description: zh_description && zh_description.toHTML(),
          ug_description: ug_description && ug_description.toHTML(),
        };
        if (
          zh_card_image &&
          zh_card_image[0] &&
          zh_card_image[0].uid !== -1 &&
          zh_card_image[0].response
        ) {
          payload.zh_card_image = zh_card_image[0].response.path;
        }
        if (
          ug_card_image &&
          ug_card_image[0] &&
          ug_card_image[0].uid !== -1 &&
          ug_card_image[0].response
        ) {
          payload.ug_card_image = ug_card_image[0].response.path;
        }
        if (state && state.cardData) {
          const { id } = state.cardData;
          url += `/${id}`;
          method = Request.put;
        }
        const res = await method(url, payload, user.token);
        if (res.result) {
          router.goBack();
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };
  const onUploadImg = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");
    const token = user.token;
    if (!token) onError("no user token");
    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "member-card-img",
    };
    try {
      const res = await api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };
  const braftUpload = async (param) => {
    const token = user.token;
    if (token) {
      const uploadFiles = [{ file: param.file }];
      const apiConfig = {
        token,
        uploadFiles,
        uploadType: "member-card-description",
      };
      try {
        const res = await api.common.uploadImages.run(apiConfig);
        if (res.result) {
          param.success({
            url: res.data[0].url,
          });
        } else {
          param.error({
            msg: res.message,
          });
        }
      } catch (error) {
        param.error({
          msg: error.message,
        });
      }
    }
  };
  useEffect(() => {
    if (state && state.cardData) {
      const {
        zh_description,
        ug_description,
        zh_card_image,
        ug_card_image,
        discount_percent,
        points,
        points_multiple_by,
        status,
        zh_name,
        ug_name,
        price,
      } = state.cardData;
      form.setFieldsValue({
        zh_description: BraftEditor.createEditorState(zh_description),
        ug_description: BraftEditor.createEditorState(ug_description),
        zh_card_image: util.getDefaultImages(zh_card_image),
        ug_card_image: util.getDefaultImages(ug_card_image),
        status: status === 1,
        discount_percent,
        price: price / 100,
        points,
        points_multiple_by,
        zh_name,
        ug_name,
      });
    }
  }, [router, form, state]);
  return (
    <Form
      style={{ backgroundColor: "#fff", paddingTop: 40, paddingBottom: 20 }}
      wrapperCol={{ span: 8 }}
      labelCol={{ span: 4 }}
      onFinish={editCard.current}
      form={form}
    >
      {[
        {
          name: "zh_name",
          label: i18n.t("agentLevel.zh"),
          message: i18n.t("sales.zhTitleWarning"),
        },
        {
          name: "ug_name",
          label: i18n.t("agentLevel.ug"),
          message: i18n.t("sales.ugTitleWarning"),
        },
      ].map((i) => (
        <Form.Item
          key={i.name}
          name={i.name}
          rules={[{ required: true, message: i.message }]}
          label={i.label}
        >
          <Input />
        </Form.Item>
      ))}
      {[
        { label: i18n.t("sales.ad.zhImg"), name: "zh_card_image" },
        { label: i18n.t("sales.ad.ugImg"), name: "ug_card_image" },
      ].map((item) => (
        <Form.Item noStyle shouldUpdate key={item.name}>
          {({ getFieldValue }) => (
            <Form.Item
              key={item.label}
              rules={[
                { required: true, message: i18n.t("sales.ad.imgWarning") },
              ]}
              label={item.label}
              name={item.name}
              valuePropName="fileList"
              getValueFromEvent={(e) => util.normFile4Img(e)}
            >
              <Upload
                accept="image/*"
                multiple={false}
                listType="picture-card"
                customRequest={onUploadImg}
              >
                {(!getFieldValue(item.name) ||
                  !getFieldValue(item.name).length) && (
                  <span>
                    <UploadOutlined /> {i18n.t("sales.upload")}
                  </span>
                )}
              </Upload>
            </Form.Item>
          )}
        </Form.Item>
      ))}
      <Form.Item
        name="discount_percent"
        label={i18n.t("member.discount")}
        rules={[{ required: true, message: i18n.t("member.discountWarning") }]}
        initialValue={1}
      >
        <InputNumber
          style={{ width: 150 }}
          formatter={(v) => `${v}%`}
          min={1}
          max={99}
        />
      </Form.Item>
      <Form.Item
        label={i18n.t("member.multiple")}
        name="points_multiple_by"
        rules={[{ required: true }]}
      >
        <InputNumber style={{ width: 150 }} />
      </Form.Item>
      {settings.membership_type === 0 ? (
        <Form.Item name="price" label={i18n.t("member.price")} initialValue={1}>
          <InputNumber
            style={{ width: 150 }}
            min={0}
            formatter={(p) => `¥${p}`}
          />
        </Form.Item>
      ) : (
        <Form.Item
          label={i18n.t("member.points")}
          name="points"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: 150 }} />
        </Form.Item>
      )}
      <Form.Item
        name="status"
        label={i18n.t("sales.status")}
        valuePropName="checked"
        initialValue={true}
      >
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Form.Item>
      {[
        { name: "zh_description", label: i18n.t("member.zhDesc") },
        {
          name: "ug_description",
          label: i18n.t("member.ugDesc"),
          contentStyle: { fontFamily: "Alp Ekran" },
        },
      ].map((i) => (
        <Form.Item colon={false} label={i.label} name={i.name} key={i.name}>
          <BraftEditor
            contentStyle={i.contentStyle}
            media={{ uploadFn: braftUpload }}
          />
        </Form.Item>
      ))}
      <Form.Item wrapperCol={{ offset: 4 }}>
        <Space>
          <Button htmlType="submit" type="primary">
            {i18n.t("property.submit")}
          </Button>
          <Button
            onClick={() => {
              router.goBack();
            }}
          >
            {i18n.t("common.cancelText")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default EditCard;
