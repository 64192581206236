import React from "react";
import {
  BellOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  HomeOutlined,
  ProfileOutlined,
  TagOutlined,
  AccountBookOutlined,
  CodeOutlined,
  BankOutlined,
  TeamOutlined,
  CameraOutlined,
  SettingOutlined,
  StarOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  VideoCameraOutlined,
  GiftOutlined,
  FileTextOutlined,
  ToolOutlined,
  RedEnvelopeOutlined,
  CarOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Badge,
  Dropdown,
  Input,
  BackTop,
  Drawer,
  Avatar,
  AutoComplete,
  Button,
  Form,
  notification,
} from "antd";
//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import {
  setUserInfo,
  setUserMerchantStatus,
} from "../../utils/redux/actions/User";
import Base from "../Base";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";

const SideMenus = [
  {
    key: "/",
    titleIcon: <HomeOutlined />,
    titleText: "main.sideMenus.home",
    subMenus: null,
  },
  {
    key: "/order",
    titleIcon: <ProfileOutlined />,
    titleText: "main.sideMenus.order",
    subMenus: [
      {
        key: "/order/list",
        titleIcon: null,
        titleText: "main.sideMenus.orderList",
      },
      {
        key: "/order/refund-list",
        titleIcon: null,
        titleText: "main.sideMenus.refundList",
      },
      {
        key: "/order/download",
        titleIcon: null,
        titleText: "main.sideMenus.downloadList",
      },
      {
        key: "/order/import",
        titleIcon: null,
        titleText: "main.sideMenus.import",
      },
    ],
  },
  {
    key: "/delivery",
    titleIcon: <CarOutlined />,
    titleText: "main.sideMenus.delivery",
    subMenus: [
      {
        key: "/delivery/deliveryman",
        titleIcon: null,
        titleText: "deliveryman.title",
      },
      {
        key: "/delivery/community",
        titleIcon: null,
        titleText: "community.title",
      },
    ],
  },
  {
    key: "/product",
    titleIcon: <TagOutlined />,
    titleText: "main.sideMenus.product",
    subMenus: [
      {
        key: "/product/list",
        titleIcon: null,
        titleText: "main.sideMenus.productList",
        contains: ["/product/edit"],
      },
    ],
  },
  {
    key: "/manage-ads",
    titleIcon: <StarOutlined />,
    titleText: "main.sideMenus.manageAds",
    subMenus: [
      {
        key: "/manage-ads/ads",
        titleIcon: null,
        titleText: "main.sideMenus.ads",
      },
      {
        key: "/manage-ads/navs",
        titleIcon: null,
        titleText: "main.sideMenus.navs",
      },
      {
        key: "/manage-ads/popups",
        titleIcon: null,
        titleText: "main.sideMenus.popups",
      },
      {
        key: "/manage-ads/splash",
        titleIcon: null,
        titleText: "main.sideMenus.splash",
      },
      {
        key: "/manage-ads/home-ad",
        titleIcon: null,
        titleText: "main.sideMenus.homeAd",
      },
    ],
  },
  {
    key: "/sales",
    titleIcon: <AccountBookOutlined />,
    titleText: "main.sideMenus.sales",
    subMenus: [
      {
        key: "/sales/coupons",
        titleIcon: null,
        titleText: "main.sideMenus.coupons",
      },
      // {
      //   key: "/sales/groupon",
      //   titleIcon: null,
      //   titleText: "main.sideMenus.groupon",
      // },
      {
        key: "/sales/flash",
        titleIcon: null,
        titleText: "main.sideMenus.flash",
        contains: ["/sales/flash-edit"],
      },
      {
        key: "/sales/gift",
        titleIcon: null,
        titleText: "main.sideMenus.gift",
        contains: ["/sales/gift"],
      },
    ],
  },
  {
    key: "/lottery",
    titleIcon: <GiftOutlined />,
    titleText: "main.sideMenus.lottery",
    subMenus: [
      {
        key: "/lottery/list",
        titleIcon: null,
        titleText: "main.sideMenus.lotteryList",
      },
      {
        key: "/lottery/theme",
        titleIcon: null,
        titleText: "main.sideMenus.lotteryTheme",
      },
    ],
  },

  {
    key: "/article",
    titleIcon: <FileTextOutlined />,
    titleText: "main.sideMenus.article",
  },
  {
    key: "/live",
    titleIcon: <VideoCameraOutlined />,
    titleText: "main.sideMenus.live",
    subMenus: [
      {
        key: "/live/list",
        titleIcon: null,
        titleText: "main.sideMenus.liveList",
      },
    ],
  },
  {
    key: "/mini-program",
    titleIcon: <CodeOutlined />,
    titleText: "main.sideMenus.mini",
    subMenus: [
      {
        key: "/mini-program/setting",
        titleIcon: null,
        titleText: "main.sideMenus.miniSetting",
      },
    ],
  },
  // {
  //   key: "/review",
  //   titleIcon: <EditOutlined />,
  //   titleText: "main.sideMenus.review",
  //   subMenus: [
  //     {
  //       key: "/review/list",
  //       titleIcon: null,
  //       titleText: "main.sideMenus.reviewList",
  //     },
  //   ],
  // },
  {
    key: "/finance",
    titleIcon: <BankOutlined />,
    titleText: "main.sideMenus.finance",
    subMenus: [
      {
        key: "/finance/home",
        titleIcon: null,
        titleText: "main.sideMenus.financeHome",
      },
      {
        key: "/finance/check",
        titleIcon: null,
        titleText: "main.sideMenus.financeCheck",
        contains: ["/finance/check-daily", "/finance/order-detail"],
      },
      {
        key: "/finance/account",
        titleIcon: null,
        titleText: "main.sideMenus.financeAccount",
        contains: ["/finance/bill-detail"],
      },
      {
        key: "/finance/download",
        titleIcon: null,
        titleText: "main.sideMenus.financeDownload",
      },
      {
        key: "/finance/withdraw-list",
        titleIcon: null,
        titleText: "main.sideMenus.withdrawList",
      },
      {
        key: "/finance/send-money-list",
        titleIcon: null,
        titleText: "main.sideMenus.sendMoney",
      },
      {
        key: "/finance/withdraw-request-list",
        titleIcon: null,
        titleText: "main.sideMenus.withdrawRequestList",
      },
    ],
  },
  {
    key: "/members",
    titleIcon: <IdcardOutlined />,
    titleText: "main.sideMenus.members",
    subMenus: [
      {
        key: "/members/list",
        titleIcon: null,
        titleText: "main.sideMenus.membersList",
      },
      {
        key: "/members/card",
        titleIcon: null,
        titleText: "main.sideMenus.membersCard",
      },
      {
        key: "/members/order",
        titleIcon: null,
        titleText: "main.sideMenus.membersOrder",
      },
    ],
  },
  {
    key: "/proxy",
    titleIcon: <TeamOutlined />,
    titleText: "main.sideMenus.proxy",
    subMenus: [
      {
        key: "/proxy/list",
        titleIcon: null,
        titleText: "main.sideMenus.proxyList",
      },
      {
        key: "/proxy/commission-list",
        titleIcon: null,
        titleText: "main.sideMenus.commissionList",
      },
      {
        key: "/proxy/agent-level",
        titleIcon: null,
        titleText: "main.sideMenus.agentLevel",
      },
    ],
  },
  {
    key: "/users",
    titleIcon: <UserOutlined />,
    titleText: "main.sideMenus.users",
    subMenus: [
      {
        key: "/users/list",
        titleIcon: null,
        titleText: "main.sideMenus.usersList",
      },
    ],
  },
  {
    key: "/balance",
    titleIcon: <MoneyCollectOutlined />,
    titleText: "main.sideMenus.balance",
    subMenus: [
      {
        key: "/balance/topup",
        titleIcon: null,
        titleText: "main.sideMenus.topup",
      },
      {
        key: "/balance/spend",
        titleIcon: null,
        titleText: "main.sideMenus.spend",
      },
    ],
  },
  {
    key: "/shows",
    titleIcon: <CameraOutlined />,
    titleText: "main.sideMenus.shows",
    subMenus: [
      {
        key: "/shows/list",
        titleIcon: null,
        titleText: "main.sideMenus.showsList",
      },
    ],
  },
  {
    key: "/weapp-management",
    titleIcon: <ToolOutlined />,
    titleText: "main.sideMenus.manageWeapp",
  },
  {
    key: "/setting",
    titleIcon: <SettingOutlined />,
    titleText: "main.sideMenus.setting",
    subMenus: [
      {
        key: "/setting/shop",
        titleIcon: null,
        titleText: "main.sideMenus.settingShop",
      },
      {
        key: "/setting/lang",
        titleIcon: null,
        titleText: "main.sideMenus.selectLang",
      },
      {
        key: "/setting/merchant-info-setting",
        titleIcon: null,
        titleText: "main.sideMenus.merchantSetting",
      },
      {
        key: "/setting/applyment-info",
        titleIcon: null,
        titleText: "main.sideMenus.wechatMerchantApplymentInfo",
      },
    ],
  },
];

class Main extends Base {
  /* lifecycle methods */

  constructor(props) {
    super(props);
    this.passForm = React.createRef();
    this.state = {
      ...this.state,
      sideMenus: null,
      sideMenusOpened: null,
      sideMenusSelected: null,
      updatingMerchant: false,
      searchValueDisplay: this.getFromRouterQuery("search") || "",
      searchValueReal: this.getFromRouterQuery("search") || "",
      drawerVisible: false,
      size: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      },
    };
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", this.onResize);
    if (this.getUser()) {
      this.onCheckNewOrder();
      this._initSideMenus();
      this._loadSideMenus();
    } else {
      this.goToLogin();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (prevProps.user !== this.props.user) {
      this._initSideMenus();
      this._loadSideMenus();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.getUser()) {
        this._loadSideMenus();
      }
    }

    if (
      prevState.searchValueDisplay !== this.state.searchValueDisplay &&
      !this.state.searchValueDisplay
    ) {
      this.onHeaderSearchClear();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.setState({
      size: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      },
    });
  };

  onCheckNewOrder = () => {
    const { user, settings } = this.props;
    if (settings.enable_voice_notification) {
      this.interval = setInterval(async () => {
        try {
          let api = this.api.order.new;
          if (user && user.token) {
            const res = await api.run({ token: user.token });
            if (res.result && res.data.length > 0) {
              const audio = new Audio(res.data);
              audio.play();
              notification.open({
                message: this.i18n.t("order.new"),
                description: this.i18n.t("order.newDesc"),
                onClick: () => {
                  this.props.router.push("/order/list?orderType=waiting");
                },
              });
            }
          }
        } catch (error) {
          console.log(error, "==================>");
        }
      }, 1000 * 60);
    }
  };

  /* render methods */

  render() {
    return (
      <Layout id="route-main">
        {this._renderSider()}
        <Layout id="route-main-layout">
          {this._renderHeader()}
          {this._renderContent()}
        </Layout>
        {this.renderDrawer()}
      </Layout>
    );
  }

  _renderSider() {
    if (!this.getUser()) return null;
    const { settings } = this.props;

    let menus = this.state.sideMenus;
    if (!menus) return null;
    const { size } = this.state;

    return (
      <Layout.Sider className="main-sider" breakpoint="md" collapsedWidth="100">
        <div className="logo">
          <Button
            type="link"
            onClick={() => {
              this.props.router.replace("/");
            }}
          >
            <h3>{this.i18n.t("common.appName")}</h3>
          </Button>
        </div>
        <Menu
          openKeys={this.state.sideMenusOpened}
          selectedKeys={this.state.sideMenusSelected}
          mode="inline"
          theme="dark"
          onClick={this._onSideMenuItemClick}
          onOpenChange={this._onSideMenuOpenChage}
          onSelect={this._onSideMenuItemSelect}
        >
          {menus.map((menu, index) => {
            let title = (
              <span>
                {menu.titleIcon}
                {size.width >= 768 && this.i18n.t(menu.titleText)}
              </span>
            );

            let subMenus = menu.subMenus;
            if (subMenus) {
              if (
                (settings.lottery_shop_type == 0 ||
                  settings.lottery_shop_type == 1 ||
                  settings.lottery_shop_type == 2) &&
                menu.key === "/mini-program"
              ) {
                return null;
              }
              if (settings.lottery_shop_type == 0 && menu.key === "/order") {
                return null;
              }
              return (
                <Menu.SubMenu key={menu.key} title={title}>
                  {subMenus.map((subMenu, subIndex) => {
                    if (
                      (settings.lottery_shop_type == 0 ||
                        settings.lottery_shop_type == 1 ||
                        settings.lottery_shop_type == 2) &&
                      subMenu.key === "/lottery/theme"
                    ) {
                      return null;
                    }
                    return (
                      <Menu.Item key={subMenu.key}>
                        {this.i18n.t(subMenu.titleText)}
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              );
            } else {
              return (
                <Menu.Item className="show-divider" key={menu.key}>
                  {title}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      </Layout.Sider>
    );
  }

  _renderHeader() {
    if (!this.getUser()) return null;

    return (
      <Layout.Header className="main-header">
        {this._renderHeaderMessage()}
        {this._renderHeaderUserDropdown()}
        {this._renderHeaderShopDropdown()}
        {this._renderHeaderStatusDropdown()}
        {this._renderHeaderSearch()}
      </Layout.Header>
    );
  }

  _renderHeaderMessage() {
    const messageCount = this.getUser().message_count || 0;

    return (
      <Button type="link" onClick={this.onHeaderMessageClick}>
        <Badge count={messageCount}>
          <BellOutlined style={{ color: "#fff" }} />
        </Badge>
      </Button>
    );
  }

  _renderHeaderUserDropdown() {
    const placement = this.i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight";

    const menu = (
      <Menu
        className="route-main-header-menus"
        onClick={this.onHeaderUserMenuClick}
      >
        <Menu.Item key="reset">{this.i18n.t("login.reset")}</Menu.Item>
        <Menu.Item key="logout">
          {this.i18n.t("main.userMenu.logout")}
        </Menu.Item>
      </Menu>
    );

    const name = this.getUser().nickname;

    return (
      <Dropdown placement={placement} overlay={menu}>
        <Button
          type="primary"
          style={{ boxShadow: "none", borderWidth: 0 }}
          className="action-dropdown"
        >
          <DownOutlined />
          <span>{name}</span>
        </Button>
      </Dropdown>
    );
  }

  _renderHeaderShopDropdown() {
    const merchant = this.getUser().merchant;
    const name = this.i18n.getPropNameByLocale(merchant, "name") || "";
    return (
      <Button
        type="primary"
        style={{ boxShadow: "none", borderWidth: 0 }}
        className="action-dropdown"
        onClick={this.onToggleDrawer}
      >
        <DownOutlined />
        <span>{name}</span>
      </Button>
    );
  }

  _renderHeaderStatusDropdown() {
    const placement = this.i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight";

    let isInBusiness = this.isMerchantInBusinuess();
    const menu = (
      <Menu
        className="route-main-header-menus status"
        onClick={this.onHeaderBusinessMenuClick}
      >
        <Menu.Item key="business" className={isInBusiness ? "current" : ""}>
          <span>{this.i18n.t("main.statusMenu.business")}</span>
          {isInBusiness ? <CheckCircleOutlined /> : null}
        </Menu.Item>
        <Menu.Item key="no-business" className={!isInBusiness ? "current" : ""}>
          <span>{this.i18n.t("main.statusMenu.noBusiness")}</span>
          {!isInBusiness ? <CheckCircleOutlined /> : null}
        </Menu.Item>
      </Menu>
    );

    let businessStatus = this.i18n.t("main.statusMenu.statusBusiness");
    if (!isInBusiness) {
      businessStatus = this.i18n.t("main.statusMenu.statusNoBusiness");
    }

    return (
      <Dropdown placement={placement} overlay={menu}>
        <Button
          style={{ borderWidth: 0, boxShadow: "none" }}
          className="action-dropdown"
          type="primary"
        >
          <DownOutlined />
          <span>{businessStatus}</span>
        </Button>
      </Dropdown>
    );
  }

  _renderHeaderSearch() {
    let suffix = <span />;
    if (Boolean(this.state.searchValueDisplay)) {
      suffix = <CloseCircleOutlined onClick={this.onHeaderSearchClear} />;
    }

    return (
      <Input
        className="action-search"
        placeholder={this.i18n.t("main.searchHint")}
        value={this.state.searchValueDisplay}
        onChange={this.onHeaderSearchChange}
        suffix={suffix}
        onPressEnter={this.onHeaderSearchConfirm}
      />
    );
  }

  renderShopAutoCompelete() {
    const merchants = this.getUser() && this.getUser().merchants;
    return (
      <AutoComplete
        placeholder={this.i18n.t("login.insertShopName")}
        options={merchants.map((m) => ({
          value: `${m.id}.${this.i18n.getPropNameByLocale(m, "name")}`,
          id: m.id,
        }))}
        filterOption={(inputValue, option) =>
          option.value
            .toLocaleLowerCase()
            .indexOf(inputValue.toLocaleLowerCase()) !== -1
        }
        style={{ width: 180 }}
        onSelect={(value, option) => this.onSelectShop(option.id)}
      />
    );
  }

  renderDrawer() {
    const merchants = this.getUser() && this.getUser().merchants;
    const placement = this.i18n.isLocaleRTL() ? "left" : "right";
    const { drawerVisible } = this.state;
    if (merchants && merchants.length > 0) {
      return (
        <Drawer
          title={this.renderShopAutoCompelete()}
          placement={placement}
          onClose={() => {
            requestAnimationFrame(() => {
              this.setState({ drawerVisible: false });
            });
          }}
          visible={drawerVisible}
        >
          <Menu onClick={this.onHeaderShopMenuClick} style={{ borderWidth: 0 }}>
            {merchants &&
              merchants.length > 0 &&
              merchants.map((item) => (
                <Menu.Item
                  key={item.id}
                  icon={
                    <Avatar
                      src={item.avatar}
                      size="small"
                      style={{ marginLeft: 10, marginRight: 10 }}
                    />
                  }
                >
                  {item.id}.{this.i18n.getPropNameByLocale(item, "name")}
                </Menu.Item>
              ))}
          </Menu>
        </Drawer>
      );
    }
    return null;
  }

  _renderContent() {
    const hasNoPagePermission = this.hasNoPermission();
    if (hasNoPagePermission) {
      return (
        <Layout.Content className="main-content">
          <div className="no-permission">
            <ExclamationCircleOutlined
              style={{ marginTop: 20, fontSize: 20 }}
            />
            <h3>{this.i18n.t("main.noPermission")}</h3>
          </div>
        </Layout.Content>
      );
    }

    let child = this.props.children;
    if (child) {
      child = {
        ...child,
        props: {
          ...child.props,
          search: this.state.searchValueReal,
          windowSizes: this.state.size,
        },
      };
    }

    return (
      <Layout.Content id="main-scroll-component" className="main-content">
        {child}
        <BackTop
          target={() => document.getElementById("main-scroll-component")}
          style={{ marginBottom: 50 }}
        />
      </Layout.Content>
    );
  }

  /* user methods */

  _onSideMenuItemClick = (item) => {
    if (this.state.sideMenusSelected.indexOf(item.key) !== -1) {
      let parentMenu = this.state.sideMenus.find(
        (menu) => menu.key === item.key
      );
      if (parentMenu) {
        this.setState({ sideMenusOpened: [] });
      }
    }
  };

  _onSideMenuOpenChage = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.sideMenusOpened.indexOf(key) === -1
    );

    for (let index = 0; index < this.state.sideMenus.length; index++) {
      const menu = this.state.sideMenus[index];
      if (menu.ley === latestOpenKey) {
        this.setState({
          sideMenusOpened: openKeys,
        });
        return;
      }
    }

    this.setState({
      sideMenusOpened: latestOpenKey ? [latestOpenKey] : [],
    });
  };

  _onSideMenuItemSelect = (item) => {
    let state = { sideMenusSelected: [item.key] };

    let parentMenu = this.state.sideMenus.find((menu) => menu.key === item.key);
    if (parentMenu) {
      state.sideMenusOpened = [];
    }

    this.setState(state);

    this.props.router.replace({ pathname: item.key });
  };

  onHeaderMessageClick = () => {
    this.showModal(this.i18n.t("common.developingFeature"));
  };

  onResetPassword = () => {
    this.passForm.current
      .validateFields()
      .then(async (values) => {
        const token = this.getUserToken();
        if (token) {
          const apiConfig = { token, data: { password: values.password } };
          const apiUrl = this.api.user.resetPassword;
          try {
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              this.showMessage(res.message, Base.Message.success);
              this.hideModal();
            } else {
              this.showMessage(res.message, Base.Message.warning);
            }
          } catch (error) {
            this.handleApiError(apiUrl, error);
          }
        }
      })
      .catch((err) => {
        console.log("Main -> onResetPassword -> err", err);
      });
  };

  onHeaderUserMenuClick = (menu) => {
    if (!menu) return;

    if (menu.key === "logout") {
      this.props.setUserInfo(null);
      this.goToLogin();
    }

    if (menu.key === "reset") {
      const self = this;
      this.showModal(
        this.i18n.t("login.reset"),
        Base.Modal.confirm,
        this.onResetPassword,
        {
          content: (
            <Form ref={this.passForm} labelCol={{ span: 8 }}>
              <Form.Item
                name="password"
                label={this.i18n.t("login.pass")}
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("login.parolHintRequired"),
                  },
                  {
                    min: 6,
                    message: this.i18n.t("login.parolHintMin"),
                  },
                  {
                    max: 16,
                    message: this.i18n.t("login.parolHintMax"),
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label={this.i18n.t("login.passAgain")}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("login.parolHintRequired"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(self.i18n.t("login.notMatch"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Form>
          ),
        }
      );
    }
  };

  async onSelectShop(merchantId) {
    try {
      const token = this.getUserToken();
      if (!token) return;

      const api = this.api.user.selectMerchant;
      const apiConfig = {
        token,
        data: {
          merchant_id: merchantId,
        },
      };

      requestAnimationFrame(() => {
        this.setState({ drawerVisible: false });
      });

      const res = await api.run(apiConfig);
      if (res.result) {
        const { setUserInfo, tokenObject, user } = this.props;
        const merchant = user.merchants.find(
          (item) => item.id === Number(merchantId)
        );
        setUserInfo({ user: { ...user, merchant }, token: tokenObject });
        if (window.location.pathname.indexOf("product/edit") === -1) {
          window.location.reload();
        } else {
          window.location.href = "/product/list";
        }
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      this.handleApiError(error);
    }
  }

  onHeaderShopMenuClick = (menu) => {
    if (!menu) return;
    this.onSelectShop(menu.key);
  };

  onToggleDrawer = () => {
    requestAnimationFrame(() => {
      this.setState({ drawerVisible: !this.state.drawerVisible });
    });
  };

  onHeaderBusinessMenuClick = (menu) => {
    if (!menu) return;

    let isInBusiness = this.isMerchantInBusinuess();
    if (menu.key === "business") {
      if (isInBusiness) {
        this.showMessage(
          this.i18n.t("main.alreadyInBusiness"),
          Base.Message.info
        );
        return;
      }

      this.showMessage(
        this.i18n.t("main.updatingBusinessStatus"),
        Base.Message.loading
      );
      this.updateMerchantStatus(true);
    } else if (menu.key === "no-business") {
      if (!isInBusiness) {
        this.showMessage(
          this.i18n.t("main.alreadyNoBusiness"),
          Base.Message.info
        );
        return;
      }

      this.showMessage(
        this.i18n.t("main.updatingBusinessStatus"),
        Base.Message.loading
      );
      this.updateMerchantStatus(false);
    }
  };

  onHeaderSearchChange = (e) => {
    let value = e.target.value.trim();

    if (!value.match(/^\d*$/g)) {
      return;
    }

    this.setState({ searchValueDisplay: value });
  };

  onHeaderSearchClear = () => {
    this.setState({ searchValueDisplay: "", searchValueReal: "" }, () => {
      let thisLocation = this.props.location;

      if (this.isLocationOrderList(thisLocation)) {
        this.props.router.replace({
          ...thisLocation,
          pathname: "/order/list",
        });
      }
    });
  };

  onHeaderSearchConfirm = (e) => {
    let value = e.target.value;

    if (!Boolean(value)) {
      this.showMessage(this.i18n.t("main.searchHint"), Base.Message.warning);
      return;
    }

    this.setState({ searchValueDisplay: value, searchValueReal: value }, () => {
      let thisLocation = this.props.location;

      if (this.isLocationOrderList(thisLocation)) {
        this.props.router.replace({
          ...thisLocation,
          pathname: "/order/list",
        });
      } else {
        this.props.router.replace({
          pathname: "/order/list",
        });
      }
    });
  };

  /* server methods */

  async updateMerchantStatus(isInBusiness = false) {
    if (this.state.updatingMerchant) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.user.updateMerchant;
    let apiConfig = {
      token: token,
      data: {
        status: isInBusiness ? 1 : 0,
      },
    };

    this.setState({ updatingMerchant: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("main.updateBusinessStatusSuccess"),
          Base.Message.success
        );
        this.props.setUserMerchantStatus(isInBusiness ? 1 : 0);
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ updatingMerchant: false });
    }
  }

  /* custom methods */

  _initSideMenus() {
    this.sideMenus = SideMenus;

    if (!this.isBBShopMerchant() && !this.shopHasAgents()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => !["/proxy", "/balance", "/shows", "/live"].includes(item.key)
      );
    }

    if (!this.isBBShopMerchant() && !this.isThirdPartyShopHasWeapp()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => !["/mini-program", "/users"].includes(item.key)
      );
      this.sideMenus = this.sideMenus.map((item) => {
        if (item.key === "/manage-ads") {
          return {
            ...item,
            subMenus: item.subMenus.filter(
              (i) =>
                i.key !== "/manage-ads/home-ad" &&
                i.key !== "/manage-ads/splash"
            ),
          };
        }
        return item;
      });
    }

    if (this.isThirdPartyShop() && !this.isLbsShop()) {
      const arr = [
        "/finance/home",
        "/finance/check",
        "/finance/account",
        "/finance/download",
      ];
      this.sideMenus = this.sideMenus.map((item) => {
        if (item.key === "/finance") {
          return {
            ...item,
            subMenus: item.subMenus.filter((i) => !arr.includes(i.key)),
          };
        }
        return item;
      });
    }

    if (!this.isBBShopMerchant()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => item.key !== "/weapp-management"
      );
    }

    if (!this.isBBShopMerchant() && !this.isArticleEnabled()) {
      this.sideMenus = this.sideMenus.filter((item) => item.key !== "/article");
    }

    if (!this.isBBShopMerchant() && !this.isLotteryEnabled()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => item.key !== "/lottery" && item.key !== "/red-packet"
      );
    }
    if (!this.isBBShopMerchant() && !this.isMembersEnabled()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => !item.key.includes("members")
      );
    }
    if (!this.isBBShopMerchant() && !this.isLbsShop()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => item.key !== "/delivery"
      );
    }
    if (this.isDabazaShop() || this.isLbsShop()) {
      this.sideMenus = this.sideMenus.map((item) => {
        if (item.key === "/finance") {
          return {
            ...item,
            subMenus: item.subMenus.filter(
              (i) =>
                i.key !== "/finance/withdraw-list" &&
                i.key !== "/finance/withdraw-request-list" &&
                i.key !== "/finance/send-money-list"
            ),
          };
        }
        return item;
      });
    }

    if (this.isSoleLotteryWeapp()) {
      this.sideMenus = this.sideMenus.map((item) => {
        if (
          [
            "/",
            "/order",
            "/product",
            "/lottery",
            "/users",
            "/setting",
            "/mini-program",
            "/red-packet",
            "/red-packet-detail",
          ].includes(item.key)
        ) {
          return item;
        }
        return null;
      });
      this.sideMenus = this.sideMenus.filter((i) => i !== null);
    }
  }

  _loadSideMenus() {
    let menus = this.sideMenus;

    //已开始默认设置为首页
    let openMenu = menus[0];
    let selectedMenu = openMenu;

    ///获取当前路径path
    let path = this.props.location.pathname;
    //首先，直接使用当前路径去查，有没有符合的Menu，比如说/product/list
    openMenu = menus.find((menu) => menu.key === path);
    if (openMenu) {
      //若找到，则不用往下查了
      selectedMenu = openMenu;
    } else {
      //若没有符合路径的Menu，则进行将路径拆开来查
      //使用路径分割符“/”将路径切割成数组
      let pathArr = path.split("/");

      //将路径数组的第一个元素设置成被打开的Menu的key
      let openKey = `/${pathArr[1]}`;
      //根据被打开的Menu的key值进行查询
      openMenu = menus.find((menu) => menu.key === openKey);
      if (openMenu) {
        //若找到被打开的Menu，则往下查询被选中的Menu
        //已开始将被选中的Menu赋值给被选中的Menu
        selectedMenu = openMenu;

        //将路径数组的第二个元素设置成被选中的Menu的key
        let selectedKey = pathArr[2];
        if (selectedKey) {
          //	若有被选中的Menu的key值，则往下查询，不然就等于被打开的Menu
          selectedKey = `${openKey}/${selectedKey}`;

          //获取被打开的Menu的子Menu，因为被选中的Menu会从中被找到
          let subMenus = openMenu.subMenus;
          if (subMenus) {
            //若没有子Menu，则不用往下查询，被选中的Menu就等于被打开的Menu
            //首先根据子Menu的key至是否等于被选中的Menu的key值查询
            selectedMenu = subMenus.find((menu) => menu.key === selectedKey);

            if (!selectedMenu) {
              //若没找到，则根据子Menu的contains列表来查询，比如/finance/bill-detail等路径
              selectedMenu = subMenus.find((menu) => {
                let contains = menu.contains;
                if (contains && contains.indexOf(selectedKey) !== -1)
                  return menu;
                return null;
              });
            }

            if (!selectedMenu) {
              //若没有找到被选中的Menu，则将子Menu的第一个Menu赋值给被选中的Menu
              selectedMenu = subMenus[0];
            }
          }
        }
      } else {
        //若没找到被打开的Menu，则将首页设置成默认的Menu
        openMenu = menus[0];
        selectedMenu = openMenu;
      }
    }

    //结束时以防万一再判断是否存在相应的Menu，若不存在则将首页设置为默认的Menu
    if (!openMenu) {
      openMenu = menus[0];
      selectedMenu = openMenu;
    }
    this.setState({
      sideMenus: menus,
      sideMenusOpened: [openMenu.key],
      sideMenusSelected: [selectedMenu.key],
    });
  }

  isLocationOrderList(location = this.props.location) {
    return location.pathname.indexOf("/order/list") !== -1;
  }

  hasNoPermission() {
    let hasNoPermission =
      this.isRouterProxyRelated() &&
      this.isThirdPartyShop() &&
      !this.shopHasAgents();
    let hasNoWeappPermission =
      this.isRouterWeappRelated() &&
      !this.isBBShopMerchant() &&
      !this.isThirdPartyShopHasWeapp();
    let hasNoLbsPermission = this.isRouterLbsRelated() && !this.isLbsShop();
    let hasNoArticlePermission =
      !this.isBBShopMerchant() &&
      !this.isArticleEnabled() &&
      this.isRouterALRelated();
    let hasNoLotteryPermission =
      !this.isBBShopMerchant() &&
      !this.isLotteryEnabled() &&
      this.isRouterLotteryRelated();
    let hasNoMembersPermission =
      !this.isBBShopMerchant() &&
      !this.isMembersEnabled() &&
      this.isRouterMembersRelated();
    return (
      hasNoPermission ||
      hasNoWeappPermission ||
      hasNoLbsPermission ||
      hasNoLotteryPermission ||
      hasNoMembersPermission ||
      hasNoArticlePermission
    );
  }
}

export default connect(
  (state) => {
    return {
      user: getUserInfo(state),
      tokenObject: state.user.info && state.user.info.token,
      settings: getMerchantSettings(state),
    };
  },
  { setUserInfo, setUserMerchantStatus }
)(Main);
