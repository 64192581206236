import { combineReducers } from "redux";

import { user } from "./User";
import { merchant } from "./Merchant";
import { medias } from "./Medias";

const reducers = combineReducers({
  user,
  merchant,
  medias,
});

export default reducers;
