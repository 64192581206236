//react
import React, { Component } from "react";

//antd
import { ConfigProvider } from "antd";
import Locale from "./utils/locale/Locale";
import zhCN from "./utils/locale/antd-zh_CN";
import ugCN from "./utils/locale/antd-ug_CN";

//react router
import { Router, Route, useRouterHistory, Redirect } from "react-router";
import { createHistory } from "history";

//app routes
import Main from "./routes/main/Main";
import Home from "./routes/home/Home";
import OrderList from "./routes/order/OrderList";
import RefundList from "./routes/order/RefundList";
import OrderDownload from "./routes/order/OrderDownload";
import ImportTackNumber from "./routes/order/ImportTackNumber";
import ProductList from "./routes/product/ProductList";
import NotFound from "./routes/404/NotFount";
import Login from "./routes/login/Login";
import ShowsEdit from "./routes/shows/ShowsEdit";
import ProductEdit from "./routes/product/ProductEdit";
import AdsManagement from "./routes/sales/ads/AdsManagement";
import CouponsManagement from "./routes/sales/coupons/CouponsManagement";
import NavigatorManagement from "./routes/sales/ads/NavigatorManagement";
import MiniProgramSetting from "./routes/mini/MiniProgramSetting";
import MerchantBasicInfo from "./routes/setting/MerchantBasicInfo";
import ApplymentInfo from "./routes/setting/ApplymentInfo";
import HolidayActivityList from "./routes/sales/activities/HolidayActivityList";
import FlashActivityList from "./routes/sales/activities/FlashActivityList";
import FlashActivityEdit from "./routes/sales/activities/FlashActivityEdit";
import GiftList from "./routes/sales/gift/GiftList";
import DeliverymanManagement from "./routes/deliveryman/DeliverymanManagement";
import CommunityManagement from "./routes/delivery/CommunityManagement";
import ReviewList from "./routes/review/ReviewList";
import FinanceHome from "./routes/finance/FinanceHome";
import FinanceCheck from "./routes/finance/FinanceCheck";
import FinanceCheckDaily from "./routes/finance/FinanceCheckDaily";
import FinanceOrderDetail from "./routes/finance/FinanceOrderDetail";
import FinanceAccount from "./routes/finance/FinanceAccount";
import FinanceBillDetail from "./routes/finance/FinanceBillDetail";
import FinanceDownload from "./routes/finance/FinanceDownload";
import PromotionShop from "./routes/promotion/PromotionShop";
import PromotionShopTicket from "./routes/promotion/PromotionShopTicket";
import PromotionNewUser from "./routes/promotion/PromotionNewUser";
import PromotionDemandReduce from "./routes/promotion/PromotionDemandReduce";
import PromotionShipPrice from "./routes/promotion/PromotionShipPrice";
import ProxyList from "./routes/proxy/ProxyList";
import CommissionList from "./routes/proxy/CommissionList";
import AgentWithdrawList from "./routes/proxy/AgentWithdrawList";
import WithdrawCommissionList from "./routes/proxy/WithdrawCommissionList";
import CommissionWithdrawRequestList from "./routes/proxy/CommissionWithdrawRequestList";
import SendMoneyList from "./routes/proxy/SendMoneyList";
import AgentLevel from "./routes/proxy/AgentLevel";
import ShowsList from "./routes/shows/ShowsList";
import SelectLang from "./routes/setting/SelectLang";
import ShopSettingGroup from "./routes/setting/ShopSettingGroup";
import PopupManagement from "./routes/sales/ads/PopupManagement";
import SplashManagement from "./routes/sales/ads/SplashManagement";
import HomeAdManagement from "./routes/sales/ads/HomeAdManagement";
import GrouponManagement from "./routes/sales/groupon/GrouponManagement";
import BalanceSpendList from "./routes/balance/BalanceSpendList";
import BalanceTopupList from "./routes/balance/BalanceTopupList";
import UsersList from "./routes/users/UsersList";
import LiveList from "./routes/live/LiveList";
import LotteryEdit from "./routes/lottery/LotteryEdit";
import LotteryList from "./routes/lottery/LotteryList";
import LotteryManagers from "./routes/lottery/LotteryManagers";
import LotteryRecord from "./routes/lottery/LotteryRecord";
import LotteryTheme from "./routes/lottery/LotteryTheme";
import LotteryPrize from "./routes/lottery/LotteryPrize";
import LotteryPrePrize from "./routes/lottery/LotteryPrePrize";
import RedPacketsList from "./routes/red/RedPacketsList";
import RedPacketDetail from "./routes/red/RedPacketDetail";
import ArticleList from "./routes/article/ArticleList";
import ArticleEdit from "./routes/article/ArticleEdit";
import WeappManagement from "./routes/weapp/WeappManagement";
import EditGroupon from "./routes/sales/groupon/EditGroupon";
import MembersList from "./routes/members/MembersList";
import MembersCard from "./routes/members/MembersCard";
import MembersOrder from "./routes/members/MembersOrder";
import EditMember from "./routes/members/EditMember";
import EditCard from "./routes/members/EditCard";
import LotteryThemeEdit from "./routes/lottery/LotteryThemeEdit";

class App extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getBrowserHistory() {
    let basename = "";
    if (window.locale && window.locale.length > 0) {
      basename += window.locale;
    }
    return useRouterHistory(createHistory)({
      basename: basename,
    });
  }

  /* render methods */
  render() {
    let antLocale = zhCN;
    const localeIsRtl = Locale.getInstance().isLocaleRTL();
    if (localeIsRtl) {
      antLocale = ugCN;
    }

    return (
      <ConfigProvider
        locale={antLocale}
        direction={localeIsRtl ? "rtl" : "ltr"}
      >
        <Router history={this.getBrowserHistory()}>
          <Route name="login" path="login" component={Login} />
          <Route name="show-edit" path="show-edit" component={ShowsEdit} />
          <Route name="main" component={Main}>
            <Route name="home" path="/" component={Home} />
            <Route name="order" path="order">
              <Route name="list" path="list" component={OrderList} />
              <Route
                name="refund-list"
                path="refund-list"
                component={RefundList}
              />
              <Route
                name="download"
                path="download"
                component={OrderDownload}
              />
              <Route name="import" path="import" component={ImportTackNumber} />
            </Route>

            <Route name="delivery" path="delivery">
              <Route
                name="deliveryman"
                path="deliveryman"
                component={DeliverymanManagement}
              />
              <Route
                name="community"
                path="community"
                component={CommunityManagement}
              />
            </Route>
            <Route name="product" path="product">
              <Route name="list" path="list" component={ProductList} />
              <Route name="edit" path="edit" component={ProductEdit} />
            </Route>
            <Route name="live" path="live">
              <Route name="list" path="list" component={LiveList} />
            </Route>
            <Route name="sales" path="sales">
              <Route
                name="groupon"
                path="groupon"
                component={GrouponManagement}
              />
              <Route
                name="groupon-edit"
                path="groupon-edit"
                component={EditGroupon}
              />
              <Route
                name="coupons"
                path="coupons"
                component={CouponsManagement}
              />
              <Route
                name="holiday"
                path="holiday"
                component={HolidayActivityList}
              />
              <Route name="flash" path="flash" component={FlashActivityList} />
              <Route
                name="flash-edit"
                path="flash-edit"
                component={FlashActivityEdit}
              />
              <Route name="gift" path="gift" component={GiftList} />
            </Route>
            <Route name="lottery" path="lottery">
              <Route name="list" path="list" component={LotteryList} />
              <Route name="theme" path="theme" component={LotteryTheme} />
            </Route>

            <Route
              name="lottery-managers-list"
              path="lottery-managers-list"
              component={LotteryManagers}
            />
            <Route
              name="lottery-prize-list"
              path="lottery-prize-list"
              component={LotteryPrize}
            />
            <Route
              name="lottery-pre-list"
              path="lottery-pre-list"
              component={LotteryPrePrize}
            />
            <Route
              name="lottery-edit"
              path="lottery-edit"
              component={LotteryEdit}
            />
            <Route
              name="lottery-theme-edit"
              path="lottery-theme-edit"
              component={LotteryThemeEdit}
            />
            <Route
              name="lottery-detail"
              path="lottery-detail"
              component={LotteryRecord}
            />
            <Route
              name="red-packet"
              path="red-packet"
              component={RedPacketsList}
            />
            <Route
              name="red-packet-detail"
              path="red-packet-detail"
              component={RedPacketDetail}
            />
            <Route name="article" path="article" component={ArticleList} />
            <Route
              name="article-edit"
              path="article-edit"
              component={ArticleEdit}
            />
            <Route name="manage-ads" path="manage-ads">
              <Route name="ads" path="ads" component={AdsManagement} />
              <Route name="navs" path="navs" component={NavigatorManagement} />
              <Route name="popups" path="popups" component={PopupManagement} />
              <Route name="splash" path="splash" component={SplashManagement} />
              <Route
                name="home-ad"
                path="home-ad"
                component={HomeAdManagement}
              />
            </Route>
            <Route name="review" path="review">
              <Route name="list" path="list" component={ReviewList} />
            </Route>
            <Route name="finance" path="finance">
              <Route name="home" path="home" component={FinanceHome} />
              <Route name="check" path="check" component={FinanceCheck} />
              <Route
                name="check-daily"
                path="check-daily"
                component={FinanceCheckDaily}
              />
              <Route
                name="order-detail"
                path="order-detail"
                component={FinanceOrderDetail}
              />
              <Route name="account" path="account" component={FinanceAccount} />
              <Route
                name="bill-detail"
                path="bill-detail"
                component={FinanceBillDetail}
              />
              <Route
                name="withdraw-list"
                path="withdraw-list"
                component={AgentWithdrawList}
              />
              <Route
                name="withdraw-commission-list"
                path="withdraw-commission-list"
                component={WithdrawCommissionList}
              />
              <Route
                name="withdraw-request-list"
                path="withdraw-request-list"
                component={CommissionWithdrawRequestList}
              />
              <Route
                name="send-money-list"
                path="send-money-list"
                component={SendMoneyList}
              />
              <Route
                name="download"
                path="download"
                component={FinanceDownload}
              />
            </Route>
            <Route name="promotion" path="promotion">
              <Route name="shop" path="shop" component={PromotionShop} />
              <Route
                name="shop-ticket"
                path="shop-ticket"
                component={PromotionShopTicket}
              />
              <Route
                name="new-user"
                path="new-user"
                component={PromotionNewUser}
              />
              <Route
                name="demand-reduce"
                path="demand-reduce"
                component={PromotionDemandReduce}
              />
              <Route
                name="ship-price"
                path="ship-price"
                component={PromotionShipPrice}
              />
            </Route>
            <Route name="proxy" path="proxy">
              <Route name="list" path="list" component={ProxyList} />
              <Route
                name="commission-list"
                path="commission-list"
                component={CommissionList}
              />
              <Route
                name="agent-level"
                path="agent-level"
                component={AgentLevel}
              />
            </Route>
            <Route name="members" path="members">
              <Route name="list" path="list" component={MembersList} />
              <Route name="card" path="card" component={MembersCard} />
              <Route name="order" path="order" component={MembersOrder} />
              <Route name="edit" path="edit" component={EditMember} />
              <Route name="editCard" path="editCard" component={EditCard} />
            </Route>
            <Route name="users" path="users">
              <Route name="list" path="list" component={UsersList} />
            </Route>
            <Route name="balance" path="balance">
              <Route name="topup" path="topup" component={BalanceTopupList} />
              <Route name="spend" path="spend" component={BalanceSpendList} />
            </Route>
            <Route name="shows" path="shows">
              <Route name="edit" path="edit" component={ShowsEdit} />
              <Route name="list" path="list" component={ShowsList} />
            </Route>
            <Route
              name="weapp-management"
              path="weapp-management"
              component={WeappManagement}
            />
            <Route name="mini-program" path="mini-program">
              <Route
                name="setting"
                path="setting"
                component={MiniProgramSetting}
              />
            </Route>
            <Route name="setting" path="setting">
              <Route name="shop" path="shop" component={ShopSettingGroup} />
              <Route name="lang" path="lang" component={SelectLang} />
              <Route
                name="merchant-info-setting"
                path="merchant-info-setting"
                component={MerchantBasicInfo}
              />

              <Route
                name="applyment-info"
                path="applyment-info"
                component={ApplymentInfo}
              />
            </Route>
          </Route>
          <Route path="404" component={NotFound} />
          <Redirect from="*" to="404" />
        </Router>
      </ConfigProvider>
    );
  }
}

export default App;
