import React from "react";

import { Layout, Button } from "antd";

import Base from "../Base";

class NotFount extends Base {
  /* lifcyle methods */

  /* render methods */

  render() {
    return (
      <Layout id="route-404">
        <img
          className="not-fount-img"
          alt=""
          src={process.env.PUBLIC_URL + "/assets/images/404-image.png"}
        />
        <div className="not-fount-actions">
          <Button type="primary" onClick={this.onBackToHomeClick}>
            {this.i18n.t("notFound.backToHome")}
          </Button>
          <Button onClick={this.onBackToFrontClick}>
            {this.i18n.t("notFound.backToPrev")}
          </Button>
        </div>
      </Layout>
    );
  }

  /* user methods */

  onBackToHomeClick = () => {
    this.props.router.replace({
      pathname: "/",
    });
  };

  onBackToFrontClick = () => {
    this.props.router.goBack();
  };

  /* server methods */

  /* custom methods */
}

export default NotFount;
