export default {
  list: {
    name: "agent level list api",
    version: 6,
    url: "agent-level",
    method: "GET",
  },
  add: {
    name: "add agent level  api",
    version: 6,
    url: "agent-level",
    method: "POST",
  },
  update: {
    name: "agent level commission update api",
    version: 6,
    url: "agent-level-commission/%{levelId}",
    method: "POST",
  },
  delete: {
    name: "agent level delete api",
    version: 6,
    url: "agent-level/%{levelId}",
    method: "DELETE",
  },
};
