// import Pagination from 'rc-pagination/lib/locale/zh_CN';
import DatePicker from "antd/lib/date-picker/locale/zh_CN";
import TimePicker from "antd/lib/time-picker/locale/zh_CN";
import Calendar from "antd/lib/calendar/locale/zh_CN";

export default {
  locale: "zh-cn",
  Pagination: {
    // Options.jsx
    items_per_page: "条/页",
    jump_to: "跳至",
    jump_to_confirm: "确定",
    page: "页",

    // Pagination.jsx
    prev_page: "上一页",
    next_page: "下一页",
    prev_5: "向前 5 页",
    next_5: "向后 5 页",
    prev_3: "向前 3 页",
    next_3: "向后 3 页",
  },
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: "筛选",
    filterConfirm: "确定",
    filterReset: "重置",
    emptyText: "暂无数据",
    selectAll: "全选当页",
    selectInvert: "反选当页",
    sortTitle: "排序",
  },
  Modal: {
    okText: "确定",
    cancelText: "取消",
    justOkText: "知道了",
  },
  Popconfirm: {
    cancelText: "取消",
    okText: "确定",
  },
  Transfer: {
    notFoundContent: "无匹配结果",
    searchPlaceholder: "请输入搜索内容",
    itemUnit: "项",
    itemsUnit: "项",
  },
  Select: {
    notFoundContent: "无匹配结果",
  },
  Upload: {
    uploading: "文件上传中",
    removeFile: "删除文件",
    uploadError: "上传错误",
    previewFile: "预览文件",
  },
};
