import React, { useEffect, useRef, useState, useCallback } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import { useSelector } from "react-redux";
import { Layout, Button, List, Checkbox, Modal } from "antd";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { Locale, ApiManager } from "../../utils";
import Base from "../Base";
import Avatar from "antd/lib/avatar/avatar";
import { AMAP_KEY, AMAP_VERSION, AMAP_ZOOM } from "../../constants";

const { Content, Header } = Layout;

const i18n = Locale.getInstance();
const base = Base.getInstance();
const api = ApiManager.getInstance();

function ServiceAreaSetting({ visible, onClose }) {
  let map = useRef(null);
  let polyEditor = useRef(null);
  let searchArea = useRef(() => {});
  const user = useSelector((state) => getUserInfo(state));
  const [selectedList, setSelectedList] = useState([]);
  const [loading, setLoading] = useState(false);

  searchArea.current = (AMap, polygon) => {
    const search = new AMap.PlaceSearch({
      pageSize: 5, // 单页显示结果条数
      pageIndex: 1, // 页码
      citylimit: true, //是否强制限制在设置的城市内搜索
      map: map.current, // 展现结果的地图实例
      panel: "map-result", // 结果列表将在此容器中进行展示。
      autoFitView: false, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
      type:
        "餐饮|酒店|商务住宅|政府机构及社会团体|银行|公司企业|学校|医院|地名",
    });
    search.searchInBounds("", polygon);
    search.on("listElementClick", ({ data }) => {
      const { adname, cityname, location, name, photos, address } = data;
      const listItem = {
        adname,
        cityname,
        location,
        name,
        photos,
        address,
        checked: true,
      };
      setSelectedList((list) => {
        if (list.length > 0 && list.findIndex((i) => i.name === name) !== -1) {
          return list;
        }
        return [...list, listItem];
      });
    });
  };

  useEffect(() => {
    AMapLoader.load({
      key: AMAP_KEY,
      version: AMAP_VERSION,
      plugins: [
        "AMap.PolygonEditor",
        "AMap.PlaceSearch",
        "AMap.Marker",
        "AMap.LngLat",
        "AMap.Polygon",
      ],
    })
      .then((AMap) => {
        const { longitude, latitude } = user.merchant;
        map.current = new AMap.Map("map-container", {
          center: [longitude, latitude],
          zoom: AMAP_ZOOM,
        });

        const shopMarker = new AMap.Marker({
          position: new AMap.LngLat(longitude, latitude),
          label: { content: "您的店铺的位置", direction: "top" },
        });
        map.current.add(shopMarker);

        const offset = 0.001;
        const path = [
          [longitude + offset, latitude + offset],
          [longitude + offset, latitude - offset],
          [longitude - offset, latitude - offset],
          [longitude - offset, latitude + offset],
        ];
        const polygon = new AMap.Polygon({
          path,
        });
        map.current.add(polygon);

        polyEditor.current = new AMap.PolygonEditor(map.current, polygon);
        polyEditor.current.addAdsorbPolygons(polygon);
        polyEditor.current.open();

        polyEditor.current.on("addnode", function (data) {
          searchArea.current(AMap, data.target);
        });
        polyEditor.current.on("adjust", function (data) {
          searchArea.current(AMap, data.target);
        });
      })
      .catch((e) => {
        console.log("ServiceAreaSetting -> e", e);
      });
  }, [user.merchant]);

  const onFinish = useCallback(async () => {
    const { token } = user;
    if (token) {
      const list = selectedList.filter((i) => i.checked);
      if (list.length > 0) {
        let apiUrl = api.community.add;
        try {
          setLoading(true);
          const res = await Promise.all(
            list.map((item) => {
              const { location, name, address } = item;
              let apiConfig = {
                token,
                data: {
                  zh_name: name,
                  ug_name: name,
                  zh_address: address,
                  ug_address: address,
                  order: 0,
                  latitude: location.lat,
                  longitude: location.lng,
                  population: 0,
                },
              };
              return apiUrl.run(apiConfig);
            })
          );
          if (Array.isArray(res) && res.every((i) => i.result)) {
            base.showMessage(i18n.t("community.success"), Base.Message.success);
            onClose();
          } else {
            base.showMessage(i18n.t("base.api.errUnknown"), Base.Message.error);
          }
        } catch (error) {
          base.handleApiError(apiUrl, error);
        } finally {
          setLoading(false);
        }
      } else {
        base.showMessage("至少选择一个小区", Base.Message.warning);
      }
    }
  }, [selectedList, user, onClose]);

  return (
    <Modal
      forceRender
      visible={visible}
      onCancel={onClose}
      width={1000}
      footer={
        <Button loading={loading} onClick={onFinish} type="primary">
          {i18n.t("community.add")}
        </Button>
      }
    >
      <Layout>
        <Header style={{ backgroundColor: "#fff" }} />
        <Content
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <div id="map-container" style={{ height: 500, width: "100%" }} />
          <div
            id="map-result"
            style={{ position: "absolute", top: 0, right: 0 }}
          />
        </Content>
        <List
          bordered
          loading={loading}
          style={{
            backgroundColor: "#fff",
            maxHeight: 400,
            overflowY: "scroll",
          }}
          dataSource={selectedList}
          renderItem={(item) => (
            <List.Item key={item.name}>
              <Checkbox
                checked={item.checked}
                onChange={() => {
                  let list = selectedList;
                  const idx = list.findIndex((i) => i.name === item.name);
                  list.splice(idx, 1, { ...item, checked: !item.checked });
                  setSelectedList([...list]);
                }}
                style={{ marginLeft: 20, marginRight: 20 }}
              />
              <List.Item.Meta
                avatar={
                  <Avatar
                    size="large"
                    shape="square"
                    src={Array.isArray(item.photos) ? item.photos[0].url : ""}
                  />
                }
                title={item.name}
                description={`${item.cityname} ${item.adname} ${item.address}`}
              />
            </List.Item>
          )}
        />
      </Layout>
    </Modal>
  );
}

export default ServiceAreaSetting;
