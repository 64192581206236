import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  message,
  Tag,
  Avatar,
  Button,
  Divider,
  Typography,
  Space,
  Timeline,
  InputNumber,
  Image,
} from "antd";
import Search from "antd/lib/input/Search";
import { ApiManager, Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { LeftOutlined } from "@ant-design/icons";

import Base from "../Base";

const { Text } = Typography;
const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const base = Base.getInstance();

function LotteryRecord({ router }) {
  const thisSorter = useRef();
  const thisPagination = useRef();
  const thisFilters = useRef();
  const thisKeyword = useRef();
  const fetchList = useRef();
  const [list, setList] = useState({ data: [], current_page: 1 });
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const user = useSelector((state) => getUserInfo(state));

  fetchList.current = useCallback(
    async (page = 1, keyword = "", sorter = null) => {
      const { token } = user;
      if (token) {
        setLoading(true);
        try {
          const { id, type } = router.location.state;
          let apiUrl = api.lottery.distributedList;
          if (type === "transaction") {
            apiUrl = api.lottery.transLog;
          }
          if (type === "prize") {
            apiUrl = api.lottery.drawLog;
          }
          if (type === "buy_ticket") {
            apiUrl = api.lottery.bought;
          }
          const apiConfig = {
            token,
            path: { id },
            query: {
              page,
              keyword,
              count: 10,
            },
          };
          if (
            thisSorter.current &&
            thisSorter.current.field &&
            thisSorter.current.order
          ) {
            const order = thisSorter.current.order.split("end")[0];
            apiConfig.query[
              `filter[sort]`
            ] = `${thisSorter.current.field}@${order}`;
          }
          if (thisFilters && thisFilters.current) {
            for (let key in thisFilters.current) {
              if (thisFilters.current[key]) {
                apiConfig.query[`filter[${key}]`] = thisFilters.current[
                  key
                ].join(",");
              }
            }
          }
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setList(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [user, router.location.state]
  );

  async function onReduceTicketAmount(data) {
    if (user.token) {
      const apiUrl = api.lottery.reduce;
      const apiConfig = {
        token: user.token,
        path: { id: data.lottery_id },
        data: {
          user_id: data.user_id,
          tickets: count,
        },
      };
      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          base.showMessage(res.message, Base.Message.success);
          setSelectedRecordId(null);
          fetchList.current(thisPagination.current, thisKeyword.current);
        } else {
          base.showMessage(res.message, Base.Message.warning);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    }
  }

  async function onRevertThisTrans(id) {
    if (user.token) {
      const apiUrl = api.lottery.revert;
      const apiConfig = {
        token: user.token,
        path: { id },
      };
      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          base.showMessage(res.message, Base.Message.success);
          setSelectedRecordId(null);
          fetchList.current(thisPagination.current, thisKeyword.current);
        } else {
          base.showMessage(res.message, Base.Message.warning);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    }
  }

  function onExchangeRedPacket(id) {
    if (user.token) {
      base.showModal(
        i18n.t("lottery.exchangeAlert"),
        Base.Modal.confirm,
        async () => {
          const apiUrl = api.lottery.exchange;
          const apiConfig = {
            token: user.token,
            path: { drawId: id },
          };
          try {
            setLoading(true);
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              base.showMessage(res.message, Base.Message.success);
              fetchList.current(thisPagination.current, thisKeyword.current);
            } else {
              base.showMessage(res.message, Base.Message.warning);
            }
          } catch (error) {
            base.handleApiError(apiUrl, error);
          } finally {
            setLoading(false);
          }
        }
      );
    }
  }

  function getColumns() {
    let columns = [];
    const { type } = router.location.state;
    if (type === "transaction") {
      columns = columns.concat([
        {
          title: i18n.t("lottery.sender"),
          key: "trans",
          align: "center",
          render: (text, record) => {
            return (
              <Space direction="vertical" align="center">
                <Avatar src={record.sender.avatar} size="large" />
                {record.sender.nickname}
              </Space>
            );
          },
        },
        {
          title: i18n.t("commissionList.channel"),
          key: "channel",
          align: "center",
          filters: list.channels
            ? Object.keys(list.channels).map((k) => ({
                text: list.channels[k],
                value: k,
              }))
            : [],
          render: (text, record) => {
            return <Tag color="purple">{record.type_label}</Tag>;
          },
        },
        {
          title: i18n.t("lottery.receiver"),
          key: "trans",
          align: "center",
          render: (text, record) => {
            return (
              <Space direction="vertical" align="center">
                <Avatar src={record.receiver.avatar} size="large" />
                {record.receiver.nickname}
              </Space>
            );
          },
        },
        {
          title: i18n.t("lottery.ticketCount"),
          key: "tickets",
          dataIndex: "tickets",
          align: "center",
          render: (text, record) => {
            return <Tag color="green">{record.quantity}</Tag>;
          },
        },
        {
          title: i18n.t("users.date"),
          key: "date",
          dataIndex: "created_at",
          align: "center",
          sorter: true,
        },
      ]);
    } else {
      if (type === "prize") {
        columns = columns.concat([
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => {
              return (
                <Avatar
                  size="large"
                  shape="circle"
                  src={record.user.avatar}
                  alt={record.user.nickname}
                />
              );
            },
          },
          {
            title: i18n.t("users.name"),
            dataIndex: "nickname",
            key: "nickname",
            render: (text, record) => {
              return record.user.nickname;
            },
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            dataIndex: "mobile",
            align: "center",
            render: (text, record) => {
              return record.user.mobile;
            },
          },
          {
            title: i18n.t("lottery.drew_at"),
            key: "drew_at",
            dataIndex: "drew_at",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("lottery.prize"),
            key: "prize",
            dataIndex: "prize",
            align: "center",
            render: (text, record) => {
              if (record.prize) {
                return (
                  <Image
                    src={i18n.getPropNameByLocale(record.prize, "image")}
                    width={80}
                  />
                );
              }
              return null;
            },
          },
          {
            title: i18n.t("lottery.exchanged_at"),
            key: "exchanged_at",
            dataIndex: "exchanged_at",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("order.orderNo"),
            key: "order_no",
            dataIndex: "order_no",
            align: "center",
            render: (text, record) => {
              if (record.order) {
                return <Text copyable>{record.order.order_no}</Text>;
              }
              return null;
            },
          },
          {
            title: i18n.t("product.cats.actions"),
            key: "actions",
            align: "center",
            render: (text, record) => {
              if (record.prize && record.prize.type === 1) {
                return (
                  <Button
                    type="link"
                    onClick={() => onExchangeRedPacket(record.id)}
                  >
                    {i18n.t("lottery.toExchange")}
                  </Button>
                );
              }
              return null;
            },
          },
        ]);
      } else {
        columns = columns.concat([
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => {
              return (
                <Avatar
                  size="large"
                  shape="circle"
                  src={record.user ? record.user.avatar : ""}
                  alt={record.user ? record.user.nickname : ""}
                />
              );
            },
          },
          {
            title: i18n.t("users.name"),
            dataIndex: "nickname",
            key: "nickname",
            render: (text, record) => {
              return record.user ? record.user.nickname : "";
            },
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            dataIndex: "mobile",
            align: "center",
            render: (text, record) => {
              return record.user ? record.user.mobile : "";
            },
          },
          {
            title: i18n.t("lottery.ticketCount"),
            key: "tickets",
            dataIndex: "tickets",
            align: "center",
            sorter: true,
            render: (text, record) => {
              if (type === "buy_ticket") {
                return <Tag color="green">{record.amount}</Tag>;
              }
              return <Tag color="green">{record.tickets}</Tag>;
            },
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "created_at",
            align: "center",
            sorter: true,
          },
        ]);
        if (type === "buy_ticket") {
          columns.splice(columns.length - 1, 0, {
            title: i18n.t("commissionList.order_no"),
            key: "order_no",
            dataIndex: "order_no",
            align: "center",
          });
        }
      }
    }

    if (type !== "prize" && type !== "buy_ticket") {
      columns.push({
        title: i18n.t("product.cats.actions"),
        key: "actions",
        align: "center",
        render: (text, record) => {
          if (type === "transaction" && record.type === 0) {
            return (
              <Button
                type="link"
                danger
                onClick={() => onRevertThisTrans(record.id)}
              >
                {i18n.t("lottery.revert")}
              </Button>
            );
          }
          if (record.id === selectedRecordId) {
            return (
              <Space>
                <InputNumber
                  min={1}
                  max={record.tickets}
                  value={count}
                  onChange={(value) => setCount(value)}
                />
                <Button
                  type="primary"
                  onClick={() => onReduceTicketAmount(record)}
                >
                  {i18n.t("base.modal.okText")}
                </Button>
                <Button onClick={() => setSelectedRecordId(null)}>
                  {i18n.t("base.modal.cancelText")}
                </Button>
              </Space>
            );
          }
          return (
            <Button type="link" onClick={() => setSelectedRecordId(record.id)}>
              {i18n.t("lottery.reduce")}
            </Button>
          );
        },
      });
    }

    return columns;
  }

  useEffect(() => {
    fetchList.current();
  }, [fetchList]);

  const { type } = router.location.state;
  return (
    <>
      <Space>
        <Button
          type="link"
          onClick={() => {
            router.goBack();
          }}
        >
          <LeftOutlined />
          {i18n.t("productEdit.headerBack")}
        </Button>
        <Divider type="vertical" />
        <Text>{router.location.state.title}</Text>
      </Space>
      <Search
        onSearch={(keyword) => {
          thisKeyword.current = keyword;
          fetchList.current(1, keyword);
        }}
        allowClear
        placeholder={i18n.t("users.searchPlace")}
        style={{
          width: 300,
          marginBottom: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
        enterButton
      />
      <Table
        loading={loading}
        dataSource={list.data}
        rowKey={(record) => `${record.id}`}
        onChange={(pagination, filters, sorter) => {
          thisSorter.current = sorter;
          thisPagination.current = pagination.current;
          thisFilters.current = filters;
          fetchList.current(pagination.current, thisKeyword.current, sorter);
        }}
        pagination={{
          current: list.current_page,
          total: list.total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        expandable={
          !type && {
            expandedRowRender: (record) => {
              if (
                Array.isArray(record.change_log) &&
                record.change_log.length > 0
              ) {
                return (
                  <div>
                    <Timeline mode={i18n.isLocaleRTL() ? "right" : "left"}>
                      {record.change_log.map((i, idx) => (
                        <Timeline.Item
                          key={`record-${idx}`}
                          label={i.changed_at.date}
                        >
                          {i.action} {i.change_log}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                );
              }
            },
          }
        }
        columns={getColumns()}
      />
    </>
  );
}

export default LotteryRecord;
