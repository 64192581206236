import { Button, message } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Locale, ApiManager } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();

function TranslateBtn({ context, onFinish, from, to, btnType }) {
  const [translating, setTranslate] = useState(false);
  const user = useSelector((state) => getUserInfo(state));
  if (user.merchant && user.merchant.is_kz_merchant) {
    if (from === "ug") {
      from = "kz";
    }
    if (to === "ug") {
      to = "kz";
    }
  }

  function onTranslate(text) {
    return new Promise(async (resolve, reject) => {
      const apiUrl = api.product.translate;
      const apiConfig = {
        data: {
          text,
          from,
          to,
        },
      };
      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          message.success(res.message);
          resolve(res);
        } else {
          message.warning(res.message);
          reject(res);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async function onTranslateUgName() {
    try {
      setTranslate(true);
      const res = await onTranslate(context);
      if (res.result) {
        onFinish(res.data.translation);
      }
    } catch (error) {
      console.log("TCL: ProductEdit -> onTranslateUgName -> error", error);
    } finally {
      setTranslate(false);
    }
  }

  return (
    <Button
      loading={translating}
      disabled={translating}
      onClick={onTranslateUgName}
      style={{ borderWidth: 0, backgroundColor: "transparent" }}
      size="small"
      type={btnType ?? "default"}
    >
      {i18n.t("productEdit.translate")}
    </Button>
  );
}

export default TranslateBtn;
