export default {
  categories: {
    name: "product categories list api",
    version: 4,
    url: "categories",
    method: "GET",
  },
  merchantCategories: {
    name: "thrird party shop product categories list api",
    version: 6,
    url: "categories",
    method: "GET",
  },
  addMerchantCategories: {
    name: "thrird party shop product categories add api",
    version: 6,
    url: "categories",
    method: "POST",
  },
  updateMerchantCategories: {
    name: "thrird party shop product categories update api",
    version: 6,
    url: "categories/%{id}",
    method: "PUT",
  },
  deleteMerchantCategories: {
    name: "thrird party shop product categories delete api",
    version: 6,
    url: "categories/%{id}",
    method: "DELETE",
  },
  saveCategoriesOrder: {
    name: "save categories order api",
    version: 5,
    url: "bbshop/merchant/categories/save-order",
    method: "POST",
  },
  addDiscount: {
    name: "add discount",
    version: 5,
    url: "bbshop/merchant/discounted-product",
    method: "POST",
  },
  removeDiscount: {
    name: "remove discount",
    version: 5,
    url: "bbshop/merchant/discounted-product/%{id}",
    method: "DELETE",
  },
  jdCategories: {
    name: "jd product categories list api",
    version: 5,
    url: "jd/merchant/categories",
    method: "GET",
  },
  updateCategories: {
    name: "update product categories list api",
    version: 4,
    url: "categories",
    method: "POST",
  },
  products: {
    name: "product list api",
    version: 4,
    url: "product",
    method: "GET",
  },
  jdProducts: {
    name: "jd product list api",
    version: 5,
    url: "jd/merchant/products",
    method: "GET",
  },
  customAdd: {
    name: "custom product add api",
    version: 4,
    url: "custom-product",
    method: "POST",
  },
  customUpdate: {
    name: "custom product update api",
    version: 4,
    url: "custom-product/%{id}",
    method: "PUT",
  },
  jdCustomUpdate: {
    name: "jd custom product update api",
    version: 5,
    url: "jd/merchant/custom-product/%{id}",
    method: "PUT",
  },
  jdCustomDelete: {
    name: "jd delete product api",
    version: 5,
    url: "jd/merchant/custom-product/%{id}",
    method: "DELETE",
  },
  jdProductImg: {
    name: "jd get product imgs api",
    version: 5,
    url: "jd/merchant/product/images/%{sku_id}",
    method: "get",
  },
  jdProductDesc: {
    name: "jd get product description api",
    version: 5,
    url: "jd/merchant/product/description/%{sku_id}",
    method: "get",
  },
  batchUpDown: {
    name: "batch up down api",
    version: 4,
    url: "product/batch-updown",
    method: "POST",
  },
  translate: {
    name: "translate",
    version: 5,
    url: "api/translate",
    method: "POST",
  },
};
