import React from "react";

import { Modal, Layout, List } from "antd";

import Base from "../Base";

class Order100Modal extends Base {
  render() {
    return (
      <Modal
        className="route-order-list-send-modal"
        visible
        title={this.getTitle()}
        onCancel={this.onCancel}
        footer={null}
      >
        <Layout className="order-send-wrap">
          <List
            bordered
            dataSource={this.getPropOrderData()}
            renderItem={(item) => (
              <List.Item>
                {item.time} {item.context}
              </List.Item>
            )}
          />
        </Layout>
      </Modal>
    );
  }

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  getPropOrderData = () => {
    return this.props.order || [];
  };

  getTitle() {
    return this.i18n.t("order.kuaidi100Title");
  }
}

export default Order100Modal;
