import React from "react";
import { List, Button, Space, Tag, Divider, Radio } from "antd";
import { connect } from "react-redux";
import moment from "dayjs";

import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import RedPacketForm from "./RedPacketForm";
import { FILTER_STATUS } from "../../constants";

const PACKET = {
  GENERATING: 0,
  SUCCESSFUL: 1,
  FAILED: 2,
};

class RedPacketsList extends Base {
  constructor(props) {
    super(props);
    this.state = {
      data: { data: [] },
      loading: false,
      currentPacket: null,
      filterStatus: FILTER_STATUS.ALL.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetDataList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterStatus !== this.state.filterStatus) {
      this.onGetDataList();
    }
  }

  onGetDataList = async (page = 1) => {
    const api = this.api.redPacket.list;
    try {
      const token = this.getUserToken();
      if (token) {
        const { filterStatus } = this.state;
        const apiConfig = {
          token,
          query: {
            page,
            count: 10,
            "filter[status]": filterStatus,
          },
        };
        this.setState({ loading: true });
        const res = await api.run(apiConfig);
        if (res.result && this.mounted) {
          this.setState({
            data: res.data,
          });
        } else {
          this.showMessage(res.message);
        }
      }
    } catch (error) {
      this.handleApiError(api, error);
    } finally {
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
  };

  onDeleteItem(id) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const api = this.api.redPacket.delete;
        if (this.mounted) {
          try {
            const token = this.getUserToken();
            if (token) {
              const apiConfig = {
                token,
                path: {
                  id,
                },
              };
              const res = await api.run(apiConfig);
              if (res.result) {
                this.onGetDataList();
              } else {
                this.showMessage(res.message, Base.Message.warning);
              }
            }
          } catch (error) {
            this.handleApiError(api, error);
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  onGoToEdit = (data) => {
    this.setState({ currentPacket: data });
  };

  onGo2Detail = (data) => {
    const { router } = this.props;
    router.push({
      pathname: "/red-packet-detail",
      state: {
        details: data.details,
      },
    });
  };

  onRegenerate = async (id) => {
    const api = this.api.redPacket.retry;
    if (this.mounted) {
      try {
        const token = this.getUserToken();
        if (token) {
          this.setState({ loading: true });
          const apiConfig = {
            token,
            path: {
              id,
            },
          };
          const res = await api.run(apiConfig);
          if (res.result) {
            this.onGetDataList();
          } else {
            this.showMessage(res.message, Base.Message.warning);
          }
        }
      } catch (error) {
        this.handleApiError(api, error);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  onAddRedPacket = () => {
    this.setState({
      currentPacket: {
        id: -1,
      },
    });
  };

  onHideSendForm = () => this.setState({ currentPacket: null });

  getFormattedDateString(date) {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  }

  getStatusColor(gStatus) {
    if (gStatus === PACKET.SUCCESSFUL) return "#87d068";
    if (gStatus === PACKET.FAILED) return "#f50";
    return "#2db7f5";
  }

  getActionsBtns(item) {
    const { loading } = this.state;
    let actions = [
      <Button
        key="list-loadmore-edit"
        type="link"
        onClick={() => this.onGoToEdit(item)}
      >
        {this.i18n.t("redPacket.update")}
      </Button>,
      <Button
        key="list-loadmore-more"
        type="link"
        onClick={() => this.onDeleteItem(item.id)}
        danger
      >
        {this.i18n.t("sales.activity.delete")}
      </Button>,
    ];
    if (item.generating_status === PACKET.SUCCESSFUL) {
      actions = [
        <Button
          key="list-loadmore-log"
          type="link"
          onClick={() => {
            this.onGo2Detail(item);
          }}
        >
          {this.i18n.t("redPacket.list")}
        </Button>,
      ].concat(actions);
    }
    if (item.generating_status === PACKET.FAILED) {
      actions = [
        <Button
          key="list-loadmore-log"
          type="link"
          danger
          disabled={loading}
          loading={loading}
          onClick={() => {
            this.onRegenerate(item.id);
          }}
        >
          {this.i18n.t("redPacket.retry")}
        </Button>,
      ].concat(actions);
    }
    return actions;
  }

  render() {
    const { data, loading, currentPacket, filterStatus } = this.state;
    return (
      <>
        <List
          bordered
          size="large"
          style={{ backgroundColor: "#fff" }}
          header={
            <Space>
              <Button type="primary" onClick={() => this.onAddRedPacket()}>
                {this.i18n.t("redPacket.add")}
              </Button>
              <Radio.Group
                style={{ marginRight: 20, marginLeft: 20 }}
                onChange={(e) => {
                  this.setState({ filterStatus: e.target.value });
                }}
                value={filterStatus}
              >
                <Radio value={FILTER_STATUS.ALL.value}>
                  {FILTER_STATUS.ALL.name}
                </Radio>
                <Radio value={FILTER_STATUS.OPENED.value}>
                  {FILTER_STATUS.OPENED.name}
                </Radio>
                <Radio value={FILTER_STATUS.CLOSED.value}>
                  {FILTER_STATUS.CLOSED.name}
                </Radio>
              </Radio.Group>
            </Space>
          }
          itemLayout="horizontal"
          dataSource={data.data}
          loading={loading}
          pagination={{
            current: data.current_page,
            pageSize: 10,
            onChange: (page) => this.onGetDataList(page),
            showQuickJumper: true,
            total: data ? data.total : 0,
          }}
          renderItem={(item) => (
            <List.Item
              actions={this.getActionsBtns(item)}
              style={{
                backgroundColor: item.status === 1 ? "#ffffff" : "#f1f1f1",
              }}
            >
              <List.Item.Meta
                title={
                  <Space direction="horizontal">
                    {`#${item.id}`}
                    <Divider type="vertical" />
                    {this.i18n.getPropNameByLocale(item, "name")}
                    <Tag
                      style={{
                        fontFamily: this.i18n.isLocaleRTL() ? "Alp Ekran" : "",
                      }}
                      color={this.getStatusColor(item.generating_status)}
                    >
                      {item.generating_status_label}
                    </Tag>
                  </Space>
                }
                description={`${this.i18n.t(
                  "sales.activity.start_date"
                )} ${this.getFormattedDateString(
                  item.start_time
                )} - ${this.i18n.t(
                  "sales.activity.end_date"
                )} ${this.getFormattedDateString(item.end_time)}`}
              />
            </List.Item>
          )}
        />
        <RedPacketForm
          selectedPacket={currentPacket}
          onCancel={this.onHideSendForm}
          onGetDataList={this.onGetDataList}
        />
      </>
    );
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(RedPacketsList);
