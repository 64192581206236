import { ACTIONS } from "../actions/Merchant";

const initialState = {};

export const merchant = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.SET_MERCHANT_SETTINGS: {
      return action.settings;
    }
    default: {
      return state;
    }
  }
};

export const getMerchantSettings = (state) => {
  return state.merchant;
};
