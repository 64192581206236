import React, { useState, useEffect, useCallback } from "react";
import { Modal, Input, List, message, Tag, Radio, Image } from "antd";
import { connect } from "react-redux";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { Locale, ApiManager } from "../../../utils";

import "./DistributedListModal.scss";

const { Search } = Input;
const i18n = Locale.getInstance();
const api = ApiManager.getInstance();

function DistributedListModal({ onCancel, user, selectedCouponId }) {
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState("all");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ data: [], current_page: 1 });

  const fetchData = useCallback(async () => {
    if (selectedCouponId == null) {
      return;
    }
    const token = user.token;
    let distributedList = api.coupons.distributedList;
    const query = {
      page: data.current_page,
      "filter[keyword]": keyword,
      "filter[type]": type,
      count: 5,
    };
    if (token) {
      const apiConfig = {
        token,
        path: { id: selectedCouponId },
        query,
      };
      try {
        setLoading(true);
        const res = await distributedList.run(apiConfig);
        setLoading(false);
        if (res.result) {
          setData(res.data);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, [data.current_page, keyword, type, user, selectedCouponId]);

  useEffect(() => {
    fetchData();
    return () => {};
  }, [data.current_page, keyword, type, selectedCouponId, fetchData]);

  const clearData = () => {
    setData({ data: [], current_page: 1 });
    setType("all");
    setLoading(false);
    setKeyword("");
  };
  const getDescription = (item) => {
    return (
      <div className="distribute-info">
        <div className="item">
          <span className="title">
            {i18n.t("sales.coupons.distributedDate")}
          </span>
          <span className="desc">{item.pivot.created_at}</span>
        </div>
        <div className="item">
          <span className="title">{i18n.t("sales.coupons.usedDate")}</span>
          <span className="desc">
            {item.pivot.order_id !== "null" ? item.pivot.updated_at : ""}
          </span>
        </div>
        <div className="item">
          <span className="title">{i18n.t("sales.coupons.orderNo")}</span>
          <span className="desc">{item.order_no ? item.order_no : ""}</span>
        </div>
        <div className="item">
          {item.order_no ? (
            <Tag color="cyan">{i18n.t("sales.coupons.used")}</Tag>
          ) : (
            <Tag color="magenta">{i18n.t("sales.coupons.unused")}</Tag>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      width={600}
      title={
        <div>
          <Search
            allowClear
            style={{ width: 400 }}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onSearch={() => {
              fetchData();
            }}
          />
          <Radio.Group
            value={type}
            onChange={(e) => setType(e.target.value)}
            style={{ marginTop: 20 }}
          >
            <Radio key={`type-all`} value="all">
              {i18n.t("sales.coupons.all")}
            </Radio>
            <Radio key={`type-used`} value={1}>
              {i18n.t("sales.coupons.used")}
            </Radio>
            <Radio key={`type-unused`} value={0}>
              {i18n.t("sales.coupons.unused")}
            </Radio>
          </Radio.Group>
        </div>
      }
      visible={selectedCouponId !== null}
      onCancel={() => {
        clearData();
        onCancel();
      }}
      footer={null}
    >
      <List
        itemLayout="horizontal"
        dataSource={data.data}
        loading={loading}
        pagination={{
          current: data.current_page,
          total: data.total,
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: (page) => setData({ ...data, current_page: page }),
        }}
        renderItem={(item, idx) => {
          return (
            <List.Item key={`history-${idx}`}>
              <div style={{ width: "100%" }}>
                <List.Item.Meta
                  avatar={<Image alt="" src={item.avatar} width={80} />}
                  title={
                    <div className="distribute-info line">
                      <div className="item">{item.nickname}</div>
                      <div className="item">{item.mobile}</div>
                    </div>
                  }
                  description={getDescription(item)}
                />
              </div>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  DistributedListModal
);
