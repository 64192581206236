import axios from "axios";
import { Locale } from "../";
class Request {
  static get(uri, token = "") {
    if (uri.indexOf("?") !== -1) {
      uri += "&t=" + new Date().getTime();
    } else {
      uri += "?t=" + new Date().getTime();
    }
    return Request._fetch("GET", uri, {}, token);
  }
  static post(uri, data, token = "") {
    return Request._fetch("POST", uri, data, token);
  }
  static put(uri, data, token = "") {
    return Request._fetch("PUT", uri, data, token);
  }
  static delete(uri, token = "") {
    return Request._fetch("DELETE", uri, {}, token);
  }
  static upload(uri, files, token, type = "") {
    let data = new FormData();
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (!file.file) {
        if (file.type && file.type.indexOf("video") !== -1) {
          data.append(`files[]`, file, file.name);
        } else {
          data.append(`files[]`, file, "images.png");
        }
      } else {
        data.append(`files[]`, file.file, file.file.name);
      }
    }
    data.append("for", type);
    let options = {
      method: "POST",
      headers: {
        "Accept-Language": Request._getDefaultLocale(),
        Authorization: token,
      },
      data,
    };
    return Request._fetchPromise(uri, options);
  }
  static _fetch(method, uri, data = {}, token = "") {
    let options = {
      method,
      headers: {
        "Accept-Language": Request._getDefaultLocale(),
        Authorization: token,
      },
      data,
    };
    return Request._fetchPromise(uri, options);
  }
  static _fetchPromise(uri, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios(uri, {
          ...options,
          timeout: 30000,
          timeoutErrorMessage: "response time out",
        });
        if (res.status === 200) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  static _getDefaultLocale() {
    return Locale.getLocale();
  }
}

export default Request;
