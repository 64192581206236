import React, { useCallback, useRef, useEffect } from "react";
import { Form, Input, Radio, message, Button } from "antd";
import { Locale, ApiManager } from "../../utils";

import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();

function AccountInfoSettingForm({ user }) {
  const [form] = Form.useForm();
  const refData = useRef();

  const getData = useCallback(async () => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.merchant.getMerchantAccountInfo;
        const apiConfig = {
          token,
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          const {
            id,
            contact_name,
            contact_phone,
            bank_name,
            bank_account,
            bank_account_name,
            bank_account_type,
            account_bank,
            bank_branch_id,
            bank_address_code,
          } = res.data;
          refData.current = res.data;
          form.setFieldsValue({
            id,
            contact_name,
            contact_phone,
            bank_name,
            bank_account,
            bank_account_name,
            bank_account_type,
            account_bank,
            bank_address_code,
            bank_branch_id,
          });
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [user, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFinish = useCallback(
    async (values) => {
      const { token } = user;
      if (token) {
        const {
          contact_name,
          contact_phone,
          bank_name,
          bank_account,
          bank_account_name,
          bank_account_type,
          account_bank,
          bank_address_code,
          bank_branch_id,
        } = values;
        const apiConfig = {
          token,
          data: {
            contact_name,
            contact_phone,
            bank_name,
            bank_account,
            bank_account_name,
            bank_account_type,
            account_bank,
            bank_address_code,
            bank_branch_id,
          },
        };
        const apiUrl = api.merchant.updateMerchantAccountInfo;
        apiConfig.path = { id: refData.current.id };

        try {
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            message.success(res.message);
            getData();
          } else {
            message.warn(res.message);
          }
        } catch (error) {
          message.error(error.message);
        }
      }
    },
    [user, getData]
  );

  return (
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 8 }}
    >
      <Form.Item
        name="contact_name"
        label={i18n.t("financeAccount.ballanceInfoContact")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="contact_phone"
        label={i18n.t("financeAccount.ballanceInfoContactPhone")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bank_account_type"
        label={i18n.t("financeAccount.bankInfoType")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Radio.Group>
          <Radio value="74">
            {i18n.t("financeAccount.bankInfoBankTypeCompany")}
          </Radio>
          <Radio value="75">
            {i18n.t("financeAccount.bankInfoBankTypePrivate")}
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="bank_name"
        label={i18n.t("financeAccount.bankInfoNameFull")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="account_bank"
        label={i18n.t("financeAccount.bankInfoName")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bank_account"
        label={i18n.t("financeAccount.bankInfoAccount")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bank_account_name"
        label={i18n.t("financeAccount.bankInfoAccountName")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="bank_branch_id"
        extra="请参考：https://pay.weixin.qq.com/wiki/doc/apiv3/terms_definition/chapter1_1_3.shtml#part-7"
        label={i18n.t("financeAccount.bankInfoBranchId")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bank_address_code"
        extra="请参考：https://pay.weixin.qq.com/wiki/doc/apiv3/terms_definition/chapter1_1_3.shtml#part-6"
        label={i18n.t("financeAccount.bankInfoAddressCode")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4 }}>
        <Button type="primary" htmlType="submit" size="large">
          {i18n.t("sales.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(AccountInfoSettingForm);
