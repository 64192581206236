import React from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Upload,
  Divider,
  Popover,
  Layout,
  Spin,
  Tabs,
  Switch,
  Radio,
} from "antd";
import {
  UploadOutlined,
  UnorderedListOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { ChromePicker, GithubPicker } from "react-color";

import Base from "../Base";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import FormSubmitBtn from "./FormSubmitBtn";
import PaymentSettingForm from "./PaymentSettingForm";

const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;

class MiniProgramSetting extends Base {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: false,
      primaryColor: "#419cb4",
      secondaryColor: "#ffffff",
      weappData: null,
      downloadUrl: null,
      demoTitle: "",
    };
    this.configForm = React.createRef();
    this.settingForm = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetData();
  }

  onChangeSecondaryColor = (color) => {
    this.setState({ secondaryColor: color.hex });
  };

  onChangePrimaryColor = (color) => {
    this.setState({ primaryColor: color.hex });
  };

  onBeforeUpload = (file) => {
    const isLt200KB = file.size / 1024 < 200;
    if (!isLt200KB) {
      this.showMessage(
        this.i18n.t("productEdit.uploadNavImageSizeWarning"),
        Base.Message.error
      );
    }
    return isLt200KB;
  };

  uploadImages = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");

    const token = this.getUserToken();
    if (!token) onError("no user token");

    const uploadFiles = [{ file }];
    const api = this.api.common.uploadImages;
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "weapp-logo",
    };

    try {
      const res = await api.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  async onGetData() {
    const api = this.api.weapp.show;
    try {
      const token = this.getUserToken();
      if (!token) return;

      this.setState({ loading: true });
      const apiConfig = {
        token,
      };
      const res = await api.run(apiConfig);
      if (res.result) {
        this.weappId = res.data.id;
        this.weappLogo = res.data.logo;
        this.onSetInitialValues(res.data);
        if (res.data.config) {
          const { app_color, font_color, url } = res.data.config;
          this.setState({
            primaryColor: app_color,
            secondaryColor: font_color,
            downloadUrl: url,
          });
        }
      }
    } catch (error) {
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  }

  onGenerateWeapp = async () => {
    const api = this.api.weapp.generate;
    const { primaryColor, secondaryColor } = this.state;
    try {
      const token = this.getUserToken();
      if (token && this.weappId) {
        const settingConfig = this.settingForm.current.getFieldsValue([
          "zh_name",
          "ug_name",
          "language",
          "has_live",
        ]);
        const apiConfig = {
          token,
          path: {
            id: this.weappId,
          },
          data: {
            config: {
              ...settingConfig,
              app_color: primaryColor,
              font_color: secondaryColor,
            },
          },
        };
        this.setState({ loading: true });
        const res = await api.run(apiConfig);
        if (res.result) {
          this.showMessage(res.message, Base.Message.success);
          this.setState({ downloadUrl: res.data.url });
        } else {
          this.showModal(res.message, Base.Modal.error);
        }
      }
    } catch (error) {
      console.log("onGenerateWeapp -> error", error);
      this.handleApiError(api, error);
    } finally {
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
  };

  onFinish = async (values) => {
    try {
      const token = this.getUserToken();
      if (!token) return;
      this.setState({ loading: true });
      let api = this.api.weapp.add;
      const apiConfig = {
        token,
      };
      if (this.weappId) {
        api = this.api.weapp.update;
        apiConfig.path = { id: this.weappId };
      }
      const { logo, ...otherValues } = values;
      apiConfig.data = {
        ...otherValues,
      };

      if (logo[0] && logo[0].uid !== -1 && logo[0].response) {
        apiConfig.data.logo = logo[0].response.path;
      }

      const res = await api.run(apiConfig);
      if (res.result) {
        this.onGetData();
      } else {
        this.showMessage(res.message);
      }
    } catch (error) {
      this.handleApiError(error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  };

  onFinishSetting = async (values) => {
    try {
      const token = this.getUserToken();
      if (!token) return;
      if (!this.weappId) {
        this.showModal(this.i18n.t("sales.mini.settingWarning"));
        return;
      }
      this.setState({ loading: true });
      let api = this.api.weapp.update;
      const apiConfig = {
        token,
        path: {
          id: this.weappId,
        },
      };

      const { primaryColor, secondaryColor } = this.state;
      const { zh_name, ug_name, language, has_live } = values;
      apiConfig.data = {
        config: {
          app_color: primaryColor,
          font_color: secondaryColor,
          zh_name,
          ug_name,
          language,
          has_live: has_live ? 1 : 0,
        },
      };

      const res = await api.run(apiConfig);
      if (res.result) {
        this.onGetData();
      } else {
        this.showMessage(res.message);
      }
    } catch (error) {
      this.handleApiError(error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  };

  onSetInitialValues(weappData) {
    if (weappData && weappData.id) {
      const {
        aes_key,
        app_id,
        app_secret,
        description,
        name,
        logo,
        name_abbr,
        token,
        config,
      } = weappData;

      this.configForm.current &&
        this.configForm.current.setFieldsValue({
          aes_key,
          app_id,
          app_secret,
          description,
          name,
          name_abbr,
          token,
          logo: this.util.getDefaultImages(logo),
        });

      if (config && this.settingForm.current) {
        const { has_live, language, ug_name, zh_name } = config;
        this.settingForm.current.setFieldsValue({
          has_live: Number(has_live) === 1,
          language,
          ug_name,
          zh_name,
        });
      }
    }
  }

  render() {
    const {
      loading,
      primaryColor,
      secondaryColor,
      downloadUrl,
      demoTitle,
    } = this.state;
    return (
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          style={{ backgroundColor: "#fff" }}
          tabBarStyle={{ backgroundColor: "#f1f1f1" }}
          direction={this.i18n.isLocaleRTL() ? "rtl" : "ltr"}
        >
          <TabPane tab={this.i18n.t("sales.mini.mainConfig")} key="1">
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 10 }}
              onFinish={this.onFinish}
              ref={this.configForm}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("sales.mini.nameWarning"),
                  },
                ]}
                label={this.i18n.t("sales.mini.name")}
                name="name"
              >
                <Input maxLength={30} />
              </Form.Item>
              <Form.Item
                label={this.i18n.t("sales.mini.shortName")}
                name="name_abbr"
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("sales.mini.appIdWarning"),
                  },
                ]}
                label="AppID"
                name="app_id"
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("sales.mini.appSecretWarning"),
                  },
                ]}
                label="AppSecret"
                name="app_secret"
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("sales.mini.tokenWarning"),
                  },
                ]}
                label="Token"
                name="token"
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("sales.mini.aesKeyWarning"),
                  },
                ]}
                label="EncodingAESKey"
                name="aes_key"
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("sales.mini.descriptionWarning"),
                  },
                ]}
                label={this.i18n.t("sales.mini.description")}
                name="description"
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("sales.mini.imgWarning"),
                  },
                ]}
                label={this.i18n.t("sales.mini.img")}
                name="logo"
                valuePropName="fileList"
                getValueFromEvent={(e) => this.util.normFile4Img(e)}
              >
                <Upload
                  accept="image/*"
                  multiple={false}
                  listType="picture"
                  beforeUpload={this.onBeforeUpload}
                  customRequest={this.uploadImages}
                >
                  <Button>
                    <UploadOutlined /> {this.i18n.t("sales.upload")}
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 10 }}>
                <FormSubmitBtn
                  weappId={this.weappId}
                  downloadUrl={downloadUrl}
                  onGenerateWeapp={this.onGenerateWeapp}
                  loading={loading}
                />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane
            tab={this.i18n.t("sales.mini.secondConfig")}
            key="2"
            forceRender
          >
            <Row
              style={{
                backgroundColor: "#fff",
              }}
            >
              <Col style={{ width: "50%" }}>
                <Form
                  ref={this.settingForm}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 14 }}
                  onFinish={this.onFinishSetting}
                >
                  <Form.Item
                    name="zh_name"
                    label={this.i18n.t("sales.mini.zhTitle")}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="ug_name"
                    label={this.i18n.t("sales.mini.ugTitle")}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="language"
                    label={this.i18n.t("sales.mini.language")}
                    initialValue="ug"
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={(e) => {
                        this.setState({
                          demoTitle:
                            this.settingForm.current &&
                            this.settingForm.current.getFieldValue(
                              `${e.target.value}_name`
                            ),
                        });
                      }}
                    >
                      <Radio.Button value="ug">
                        {this.i18n.t("sales.mini.ug")}
                      </Radio.Button>
                      <Radio.Button value="zh">
                        {this.i18n.t("sales.mini.zh")}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="has_live"
                    label={this.i18n.t("sales.mini.live")}
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    label={this.i18n.t("sales.mini.primary")}
                    name="primary"
                  >
                    <Popover
                      content={
                        <ChromePicker
                          color={primaryColor}
                          onChange={this.onChangePrimaryColor}
                        />
                      }
                      placement="right"
                    >
                      <div
                        style={{
                          backgroundColor: primaryColor,
                          width: 50,
                          height: 20,
                          border: "1px solid #333",
                        }}
                      />
                    </Popover>
                  </Form.Item>
                  <Form.Item
                    label={this.i18n.t("sales.mini.secondary")}
                    name="secondary"
                  >
                    <Popover
                      placement="right"
                      style={{ backgroundColor: "#eee" }}
                      content={
                        <GithubPicker
                          triangle="hide"
                          colors={["#ffffff", "#333333"]}
                          onSwatchHover={this.onChangeSecondaryColor}
                        />
                      }
                    >
                      <div
                        style={{
                          backgroundColor: secondaryColor,
                          width: 50,
                          height: 20,
                          border: "1px solid #333",
                        }}
                      />
                    </Popover>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 10 }}>
                    <FormSubmitBtn
                      weappId={this.weappId}
                      downloadUrl={downloadUrl}
                      onGenerateWeapp={this.onGenerateWeapp}
                      loading={loading}
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Divider
                type="vertical"
                style={{
                  height: 300,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 50,
                }}
              ></Divider>
              <Col xs={24} sm={18} md={12} lg={6}>
                <Layout style={{ border: "1px solid #eee", marginBottom: 20 }}>
                  <Header
                    style={{
                      backgroundColor: primaryColor,
                      color: secondaryColor,
                      textAlign: "center",
                    }}
                  >
                    {demoTitle}
                  </Header>
                  <Content>
                    <Col
                      style={{
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 500,
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: primaryColor,
                          color: secondaryColor,
                          border: "none",
                        }}
                        size="large"
                      >
                        {this.i18n.t("sales.mini.testBtnTitle")}
                      </Button>
                    </Col>
                  </Content>
                  <Footer
                    style={{
                      backgroundColor: "#fff",
                      borderTop: "1px solid #eee",
                      height: 70,
                      paddingTop: 14,
                      paddingRight: 8,
                      paddingLeft: 8,
                      paddingBottom: 0,
                    }}
                  >
                    <Row justify="space-between">
                      {[
                        {
                          key: 1,
                          icon: <HomeOutlined style={{ fontSize: 22 }} />,
                        },
                        {
                          key: 2,
                          icon: (
                            <UnorderedListOutlined style={{ fontSize: 22 }} />
                          ),
                        },
                        {
                          key: 3,
                          icon: (
                            <ShoppingCartOutlined style={{ fontSize: 22 }} />
                          ),
                        },
                        {
                          key: 4,
                          icon: <UserOutlined style={{ fontSize: 22 }} />,
                        },
                      ].map((i) => (
                        <Button
                          icon={i.icon}
                          size="large"
                          style={{ border: "none", color: primaryColor }}
                          key={i.key}
                        ></Button>
                      ))}
                    </Row>
                  </Footer>
                </Layout>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={this.i18n.t("sales.mini.paymentConfig")}
            key="3"
            forceRender
          >
            <PaymentSettingForm />
          </TabPane>
        </Tabs>
      </Spin>
    );
  }
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(MiniProgramSetting);
