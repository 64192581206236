import React from "react";

import {
  ArrowRightOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Radio,
  DatePicker,
  Button,
  Pagination,
  Spin,
  Popconfirm,
} from "antd";

import Base from "../Base";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

class FinanceDownload extends Base {
  static Tabs = [
    { key: "generate", name: "financeDownload.tabs.generate" },
    { key: "history", name: "financeDownload.tabs.history" },
  ];

  static Times = [
    { key: "month", name: "financeDownload.times.month" },
    { key: "season", name: "financeDownload.times.season" },
    { key: "custom", name: "financeDownload.times.custom" },
  ];

  /* lifcyle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      currentTab: this.getRouterQueryItemByKey(FinanceDownload.Tabs, "tab"),

      timeType: this.getRouterQueryItemByKey(FinanceDownload.Times, "timeType"),
      rangeTime: this.getDefaultTimeRange(),

      generatingBill: false,

      list: null,
      loadingList: false,
      deletingBill: false,
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(),
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      if (this.isCurrentTabHistory()) {
        this.showMessage(
          this.i18n.t("financeDownload.loadingData"),
          Base.Message.loading
        );
        this.loadList();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (prevState.currentTab.key !== this.state.currentTab.key) {
      if (this.isCurrentTabHistory()) {
        this.showMessage(
          this.i18n.t("financeDownload.loadingData"),
          Base.Message.loading
        );
        this.loadList();

        this.setRouterQuery({
          tab: this.state.currentTab.key,
          page: this.state.currentPage,
          pageSize: this.state.currentPageSize,
        });
      } else {
        this.setRouterQuery({
          tab: this.state.currentTab.key,
          timeType: this.state.timeType.key,
          timeRange: `${this.state.rangeTime[0].format(
            "YYYY-MM-DD"
          )}~${this.state.rangeTime[1].format("YYYY-MM-DD")}`,
        });
      }
    } else {
      if (this.isCurrentTabHistory()) {
        if (
          prevState.currentPage !== this.state.currentPage ||
          prevState.currentPageSize !== this.state.currentPageSize
        ) {
          this.showMessage(
            this.i18n.t("financeDownload.loadingData"),
            Base.Message.loading
          );
          this.loadList();

          this.setRouterQuery({
            page: this.state.currentPage,
            pageSize: this.state.currentPageSize,
          });
        }
      } else {
        if (
          prevState.timeType.key !== this.state.timeType.key ||
          prevState.rangeTime[0].diff(this.state.rangeTime[0]) !== 0 ||
          prevState.rangeTime[1].diff(this.state.rangeTime[1]) !== 0
        ) {
          this.setRouterQuery({
            timeType: this.state.timeType.key,
            timeRange: `${this.state.rangeTime[0].format(
              "YYYY-MM-DD"
            )}~${this.state.rangeTime[1].format("YYYY-MM-DD")}`,
          });
        }
      }
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-finance-download">
        {this._renderHeader()}
        {this._renderContentByTab()}
      </Layout>
    );
  }

  _renderHeader() {
    return (
      <div className="header-wrap">
        {FinanceDownload.Tabs.map((tab, index) => {
          let items = [];
          let className =
            this.state.currentTab.key === tab.key ? "primary" : "default";
          items.push(
            <Button
              key={`tab-${index}`}
              type={className}
              onClick={() => this.onHeaderTabClick(tab)}
            >
              {this.i18n.t(tab.name)}
            </Button>
          );

          if (index !== FinanceDownload.Tabs.length - 1) {
            items.push(<ArrowRightOutlined key={`arrow-${index}`} />);
          }

          return items;
        })}
      </div>
    );
  }

  _renderContentByTab() {
    if (this.isCurrentTabGenerate()) {
      return this._renderGenerateContent();
    } else if (this.isCurrentTabHistory()) {
      return this._renderHistoryContent();
    } else {
      return null;
    }
  }

  _renderGenerateContent() {
    return (
      <div className="generate-wrap">
        {this._renderGenerateFilter()}
        {this._renderGenerateButton()}
      </div>
    );
  }

  _renderGenerateFilter() {
    return (
      <div className="generate-filter">
        <div className="filter-row">
          <span className="label">
            {this.i18n.t("financeDownload.generateFilterTime")}
          </span>
          <Radio.Group
            size="small"
            value={this.state.timeType.key}
            onChange={this.handleFilterTimeTypeChange}
          >
            {FinanceDownload.Times.map((time, index) => {
              return (
                <Radio.Button key={time.key} value={time.key}>
                  {this.i18n.t(time.name)}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          <DatePicker.RangePicker
            size="small"
            defaultValue={this.state.rangeTime}
            format="YYYY-MM-DD"
            allowClear={false}
            disabled={
              this.state.timeType.key !==
              FinanceDownload.Times[FinanceDownload.Times.length - 1].key
            }
            onChange={this.handleFiltersTimeChange}
          />
        </div>
      </div>
    );
  }

  _renderGenerateButton() {
    return (
      <div className="generate-button">
        <Button type="primary" onClick={this.onGenerateClick}>
          {this.i18n.t("financeDownload.generateActionGenerate")}
        </Button>
      </div>
    );
  }

  _renderHistoryContent() {
    return (
      <div className="history-wrap">
        {this._renderHistoryHeader()}
        {this._renderHistoryList()}
        {this._renderHistoryPagination()}
      </div>
    );
  }

  _renderHistoryHeader() {
    return (
      <div className="history-header">
        <span className="no">
          {this.i18n.t("financeDownload.historyHeaderNo")}
        </span>
        <span className="name">
          {this.i18n.t("financeDownload.historyHeaderName")}
        </span>
        <span className="status">
          {this.i18n.t("financeDownload.historyHeaderStatus")}
        </span>
        <span className="actions">
          {this.i18n.t("financeDownload.historyHeaderActions")}
        </span>
      </div>
    );
  }

  _renderHistoryList() {
    let data = this.state.list;
    if (data) {
      let list = data ? data.data : null;
      if (list && list.length > 0) {
        return (
          <Spin spinning={this.state.loadingList || this.state.deletingBill}>
            <div className="history-list">
              {list.map((item, index) => {
                let statusClassName = "";
                let statusIconType = "";
                let statusText = "";
                let actions = [];

                if (item.status === 0) {
                  statusClassName = "status progress";
                  statusIconType = <ClockCircleOutlined />;
                  statusText = this.i18n.t(
                    "financeDownload.historyItemStatusGenerating"
                  );
                } else if (item.status === 1) {
                  statusClassName = "status";
                  statusIconType = <CheckCircleOutlined />;
                  statusText = this.i18n.t(
                    "financeDownload.historyItemStatusSuccess"
                  );
                  actions.push(
                    <a
                      key="action-download"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.download_url}
                    >
                      {this.i18n.t("financeDownload.historyItemActionDownload")}
                    </a>
                  );
                  actions.push(
                    <Popconfirm
                      key="action-delete"
                      placement={
                        this.i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight"
                      }
                      title={this.i18n.t(
                        "financeDownload.historyItemActionDeleteWarning"
                      )}
                      okText={this.i18n.t(
                        "financeDownload.historyItemActionDeleteWarningOk"
                      )}
                      cancelText={this.i18n.t(
                        "financeDownload.historyItemActionDeleteWarningCancel"
                      )}
                      onConfirm={() => this.onHistoryListItemDeleteClick(item)}
                    >
                      <Button style={{ borderWidth: 0 }}>
                        {this.i18n.t("financeDownload.historyItemActionDelete")}
                      </Button>
                    </Popconfirm>
                  );
                } else if (item.status === 2) {
                  statusClassName = "status error";
                  statusIconType = <CloseCircleOutlined />;
                  statusText = this.i18n.t(
                    "financeDownload.historyItemStatusFailed"
                  );
                  actions.push(
                    <Popconfirm
                      key="action-delete"
                      placement={
                        this.i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight"
                      }
                      title={this.i18n.t(
                        "financeDownload.historyItemActionDeleteWarning"
                      )}
                      okText={this.i18n.t(
                        "financeDownload.historyItemActionDeleteWarningOk"
                      )}
                      cancelText={this.i18n.t(
                        "financeDownload.historyItemActionDeleteWarningCancel"
                      )}
                      onConfirm={() => this.onHistoryListItemDeleteClick(item)}
                    >
                      <Button style={{ borderWidth: 0 }}>
                        {this.i18n.t("financeDownload.historyItemActionDelete")}
                      </Button>
                    </Popconfirm>
                  );
                }

                return (
                  <div
                    key={`list-item-${index}`}
                    className="history-header item"
                  >
                    <span className="no">{item.id}</span>
                    <span className="name">
                      {this.i18n.t("financeDownload.listItemName", {
                        start_date: this.moment(item.start_date).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: this.moment(item.end_date).format(
                          "YYYY-MM-DD"
                        ),
                      })}
                    </span>
                    <span className={statusClassName}>
                      {statusIconType}
                      <span>{statusText}</span>
                    </span>
                    <span className="actions">{actions}</span>
                  </div>
                );
              })}
            </div>
          </Spin>
        );
      } else {
        return (
          <div className="history-list">
            <div className="list-message">
              {this.i18n.t("financeDownload.loadingEmpty")}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.loadingList) {
        return (
          <div className="history-list">
            <div className="list-message">
              {this.i18n.t("financeDownload.loadingData")}
            </div>
          </div>
        );
      }

      return (
        <div className="history-list">
          <div className="list-message">
            {this.i18n.t("financeDownload.loadingEmpty")}
          </div>
        </div>
      );
    }
  }

  _renderHistoryPagination() {
    let data = this.state.list;
    if (data && data.data && data.data.length > 0) {
      return (
        <div className="history-pagination">
          <Pagination
            total={data.total}
            current={this.state.currentPage}
            showSizeChanger={true}
            pageSizeOptions={["10", "20", "30"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
            showQuickJumper
          />
        </div>
      );
    } else {
      return null;
    }
  }

  /* user methods */

  onHeaderTabClick(tab) {
    this.setState({ currentTab: tab });
  }

  handleFilterTimeTypeChange = (e) => {
    if (this.state.generatingBill) {
      this.showMessage(
        this.i18n.t("financeDownload.generatingWarning"),
        Base.Message.warning
      );
      return;
    }

    let timeType = FinanceDownload.Times.find(
      (time) => time.key === e.target.value
    );
    let rangeTime = this.state.rangeTime;

    if (timeType === FinanceDownload.Times[0]) {
      rangeTime = [this.moment().subtract(1, "days"), this.moment()];
    } else if (timeType === FinanceDownload.Times[1]) {
      rangeTime = [this.moment().subtract(7, "days"), this.moment()];
    }

    this.setState({ timeType: timeType, rangeTime: rangeTime });
  };

  handleFiltersTimeChange = (dates) => {
    if (this.state.generatingBill) {
      this.showMessage(
        this.i18n.t("financeDownload.generatingWarning"),
        Base.Message.warning
      );
      return;
    }

    const [start, end] = [...dates];
    const backupStart = this.moment(start);
    const diff = end.diff(start, "days") > 15;

    if (diff) {
      this.showMessage(
        this.i18n.t("financeDownload.range"),
        Base.Message.warning
      );
      dates.splice(1, 1, backupStart.add(14, "days"));
    }

    this.setState({ rangeTime: dates });
  };

  onGenerateClick = () => {
    if (this.state.generatingBill) {
      this.showMessage(
        this.i18n.t("financeDownload.generatingWarning"),
        Base.Message.warning
      );
      return;
    }

    this.showMessage(
      this.i18n.t("financeDownload.generating"),
      Base.Message.loading
    );
    this.generateBill();
  };

  onHistoryListItemDeleteClick = (item) => {
    if (!item) return;

    this.showMessage(
      this.i18n.t("financeDownload.deleting"),
      Base.Message.loading
    );
    this.deleteBill(item.id);
  };

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  /* server methods */

  async generateBill() {
    if (this.state.generatingBill) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.createExel;
    let apiConfig = {
      token: token,
      data: {
        start_date: this.state.rangeTime[0].format("YYYY-MM-DD"),
        end_date: this.state.rangeTime[1].format("YYYY-MM-DD"),
      },
    };

    this.setState({ generatingBill: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("financeDownload.generateSuccess"),
          Base.Message.success
        );
        this.setState({ currentTab: FinanceDownload.Tabs[1], currentPage: 1 });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ generatingBill: false });
    }
  }

  async loadList() {
    if (this.state.loadingList) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.listExel;
    let apiConfig = {
      token: token,
      query: {
        page: this.state.currentPage,
        count: this.state.currentPageSize,
      },
    };

    this.setState({ loadingList: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        let data = res.data;
        this.setState({ list: data });

        let list = data ? data.data : null;
        if (list && list.length > 0) {
          let generatingBill = list.find((item) => item.status === 0);
          if (generatingBill) {
            setTimeout(() => {
              if (!this.mounted) return;

              this.showMessage(
                this.i18n.t("financeDownload.updatingList"),
                Base.Message.loading
              );
              this.loadList();
            }, 5000);
          }
        }
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingList: false });
    }
  }

  async deleteBill(id) {
    if (this.state.deletingBill) return;

    let token = this.getUserToken();
    if (!token) return;

    if (id <= 0) return;

    let api = this.api.checkout.deleteExel;
    let apiConfig = {
      token: token,
      path: {
        id: id,
      },
    };

    this.setState({ deletingBill: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("financeDownload.deleteSuccess"),
          Base.Message.loading
        );
        this.loadList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ deletingBill: false });
    }
  }

  /* custom methods */

  getDefaultTimeRange() {
    let rangeTime = [this.moment().subtract(30, "days"), this.moment()];

    let type = this.getRouterQueryItemByKey(FinanceDownload.Times, "timeType");
    if (type.key === FinanceDownload.Times[1].key) {
      rangeTime = [this.moment().subtract(90, "days"), this.moment()];
    } else if (type.key === FinanceDownload.Times[2].key) {
      let queryTime = this.getRouterQueryTimeRange();
      if (queryTime) {
        rangeTime = queryTime;
      }
    }

    return rangeTime;
  }

  isCurrentTabGenerate() {
    return this.state.currentTab.key === FinanceDownload.Tabs[0].key;
  }

  isCurrentTabHistory() {
    return this.state.currentTab.key === FinanceDownload.Tabs[1].key;
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
}, {})(FinanceDownload);
