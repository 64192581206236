import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Row,
  List,
  Divider,
  Input,
  message,
  Avatar,
  Button,
  InputNumber,
} from "antd";
import { connect } from "react-redux";

import { Locale } from "../../../utils";
import { getUserInfo } from "../../../utils/redux/reducers/User";

const i18n = Locale.getInstance();

function omit(obj, arr) {
  return Object.keys(obj)
    .filter((k) => !arr.includes(k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {}); //eslint-disable-line
}

function SendCouponForm({ selectedCoupon, onFinish, onCancel, user, api }) {
  const [keyword, onChangeKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState({ data: [] });
  const [selectedUserList, setSelectedUserList] = useState({});

  const onSearchUsers = useCallback(
    async (page = 1) => {
      const { token } = user;
      if (token) {
        try {
          setLoading(true);
          const apiConfig = {
            token,
            query: {
              "filter[name]": keyword,
              page,
              count: 8,
            },
          };
          const res = await api.run(apiConfig);
          if (res.result) {
            setUsersList(res.data);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [api, keyword, user]
  );

  function onDeleteUser(userId) {
    const others = omit(selectedUserList, [`${userId}`]);
    setSelectedUserList(others);
  }

  function onSelectUser(item) {
    setSelectedUserList({
      ...selectedUserList,
      [item.id]: { ...item, count: 1 },
    });
  }

  function checkIfItemSelected(itemId) {
    const currentKeys = Object.keys(selectedUserList);
    return currentKeys.findIndex((key) => key === `${itemId}`) !== -1;
  }

  useEffect(() => {
    onSearchUsers();
  }, [onSearchUsers]);

  return (
    <Modal
      visible={selectedCoupon !== null}
      onCancel={() => {
        setSelectedUserList({});
        onCancel();
      }}
      title={i18n.t("sales.coupons.send")}
      width={1020}
      okText={i18n.t("base.modal.okText")}
      cancelText={i18n.t("base.modal.cancelText")}
      onOk={() => {
        const data = {};
        Object.keys(selectedUserList).forEach((key) => {
          data[key] = selectedUserList[key].count;
        });
        onFinish(data);
      }}
    >
      <Row align="top">
        <List
          loading={loading}
          style={{ width: "49%", height: "100%" }}
          header={
            <Input.Search
              enterButton
              value={keyword}
              onChange={(e) => onChangeKeyword(e.target.value)}
              onSearch={() => onSearchUsers()}
            />
          }
          pagination={{
            current: usersList.current_page,
            onChange: (page) => onSearchUsers(page),
            total: usersList.total,
            pageSize: 8,
            showSizeChanger: false,
            showQuickJumper: true,
          }}
          dataSource={usersList.data}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  disabled={checkIfItemSelected(item.id)}
                  onClick={() => onSelectUser(item)}
                >
                  {checkIfItemSelected(item.id)
                    ? i18n.t("sales.coupons.selected")
                    : i18n.t("sales.coupons.select")}
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar shape="circle" size="large" src={item.avatar} />
                }
                title={item.nickname}
                description={item.mobile}
              />
            </List.Item>
          )}
        />
        <Divider type="vertical" style={{ height: 500 }} />
        <List
          style={{ width: "49%" }}
          dataSource={Object.keys(selectedUserList)}
          renderItem={(key) => {
            const item = selectedUserList[key];
            return (
              <List.Item
                actions={[
                  <InputNumber
                    key="coupon-amount"
                    min={1}
                    value={item.count}
                    onChange={(value) =>
                      setSelectedUserList({
                        ...selectedUserList,
                        [item.id]: { ...item, count: value },
                      })
                    }
                  />,
                  <Button
                    key="delete"
                    danger
                    onClick={() => onDeleteUser(item.id)}
                  >
                    {i18n.t("sales.activity.delete")}
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar shape="circle" size="large" src={item.avatar} />
                  }
                  title={item.nickname}
                  description={item.mobile}
                />
              </List.Item>
            );
          }}
        />
      </Row>
    </Modal>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(SendCouponForm);
