import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Radio,
  message,
  Layout,
  Divider,
  Input,
  Upload,
  Popover,
  Switch,
} from "antd";
import {
  LeftOutlined,
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import "braft-editor/dist/index.css";
import { ChromePicker } from "react-color";
import { Locale, ApiManager, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import TranslateBtn from "../../components/product/TranslateBtn";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";

const i18n = Locale.getInstance();
const util = Util.getInstance();
const api = ApiManager.getInstance();
const base = Base.getInstance();

function onBeforeUpload(file) {
  if (file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error(i18n.t("productEdit.uploadImageSizeWarning"));
    }
    return isLt1M;
  }
  return false;
}

const areas = {
  wheel: { label: i18n.t("lottery.wheel"), count: 10 },
  nine: { label: i18n.t("lottery.nine"), count: 8 },
  fruit: { label: i18n.t("lottery.fruit"), count: 8 },
};

const LotteryThemeEdit = ({ router }) => {
  const [form] = Form.useForm();

  const user = useSelector((state) => getUserInfo(state));
  const settings = useSelector((state) => getMerchantSettings(state));
  const [loading, setLoading] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(
    router.location.state.data
      ? router.location.state.data.background_color
      : ""
  );

  const onUploadImg = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");

    const { token } = user;
    if (!token) onError("no user token");

    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "lottery",
    };

    try {
      const res = await api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  const onSave2Local = () => {
    const vals = form.getFieldsValue();
    storage.setItem(
      "lotteryThemeData",
      JSON.stringify({
        ...vals,
        background_color: backgroundColor,
      })
    );
    router.goBack();
  };

  const onFinish = async (values) => {
    if (user.token) {
      let apiUrl = api.lottery.themeAdd;
      const {
        zh_background,
        zh_name,
        ug_background,
        ug_name,
        thumb,
        plate_background,
        zh_start_btn,
        ug_start_btn,
        zh_header_image,
        ug_header_image,
        zh_prize_background,
        ug_prize_background,
        status,
      } = values;
      const apiConfig = {
        token: user.token,
        data: {
          type: values.type === "wheel" ? 0 : values.type === "nine" ? 1 : 2,
          thumb: "",
          name: {
            zh: zh_name,
            ug: ug_name,
          },
          status: status ? 1 : 0,
          background_color: backgroundColor,
          background: {
            zh: "",
            ug: "",
          },
          title_image: {
            zh: "",
            ug: "",
          },
          start_button: {
            zh: "",
            ug: "",
          },
          item_background: "",
          prize_background: {
            zh: "",
            ug: "",
          },
        },
      };
      if (
        zh_background &&
        zh_background[0] &&
        zh_background[0].uid !== -1 &&
        zh_background[0].response
      ) {
        apiConfig.data.background.zh = zh_background[0].response.path;
      }
      if (
        ug_background &&
        ug_background[0] &&
        ug_background[0].uid !== -1 &&
        ug_background[0].response
      ) {
        apiConfig.data.background.ug = ug_background[0].response.path;
      }
      if (thumb && thumb[0] && thumb[0].uid !== -1 && thumb[0].response) {
        apiConfig.data.thumb = thumb[0].response.path;
      }
      if (
        plate_background &&
        plate_background[0] &&
        plate_background[0].uid !== -1 &&
        plate_background[0].response
      ) {
        apiConfig.data.item_background = plate_background[0].response.path;
      }
      if (
        zh_prize_background &&
        zh_prize_background[0] &&
        zh_prize_background[0].uid !== -1 &&
        zh_prize_background[0].response
      ) {
        apiConfig.data.prize_background.zh =
          zh_prize_background[0].response.path;
      }
      if (
        ug_prize_background &&
        ug_prize_background[0] &&
        ug_prize_background[0].uid !== -1 &&
        ug_prize_background[0].response
      ) {
        apiConfig.data.prize_background.ug =
          ug_prize_background[0].response.path;
      }
      if (
        zh_start_btn &&
        zh_start_btn[0] &&
        zh_start_btn[0].uid !== -1 &&
        zh_start_btn[0].response
      ) {
        apiConfig.data.start_button.zh = zh_start_btn[0].response.path;
      }
      if (
        ug_start_btn &&
        ug_start_btn[0] &&
        ug_start_btn[0].uid !== -1 &&
        ug_start_btn[0].response
      ) {
        apiConfig.data.start_button.ug = ug_start_btn[0].response.path;
      }
      if (
        zh_header_image &&
        zh_header_image[0] &&
        zh_header_image[0].uid !== -1 &&
        zh_header_image[0].response
      ) {
        apiConfig.data.title_image.zh = zh_header_image[0].response.path;
      } else {
        apiConfig.data.title_image.zh = "";
      }
      if (
        ug_header_image &&
        ug_header_image[0] &&
        ug_header_image[0].uid !== -1 &&
        ug_header_image[0].response
      ) {
        apiConfig.data.title_image.ug = ug_header_image[0].response.path;
      } else {
        apiConfig.data.title_image.ug = "";
      }
      const { state } = router.location;
      if (state.data && state.data.id && state.action !== "copy") {
        apiUrl = api.lottery.themeUpdate;
        apiConfig.path = { id: state.data.id };
      }
      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          message.success(res.message);
          router.replace({ pathname: "/lottery/theme" });
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    } else {
      base.showModal(i18n.t("lottery.empty"), Base.Modal.warning);
    }
  };

  useEffect(() => {
    let { data, action } = router.location.state;
    if (action === "local") {
      storage.getItem("lotteryThemeData").then((localData) => {
        const { background_color, ...others } = JSON.parse(localData);
        setBackgroundColor(background_color);
        form.setFieldsValue({
          ...others,
        });
      });
      return;
    }
    let getDefaultImages = util.getDefaultImages;
    if (action === "copy") {
      getDefaultImages = util.getDefaultImagesForCopy;
    }
    if (data) {
      form.setFieldsValue({
        zh_name: data.name.zh,
        ug_name: data.name.ug,
        thumb: getDefaultImages(data.thumb),
        zh_background: getDefaultImages(data.background.zh),
        ug_background: getDefaultImages(data.background.ug),
        status: data.status === 1,
        type: data.type === 0 ? "wheel" : data.type === 1 ? "nine" : "fruit",
        plate_background: getDefaultImages(data.item_background),
        zh_start_btn: getDefaultImages(data.start_button.zh),
        ug_start_btn: getDefaultImages(data.start_button.ug),
        zh_header_image: getDefaultImages(data.title_image.zh),
        zh_prize_background: getDefaultImages(data.prize_background.zh),
        ug_prize_background: getDefaultImages(data.prize_background.ug),
        ug_header_image: getDefaultImages(data.title_image.ug),
      });
    }
  }, [router.location.state, form]);

  let { data } = router.location.state;

  return (
    <Layout id="route-product-edit">
      <Layout.Header className="edit-header">
        <Button
          type="link"
          className="back"
          onClick={() => {
            router.goBack();
          }}
        >
          <LeftOutlined />
          {i18n.t("productEdit.headerBack")}
        </Button>
        <Divider type="vertical" />
        <span className="title">{i18n.t("sales.activity.addTheme")}</span>
      </Layout.Header>
      <Layout.Content className="edit-content">
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            backgroundColor: "#fff",
          }}
        >
          {" "}
          <Form.Item
            name="type"
            label={i18n.t("lottery.type")}
            initialValue="wheel"
          >
            <Radio.Group buttonStyle="solid">
              {Object.keys(areas).map((k) => (
                <Radio.Button key={areas[k].label} value={k}>
                  {areas[k].label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={i18n.t("sales.zhTitle")}
                name="zh_name"
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: i18n.t("sales.zhTitleWarning") },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={i18n.t("sales.ugTitle")}
                name="ug_name"
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: i18n.t("sales.ugTitleWarning") },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label={i18n.t("sales.status")}
            name="status"
            valuePropName="checked"
            wrapperCol={{ span: 5 }}
            initialValue={true}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  label={i18n.t("lottery.thumb")}
                  name="thumb"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => util.normFile4Img(e)}
                  extra={i18n.t("lottery.imgRule", {
                    pixel: "610px*610px",
                    type: ".png",
                  })}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.activity.imgWarning"),
                    },
                  ]}
                >
                  <Upload
                    accept="image/*"
                    multiple={false}
                    listType="picture-card"
                    beforeUpload={onBeforeUpload}
                    customRequest={onUploadImg}
                  >
                    {(!getFieldValue("thumb") ||
                      !getFieldValue("thumb").length) && (
                      <span>
                        <UploadOutlined /> {i18n.t("sales.upload")}
                      </span>
                    )}
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  label={i18n.t("lottery.zhAwardImg")}
                  name="zh_prize_background"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => util.normFile4Img(e)}
                  extra={i18n.t("lottery.imgRule", {
                    pixel: "610px*610px",
                    type: ".png",
                  })}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.activity.imgWarning"),
                    },
                  ]}
                >
                  <Upload
                    accept="image/*"
                    multiple={false}
                    listType="picture-card"
                    beforeUpload={onBeforeUpload}
                    customRequest={onUploadImg}
                  >
                    {(!getFieldValue("zh_prize_background") ||
                      !getFieldValue("zh_prize_background").length) && (
                      <span>
                        <UploadOutlined /> {i18n.t("sales.upload")}
                      </span>
                    )}
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  label={i18n.t("lottery.ugAwardImg")}
                  name="ug_prize_background"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => util.normFile4Img(e)}
                  extra={i18n.t("lottery.imgRule", {
                    pixel: "610px*610px",
                    type: ".png",
                  })}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.activity.imgWarning"),
                    },
                  ]}
                >
                  <Upload
                    accept="image/*"
                    multiple={false}
                    listType="picture-card"
                    beforeUpload={onBeforeUpload}
                    customRequest={onUploadImg}
                  >
                    {(!getFieldValue("ug_prize_background") ||
                      !getFieldValue("ug_prize_background").length) && (
                      <span>
                        <UploadOutlined /> {i18n.t("sales.upload")}
                      </span>
                    )}
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
          {[
            { label: i18n.t("lottery.zhStartImg"), name: "zh_start_btn" },
            { label: i18n.t("lottery.ugStartImg"), name: "ug_start_btn" },
          ].map((item) => (
            <Form.Item noStyle shouldUpdate key={item.label}>
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    label={item.label}
                    name={item.name}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => util.normFile4Img(e)}
                    extra={i18n.t("lottery.imgRule", {
                      pixel: "159px*205px",
                      type: ".png",
                    })}
                    rules={[
                      {
                        required: true,
                        message: i18n.t("sales.activity.imgWarning"),
                      },
                    ]}
                  >
                    <Upload
                      accept="image/*"
                      multiple={false}
                      listType="picture-card"
                      beforeUpload={onBeforeUpload}
                      customRequest={onUploadImg}
                    >
                      {(!getFieldValue(item.name) ||
                        !getFieldValue(item.name).length) && (
                        <span>
                          <UploadOutlined /> {i18n.t("sales.upload")}
                        </span>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
          ))}
          {[
            { label: i18n.t("lottery.zhHeaderImg"), name: "zh_header_image" },
            { label: i18n.t("lottery.ugHeaderImg"), name: "ug_header_image" },
          ].map((item) => (
            <Form.Item noStyle shouldUpdate key={item.label}>
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    label={item.label}
                    name={item.name}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => util.normFile4Img(e)}
                  >
                    <Upload
                      accept="image/*"
                      multiple={false}
                      listType="picture-card"
                      beforeUpload={onBeforeUpload}
                      customRequest={onUploadImg}
                    >
                      {(!getFieldValue(item.name) ||
                        !getFieldValue(item.name).length) && (
                        <span>
                          <UploadOutlined /> {i18n.t("sales.upload")}
                        </span>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
          ))}
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  label={i18n.t("lottery.plateBack")}
                  name="plate_background"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => util.normFile4Img(e)}
                  extra={i18n.t("lottery.imgRule", {
                    pixel: "610px*610px",
                    type: ".png",
                  })}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.activity.imgWarning"),
                    },
                  ]}
                >
                  <Upload
                    accept="image/*"
                    multiple={false}
                    listType="picture-card"
                    beforeUpload={onBeforeUpload}
                    customRequest={onUploadImg}
                  >
                    {(!getFieldValue("plate_background") ||
                      !getFieldValue("plate_background").length) && (
                      <span>
                        <UploadOutlined /> {i18n.t("sales.upload")}
                      </span>
                    )}
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
          {[
            { label: i18n.t("lottery.zhImg"), name: "zh_background" },
            { label: i18n.t("lottery.ugImg"), name: "ug_background" },
          ].map((item) => (
            <Form.Item noStyle shouldUpdate key={item.label}>
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    label={item.label}
                    name={item.name}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => util.normFile4Img(e)}
                    extra={i18n.t("lottery.imgRule", {
                      pixel: "1080px*1920px",
                      type: ".png",
                    })}
                    rules={[
                      {
                        required: true,
                        message: i18n.t("sales.activity.imgWarning"),
                      },
                    ]}
                  >
                    <Upload
                      accept="image/*"
                      multiple={false}
                      listType="picture-card"
                      beforeUpload={onBeforeUpload}
                      customRequest={onUploadImg}
                    >
                      {(!getFieldValue(item.name) ||
                        !getFieldValue(item.name).length) && (
                        <span>
                          <UploadOutlined /> {i18n.t("sales.upload")}
                        </span>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
          ))}
          <Form.Item
            label={i18n.t("lottery.backColor")}
            extra={i18n.t("lottery.backColorRule")}
          >
            <Popover
              content={
                <ChromePicker
                  color={backgroundColor}
                  onChange={(color) => setBackgroundColor(color.hex)}
                />
              }
              placement="right"
            >
              <div
                style={{
                  backgroundColor,
                  width: 50,
                  height: 20,
                  border: "1px solid #333",
                }}
              />
            </Popover>
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              {i18n.t("sales.submit")}
            </Button>
            {!data?.id && (
              <Button
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={onSave2Local}
              >
                {i18n.t("sales.save")}
              </Button>
            )}
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
};

export default LotteryThemeEdit;
