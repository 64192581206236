export default {
  list: {
    name: "lottery list api",
    version: 6,
    url: "lottery-manager",
    method: "GET",
  },
  add: {
    name: "add lottery api",
    version: 6,
    url: "lottery-manager",
    method: "POST",
  },
  update: {
    name: "update lottery api",
    version: 6,
    url: "lottery-manager/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete lottery api",
    version: 6,
    url: "lottery-manager/%{id}",
    method: "DELETE",
  },
};
