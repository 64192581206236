import {
  Form,
  DatePicker,
  Radio,
  message,
  Button,
  Space,
  Avatar,
  List,
  Input,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import moment from "dayjs";
import { useSelector } from "react-redux";
import { ApiManager, Locale, Request, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";

const util = Util.getInstance();
const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const { RangePicker } = DatePicker;

function EditMember({ router }) {
  const user = useSelector((state) => getUserInfo(state));
  const [cards, setCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [usersList, setUsersList] = useState({ data: [] });
  const [memberUser, setMemberUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchCards = useRef();
  const searchUser = useRef();
  const editMember = useRef();
  const [form] = useForm();
  const {
    location: { state },
  } = router;
  fetchCards.current = async () => {
    try {
      const url = "/member/api/v6/merchant/member-card";
      const res = await Request.get(url, user.token);
      if (res.result) {
        setCards(res.data.data);
      } else {
        message.warn(res.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  searchUser.current = async (keyword = "", page = 1) => {
    const { token } = user;
    if (token) {
      try {
        setLoading(true);
        const apiConfig = {
          token,
          query: {
            "filter[name]": keyword,
            page,
            count: 8,
          },
        };
        const res = await api.coupons.users.run(apiConfig);
        if (res.result) {
          setUsersList(res.data);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };
  editMember.current = async (values) => {
    if (user.token) {
      try {
        let url = "/member/api/v6/merchant/member";
        let method = Request.post;
        const { range, member_card_id } = values;
        const payload = {
          user_id: memberUser.id,
          joined_at: util.getFormattedDateString(range[0]),
          expired_at: util.getFormattedDateString(range[1]),
          member_card_id,
        };
        if (state && state.memberData) {
          const { id } = state.memberData;
          url += `/${id}`;
          method = Request.put;
          delete payload.user_id;
        }
        const res = await method(url, payload, user.token);
        if (res.result) {
          router.goBack();
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };
  useEffect(() => {
    fetchCards.current();
    searchUser.current();
    if (state && state.memberData) {
      const { user, joined_at, expired_at, member_card_id } = state.memberData;
      setMemberUser(user);
      form.setFieldsValue({
        range: [moment(joined_at), moment(expired_at)],
        member_card_id,
      });
    }
  }, [router, form, state]);
  return (
    <>
      <Form
        style={{ backgroundColor: "#fff", paddingTop: 40, paddingBottom: 20 }}
        wrapperCol={{ span: 8 }}
        labelCol={{ span: 4 }}
        onFinish={editMember.current}
        form={form}
      >
        <Form.Item
          rules={[
            {
              required: true,
              validator: async () => {
                if (!memberUser) {
                  throw new Error(i18n.t("member.selectUser"));
                }
              },
            },
          ]}
          name="user"
          label={i18n.t("member.user")}
        >
          {memberUser && typeof memberUser === "object" ? (
            <Space>
              <Avatar src={memberUser.avatar} />
              {memberUser.nickname}
              {!state?.memberData && (
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  type="link"
                >
                  {i18n.t("member.reselect")}
                </Button>
              )}
            </Space>
          ) : (
            <Button
              onClick={() => {
                setShowModal(true);
              }}
              type="primary"
            >
              {i18n.t("member.selectUser")}
            </Button>
          )}
        </Form.Item>
        <Form.Item
          name="range"
          label={i18n.t("member.duration")}
          rules={[
            { required: true, message: i18n.t("member.durationWarning") },
          ]}
        >
          <RangePicker />
        </Form.Item>
        <Form.Item
          name="member_card_id"
          label={i18n.t("member.type")}
          rules={[{ required: true, message: i18n.t("member.cardWarning") }]}
        >
          <Radio.Group>
            {cards.length > 0 &&
              cards.map((i) => (
                <Radio value={i.id} key={i.id}>
                  {i18n.getPropNameByLocale(i, "name")}
                </Radio>
              ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Space>
            <Button htmlType="submit" type="primary">
              {i18n.t("property.submit")}
            </Button>
            <Button
              onClick={() => {
                router.goBack();
              }}
            >
              {i18n.t("common.cancelText")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <Modal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        width={1020}
        footer={null}
      >
        <List
          loading={loading}
          header={
            <Input.Search
              style={{ width: "50%" }}
              enterButton
              onSearch={(word) => searchUser.current(word, 1)}
            />
          }
          pagination={{
            current: usersList.current_page,
            onChange: (page) => searchUser.current("", page),
            total: usersList.total,
            pageSize: 8,
            showSizeChanger: false,
            showQuickJumper: true,
          }}
          dataSource={usersList.data}
          renderItem={(item) => (
            <List.Item
              style={{
                backgroundColor:
                  memberUser && item.id === memberUser.id ? "#eee" : "",
              }}
              actions={[
                <Button
                  onClick={() => {
                    setMemberUser(item);
                    setShowModal(false);
                  }}
                >
                  {i18n.t("sales.coupons.select")}
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar shape="circle" size="large" src={item.avatar} />
                }
                title={item.nickname}
                description={item.mobile}
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
}

export default EditMember;
