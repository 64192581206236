import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, List, Select, message, Button, Image } from "antd";
import { connect } from "react-redux";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { Locale, ApiManager, Util } from "../../../utils";
import SimpleProductInfo from "../../../components/product/SimpleProductInfo";

import { JD_ID } from "../../../constants";

const { Search } = Input;
const { Option } = Select;
const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const util = Util.getInstance();

function ProductListModal({ visible, onCancel, user, products, onSelect }) {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("name");
  const [data, setData] = useState({ data: [], current_page: 1 });
  const fetchData = useRef();

  fetchData.current = async (page = 1) => {
    const token = user.token;
    let customApi = api.product.products;
    if (user && user.merchant.id === JD_ID) {
      customApi = api.product.jdProducts;
    }
    if (token) {
      const query = {
        category_id: -1,
        page,
        keyword,
        search_type: searchType,
        count: 5,
      };
      const apiConfig = {
        token,
        query,
      };
      try {
        setLoading(true);
        const res = await customApi.run(apiConfig);
        setLoading(false);
        if (res.result) {
          setData(res.data);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("loadProducts -> error", error);
      }
    }
  };

  useEffect(() => {
    fetchData.current();
  }, []);

  const selectBefore = (
    <Select
      value={searchType}
      className="select-before"
      onChange={(value) => setSearchType(value)}
    >
      <Option value="name"> {i18n.t("product.search.name")}</Option>
      <Option value="product_no">{i18n.t("product.search.product_no")}</Option>
    </Select>
  );

  const onSelectItem = (item) => {
    if (checkIfSelected(item)) {
      const otherById = util.omit(products.byId, [item.id]);
      const otherItems = products.items.filter((id) => id !== item.id);
      onSelect({
        items: otherItems,
        byId: otherById,
      });
    } else {
      products.byId[item.id] = item;
      products.items.push(item.id);
      onSelect({ ...products });
    }
  };

  const checkIfSelected = (item) => {
    return products && products.items.indexOf(item.id) !== -1;
  };

  return (
    <Modal
      width={1020}
      title={
        <Search
          allowClear
          style={{ width: 400 }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          addonBefore={selectBefore}
          onSearch={() => {
            fetchData.current();
          }}
        />
      }
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <List
        itemLayout="horizontal"
        dataSource={data.data}
        loading={loading}
        pagination={{
          current: data?.current_page,
          total: data?.total,
          showSizeChanger: false,
          showQuickJumper: true,
          pageSize: 5,
          onChange: (page) => fetchData.current(page),
        }}
        renderItem={(item) => {
          return (
            <List.Item
              key={item.id}
              actions={[
                <Button
                  type={checkIfSelected(item) ? "default" : "primary"}
                  onClick={() => {
                    onSelectItem(item);
                  }}
                >
                  {checkIfSelected(item)
                    ? i18n.t("base.modal.cancelText")
                    : i18n.t("sales.activity.add_product")}
                </Button>,
              ]}
            >
              <div style={{ width: "100%" }}>
                <List.Item.Meta
                  avatar={<Image alt="" src={item.images[0]?.url} width={80} />}
                  title={i18n.getPropNameByLocale(item, "name")}
                  description={<SimpleProductInfo item={item} />}
                />
              </div>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  ProductListModal
);
