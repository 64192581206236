import React from "react";
import { connect } from "react-redux";
import { Table, Modal, Button } from "antd";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { Locale, Util } from "../../utils";
import printJS from "print-js";

const i18n = Locale.getInstance();
const util = Util.getInstance();

function OrderPrintView({ user, order, onCancel }) {
  let detailData = [];
  let total = 0;
  let totalAmount = 0;
  order.details.forEach((detail, k) => {
    const detailTotal = detail.amount * detail.price;
    const property =
      order.merchant.id === 525
        ? i18n.getPropNameByLocale(detail.product, "detail_description")
        : i18n.getPropNameByLocale(detail.selected_sku, "name");
    detailData[k] = {
      name: i18n.getPropNameByLocale(detail.product, "name"),
      property: property,
      price: util.getFormattedPrice(detail.price, "￥"),
      amount: detail.amount,
      total: util.getFormattedPrice(detail.amount * detail.price, "￥"),
    };
    total += detailTotal;
    totalAmount += detail.amount;
  });
  detailData[detailData.length] = {
    name: i18n.t("order.printView.total"),
    property: "",
    price: "",
    amount: totalAmount,
    total: util.getFormattedPrice(total, "¥"),
  };
  const printNow = (merchantName) => {
    let css = "https://bulutbazar.com/css/print.css?" + new Date().getTime();
    const isLangUg = Locale.getLocale() === Locale.Locales.ug.locale;
    if (isLangUg) {
      css = "https://bulutbazar.com/css/print-rtl.css?" + new Date().getTime();
    }
    printJS({
      printable: "printView",
      type: "html",
      // style: "border: 1px solid lightgray; width: 100%;",
      // gridStyle: "border: 1px solid lightgray; margin-bottom: -1px;",
      // header: '<div class="print-header">' + merchantName + "</div>",
      // headerStyle: "font-weight: bold; text-align: center; font-size: 30px;",
      css,
    });
  };

  const headerContainer = (order) => {
    const sertem = order.merchant.id === 525;
    let title = null;
    if (sertem) {
      title = <div className="header">سەرتەم خۇرۇچلىرى توپ تارقىتىش ئورنى</div>;
    }

    return (
      <>
        <div className="header">
          {i18n.getPropNameByLocale(order.merchant, "name")}
        </div>
        {title}
        <div className="body-top">
          {order.merchant.tel} {order.created_at}
        </div>
      </>
    );
  };

  const footerContainer = (order) => {
    const sertem = order.merchant.id === 525;
    let footer = null;
    if (sertem) {
      footer = (
        <div className="footer">
          <div className="section">
            <span className="label">ئىسكىلات باشقۇرغۇچى:</span>
          </div>
          <div className="section">
            <span className="label">تالون كەسكۈچى:</span>
          </div>
          <div className="section">
            <span className="label">مال يەتكۈزگۈچى:</span>
          </div>
          <div className="section">
            <span className="label">تاپشۇرۇپ ئالغۇچى:</span>
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="footer" style={{ textAlign: "center" }}>
          {order.receiver} {order.tel} {order.zh_address}
        </div>
        {footer}
      </>
    );
  };

  return (
    <>
      <Modal
        width={800}
        className="route-order-list-print-modal"
        visible
        title={i18n.t("order.print")}
        onCancel={onCancel}
        footer={null}
      >
        <Table
          pagination={false}
          key={order.id}
          bordered
          id="printView"
          className="printView"
          title={() => headerContainer(order)}
          footer={() => footerContainer(order)}
          dataSource={detailData}
          columns={[
            {
              title: i18n.t("product.search.name"),
              dataIndex: "name",
              key: "name",
            },
            {
              title: i18n.t("productEdit.propertyLabel"),
              key: "property",
              dataIndex: "property",
              align: "center",
            },
            {
              title: i18n.t("order.printView.price"),
              dataIndex: "price",
              key: "price",
            },
            {
              title: i18n.t("order.printView.amount"),
              key: "amount",
              dataIndex: "amount",
              align: "center",
            },
            {
              title: i18n.t("order.printView.total"),
              key: "total",
              dataIndex: "total",
              align: "center",
            },
          ]}
        />
        <Button
          type="primary"
          onClick={() => printNow(order.id, order.merchant.zh_name)}
        >
          {i18n.t("order.print")}
        </Button>
      </Modal>
    </>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(OrderPrintView);
