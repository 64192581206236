import React, { useState, useCallback } from "react";
import { Space, Input, Button, Modal, message } from "antd";
import { ApiManager, Locale } from "../../utils";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();

function TopupInput({
  userId,
  fetchList,
  user,
  historyId,
  isUserList,
  type,
  status,
}) {
  const [currentClient, setCurrentClient] = useState(null);
  const [topupAmount, setTopupAmount] = useState(0);

  const onTopup2User = useCallback(() => {
    Modal.confirm({
      title: i18n.t("users.topupWarning"),
      okText: i18n.t("base.modal.okText"),
      cancelText: i18n.t("base.modal.cancelText"),
      onOk: async () => {
        const { token } = user;
        if (token) {
          try {
            const apiUrl = api.balance.topup;
            const apiConfig = {
              token,
              data: {
                user_id: `${currentClient}`,
                amount: topupAmount * 100,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              message.success(res.message);
              setCurrentClient(null);
              fetchList();
            } else {
              message.warning(res.message);
            }
          } catch (error) {
            message.error(error.message);
          }
        }
      },
    });
  }, [user, fetchList, currentClient, topupAmount]);

  const onRecallThisTopup = useCallback(() => {
    Modal.confirm({
      title: i18n.t("users.recallWarning"),
      okText: i18n.t("base.modal.okText"),
      cancelText: i18n.t("base.modal.cancelText"),
      onOk: async () => {
        const { token } = user;
        if (token) {
          try {
            const apiUrl = api.balance.recall;
            const apiConfig = {
              token,
              data: {
                history_id: historyId,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              message.success(res.message);
              fetchList();
            } else {
              message.warning(res.message);
            }
          } catch (error) {
            message.error(error.message);
          }
        }
      },
    });
  }, [user, fetchList, historyId]);

  if (userId === currentClient) {
    return (
      <Space direction="vertical">
        <Input
          prefix="￥"
          type="number"
          min={0}
          value={topupAmount}
          onChange={(e) => setTopupAmount(e.target.value)}
        />
        <Space direction="horizontal" size={8}>
          <Button type="primary" size="small" onClick={onTopup2User}>
            {i18n.t("base.modal.okText")}
          </Button>
          <Button size="small" onClick={() => setCurrentClient(null)}>
            {i18n.t("base.modal.cancelText")}
          </Button>
        </Space>
      </Space>
    );
  } else {
    if (isUserList) {
      return (
        <Button type="link" onClick={() => setCurrentClient(userId)}>
          {i18n.t("users.topup")}
        </Button>
      );
    }
    if (type === 0 && status === 1) {
      return (
        <Button danger type="link" onClick={onRecallThisTopup}>
          {i18n.t("users.recall")}
        </Button>
      );
    }
    return null;
  }
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(TopupInput);
