import React from "react";
import { List, Button, Tag, Space, Statistic, Radio, Modal, Card } from "antd";
import {
  DeleteOutlined,
  CopyOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "dayjs";
import storage from "redux-persist/lib/storage";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import { FILTER_THEME_STATUS } from "../../constants";

class LotteryTheme extends Base {
  constructor(props) {
    super(props);

    this.state = {
      data: { data: [] },
      loading: false,
      filterStatus: FILTER_THEME_STATUS.ALL.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetDataList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterStatus !== this.state.filterStatus) {
      this.onGetDataList();
    }
  }

  onGetDataList = async (page = 1) => {
    const api = this.api.lottery.theme;
    try {
      const token = this.getUserToken();
      if (token) {
        const { filterStatus } = this.state;
        const apiConfig = {
          token,
          query: {
            page,
            count: 10,
            "filter[type]": filterStatus,
          },
        };
        this.setState({ loading: true });
        const res = await api.run(apiConfig);
        if (res.result && this.mounted) {
          this.setState({
            data: res.data,
          });
        } else {
          this.showMessage(res.message);
        }
      }
    } catch (error) {
      this.handleApiError(api, error);
    } finally {
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
  };

  onDeleteItem(id) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const api = this.api.lottery.themeDelete;
        if (this.mounted) {
          try {
            const token = this.getUserToken();
            if (token) {
              const apiConfig = {
                token,
                path: {
                  id,
                },
              };
              const res = await api.run(apiConfig);
              if (res.result) {
                this.onGetDataList();
              } else {
                this.showMessage(res.message, Base.Message.warning);
              }
            }
          } catch (error) {
            this.handleApiError(api, error);
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  onCopyItem(data) {
    this.props.router.push({
      pathname: "/lottery-theme-edit",
      state: {
        data,
        action: "copy",
      },
    });
  }

  onCreateNew = (data) => {
    this.props.router.push({
      pathname: "/lottery-theme-edit",
      state: {
        data,
      },
    });
  };

  onGoToEdit(data = null) {
    if (data) {
      this.onCreateNew(data);
    } else {
      storage.getItem("lotteryThemeData").then((localData) => {
        if (localData) {
          Modal.confirm({
            title: this.i18n.t("sales.localWarning"),
            okText: this.i18n.t("sales.localOk"),
            cancelText: this.i18n.t("sales.localNew"),
            onOk: () => {
              this.props.router.push({
                pathname: "/lottery-theme-edit",
                state: {
                  action: "local",
                },
              });
            },
            onCancel: () => {
              storage.removeItem("lotteryThemeData");
              this.onCreateNew(data);
            },
          });
        } else {
          this.onCreateNew(data);
        }
      });
    }
  }

  render() {
    const { data, loading, filterStatus } = this.state;

    return (
      <>
        <List
          bordered
          grid={{ gutter: 20, column: 4 }}
          size="large"
          style={{ backgroundColor: "#fff" }}
          header={
            <Space direction="horizontal">
              <Button type="primary" onClick={() => this.onGoToEdit()}>
                {this.i18n.t("sales.activity.addTheme")}
              </Button>
              <Radio.Group
                style={{ marginRight: 20, marginLeft: 20 }}
                onChange={(e) => {
                  this.setState({ filterStatus: e.target.value });
                }}
                value={filterStatus}
              >
                <Radio value={FILTER_THEME_STATUS.ALL.value}>
                  {FILTER_THEME_STATUS.ALL.name}
                </Radio>
                <Radio value={FILTER_THEME_STATUS.WHEEL.value}>
                  {FILTER_THEME_STATUS.WHEEL.name}
                </Radio>
                <Radio value={FILTER_THEME_STATUS.NINE.value}>
                  {FILTER_THEME_STATUS.NINE.name}
                </Radio>
                <Radio value={FILTER_THEME_STATUS.FRUIT.value}>
                  {FILTER_THEME_STATUS.FRUIT.name}
                </Radio>
              </Radio.Group>
            </Space>
          }
          itemLayout="horizontal"
          dataSource={data.data}
          loading={loading}
          pagination={{
            current: data.current_page,
            pageSize: 10,
            total: data.total,
            showQuickJumper: true,
            onChange: (page) => this.onGetDataList(page),
          }}
          renderItem={(item) => (
            <List.Item>
              <Card
                hoverable
                style={{
                  width: "auto",
                  height: "auto",
                  flexDirection: "column",
                }}
                cover={
                  <img
                    style={{ width: "100%", height: "350px" }}
                    alt="example"
                    src={item.thumb}
                  />
                }
                actions={[
                  <SettingOutlined
                    key="setting"
                    onClick={() => this.onGoToEdit(item)}
                  />,
                  <CopyOutlined
                    key="copy"
                    onClick={() => this.onCopyItem(item)}
                  />,
                  <DeleteOutlined
                    key="delete"
                    onClick={() => this.onDeleteItem(item.id)}
                    danger="true"
                  />,
                ]}
              >
                <Card.Meta
                  title={this.i18n.getThemeNameByLocale(item, "name")}
                  description={
                    (item.type === 0
                      ? this.i18n.t("lottery.wheel")
                      : item.type === 1
                      ? this.i18n.t("lottery.nine")
                      : this.i18n.t("lottery.fruit")) +
                    " " +
                    item.id
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      </>
    );
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(LotteryTheme);
