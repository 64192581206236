import React, { useEffect } from "react";

import { Modal, Input, Form, Button, Switch } from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Locale } from "../../utils";
import TranslateBtn from "../../components/product/TranslateBtn";

const i18n = Locale.getInstance();

const EditDeliverymanForm = ({
  deliverymanData,
  onCancel,
  onFinish,
  loading,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (deliverymanData && Object.keys(deliverymanData).length > 0) {
      const { zh_name, ug_name, mobile, status } = deliverymanData;
      const fields = {
        zh_name,
        ug_name,
        mobile,
        status,
      };
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
    };
  }, [deliverymanData, form]);

  return (
    <Modal
      title={
        deliverymanData && deliverymanData.zh_name
          ? i18n.t("deliveryman.edit")
          : i18n.t("deliveryman.add")
      }
      visible={!!deliverymanData}
      onCancel={onCancel}
      footer={null}
      forceRender
    >
      <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              rules={[
                { required: true, message: i18n.t("deliveryman.zhNameHint") },
              ]}
              label={i18n.t("deliveryman.zhName")}
              name="zh_name"
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_name: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              rules={[
                { required: true, message: i18n.t("deliveryman.ugNameHint") },
              ]}
              label={i18n.t("deliveryman.ugName")}
              name="ug_name"
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_name: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label={i18n.t("deliveryman.mobile")}
          name="mobile"
          rules={[
            {
              validator: (_, value) => {
                if (!/^1[3456789]\d{9}$/.test(value)) {
                  return Promise.reject(i18n.t("deliveryman.mobileHint"));
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            style={{ width: "100%" }}
            maxLength={13}
            placeholder={i18n.t("deliveryman.mobileHolder")}
          />
        </Form.Item>
        <Form.Item name="password" label={i18n.t("login.password")}>
          <Input.Password
            placeholder={i18n.t("login.parolHint")}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item
          name="status"
          valuePropName="checked"
          label={i18n.t("sales.status")}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDeliverymanForm;
