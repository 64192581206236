import moment from "dayjs";
import "dayjs/locale/zh-cn";

export default class Moment {
  static myMoment = null;
  static getInstance() {
    if (!Moment.myMoment) Moment.myMoment = moment;
    Moment.myMoment.locale("zh-cn");
    return Moment.myMoment;
  }
}
