import React, { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Form,
  Upload,
  message,
  InputNumber,
  Switch,
  DatePicker,
} from "antd";
import moment from "dayjs";
import {
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Locale, Util, ApiManager } from "../../utils";
import TranslateBtn from "../../components/product/TranslateBtn";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";

const i18n = Locale.getInstance();
const util = Util.getInstance();
const base = Base.getInstance();
const api = ApiManager.getInstance();
const { RangePicker } = DatePicker;

function onBeforeUpload(file) {
  if (file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error(i18n.t("productEdit.uploadImageSizeWarning"));
    }
    return isLt1M;
  }
  return false;
}

const RedPacketForm = ({ selectedPacket, onCancel, onGetDataList }) => {
  const [form] = Form.useForm();
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedPacket && selectedPacket.id && selectedPacket.id !== -1) {
      const {
        zh_name,
        ug_name,
        ug_red_packet_image,
        zh_red_packet_image,
        start_time,
        end_time,
        quantity,
        amount,
        status,
      } = selectedPacket;
      form.setFieldsValue({
        zh_name,
        ug_name,
        quantity,
        amount: amount / 100,
        status: status === 1,
        ug_red_packet_image: util.getDefaultImages(ug_red_packet_image),
        zh_red_packet_image: util.getDefaultImages(zh_red_packet_image),
        range: [moment(start_time), moment(end_time)],
      });
    }
    return () => {
      form.resetFields();
    };
  }, [selectedPacket, form]);

  const onUploadImg = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");
    const token = user.token;
    if (!token) onError("no user token");
    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "red-packet",
    };
    try {
      const res = await api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  function getFormattedDateString(date) {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD");
    }
    return null;
  }

  function onFinishForm() {
    if (user.token) {
      form.validateFields().then(async (values) => {
        const {
          zh_name,
          ug_name,
          range,
          amount,
          quantity,
          status,
          ug_red_packet_image,
          zh_red_packet_image,
        } = values;
        let apiUrl = api.redPacket.add;
        let apiConfig = {
          token: user.token,
          data: {
            zh_name,
            ug_name,
            start_time: getFormattedDateString(range[0]),
            end_time: getFormattedDateString(range[1]),
            amount: amount * 100,
            quantity,
            status: status ? 1 : 0,
          },
        };
        if (selectedPacket && selectedPacket.id !== -1) {
          apiUrl = api.redPacket.update;
          apiConfig.path = { id: selectedPacket.id };
        }

        if (
          zh_red_packet_image[0] &&
          zh_red_packet_image[0].uid !== -1 &&
          zh_red_packet_image[0].response
        ) {
          apiConfig.data.zh_red_packet_image =
            zh_red_packet_image[0].response.path;
        }
        if (
          ug_red_packet_image[0] &&
          ug_red_packet_image[0].uid !== -1 &&
          ug_red_packet_image[0].response
        ) {
          apiConfig.data.ug_red_packet_image =
            ug_red_packet_image[0].response.path;
        }
        try {
          setLoading(true);
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            onCancel();
            onGetDataList();
          } else {
            base.showMessage(res.message, Base.Message.error);
          }
        } catch (error) {
          base.handleApiError(api, error);
        } finally {
          setLoading(false);
        }
      });
    }
  }

  const disabled =
    selectedPacket && selectedPacket.id && selectedPacket.id !== -1;

  return (
    <Modal
      width={600}
      title={disabled ? i18n.t("redPacket.update") : i18n.t("redPacket.add")}
      visible={!!selectedPacket}
      okText={i18n.t("base.modal.okText")}
      cancelText={i18n.t("base.modal.cancelText")}
      onCancel={onCancel}
      okButtonProps={{ disabled: loading, loading }}
      onOk={() => {
        onFinishForm();
      }}
      forceRender
    >
      <Form labelCol={{ span: 6 }} form={form}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.zhTitle")}
              name="zh_name"
              rules={[
                { required: true, message: i18n.t("sales.zhTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_name: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.ugTitle")}
              name="ug_name"
              rules={[
                { required: true, message: i18n.t("sales.ugTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_name: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: i18n.t("sales.coupons.rangeWarning") },
          ]}
          label={i18n.t("sales.coupons.range")}
          name="range"
        >
          <RangePicker showTime />
        </Form.Item>
        {[
          { label: i18n.t("redPacket.zhImg"), name: "zh_red_packet_image" },
          { label: i18n.t("redPacket.ugImg"), name: "ug_red_packet_image" },
        ].map((item) => (
          <Form.Item noStyle shouldUpdate key={item.label}>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  rules={[
                    { required: true, message: i18n.t("sales.ad.imgWarning") },
                  ]}
                  label={item.label}
                  name={item.name}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => util.normFile4Img(e)}
                >
                  <Upload
                    accept="image/*"
                    multiple={false}
                    listType="picture-card"
                    beforeUpload={onBeforeUpload}
                    customRequest={onUploadImg}
                  >
                    {(!getFieldValue(item.name) ||
                      !getFieldValue(item.name).length) && (
                      <span>
                        <UploadOutlined /> {i18n.t("sales.upload")}
                      </span>
                    )}
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
        ))}
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return (
              <Form.Item
                name="amount"
                label={i18n.t("redPacket.price")}
                rules={[
                  { required: true, message: i18n.t("redPacket.priceWarning") },
                ]}
                extra={i18n.t("redPacket.atLeast", {
                  price:
                    typeof getFieldValue("quantity") === "number"
                      ? getFieldValue("quantity") * 1.5
                      : 0,
                })}
              >
                <InputNumber min={0} disabled={disabled} />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          name="quantity"
          label={i18n.t("redPacket.count")}
          rules={[
            { required: true, message: i18n.t("redPacket.countWarning") },
          ]}
        >
          <InputNumber min={0} disabled={disabled} />
        </Form.Item>
        <Form.Item
          name="status"
          label={i18n.t("sales.status")}
          valuePropName="checked"
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RedPacketForm;
