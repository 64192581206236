import React from "react";
import { Table, Avatar, Space, Button } from "antd";
import { Locale } from "../../utils";
import { LeftOutlined } from "@ant-design/icons";

const i18n = Locale.getInstance();

function RedPacketDetail({ router }) {
  const {
    state: { details },
  } = router.location;

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: i18n.t("redPacket.price"),
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => `¥${record.amount / 100}`,
    },
    {
      title: i18n.t("redPacket.user"),
      key: "avatar",
      render: (text, record) => {
        if (record.user) {
          return (
            <Space direction="vertical">
              <Avatar size="large" shape="circle" src={record.user.avatar} />
              {record.user.nickname}
            </Space>
          );
        }
        return null;
      },
    },
    {
      title: i18n.t("commissionList.mobile"),
      key: "mobile",
      dataIndex: "mobile",
      align: "center",
      render: (text, record) => {
        if (record.user) {
          return record.user.mobile;
        }
        return null;
      },
    },
    {
      title: "",
      key: "updated_at",
      dataIndex: "updated_at",
      align: "center",
    },
  ];
  return (
    <>
      <Button
        type="primary"
        style={{ marginBottom: 20 }}
        onClick={() => {
          router.goBack();
        }}
      >
        <LeftOutlined />
        {i18n.t("productEdit.headerBack")}
      </Button>
      <Table dataSource={details} columns={columns} />
    </>
  );
}

export default RedPacketDetail;
