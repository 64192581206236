export default {
  list: {
    name: "admin list api",
    version: 6,
    url: "admin/weapp",
    method: "GET",
  },
  add: {
    name: "add admin api",
    version: 6,
    url: "admin/weapp",
    method: "POST",
  },
  update: {
    name: "update admin api",
    version: 6,
    url: "admin/weapp/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete admin api",
    version: 6,
    url: "admin/weapp/%{id}",
    method: "DELETE",
  },
};
