export default {
  list: {
    name: "setting list api",
    version: 6,
    url: "setting",
    method: "GET",
  },
  update: {
    name: "update setting list",
    version: 6,
    url: "setting",
    method: "POST",
  },
};
