import React from "react";
import {
  Col,
  Row,
  Button,
  Spin,
  Table,
  Tag,
  Space,
  Divider,
  Checkbox,
} from "antd";
import Search from "antd/lib/input/Search";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import Base from "../Base";
import { getUserInfo } from "../../utils/redux/reducers/User";
import EditCommunityForm from "./EditCommunityForm";
import ServiceAreaSetting from "./ServiceAreaSetting";

class CommunityManagement extends Base {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      data: { data: [], current_page: 1 },
      communityData: null,
      loading: false,
      showAddManyModal: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetCommunityList();
  }

  onGetCommunityList = async (page = 1, keyword = "") => {
    const token = this.getUserToken();
    if (!token) return;

    const api = this.api.community.list;
    const apiConfig = {
      token,
      query: {
        page,
        keyword,
      },
    };

    try {
      this.setState({ loading: true });
      const res = await api.run(apiConfig);
      if (!this.mounted) return;

      if (res.result) {
        this.setState({
          data: res.data,
        });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false, communityData: null });
    }
  };

  onHandleCancel = () => {
    this.setState({ communityData: null });
  };

  onAddNewCommunity = () => {
    this.setState({ communityData: {} });
  };

  onAddManyCommunity = () => {
    this.setState({ showAddManyModal: true });
  };

  onFinishForm = async (values) => {
    const token = this.getUserToken();
    if (!token) return;
    const {
      zh_name,
      ug_name,
      zh_address,
      ug_address,
      order,
      latitude,
      longitude,
      population,
    } = values;

    let api = this.api.community.add;
    let apiConfig = {
      token,
      data: {
        zh_name,
        ug_name,
        zh_address,
        ug_address,
        order,
        latitude,
        longitude,
        population,
      },
    };

    const { communityData, data } = this.state;
    if (communityData && communityData.id) {
      api = this.api.community.update;
      apiConfig.path = { id: communityData.id };
    }

    this.setState({ loading: true });
    try {
      const res = await api.run(apiConfig);
      if (!this.mounted) return;
      if (res.result) {
        this.onGetCommunityList(data.current_page);
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  };

  onDeleteCommunity(id) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const token = this.getUserToken();
        if (!token) return;

        const api = this.api.community.delete;
        const apiConfig = {
          token,
          path: { id },
        };
        try {
          const res = await api.run(apiConfig);
          if (res.result) {
            const { data } = this.state;
            this.onGetCommunityList(data.current_page);
          } else {
            this.showMessage(res.message);
          }
        } catch (error) {
          if (!this.mounted) return;
          this.handleApiError(api, error);
        }
      }
    );
  }

  onEditCommunity(communityData) {
    this.setState({ communityData });
  }

  render() {
    const { data, communityData, loading, showAddManyModal } = this.state;
    const { onSelectCommunity, selectedCommunities } = this.props;

    return (
      <Spin spinning={loading}>
        <Row className="filter-container">
          <Col span={16}>
            <Search
              onSearch={(value) => this.onGetCommunityList(1, value)}
              placeholder={this.i18n.t("users.searchPlace")}
              style={{ width: 300, marginBottom: 16 }}
              allowClear
              enterButton
            />
          </Col>
          <Col span={8}>
            <Space direction="horizontal">
              <Button type="primary" onClick={this.onAddNewCommunity}>
                {this.i18n.t("community.add")}
              </Button>
              <Button type="primary" onClick={this.onAddManyCommunity}>
                {this.i18n.t("community.addMany")}
              </Button>
            </Space>
          </Col>
        </Row>
        <Table
          dataSource={data.data}
          rowKey={(record) => `${record.id}`}
          style={{ fontFamily: "Alp Ekran" }}
          pagination={{
            current: data.current_page,
            total: data.total,
            onChange: (value) => this.onGetCommunityList(value),
            showSizeChanger: false,
            showQuickJumper: true,
            style: {
              paddingBottom: 20,
            },
          }}
          columns={[
            {
              title: this.i18n.t("community.zhName"),
              dataIndex: "zh_name",
              key: "zh_name",
            },
            {
              title: this.i18n.t("community.ugName"),
              dataIndex: "ug_name",
              key: "ug_name",
            },
            {
              title: this.i18n.t("community.zhAddress"),
              dataIndex: "zh_address",
              key: "zh_address",
            },
            {
              title: this.i18n.t("community.ugAddress"),
              dataIndex: "ug_address",
              key: "ug_address",
            },

            {
              title: this.i18n.t("community.position"),
              key: "position",
              dataIndex: "position",
              render: (text, record) => {
                return (
                  <Tag color="green">
                    {record.longitude},{record.latitude}
                  </Tag>
                );
              },
            },
            {
              title: this.i18n.t("community.order"),
              dataIndex: "order",
              key: "order",
            },
            {
              title: this.i18n.t("community.population"),
              dataIndex: "population",
              key: "population",
            },
            {
              title: this.i18n.t("users.actions"),
              key: "actions",
              align: "center",
              render: (text, item) => {
                const checked =
                  selectedCommunities && selectedCommunities.includes(item.id);
                return (
                  <Space direction="horizontal">
                    <Button
                      key="edit"
                      icon={<EditOutlined />}
                      type="link"
                      onClick={() => this.onEditCommunity(item)}
                    />
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      key="delete"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => this.onDeleteCommunity(item.id)}
                    />

                    {onSelectCommunity && <Divider type="vertical" />}
                    {onSelectCommunity && (
                      <Checkbox
                        checked={checked}
                        onClick={() => {
                          if (checked) {
                            const list = selectedCommunities.filter(
                              (id) => id !== item.id
                            );
                            onSelectCommunity(list);
                          } else {
                            onSelectCommunity([
                              ...new Set(selectedCommunities.concat(item.id)),
                            ]);
                          }
                        }}
                      />
                    )}
                  </Space>
                );
              },
            },
          ]}
        />
        <EditCommunityForm
          communityData={communityData}
          onCancel={this.onHandleCancel}
          onFinish={this.onFinishForm}
          loading={loading}
        />
        <ServiceAreaSetting
          visible={showAddManyModal}
          onClose={() => {
            this.setState({ showAddManyModal: false });
          }}
        />
      </Spin>
    );
  }
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(CommunityManagement);
