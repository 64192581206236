export const ACTIONS = {
	ADD_MEDIA: 'ADD_MEDIA',
	REMOVE_MEDIA: 'REMOVE_MEDIA',
};

export const addMedia = (media) => (dispatch, getState) => {
	dispatch({
		type: ACTIONS.ADD_MEDIA,
		media: media,
	});
}

export const removeMedia = (medias) => (dispatch, getState) => {
	dispatch({
		type: ACTIONS.REMOVE_MEDIA,
		medias: medias,
	});
}