export default class Logger {

	static logger = null;
	static getInstance() {
		if (!Logger.logger)
			Logger.logger = new Logger();
		return Logger.logger;
	}


	constructor() {
		this.isDebug = this._isEnvDevelopment();
	}

	log() {
		if (this.isDebug) {
			console.log.apply(console, arguments);
		}
	}

	info() {
		if (this.isDebug) {
			console.info.apply(console, arguments);
		}
	}

	warn() {
		if (this.isDebug) {
			console.warn.apply(console, arguments);
		}
	}

	debug() {
		if (this.isDebug) {
			console.debug.apply(console, arguments);
		}
	}

	error() {
		if (this.isDebug) {
			console.error.apply(console, arguments);
		}
	}

	_isEnvDevelopment() {
		return process.env.NODE_ENV === 'development';
	}

}