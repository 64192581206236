import { ACTIONS } from "../actions/User";

const initialState = {};

export const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.SET_USER_INFO: {
      return { ...state, info: action.info };
    }
    case ACTIONS.SET_USER_PROFILE: {
      if (state.info) {
        return { ...state, info: { ...state.info, user: action.profile } };
      }
      return state;
    }
    case ACTIONS.SET_USER_MERCHANT_CATEGORIES: {
      if (state.info) {
        return {
          ...state,
          info: {
            ...state.info,
            user: {
              ...state.info.user,
              merchant: {
                ...state.info.user.merchant,
                categories: action.categories,
              },
            },
          },
        };
      }
      return state;
    }
    case ACTIONS.SET_USER_MERCHANT_STATSUS: {
      if (state.info) {
        return {
          ...state,
          info: {
            ...state.info,
            user: {
              ...state.info.user,
              merchant: {
                ...state.info.user.merchant,
                status: action.status,
              },
            },
          },
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export const getUserInfo = (state) => {
  let userInfo = null;

  let info = state.user.info;
  if (info) {
    userInfo = { ...info.user };
    if (info.token) {
      userInfo.token = info.token.token_type + " " + info.token.access_token;
      userInfo.refresh_token = info.token.refresh_token;
    }
  }

  return userInfo;
};
