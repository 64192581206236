import React, { useState } from "react";
import {
  Form,
  Select,
  Input,
  message,
  Tag,
  Avatar,
  Spin,
  Space,
  Typography,
} from "antd";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { ApiManager, Locale } from "../../../utils";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { JD_ID } from "../../../constants";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const { Option } = Select;
const { Text } = Typography;

export default function LinkSelector({
  type,
  onSelect,
  hasWeapp,
  isBBShopMerchant,
}) {
  const user = useSelector((state) => getUserInfo(state));
  const [fetching, setFetching] = useState(false);
  const [productList, setProductList] = useState({ data: [] });
  const [liveList, setLiveList] = useState({ data: [] });
  const [couponsList, setCouponsList] = useState({ data: [] });
  const [articlesList, setArticlesList] = useState({ data: [] });

  const onSearchProduct = async (keyword, search_type = "keyword") => {
    let customApi = api.product.products;
    if (user && user.merchant.id === JD_ID) {
      customApi = api.product.jdProducts;
    }
    if (user && user.token) {
      const query = {
        category_id: -1,
        page: 1,
        keyword,
        search_type,
        count: 5,
      };
      const apiConfig = {
        token: user.token,
        query,
      };
      try {
        setFetching(true);
        const res = await customApi.run(apiConfig);
        if (res.result) {
          setProductList(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        console.log("loadProducts -> error", error);
      } finally {
        setFetching(false);
      }
    }
  };

  const onSearchLiveList = debounce(async (keyword = "") => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.live.list;
        const apiConfig = {
          token,
          query: { page: 1, count: 10, keyword },
        };
        setFetching(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setLiveList(res.data);
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setFetching(false);
      }
    }
  }, 800);

  const onSearchArticleList = async (keyword = "") => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.article.list;
        const apiConfig = {
          token,
          query: { page: 1, count: 10, keyword },
        };
        setFetching(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setArticlesList(res.data);
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setFetching(false);
      }
    }
  };

  const onSearchCouponList = async (keyword = "") => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.coupons.list;
        const apiConfig = {
          token,
          query: { page: 1, count: 10, keyword },
        };
        setFetching(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setCouponsList(res.data);
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setFetching(false);
      }
    }
  };

  const optionsList = [
    {
      value: "#product#",
      title: i18n.t("sales.ad.product"),
    },
    {
      value: "#show#",
      title: i18n.t("sales.ad.show"),
    },
    {
      value: "#webview#",
      title: i18n.t("sales.ad.webview"),
    },
  ];

  if (type === "coupon") {
    optionsList.push({
      value: "#coupon#",
      title: i18n.t("main.sideMenus.coupons"),
    });
  }

  if (hasWeapp || isBBShopMerchant) {
    optionsList.push({
      value: "#lottery#",
      title: i18n.t("lottery.wheel"),
    });
    optionsList.push({
      value: "#live#",
      title: i18n.t("sales.ad.live"),
    });
    optionsList.push({
      value: "#special-holiday#",
      title: i18n.t("sales.ad.holiday"),
    });
    optionsList.push({
      value: "#groupon#",
      title: i18n.t("sales.ad.groupon"),
    });
    optionsList.push({
      value: "#weapp#",
      title: i18n.t("sales.ad.weapp"),
    });
    if (user.merchant && user.merchant.enabled_article) {
      optionsList.push({
        value: "#article#",
        title: i18n.t("article.title"),
      });
    }
    if (user.merchant && user.merchant.has_agents) {
      optionsList.push({
        value: "#agents#",
        title: i18n.t("proxy.apply"),
      });
    }
  }

  let suffixSelector;
  if (isBBShopMerchant) {
    optionsList.push({
      value: "#market-shop#",
      title: i18n.t("sales.ad.market"),
    });
    suffixSelector = (
      <Form.Item noStyle name="suffix">
        <Select
          style={{
            width: 160,
          }}
          onSelect={onSelect}
        >
          {user.merchants.map((i) => (
            <Option key={i.id} value={`${i.id}`}>
              {i18n.getPropNameByLocale(i, "name")}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  const prefixSelector = (
    <Form.Item
      noStyle
      name="prefix"
      rules={[
        {
          required: true,
          type: "string",
          message: i18n.t("sales.ad.typeWarning"),
        },
      ]}
    >
      <Select
        style={{
          width: 100,
        }}
      >
        {optionsList.map((i) => (
          <Option key={i.value} value={i.value}>
            {i.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const productSelector = (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const emptylist =
          getFieldValue("suffix") && getFieldValue("suffix").length > 0;
        return (
          <Form.Item
            noStyle
            name="suffix"
            rules={[
              {
                required: true,
                message: i18n.t("showsEdit.selectProduct"),
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: 260 }}
              placeholder={i18n.t("showsEdit.selectProduct")}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={onSearchProduct}
              onSelect={onSelect}
              tagRender={({ closable, onClose, value }) => {
                return (
                  <Tag
                    closable={closable}
                    onClose={onClose}
                    style={{ borderWidth: 0 }}
                  >
                    {value}
                  </Tag>
                );
              }}
            >
              {!emptylist
                ? productList.data.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      <Avatar
                        src={d.images[0].url}
                        shape="square"
                        size="large"
                      />
                      {i18n.getPropNameByLocale(d, "name")}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );

  const liveSelector = (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const emptylist =
          getFieldValue("suffix") && getFieldValue("suffix").length > 0;
        return (
          <Form.Item
            noStyle
            name="suffix"
            rules={[
              {
                required: true,
                message: i18n.t("sales.nav.roomIdWarning"),
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: 260 }}
              placeholder={i18n.t("sales.nav.roomIdWarning")}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={onSearchLiveList}
              onSelect={onSelect}
              tagRender={({ closable, onClose, value }) => {
                return (
                  <Tag
                    closable={closable}
                    onClose={onClose}
                    style={{ borderWidth: 0 }}
                  >
                    {value}
                  </Tag>
                );
              }}
            >
              {!emptylist
                ? liveList.data.map((d) => (
                    <Select.Option key={d.id} value={d.room_id}>
                      <Avatar
                        src={i18n.getPropNameByLocale(d, "poster_image")}
                        shape="square"
                        size="large"
                      />
                      {i18n.getPropNameByLocale(d, "name")}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );

  const articleSelector = (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const emptylist =
          getFieldValue("suffix") && getFieldValue("suffix").length > 0;
        return (
          <Form.Item
            noStyle
            name="suffix"
            rules={[
              {
                required: true,
                message: i18n.t("article.selectWarning"),
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: 260 }}
              placeholder={i18n.t("article.selectWarning")}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={onSearchArticleList}
              onSelect={onSelect}
              tagRender={({ closable, onClose, value }) => {
                return (
                  <Tag
                    closable={closable}
                    onClose={onClose}
                    style={{ borderWidth: 0 }}
                  >
                    {value}
                  </Tag>
                );
              }}
            >
              {!emptylist
                ? articlesList.data.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {i18n.getPropNameByLocale(d, "name")}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );

  const couponSelector = (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const emptylist =
          getFieldValue("suffix") && getFieldValue("suffix").length > 0;
        return (
          <Form.Item
            noStyle
            name="suffix"
            rules={[
              {
                required: true,
                message: i18n.t("sales.nav.couponIdWarning"),
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: 260 }}
              placeholder={i18n.t("sales.nav.couponIdWarning")}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={onSearchCouponList}
              onSelect={onSelect}
              tagRender={({ closable, onClose, value }) => {
                return (
                  <Tag
                    closable={closable}
                    onClose={onClose}
                    style={{ borderWidth: 0 }}
                  >
                    {value}
                  </Tag>
                );
              }}
            >
              {!emptylist
                ? couponsList.data.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      <Space direction="horizontal">
                        {i18n.getPropNameByLocale(d, "name")}
                        <Text>
                          {d.start_price
                            ? i18n.t("sales.coupons.start", {
                                price: d.start_price / 100,
                              })
                            : i18n.t("sales.coupons.no_limit")}
                        </Text>
                        <Text type="danger">
                          {i18n.t("sales.coupons.face_price", {
                            price: d.face_price / 100,
                          })}
                        </Text>
                      </Space>
                    </Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );

  const requiredValue = (prefixValue) =>
    prefixValue !== "#groupon#" &&
    prefixValue !== "#show#" &&
    prefixValue !== "#lottery#" &&
    prefixValue !== "#agents#";

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const prefixValue = getFieldValue("prefix");
        if (
          [
            "#market-shop#",
            "#product#",
            "#live#",
            "#coupon#",
            "#article#",
          ].includes(prefixValue)
        ) {
          return (
            <Form.Item
              name="type"
              label={i18n.t("sales.ad.type")}
              rules={[
                {
                  required: requiredValue(prefixValue),
                  message: i18n.t("sales.ad.target"),
                  type: "string",
                },
              ]}
            >
              {prefixValue === "#market-shop#" && (
                <Input.Group compact>
                  {prefixSelector}
                  {suffixSelector}
                </Input.Group>
              )}
              {prefixValue === "#product#" && (
                <Input.Group compact>
                  {prefixSelector}
                  {productSelector}
                </Input.Group>
              )}
              {prefixValue === "#live#" && (
                <Input.Group compact>
                  {prefixSelector}
                  {liveSelector}
                </Input.Group>
              )}
              {prefixValue === "#article#" && (
                <Input.Group compact>
                  {prefixSelector}
                  {articleSelector}
                </Input.Group>
              )}
              {prefixValue === "#coupon#" && (
                <Input.Group compact>
                  {prefixSelector}
                  {couponSelector}
                </Input.Group>
              )}
            </Form.Item>
          );
        }
        return (
          <Form.Item
            name="type"
            label={i18n.t("sales.ad.type")}
            rules={[
              {
                required: requiredValue(prefixValue),
                message: i18n.t("sales.ad.target"),
                type: "string",
              },
            ]}
          >
            <Input
              addonBefore={prefixSelector}
              placeholder={
                prefixValue === "#weapp#" ? i18n.t("sales.ad.appId") : ""
              }
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
