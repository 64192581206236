export const ACTIONS = {
  SET_USER_INFO: "SET_USER_INFO",
  SET_USER_PROFILE: "SET_USER_PROFILE",
  SET_USER_MERCHANT_CATEGORIES: "SET_MERCHANT_CATEGORIES",
  SET_USER_MERCHANT_STATSUS: "SET_MERCHANT_STATUS",
};

export const setUserInfo = (info) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.SET_USER_INFO,
    info: info,
  });
};

export const setUserProfile = (profile) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.SET_USER_PROFILE,
    profile: profile,
  });
};

export const setUserMerchantCategories = (categories) => (
  dispatch,
  getState
) => {
  dispatch({
    type: ACTIONS.SET_USER_MERCHANT_CATEGORIES,
    categories: categories,
  });
};

export const setUserMerchantStatus = (status) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.SET_USER_MERCHANT_STATSUS,
    status: status,
  });
};
