export default {
  login: {
    name: "user login api",
    version: 1,
    url: "access_token",
    method: "POST",
  },
  updateMerchant: {
    name: "merchant update api",
    version: 1,
    url: "merchant",
    method: "POST",
  },
  selectMerchant: {
    name: "merchant select api",
    version: 7,
    url: "select-merchant",
    method: "POST",
  },
  merchantSettings: {
    name: "merchant setting api",
    version: 6,
    url: "%{merchant_id}/merchant-settings",
    method: "GET",
  },
  resetPassword: {
    name: "merchant reset password api",
    version: 6,
    url: "reset-password",
    method: "POST",
  },
  sendCode: {
    name: "merchant reset password api",
    version: 1,
    url: "api/v1/client/oauth/send-code",
    method: "POST",
  },
  resetLoginPassword: {
    name: "merchant reset login  password api",
    version: 1,
    url: "api/v1/client/oauth/reset-password",
    method: "POST",
  },
  list: {
    name: "get users list api",
    version: 6,
    url: "user/list",
    method: "GET",
  },
  unbindWechat: {
    name: "get users list api",
    version: 6,
    url: "user/unbind/wechat",
    method: "POST",
  },
  appointUser: {
    name: "appoint user api",
    version: 6,
    url: "user/make-specified-user/%{user_id}",
    method: "POST",
  },
  unappointUser: {
    name: "unappoint user api",
    version: 6,
    url: "user/cancel-specified-user/%{user_id}",
    method: "POST",
  },
  balanceSum: {
    name: "balance sum api",
    version: 6,
    url: "balance/sum",
    method: "GET",
  },
};
