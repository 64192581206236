import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  message,
  Space,
  Statistic,
  Table,
  Tag,
  Divider,
} from "antd";
import { useSelector } from "react-redux";

import { Locale, Request } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import TopupInput from "../balance/TopupInput";

const i18n = Locale.getInstance();

function MembersList({ router }) {
  const fetchList = useRef();
  const [listData, setListData] = useState({ data: null });
  const user = useSelector((state) => getUserInfo(state));
  const merchant = useSelector((state) => state.merchant);

  fetchList.current = async () => {
    if (user && user.token) {
      try {
        const url = "/member/api/v6/merchant/member";
        const res = await Request.get(url, user.token);
        if (res.result) {
          setListData(res.data);
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  useEffect(() => {
    fetchList.current();
  }, []);

  const columns = [
    {
      key: "info",
      title: i18n.t("member.info"),
      render: (text, record) => {
        if (record.user) {
          const { user } = record;
          return (
            <Space direction="horizontal">
              <Avatar src={user.avatar} size="large" />
              <Space direction="vertical">
                {user.nickname}
                {user.mobile}
              </Space>
            </Space>
          );
        }
        return null;
      },
    },
    { key: "number", title: i18n.t("member.number"), dataIndex: "member_no" },
    {
      key: "card",
      title: i18n.t("main.sideMenus.membersCard"),
      render: (text, record) => (
        <Tag title={i18n.getPropNameByLocale(record.member_card)} />
      ),
    },
    {
      key: "joined_at",
      title: i18n.t("member.joined_at"),
      dataIndex: "joined_at",
    },
    {
      key: "expired_at",
      title: i18n.t("member.expired_at"),
      dataIndex: "expired_at",
    },
    {
      key: "saved",
      title: i18n.t("member.saved"),
      render: (text, record) => (
        <Statistic
          value={record.total_saved / 100}
          prefix="¥"
          precision={2}
          valueStyle={{ color: "royalblue" }}
        />
      ),
    },
    {
      key: "actions",
      title: i18n.t("product.cats.actions"),
      align: "center",
      render: (text, record) => (
        <div className="card-item-actions">
          <Button
            type="link"
            onClick={() => {
              router.push({
                pathname: "/members/edit",
                state: {
                  memberData: record,
                },
              });
            }}
          >
            {i18n.t("product.productItem.actionEdit")}
          </Button>
          <Divider type="vertical" />
          <TopupInput
            userId={record.user_id}
            fetchList={() => fetchList.current()}
            isUserList={merchant?.enable_membership}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Button
        style={{ marginBottom: 20 }}
        type="primary"
        onClick={() => {
          router.push({ pathname: "/members/edit" });
        }}
      >
        {i18n.t("member.add")}
      </Button>
      <Table
        loading={listData.data === null}
        dataSource={listData.data ?? []}
        columns={columns}
        rowKey={(record) => `member-${record.user_id}`}
      />
    </>
  );
}

export default MembersList;
