import React, { useEffect } from "react";

import {
  Modal,
  Input,
  Form,
  Upload,
  message,
  Button,
  Select,
  InputNumber,
  Switch,
} from "antd";

import {
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Locale, Util } from "../../../utils";
import TranslateBtn from "../../../components/product/TranslateBtn";

const i18n = Locale.getInstance();
const util = Util.getInstance();
const { Option } = Select;
const LIVE_ROOM =
  "plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin";

function onBeforeUpload(file) {
  const isLt200KB = file.size / 1024 < 200;
  if (!isLt200KB) {
    message.error(i18n.t("productEdit.uploadNavImageSizeWarning"));
  }
  return isLt200KB;
}

const EditNavForm = ({
  navData,
  onCancel,
  onFinish,
  onUploadImg,
  loading,
  shopCats,
  showExtraOptions,
  showAgent,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (navData && Object.keys(navData).length > 0) {
      const { zh_name, ug_name, order, image, status, target } = navData;
      let targetUrl;
      const fields = {
        zh_name,
        ug_name,
        order: !Number.isNaN(Number(order)) ? Number(order) : 0,
        status: status === 1,
        image: util.getDefaultImages(image),
      };
      if (target.indexOf("room_id") !== -1) {
        targetUrl = target.split("?room_id=")[0];
        const room_id = Number.isNaN(Number(target.split("?room_id=")[1]))
          ? -1
          : Number(target.split("?room_id=")[1]);
        fields.room_id = room_id;
      } else if (target.indexOf("app_id") !== -1) {
        targetUrl = target.split("?app_id=")[0];
        const app_id = target.split("?app_id=")[1];
        fields.app_id = app_id;
      } else {
        targetUrl = target.split("?tabId=")[0];
        const tab_id = Number.isNaN(Number(target.split("?tabId=")[1]))
          ? -1
          : Number(target.split("?tabId=")[1]);
        fields.tab_id = tab_id;
      }
      fields.target = targetUrl;
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
    };
  }, [navData, form]);

  const extraList = [
    { value: "/lottery/home/index", title: i18n.t("lottery.wheel") },
    { value: "/pages/coupons/index", title: i18n.t("sales.nav.coupon") },
    { value: "/pages/orders/index", title: i18n.t("sales.nav.orders") },
    {
      value: "/pages/discounted-products/index",
      title: i18n.t("sales.nav.discount"),
    },
    {
      value: "/pages/discovery/index",
      title: i18n.t("main.sideMenus.shows"),
    },
    { value: "/pages/live-list/index", title: i18n.t("sales.nav.liveList") },
    { value: LIVE_ROOM, title: i18n.t("sales.nav.liveRoom") },
    { value: "weapp", title: i18n.t("sales.ad.weapp") },
  ];

  if (showAgent) {
    extraList.push({
      value: "/agent/pages/become-agent/index",
      title: i18n.t("proxy.apply"),
    });
  }

  return (
    <Modal
      title={i18n.t("sales.nav.title")}
      visible={!!navData}
      onCancel={onCancel}
      footer={null}
      forceRender
    >
      <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.zhTitle")}
              name="zh_name"
              rules={[
                { required: true, message: i18n.t("sales.zhTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_name: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.ugTitle")}
              name="ug_name"
              rules={[
                { required: true, message: i18n.t("sales.ugTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_name: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item label={i18n.t("sales.nav.order")} name="order">
          <InputNumber
            style={{ width: "100%" }}
            max={99999}
            placeholder={i18n.t("sales.nav.orderPlace")}
          />
        </Form.Item>
        <Form.Item
          name="target"
          label={i18n.t("sales.nav.target")}
          rules={[
            {
              required: true,
              message: i18n.t("sales.nav.targetWarning"),
            },
          ]}
        >
          <Select>
            <Option value="/pages/category-list/index">
              {i18n.t("sales.nav.product")}
            </Option>
            {showExtraOptions &&
              extraList.map((i) => (
                <Option key={i.title} value={i.value}>
                  {i.title}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue("target") === "/pages/category-list/index") {
              return (
                <Form.Item label={i18n.t("sales.nav.childTab")} name="tab_id">
                  <Select>
                    {shopCats &&
                      [{ id: -1, ug_name: "بارلىق", zh_name: "全部" }]
                        .concat(shopCats)
                        .map((cat) => (
                          <Option key={cat.id} value={cat.id}>
                            {i18n.getPropNameByLocale(cat, "name")}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue("target") === LIVE_ROOM) {
              return (
                <Form.Item
                  label={i18n.t("sales.nav.roomId")}
                  name="room_id"
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.nav.roomIdWarning"),
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue("target") === "weapp") {
              return (
                <Form.Item
                  label={i18n.t("sales.ad.weapp")}
                  name="app_id"
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.ad.appId"),
                    },
                  ]}
                >
                  <Input placeholder={i18n.t("sales.ad.appId")} />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: i18n.t("sales.nav.imgWarning") }]}
          label={i18n.t("sales.nav.img")}
          name="image"
          valuePropName="fileList"
          getValueFromEvent={(e) => util.normFile4Img(e)}
          extra={i18n.t("sales.nav.imgTip")}
        >
          <Upload
            accept="image/*"
            multiple={false}
            listType="picture"
            beforeUpload={onBeforeUpload}
            customRequest={onUploadImg}
          >
            <Button>
              <UploadOutlined /> {i18n.t("sales.upload")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="status"
          valuePropName="checked"
          label={i18n.t("sales.status")}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditNavForm;
