export default {
  uploadImages: {
    name: "upload images api",
    version: 4,
    url: "uploadImage",
    method: "UPLOAD",
  },
  homeStatistics: {
    name: "home statistics api",
    version: 4,
    url: "home/statistic",
    method: "GET",
  },
  weappHomeStatistics: {
    name: "home statistics api",
    version: 6,
    url: "home/statistic",
    method: "GET",
  },
};
