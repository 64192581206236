export default {
  list: {
    name: "proxy list api",
    version: 6,
    url: "vip",
    method: "GET",
  },
  withdrawList: {
    name: "agent withdraw list api",
    version: 6,
    url: "agent-withdrawal",
    method: "GET",
  },
  transfer: {
    name: "transfer to agent  api",
    version: 6,
    url: "withdrawal",
    method: "POST",
  },
  transferList: {
    name: "transfer list  api",
    version: 6,
    url: "withdrawal",
    method: "GET",
  },
  add: {
    name: "add proxy api",
    version: 6,
    url: "vip",
    method: "POST",
  },
  update: {
    name: "update proxy api",
    version: 6,
    url: "vip/%{proxyId}",
    method: "PUT",
  },
  delete: {
    name: "delete proxy api",
    version: 6,
    url: "vip/%{proxyId}",
    method: "DELETE",
  },
};
