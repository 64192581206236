import React from "react";

import { Locale, Logger, Moment, Util } from "../utils";

export default class Base extends React.Component {
    constructor(props) {
        super(props);

        this.logger = Logger.getInstance();
        this.i18n = Locale.getInstance();
        this.moment = Moment.getInstance();
        this.util = Util.getInstance();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentDidUpdate(prevProps, prevState) {}

    componentWillUnmount() {
        this.mounted = false;
    }
}
