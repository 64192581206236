export default {
    list: {
        name: "community list api",
        version: 6,
        url: "community",
        method: "GET",
    },
    add: {
        name: "add community api",
        version: 6,
        url: "add-community",
        method: "POST",
    },
    update: {
        name: "update community api",
        version: 6,
        url: "community/%{id}",
        method: "PUT",
    },
    delete: {
        name: "delete community api",
        version: 6,
        url: "community/%{id}",
        method: "DELETE",
    },
    batchAdd: {
        name: "batch add community api",
        version: 6,
        url: "community/batch-add",
        method: "POST",
    },
};
