import { Table, Tag, Input } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ApiManager, Locale, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const base = Base.getInstance();
const util = Util.getInstance();

const { Search } = Input;

function AgentWithdrawList() {
  const thisSorter = useRef();
  const thisFilters = useRef();
  const fetchData = useRef();

  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState({ data: [] });

  function getStatusColor(recordStatus) {
    let color;
    switch (recordStatus) {
      case 2:
        color = "green";
        break;
      case 0:
        color = "blue";
        break;
      case 3:
        color = "red";
        break;
      default:
        color = "default";
        break;
    }
    return color;
  }

  fetchData.current = async (page = 1, keyword = "") => {
    if (user.token) {
      const apiUrl = api.proxy.withdrawList;
      const apiConfig = {
        token: user.token,
        query: { page, keyword, count: 10 },
      };
      if (thisSorter.current && thisSorter.current.field) {
        const order = thisSorter.current.order.split("end")[0];
        apiConfig.query[
          `filter[sort]`
        ] = `${thisSorter.current.field}@${order}`;
      }
      if (thisFilters && thisFilters.current) {
        for (let key in thisFilters.current) {
          if (thisFilters.current[key]) {
            apiConfig.query[`filter[${key}]`] = thisFilters.current[key].join(
              ","
            );
          }
        }
      }
      setLoading(true);
      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setListData(res.data);
        } else {
          base.showModal(res.message, Base.Modal.warning);
        }
      } catch (error) {
        base.handleApiError(api, error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData.current();
  }, [fetchData]);

  return (
    <>
      <Search
        onSearch={(keyword) => fetchData.current(1, keyword)}
        placeholder={i18n.t("users.searchPlace")}
        style={{ width: 300, marginBottom: 20 }}
        enterButton
      />
      <Table
        loading={loading}
        dataSource={listData.data}
        rowKey={(record) => `${record.id}`}
        onChange={(pagination, filters, sorter) => {
          thisSorter.current = sorter;
          thisFilters.current = filters;
          fetchData.current(pagination.current);
        }}
        pagination={{
          current: listData.current_page,
          total: listData.total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        columns={[
          {
            title: "user_id",
            key: "user_id",
            dataIndex: "user_id",
          },
          {
            title: i18n.t("users.name"),
            key: "nickname",
            render: (text, record) => record.re_user_name,
          },
          {
            title: i18n.t("commissionList.channel"),
            key: "channel",
            align: "center",
            render: (text, record) => {
              return <Tag color="purple">{record.desc}</Tag>;
            },
          },
          {
            title: i18n.t("commissionList.comm"),
            key: "amount",
            dataIndex: "amount",
            sorter: true,
            render: (text, record) => {
              return util.getFormattedPrice(record.received_amount, "¥");
            },
          },
          {
            title: i18n.t("commissionList.status"),
            key: "status",
            align: "center",
            filters: listData.status
              ? listData.status.map((i, idx) => ({
                  text: i,
                  value: idx,
                }))
              : [],
            render: (text, record) => {
              if (record.user_id) {
                return (
                  <Tag color={getStatusColor(record.status)}>
                    {record.status_label}
                  </Tag>
                );
              }
              return null;
            },
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "created_at",
            align: "center",
            sorter: true,
          },
        ]}
      />
    </>
  );
}

export default AgentWithdrawList;
