import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  List,
  message,
  Button,
  Layout,
  Space,
  Menu,
  Empty,
  Modal,
  Input,
  Form,
  Typography,
  InputNumber,
  Radio,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TranslateBtn from "../../components/product/TranslateBtn";
import { FILTER_STATUS } from "../../constants";
import { ApiManager, Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const base = Base.getInstance();
const ALL_ID = "-1";
const { Text } = Typography;

function ArticleList({ router, location }) {
  const fetching = useRef(false);
  const fetchData = useRef(() => {});
  const fetchCatData = useRef(() => {});
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const [showCatModal, setShowCatModal] = useState(false);
  const [selectedCatId, setSelectedCat] = useState(
    location.query.catId ?? ALL_ID
  );
  const [filterStatus, setFilterStatus] = useState(FILTER_STATUS.ALL.value);
  const [data, setListData] = useState({ data: [] });
  const [catData, setCatData] = useState([
    { id: -1, zh_name: "全部", ug_name: "ھەممىسى" },
  ]);
  const [form] = Form.useForm();

  fetchData.current = async (page = 1) => {
    if (user.token) {
      const apiUrl = api.article.list;
      const apiConfig = {
        token: user.token,
        query: {
          page,
          category_id: selectedCatId,
          count: 10,
          "filter[status]": filterStatus,
        },
      };
      if (fetching.current) return;
      fetching.current = true;
      setLoading(true);
      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setListData(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        fetching.current = false;
        setLoading(false);
      }
    }
  };

  fetchCatData.current = async () => {
    if (user.token) {
      const apiUrl = api.article.catList;
      const apiConfig = {
        token: user.token,
      };
      setLoading(true);
      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setCatData([...catData, ...res.data]);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    }
  };

  function onDeleteItem(id) {
    base.showModal(
      i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const apiUrl = api.article.delete;
        const { token } = user;
        try {
          if (token) {
            const apiConfig = {
              token,
              path: {
                id,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              fetchData.current(data.current_page);
            } else {
              base.showMessage(res.message, Base.Message.warning);
            }
          }
        } catch (error) {
          base.handleApiError(api, error);
        }
      }
    );
  }

  function onDeleteCat() {
    base.showModal(
      i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const apiUrl = api.article.catDelete;
        const { token } = user;
        try {
          if (token) {
            const apiConfig = {
              token,
              path: {
                id: selectedCatId,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              const newCts = catData.filter(
                (i) => i.id !== Number(selectedCatId)
              );
              setCatData(newCts);
            } else {
              base.showMessage(res.message, Base.Message.warning);
            }
          }
        } catch (error) {
          base.handleApiError(api, error);
        }
      }
    );
  }

  function onFinish() {
    form
      .validateFields()
      .then(async (values) => {
        const { token } = user;
        if (token) {
          let apiUrl = api.article.catAdd;
          let apiConfig = {
            token,
            data: { ...values },
          };
          if (showCatModal.id && showCatModal.id !== ALL_ID) {
            apiUrl = api.article.catUpdate;
            apiConfig.path = { id: showCatModal.id };
          }
          try {
            setLoading(true);
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              setShowCatModal(false);
              form.resetFields();
              if (showCatModal.id && showCatModal.id !== ALL_ID) {
                const selectedCatIndex = catData.findIndex(
                  (i) => i.id === selectedCatId
                );
                catData.splice(selectedCatIndex, 1, res.data);
                setCatData(catData);
              } else {
                setCatData([...catData, res.data]);
                setSelectedCat(ALL_ID);
              }
            } else {
              base.showModal(res.message, Base.Modal.warning);
            }
          } catch (error) {
            base.handleApiError(apiUrl, error);
          } finally {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log("onFinish -> error", error);
      });
  }

  useEffect(() => {
    fetchCatData.current();
    fetchData.current();
  }, [fetchCatData, fetchData]);

  useEffect(() => {
    fetchData.current();
  }, [selectedCatId, filterStatus]);

  return (
    <Layout>
      <Layout.Sider
        theme="light"
        style={{
          overflowY: "scroll",
          height: 940,
        }}
      >
        {catData && catData.length > 0 ? (
          <Menu
            mode="inline"
            onSelect={(e) => {
              setSelectedCat(e.key);
              location.query = { ...location.query, catId: e.key };
              router.replace(location);
            }}
            defaultSelectedKeys={[ALL_ID]}
            selectedKeys={[selectedCatId]}
          >
            {catData.map((i) => {
              return (
                <Menu.Item key={`${i.id}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <Text
                      style={{
                        width: 140,
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {i18n.getPropNameByLocale(i, "name")}
                    </Text>
                    {selectedCatId !== ALL_ID &&
                      Number(selectedCatId) === i.id && (
                        <Space direction="horizontal">
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => {
                              setShowCatModal(i);
                              form.setFieldsValue({
                                zh_name: i.zh_name,
                                ug_name: i.ug_name,
                                order: i.order,
                              });
                            }}
                            type="link"
                          />
                          <Button
                            icon={<DeleteOutlined />}
                            type="link"
                            danger
                            onClick={() => onDeleteCat()}
                          />
                        </Space>
                      )}
                  </div>
                </Menu.Item>
              );
            })}
          </Menu>
        ) : (
          <Empty description={i18n.t("product.catsEmpty")} image={null} />
        )}
      </Layout.Sider>
      <Layout.Content>
        <List
          header={
            <Space direction="horizontal" size={10}>
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowCatModal(true);
                }}
              >
                {i18n.t("product.cats.add")}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (catData.length === 1) {
                    base.showModal(i18n.t("article.addCatPlz"));
                  } else {
                    catData.shift();
                    router.push({
                      pathname: "/article-edit",
                      state: {
                        categories: catData,
                      },
                    });
                  }
                }}
              >
                {i18n.t("article.add")}
              </Button>
              <Radio.Group
                style={{ marginRight: 20, marginLeft: 20 }}
                onChange={(e) => {
                  setFilterStatus(e.target.value);
                }}
                value={filterStatus}
              >
                <Radio value={FILTER_STATUS.ALL.value}>
                  {FILTER_STATUS.ALL.name}
                </Radio>
                <Radio value={FILTER_STATUS.OPENED.value}>
                  {FILTER_STATUS.OPENED.name}
                </Radio>
                <Radio value={FILTER_STATUS.CLOSED.value}>
                  {FILTER_STATUS.CLOSED.name}
                </Radio>
              </Radio.Group>
            </Space>
          }
          bordered
          size="large"
          style={{ backgroundColor: "#fff" }}
          loading={loading}
          dataSource={data.data}
          pagination={{
            current: data.current_page,
            total: data.total,
            pageSize: 10,
            onChange: (page) => fetchData.current(page),
            showQuickJumper: true,
          }}
          rowKey={(record) => `item-${record.id}`}
          renderItem={(item) => (
            <List.Item
              style={{
                backgroundColor: item.status === 1 ? "#ffffff" : "#f1f1f1",
              }}
              actions={[
                <Button
                  key="list-loadmore-edit"
                  onClick={() => {
                    router.push({
                      pathname: "/article-edit",
                      state: {
                        data: item,
                        categories: catData,
                      },
                    });
                  }}
                  type="link"
                >
                  {i18n.t("product.productItem.actionEdit")}
                </Button>,
                <Button
                  key="list-loadmore-more"
                  onClick={() => onDeleteItem(item.id)}
                  type="link"
                  danger
                >
                  {i18n.t("sales.activity.delete")}
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={i18n.getPropNameByLocale(item, "name")}
                avatar={
                  <Avatar
                    src={i18n.getPropNameByLocale(item, "image")}
                    size="large"
                    shape="square"
                  />
                }
                description={<div>ID: {item.id}</div>}
              />
            </List.Item>
          )}
        />
      </Layout.Content>
      <Modal
        title={i18n.t("product.cats.add")}
        visible={showCatModal}
        onCancel={() => {
          setShowCatModal(false);
          form.resetFields();
        }}
        onOk={() => onFinish()}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        okButtonProps={{ loading: loading, disabled: loading }}
      >
        <Form form={form}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="zh_name"
                label={i18n.t("sales.mini.zhTitle")}
                rules={[
                  { required: true, message: i18n.t("sales.zhTitleWarning") },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="ug_name"
                label={i18n.t("sales.mini.ugTitle")}
                rules={[
                  { required: true, message: i18n.t("sales.ugTitleWarning") },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item name="order" label={i18n.t("showsEdit.orderLabel")}>
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
}

export default ArticleList;
