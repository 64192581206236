import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  Table,
  message,
  Button,
  Tag,
  Avatar,
  Modal,
  Space,
  Divider,
} from "antd";
import Search from "antd/lib/input/Search";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { ApiManager, Locale, Util } from "../../utils";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();

function UsersList({ user }) {
  const [list, setList] = useState({ data: [], current_page: 1 });
  const [balanceSum, setBalanceSum] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchList = useCallback(
    async (page = 1, keyword = "", sorter = null) => {
      const { token } = user;
      if (token) {
        setLoading(true);
        try {
          const apiUrl = api.user.list;
          const apiConfig = {
            token,
            query: {
              keyword,
              page,
            },
          };
          if (sorter && sorter.field) {
            apiConfig.query[`filter[${sorter.field}]`] = sorter.order;
          }
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setList(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [user]
  );

  const fetchBalanceSum = useCallback(async () => {
    const { token } = user;
    if (token) {
      setLoading(true);
      try {
        const apiUrl = api.user.balanceSum;
        const apiConfig = {
          token,
        };

        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setBalanceSum(res.data.balance_sum);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  }, [user]);

  const unbindUser = useCallback(
    (user_id, open_id) => {
      Modal.confirm({
        title: i18n.t("users.unbindWarning"),
        okText: i18n.t("base.modal.okText"),
        cancelText: i18n.t("base.modal.cancelText"),
        onOk: async () => {
          const { token } = user;
          if (token) {
            try {
              const apiUrl = api.user.unbindWechat;
              const apiConfig = {
                token,
                data: {
                  user_id,
                  open_id,
                },
              };
              const res = await apiUrl.run(apiConfig);
              if (res.result) {
                message.success(res.message);
                fetchList();
              } else {
                message.warning(res.message);
              }
            } catch (error) {
              message.error(error.message);
            }
          }
        },
      });
    },
    [user, fetchList]
  );

  const toggleAppointUser = useCallback(
    async (client) => {
      const { token } = user;
      if (token) {
        try {
          let apiUrl = api.user.appointUser;
          if (client.user && client.user.is_specified_user) {
            apiUrl = api.user.unappointUser;
          }
          const apiConfig = {
            token,
            path: {
              user_id: client.user_id,
            },
          };
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            message.success(res.message);
            fetchList(list.current_page);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        }
      }
    },
    [user, fetchList, list.current_page]
  );

  useEffect(() => {
    fetchList();
    fetchBalanceSum();
  }, [fetchList, fetchBalanceSum]);

  return (
    <>
      <Search
        onSearch={(keyword) => fetchList(1, keyword)}
        placeholder={i18n.t("users.searchPlace")}
        style={{ width: 300, marginBottom: 16 }}
        enterButton
      />
      <div>
        <span>{i18n.t("users.balanceSum")}</span>
        <span> ¥{util.getPrice(balanceSum)}</span>
      </div>
      <Table
        loading={loading}
        dataSource={list.data}
        rowKey={(record) => `${record.open_id}`}
        onChange={(pagination, f, sorter) =>
          fetchList(pagination.current, "", sorter)
        }
        pagination={{
          current: list.current_page,
          total: list.total,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        columns={[
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => (
              <Avatar
                size="large"
                shape="circle"
                src={record.avatar}
                alt={record.nickname}
              />
            ),
          },
          {
            title: i18n.t("users.name"),
            dataIndex: "nickname",
            key: "nickname",
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            align: "center",
            render: (text, record) => {
              if (record.user) {
                return record.user.mobile;
              }
              return null;
            },
          },
          {
            title: i18n.t("users.order"),
            key: "orderCount",
            dataIndex: "orders_count",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("users.balance"),
            key: "balance",
            dataIndex: "balance",
            sorter: true,
            render: (text, record) => {
              if (record.user) {
                return util.getFormattedPrice(record.user.balance, "¥");
              }
              return null;
            },
          },
          {
            title: i18n.t("users.tags"),
            key: "tags",
            render: (text, record) => {
              if (record.user_id) {
                return <Tag color="green">{i18n.t("users.registered")}</Tag>;
              }
              return null;
            },
          },
          {
            title: "Open Id",
            key: "open_id",
            dataIndex: "open_id",
            align: "center",
            responsive: ["xxl"],
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "created_at",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("users.actions"),
            key: "actions",
            align: "center",
            render: (text, record) => {
              if (record.user_id) {
                const isEnabledSpecifiedUsers =
                  user.merchant && user.merchant.enabled_specified_users;
                return (
                  <Space>
                    <Button
                      type="link"
                      onClick={() => unbindUser(record.user_id, record.open_id)}
                    >
                      {i18n.t("users.unbind")}
                    </Button>
                    {isEnabledSpecifiedUsers && <Divider type="vertical" />}
                    {isEnabledSpecifiedUsers && (
                      <Button
                        type="link"
                        danger={record.user && record.user.is_specified_user}
                        onClick={() => toggleAppointUser(record)}
                      >
                        {record.user && record.user.is_specified_user
                          ? i18n.t("users.unappoint")
                          : i18n.t("users.appoint")}
                      </Button>
                    )}
                  </Space>
                );
              }
              return null;
            },
          },
        ]}
      />
    </>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(UsersList);
