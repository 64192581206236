export default {
  generate: {
    name: "generate weapp api",
    version: 6,
    url: "generate-weapp/%{id}",
    method: "POST",
  },
  add: {
    name: "add weapp api",
    version: 6,
    url: "weapp",
    method: "POST",
  },
  update: {
    name: "update weapp api",
    version: 6,
    url: "weapp/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete weapp api",
    version: 6,
    url: "weapp/%{id}",
    method: "DELETE",
  },
  show: {
    name: "show weapp api",
    version: 6,
    url: "weapp/show",
    method: "GET",
  },
  getPayment: {
    name: "get weapp payment config api",
    version: 6,
    url: "wechat/payment/config",
    method: "GET",
  },
  addPayment: {
    name: "add weapp payment config api",
    version: 6,
    url: "wechat/payment/config",
    method: "POST",
  },
  updatePayment: {
    name: "update weapp payment config api",
    version: 6,
    url: "wechat/payment/config/%{id}",
    method: "PUT",
  },
  upload: {
    name: "upload weapp payment cert api",
    version: 6,
    url: "weapp/upload-cert",
    method: "UPLOAD",
  }
}