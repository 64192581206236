export default {
  list: {
    name: "groupon list api",
    version: 6,
    url: "groupon",
    method: "GET",
  },
  add: {
    name: "add groupon api",
    version: 6,
    url: "groupon",
    method: "POST",
  },
  update: {
    name: "update groupon api",
    version: 6,
    url: "groupon/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete groupon api",
    version: 6,
    url: "groupon/%{id}",
    method: "DELETE",
  },
  productAdd: {
    name: "add groupon product api",
    version: 6,
    url: "groupon/%{id}/add",
    method: "POST",
  },
  productDelete: {
    name: "delete groupon product api",
    version: 6,
    url: "groupon/%{id}/delete/%{product_id}",
    method: "POST",
  },
  productList: {
    name: "get groupon product-list api",
    version: 6,
    url: "groupon/%{id}/list",
    method: "GET",
  },
};
