import React, { useState, useEffect, useCallback } from "react";
import {
  Input,
  Select,
  Row,
  Col,
  Button,
  Space,
  Popover,
  Form,
  InputNumber,
  Spin,
  message,
  Modal,
} from "antd";
import { MinusOutlined } from "@ant-design/icons";

import { Locale, ApiManager } from "../../utils";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import TranslateBtn from "../../components/product/TranslateBtn";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const { Option } = Select;

function ProductPropertyForm({ user, product }) {
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [childList, setChildList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onSetInitialProperty = useCallback(
    (list) => {
      if (list && list.length > 0) {
        list.forEach((item) => {
          form.setFieldsValue({
            [`${item.product_sku_id}_zh_name`]: item.zh_name,
            [`${item.product_sku_id}_ug_name`]: item.ug_name,
            [`${item.product_sku_id}_product_no`]: item.product_no,
            [`${item.product_sku_id}_quantity`]: item.quantity,
          });
        });
      }
    },
    [form]
  );

  const getListData = useCallback(async () => {
    const { token } = user;
    if (token && product) {
      const listApi = api.productProperty.list;
      const apiConfig = {
        token,
        query: {
          product_id: product.id,
        },
      };
      try {
        const res = await listApi.run(apiConfig);
        if (res.result) {
          setGroupList(res.data);
          if (res.data[0]) {
            if (!selectedGroup) {
              setSelectedGroup(res.data[0].id);
              setChildList(res.data[0].product_property);
            }
            let list = res.data[0].product_property;
            if (selectedGroup) {
              const currentGroup = res.data.find((i) => i.id === selectedGroup);
              if (currentGroup) {
                list = currentGroup.product_property;
              }
            }
            onSetInitialProperty(list);
            setChildList(list);
          }
        }
      } catch (error) {
        console.log("getListData -> error", error);
      }
    }
  }, [onSetInitialProperty, product, selectedGroup, user]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  function onAddChildProperty() {
    setChildList(
      childList.concat([
        {
          product_sku_id: `new-${new Date().getTime()}`,
          zh_name: "",
          ug_name: "",
          quantity: 0,
          product_no: null,
        },
      ])
    );
  }

  function onDeleteGroup() {
    Modal.confirm({
      title: i18n.t("sales.deleteWarning"),
      okText: i18n.t("base.modal.okText"),
      cancelText: i18n.t("base.modal.cancelText"),
      onOk: async () => {
        const { token } = user;
        if (token) {
          const deleteApi = api.productProperty.delete;
          const apiConfig = {
            token,
            path: { id: selectedGroup },
          };
          try {
            const res = await deleteApi.run(apiConfig);
            if (res.result) {
              const remainedList = groupList.filter(
                (i) => i.id !== selectedGroup
              );
              setGroupList(remainedList);
              setSelectedGroup(null);
              setChildList([]);
            } else {
              message.error(res.message);
            }
          } catch (error) {
            console.log("onDeleteGroup -> error", error);
          }
        }
      },
    });
  }

  function onDeleteChild(id) {
    if (typeof id === "string") {
      const remainedList = childList.filter((i) => i.product_sku_id !== id);
      setChildList(remainedList);
    } else {
      Modal.confirm({
        title: i18n.t("sales.deleteWarning"),
        okText: i18n.t("base.modal.okText"),
        cancelText: i18n.t("base.modal.cancelText"),
        onOk: async () => {
          const { token } = user;
          if (token) {
            const deleteApi = api.productProperty.deleteChild;
            const apiConfig = {
              token,
              path: { id },
            };
            try {
              const res = await deleteApi.run(apiConfig);
              if (res.result) {
                message.success(res.message);
                const remainedList = childList.filter(
                  (i) => i.product_sku_id !== id
                );
                setChildList(remainedList);
              } else {
                message.error(res.message);
              }
            } catch (error) {
              console.log("onDeleteGroup -> error", error);
            }
          }
        },
      });
    }
  }

  async function onAddGroup(values) {
    const { token } = user;
    if (token) {
      const adApi = api.productProperty.add;
      setLoading(true);
      try {
        const { zh_name, ug_name } = values;
        const apiConfig = {
          token,
          data: {
            zh_name,
            ug_name,
            product_id: product.id,
          },
        };
        const res = await adApi.run(apiConfig);
        if (res.result) {
          setGroupList(
            groupList.concat({
              zh_name: res.data.zh_name,
              ug_name: res.data.ug_name,
              id: res.data.id,
            })
          );
          setSelectedGroup(res.data.id);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        console.log("onAddGroup -> error", error);
      } finally {
        setLoading(false);
      }
    }
  }

  function onSelectGroup(value) {
    setSelectedGroup(value);
    const currentGroup = groupList.find((i) => i.id === value);
    if (currentGroup) {
      setChildList(currentGroup.product_property);
    }
  }

  async function onSubmitProperties(values) {
    const { token } = user;
    const addChildApi = api.productProperty.addChild;
    if (token) {
      try {
        const apiConfig = {
          token,
          data: {
            property_group_id: selectedGroup,
            product_id: product.id,
            properties: childList.map((property) => {
              const newProp = {
                zh_name: values[`${property.product_sku_id}_zh_name`],
                ug_name: values[`${property.product_sku_id}_ug_name`],
                quantity: values[`${property.product_sku_id}_quantity`],
                product_no: values[`${property.product_sku_id}_product_no`],
              };
              if (
                property.product_sku_id &&
                typeof property.product_sku_id !== "string"
              ) {
                newProp.product_sku_id = property.product_sku_id;
              }
              return newProp;
            }),
          },
        };
        setLoading(true);
        const res = await addChildApi.run(apiConfig);
        if (res.result) {
          message.success(res.message);
          getListData();
        } else {
          message.error(res.message);
        }
      } catch (error) {
        console.log("onSubmitProperties -> error", error);
      } finally {
        setLoading(false);
      }
    }
  }

  if (!product || !product.id) return null;

  const currentGroup = groupList.find((i) => i.id === selectedGroup);
  let selectValue;
  if (currentGroup) {
    selectValue = i18n.getPropNameByLocale(currentGroup, "name");
  }
  return (
    <Row className="ant-form-item ant-form-item-no-colon standard">
      <Col
        span={i18n.isLocaleRTL() ? 6 : 4}
        className="ant-form-item-label ant-legacy-form-item-label"
      >
        <label className=" ant-legacy-form-item-no-colon">
          {i18n.t("productEdit.propertyLabel")}
        </label>
      </Col>
      <Col
        span={i18n.isLocaleRTL() ? 16 : 8}
        className="ant-form-item-control-wrapper standard"
      >
        <Space
          direction="horizontal"
          size={12}
          style={{ marginLeft: 8, marginRight: 8 }}
        >
          {groupList.length > 0 && (
            <Select
              style={{ width: 120 }}
              value={selectValue}
              onChange={(value) => onSelectGroup(value)}
            >
              {groupList.map((g) => (
                <Option key={g.id} value={g.id}>
                  {i18n.getPropNameByLocale(g, "name")}
                </Option>
              ))}
            </Select>
          )}
          {selectedGroup && (
            <Button type="primary" onClick={() => onAddChildProperty()}>
              {i18n.t("property.addChild")}
            </Button>
          )}
          {selectedGroup && (
            <Button type="danger" onClick={() => onDeleteGroup()}>
              {i18n.t("property.delete")}
            </Button>
          )}
          <Popover
            trigger="click"
            content={
              <Space direction="horizontal" align="center">
                <Form onFinish={(values) => onAddGroup(values)}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => (
                      <Form.Item
                        name="zh_name"
                        rules={[
                          {
                            required: true,
                            message: i18n.t("sales.zhTitleWarning"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={i18n.t("property.groupZh")}
                          addonAfter={
                            <TranslateBtn
                              context={getFieldValue("ug_name")}
                              onFinish={(value) =>
                                setFieldsValue({
                                  zh_name: value,
                                })
                              }
                              from="ug"
                              to="zh"
                            />
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => (
                      <Form.Item
                        name="ug_name"
                        rules={[
                          {
                            required: true,
                            message: i18n.t("sales.ugTitleWarning"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={i18n.t("property.groupUg")}
                          addonAfter={
                            <TranslateBtn
                              context={getFieldValue("zh_name")}
                              onFinish={(value) =>
                                setFieldsValue({
                                  ug_name: value,
                                })
                              }
                              from="zh"
                              to="ug"
                            />
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    htmlType="submit"
                  >
                    {i18n.t("property.submit")}
                  </Button>
                </Form>
              </Space>
            }
          >
            <Button>{i18n.t("property.addGroup")}</Button>
          </Popover>
        </Space>
        <Spin spinning={loading}>
          <Form onFinish={(values) => onSubmitProperties(values)} form={form}>
            {childList.length > 0 &&
              childList.map((i) => (
                <Space
                  key={i.product_sku_id}
                  style={{ marginRight: 8, marginLeft: 8, marginTop: 8 }}
                  direction="horizontal"
                  align="center"
                >
                  <Form.Item
                    rules={[
                      { required: true, message: i18n.t("property.must") },
                    ]}
                    name={`${i.product_sku_id}_zh_name`}
                  >
                    <Input placeholder={i18n.t("property.childZh")} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: i18n.t("property.must") },
                    ]}
                    name={`${i.product_sku_id}_ug_name`}
                  >
                    <Input placeholder={i18n.t("property.childUg")} />
                  </Form.Item>
                  <Form.Item name={`${i.product_sku_id}_product_no`}>
                    <InputNumber placeholder={i18n.t("property.number")} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: i18n.t("property.must") },
                    ]}
                    name={`${i.product_sku_id}_quantity`}
                  >
                    <InputNumber placeholder={i18n.t("property.quantity")} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      danger
                      icon={<MinusOutlined />}
                      onClick={() => onDeleteChild(i.product_sku_id)}
                    />
                  </Form.Item>
                </Space>
              ))}
            {childList.length > 0 && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 8, marginRight: 8 }}
                >
                  {i18n.t("property.submit")}
                </Button>
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Col>
    </Row>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  ProductPropertyForm
);
