export default {
  list: {
    name: "flash list api",
    version: 6,
    url: "flash-sale",
    method: "GET",
  },
  add: {
    name: "add flash api",
    version: 6,
    url: "flash-sale",
    method: "POST",
  },
  update: {
    name: "update flash api",
    version: 6,
    url: "flash-sale/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete flash api",
    version: 6,
    url: "flash-sale/%{id}",
    method: "DELETE",
  },
  productAdd: {
    name: "add flash product api",
    version: 6,
    url: "flash-sale/%{id}/add",
    method: "POST",
  },
  productDelete: {
    name: "delete flash product api",
    version: 6,
    url: "flash-sale/%{id}/delete/%{product_id}",
    method: "POST",
  },
  productList: {
    name: "get flash product-list api",
    version: 6,
    url: "flash-sale/%{id}/list",
    method: "GET",
  },
};
