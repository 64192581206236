import React, { useCallback, useRef, useEffect } from "react";
import { Form, Input, Upload, Button, message, DatePicker, Switch } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "dayjs";
import { Locale, ApiManager, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const util = Util.getInstance();

function MerchantInfoForm({ user }) {
  const [form] = Form.useForm();
  const refData = useRef();

  const getDefaultFile = (fileUrl = "") => {
    if (fileUrl) {
      let file = {
        uid: -1,
        name: `file-${new Date().getTime()}`,
        status: "done",
        url: typeof fileUrl === "boolean" ? "" : fileUrl,
      };

      return [file];
    }
    return [];
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const getData = useCallback(async () => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.merchant.getMerchantInfo;
        const apiConfig = {
          token,
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          const {
            licence_number,
            licence_image,
            licence_name,
            licence_legal_representative,
            id_card_front,
            id_card_back,
            id_full_name,
            id_number,
            id_start_date,
            id_end_date,
            is_id_long_term,
            merchant_abbr,
            customer_number,
          } = res.data;
          refData.current = res.data;
          form.setFieldsValue({
            licence_number,
            licence_image: getDefaultFile(licence_image),
            licence_name,
            licence_legal_representative,
            id_card_front: getDefaultFile(id_card_front),
            id_card_back: getDefaultFile(id_card_back),
            id_full_name,
            id_number,
            id_start_date: moment(id_start_date),
            id_end_date: moment(id_end_date),
            is_id_long_term,
            merchant_abbr,
            customer_number,
          });
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [user, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFinish = useCallback(
    async (values) => {
      const { token } = user;
      if (token) {
        const {
          licence_number,
          licence_image,
          licence_name,
          licence_legal_representative,
          id_card_front,
          id_card_back,
          id_full_name,
          id_number,
          id_start_date,
          id_end_date,
          is_id_long_term,
          merchant_abbr,
          customer_number,
        } = values;

        const apiConfig = {
          token,
          data: {
            licence_number,
            licence_name,
            licence_legal_representative,
            id_full_name,
            id_number,
            id_start_date,
            id_end_date,
            is_id_long_term,
            merchant_abbr,
            customer_number,
          },
        };
        apiConfig.data.id_start_date = id_start_date.format("YYYY-MM-DD");
        apiConfig.data.id_end_date = id_end_date.format("YYYY-MM-DD");
        let apiUrl = api.merchant.addMerchantInfo;
        if (refData.current && refData.current.id) {
          apiUrl = api.merchant.updateMerchantInfo;
          apiConfig.path = { id: refData.current.id };
        }

        if (
          licence_image[0] &&
          licence_image[0].uid !== -1 &&
          licence_image[0].response
        ) {
          console.log(licence_image[0], "log");
          apiConfig.data.licence_image = licence_image[0].response.path;
        }
        if (
          id_card_front[0] &&
          id_card_front[0].uid !== -1 &&
          id_card_front[0].response
        ) {
          apiConfig.data.id_card_front = id_card_front[0].response.path;
        }
        if (
          id_card_back[0] &&
          id_card_back[0].uid !== -1 &&
          id_card_back[0].response
        ) {
          apiConfig.data.id_card_back = id_card_back[0].response.path;
        }

        try {
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            message.success(res.message);
            getData();
          } else {
            message.warn(res.message);
          }
        } catch (error) {
          message.error(error.message);
        }
      }
    },
    [user, getData]
  );
  const onBeforeUpload = (file) => {
    const isLt2000KB = file.size / 1024 < 2000;
    if (!isLt2000KB) {
      message.warn(i18n.t("productEdit.uploadNavImageSizeWarning"));
    }
    return isLt2000KB;
  };

  const uploadImages = useCallback(
    async ({ file, onError, onSuccess, uploadType }) => {
      if (!file) onError("invalid images");

      const { token } = user;
      if (!token) onError("no user token");

      const uploadFiles = [{ file }];
      const apiUrl = api.common.uploadImages;
      const apiConfig = {
        token,
        uploadFiles,
        uploadType: "merchant-info",
      };

      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          onSuccess(res.data[0], file);
        } else {
          onError(res.message);
        }
      } catch (error) {
        onError(error);
      }
    },
    [user]
  );
  const onStartDateChange = (date, dateString) => {
    // const tzDate = moment(date.format("YYYY-MM-DD")).tz("Asia/Shanghai");
    // console.log(date,dateString, 'tzdate');
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 8 }}
    >
      <Form.Item
        name="licence_number"
        label={i18n.t("financeAccount.licenceNumber")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="licence_name"
        label={i18n.t("financeAccount.licenceName")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="licence_image"
        label={i18n.t("financeAccount.licenceImage")}
        valuePropName="fileList"
        getValueFromEvent={(e) => util.normFile(e)}
      >
        <Upload
          accept="image/*"
          multiple={false}
          maxCount={1}
          listType="picture-card"
          beforeUpload={onBeforeUpload}
          onPreview={onPreview}
          customRequest={uploadImages}
        >
          <Button>
            <UploadOutlined /> {i18n.t("sales.upload")}
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="licence_legal_representative"
        label={i18n.t("financeAccount.licenceLegal")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="id_card_front"
        label={i18n.t("financeAccount.licenceIdCardFront")}
        valuePropName="fileList"
        getValueFromEvent={(e) => util.normFile(e)}
      >
        <Upload
          accept="image/*"
          multiple={false}
          maxCount={1}
          listType="picture-card"
          beforeUpload={onBeforeUpload}
          customRequest={uploadImages}
        >
          <Button>
            <UploadOutlined /> {i18n.t("sales.upload")}
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="id_card_back"
        label={i18n.t("financeAccount.licenceIdCardBack")}
        valuePropName="fileList"
        getValueFromEvent={(e) => util.normFile(e)}
      >
        <Upload
          accept="image/*"
          multiple={false}
          maxCount={1}
          listType="picture-card"
          beforeUpload={onBeforeUpload}
          customRequest={uploadImages}
        >
          <Button>
            <UploadOutlined /> {i18n.t("sales.upload")}
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="id_full_name"
        label={i18n.t("financeAccount.licenceIdName")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="id_number"
        label={i18n.t("financeAccount.licenceIdNumber")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="id_start_date"
        label={i18n.t("financeAccount.licenceIdStartDate")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <DatePicker onChange={onStartDateChange} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {() => {
          console.log("id_long_term", form.getFieldValue("is_id_long_term"));
          return (
            <Form.Item
              name="id_end_date"
              label={i18n.t("financeAccount.licenceIdEndDate")}
              rules={[
                {
                  required: true,
                  message: i18n.t("product.headerSeatchHint"),
                },
              ]}
            >
              <DatePicker disabled={form.getFieldValue("is_id_long_term")} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item
        name="is_id_long_term"
        valuePropName="checked"
        label={i18n.t("financeAccount.idLongTerm")}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="merchant_abbr"
        label={i18n.t("financeAccount.merchantAbbr")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="customer_number"
        label={i18n.t("financeAccount.customerNumber")}
        rules={[
          { required: true, message: i18n.t("product.headerSeatchHint") },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4 }}>
        <Button type="primary" htmlType="submit" size="large">
          {i18n.t("sales.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(MerchantInfoForm);
