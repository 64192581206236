import React, { useCallback, useEffect } from "react";
import {
  Steps,
  Button,
  message,
  Descriptions,
  Badge,
  Space,
  Typography,
} from "antd";
import { connect } from "react-redux";

import QRCode from "qrcode.react";
import { Locale, ApiManager } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const { Title } = Typography;
const { Step } = Steps;

const steps = [
  {
    title: i18n.t("applyment.apply"),
    content: i18n.t("applyment.applyDescription"),
    error: "",
  },
  {
    title: i18n.t("applyment.procesing"),
    content: "",
    error: "",
  },
  {
    title: i18n.t("applyment.finish"),
    content: "",
    error: "",
  },
];

function ApplymentInfo({ user }) {
  const [current, setCurrent] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const getData = useCallback(async () => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.applyment.show;
        const apiConfig = {
          token,
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          if (!res.data) {
            setCurrent(0);
          }
          setData(res.data);
          if (res.data) {
            if (res.data.applyment_state === "REJECTED") {
              const { audit_detail } = res.data;
              if (Array.isArray(audit_detail) && audit_detail.length > 0) {
                let err = audit_detail.map((i) => i.reject_reason);
                setError(err);
              } else {
                setError(audit_detail);
              }
            }
            if (res.data.applyment_state !== "FINISH") {
              setCurrent(1);
            } else {
              setCurrent(2);
            }
          }
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [user]);

  const queryNow = useCallback(async () => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.applyment.query;
        const apiConfig = {
          token,
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setData(res.data);
          if (!res.data) {
            setCurrent(0);
          }
          if (res.data.applyment_state === "REJECTED") {
            setError(res.data.audit_detail[0].reject_reason);
          }
          if (res.data.applyment_state !== "FINISH") {
            setCurrent(1);
          } else {
            setCurrent(2);
          }
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [user]);

  const applyNow = useCallback(async () => {
    setError(null);
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.applyment.apply;
        const apiConfig = {
          token,
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setData(res.data);
          if (!res.data) {
            setCurrent(0);
          }
          if (res.data.applyment_state === "REJECTED") {
            setError(res.data.audit_detail[0].reject_reason);
          }
          if (res.data.applyment_state !== "FINISH") {
            setCurrent(1);
          } else {
            setCurrent(2);
          }
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [user]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getStatus = (data) => {
    if (data && data.applyment_state) {
      if (data.applyment_state === "FINISH") {
        return "finish";
      }
      if (data.applyment_state === "REJECTED") {
        return "error";
      }
      return "";
    }
  };

  const getBadgeStatus = (data) => {
    if (data.applyment_state === "FINISH") {
      return "success";
    }
    if (data.applyment_state === "REJECTED") {
      return "error";
    }
    return "processing";
  };

  const getStatusLabel = (data) => {
    if (data.applyment_state === "CHECKING") {
      return i18n.t("applyment.checking");
    }
    if (data.applyment_state === "ACCOUNT_NEED_VERIFY") {
      return i18n.t("applyment.accountNeedVerify");
    }
    if (data.applyment_state === "AUDITING") {
      return i18n.t("applyment.auditing");
    }
    if (data.applyment_state === "REJECTED") {
      return i18n.t("applyment.rejected");
    }
    if (data.applyment_state === "NEED_SIGN") {
      return i18n.t("applyment.needSign");
    }
    if (data.applyment_state === "FINISH") {
      return i18n.t("applyment.finish");
    }
    if (data.applyment_state === "FROZEN") {
      return i18n.t("applyment.frozen");
    }
  };
  return (
    <>
      <Steps className="steps" current={current} status={getStatus(data)}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={`steps-content ${getStatus(data)} `}>
        {data && data.applyment_state === "NEED_SIGN" && (
          <div>
            <QRCode value={data.sign_url} />
            <div>商家需要扫码签约</div>
          </div>
        )}

        {steps[current].content}
        <div>{error}</div>
      </div>
      <div className="steps-action">
        <Space direction="horizontal">
          {current === 0 && (
            <Button type="primary" onClick={() => applyNow()}>
              {i18n.t("applyment.apply")}
            </Button>
          )}
          {current === 1 && (
            <Button type="primary" onClick={() => queryNow()}>
              {i18n.t("applyment.check")}
            </Button>
          )}
          {data && data.applyment_state === "REJECTED" && (
            <Button type="primary" onClick={() => applyNow()}>
              {i18n.t("applyment.applyAgain")}
            </Button>
          )}
        </Space>
      </div>
      {data && data.applyment_state && (
        <div className="steps-info">
          <Descriptions
            style={{ backgroundColor: "#fff" }}
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            title={
              <Title style={{ margin: "20px 20px 0 20px" }} level={4}>
                {i18n.t("applyment.title")}
              </Title>
            }
            bordered
          >
            <Descriptions.Item label={i18n.t("applyment.id")}>
              {data.applyment_id}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t("applyment.status")}>
              <Badge
                status={getBadgeStatus(data)}
                text={getStatusLabel(data)}
              />
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t("applyment.merchantId")}>
              {data.merchant_id}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t("applyment.createdDate")}>
              {data.created_at}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t("applyment.updatedDate")}>
              {data.updated_at}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t("applyment.subMerchantId")}>
              {data.sub_mchid}
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
    </>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(ApplymentInfo);
