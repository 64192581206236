import React from "react";
import { Tag } from "antd";

import { Locale, Util } from "../../utils";
import "./SimpleProductInfo.scss";

const i18n = Locale.getInstance();
const util = Util.getInstance();

function SimpleProductInfo({ item }) {
  return (
    <div className="simple-product-info">
      <div className="price">
        <span>ID:</span>
        <span>{item.id}</span>
      </div>
      <div className="price">
        <span>{i18n.t("productEdit.standardLabel")}</span>
        <span>{util.getFormattedPrice(item.price, "¥")}</span>
      </div>
      <div className="price">
        <span>{i18n.t("productEdit.inPriceLabel")}</span>
        <span>{util.getFormattedPrice(item.purchase_price, "¥")}</span>
      </div>
      <div className="price">
        <span>{i18n.t("productEdit.quantityLabel")}</span>
        <span>{item.quantity}</span>
      </div>
      {item.status ? (
        <Tag color="cyan">{i18n.t("product.filter.onShelf")}</Tag>
      ) : (
        <Tag color="magenta">{i18n.t("product.filter.offShelf")}</Tag>
      )}
    </div>
  );
}

export default SimpleProductInfo;
