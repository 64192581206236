import React from "react";

import {
  CaretDownOutlined,
  EnvironmentOutlined,
  QuestionCircleOutlined,
  UpOutlined,
  WechatFilled,
  MoneyCollectFilled,
  DownOutlined,
} from "@ant-design/icons";

import {
  Divider,
  Badge,
  Button,
  Popover,
  Steps,
  Collapse,
  Typography,
  Image,
  Space,
} from "antd";
import Base from "../Base";

const { Text } = Typography;

export default class OrderListItem extends Base {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  render() {
    if (!this.props.order) return null;

    return (
      <div className="col-box orders" id={`orderItem${this.props.order.id}`}>
        {this._renderHeader()}
        {this._renderContainer()}
        {this._renderFooter()}
      </div>
    );
  }

  _renderHeader() {
    let order = this.props.order;
    let orderUser = order.user;

    return (
      <div className="box-header">
        <span className="num">
          <span>#</span>
          {order.sequence}
        </span>
        <div className="info">
          {!this.isBBShopMerchant() ? null : (
            <span className="text warning">
              {this.i18n.t("order.user", {
                user: `${orderUser.nickname} ${orderUser.mobile}`,
              })}
            </span>
          )}
          {order.is_from_bulutbazar === 1 && <Divider type="vertical" />}
          {order.is_from_bulutbazar === 1 && (
            <span className="text">{this.i18n.t("order.isFromBB")}</span>
          )}
          {order.is_douyin_order === 1 && <Divider type="vertical" />}
          {order.is_douyin_order === 1 && (
            <span className="text">{this.i18n.t("order.isDouyin")}</span>
          )}
        </div>
        <span className="status">{this.props.order.status_label}</span>
      </div>
    );
  }

  _renderContainer() {
    return (
      <div className="box-container">
        {this._renderReceiver()}
        {this._renderDeliverman()}
        {this._renderProducts()}
      </div>
    );
  }

  _renderReceiver() {
    const { order } = this.props;
    let text = "";
    let color;
    if (order?.self_order === 1) {
      text = this.i18n.t("order.eat");
      color = "red";
    }
    if (order?.self_order === 2) {
      text = this.i18n.t("order.take");
      color = "green";
    }
    let insideCom = (
      <div className="info-row receiver">
        <div className="info">
          <span className="name">
            {this.i18n.getPropNameByLocale(order, "receiver")}
          </span>
        </div>
        <div className="contact">
          <span className="phone">{order.tel}</span>
        </div>
        <div className="address">
          <span className="text">
            {this.isBBShopMerchant()
              ? this.i18n.getPropNameByLocale(order, "address")
              : `
          ${this.i18n.getPropNameByLocale(order.community, "name")}
          (
            ${this.i18n.getPropNameByLocale(order.city, "name")}
            ${this.i18n.getPropNameByLocale(order.region, "name")}
            ${this.i18n.getPropNameByLocale(order.street, "name")}
          )
        `}
          </span>
          <span className="location">
            <EnvironmentOutlined />
            <span>{` > ${order.distance}km`}</span>
          </span>
        </div>
      </div>
    );
    if (Number(order?.self_order) > 0) {
      return (
        <Badge.Ribbon text={text} color={color}>
          {insideCom}
        </Badge.Ribbon>
      );
    }
    return insideCom;
  }

  _renderDeliverman() {
    let forceActions = null;
    if (this.isBBShopMerchant()) {
      forceActions = this.getOrderForceActions();
    }
    return (
      <div>
        <div className="info-row deliver noprint">
          <div className="info">
            {this._renderDelivermanInfo()}
            {this._renderDelivermanOrderHistory()}
          </div>
          {this._renderDelivermanActions()}
        </div>
        <div className="info-row deliver noprint">
          <div className="info" />
          {forceActions}
        </div>
      </div>
    );
  }

  _renderDelivermanInfo() {
    let devliverman = this.props.order.deliveryman;
    if (devliverman) {
      return (
        <div className="inner-row">
          <span className="name">
            {this.i18n.getPropNameByLocale(devliverman, "name")}
          </span>
          <span className="phone">{devliverman.mobile}</span>
        </div>
      );
    }

    return null;
  }

  _renderDelivermanOrderHistory() {
    let placement = this.i18n.isLocaleRTL() ? "bottomRight" : "bottomLeft";
    let statusHistory = this.props.order.status_history;
    if (statusHistory && statusHistory.length > 0) {
      return (
        <div className="inner-row">
          <span className="time">
            {statusHistory[statusHistory.length - 1].changed_at}
          </span>
          <Popover
            content={statusPopoverContent(statusHistory, this.moment)}
            placement={placement}
          >
            <span className="status">
              {statusHistory[statusHistory.length - 1].status_label}
              <CaretDownOutlined />
            </span>
          </Popover>
        </div>
      );
    }

    return null;
  }

  _renderDelivermanActions() {
    let actions = this.getOrderActions();
    if (this.isBBShopMerchant()) {
      actions = this.getOrderBBShopActions();
    }
    return <div className="actions">{actions}</div>;
  }

  _renderProducts() {
    return (
      <div className="info-row products">
        {this._renderProductsHeader()}
        {this._renderProductsDetail()}
      </div>
    );
  }

  _renderProductsHeader() {
    let actionText = this.i18n.t("order.productInfoExpand");
    let actionIcon = <DownOutlined />;
    if (this.state.expanded) {
      actionText = this.i18n.t("order.productInfoCollapse");
      actionIcon = <UpOutlined />;
    }

    return (
      <div className="header">
        <span className="title">{this.i18n.t("order.productInfo")}</span>
        <Button
          className="noprint"
          type="link"
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          <span>{actionText}</span>
          {actionIcon}
        </Button>
      </div>
    );
  }

  _renderProductsDetail() {
    let activeKeys = [];
    if (this.state.expanded) {
      activeKeys = ["1"];
    }

    return (
      <Collapse bordered={false} activeKey={activeKeys}>
        <Collapse.Panel key="1" showArrow={false} header={null}>
          {this._renderProductsDetailExtra()}
          {this._renderProductsDetailItems()}
          {this.props.order.gifts && (
            <div className="order-gift">
              {this._renderGifts(this.props.order.gifts)}
            </div>
          )}
          {this._renderProductsDetailPackPrice()}
          {this._renderProductsDetailSubtotal()}
          {this._renderProductsDetailTotal()}
        </Collapse.Panel>
      </Collapse>
    );
  }

  _renderProductsDetailExtra() {
    let notes = this.i18n.t("order.noteNo");
    if (this.props.order.notes) {
      notes = this.props.order.notes;
    }

    let tableWare = this.i18n.t("order.tablewareNo");
    if (this.props.order.tableware_count) {
      tableWare = this.i18n.t("order.tablewarePeople", {
        count: this.props.order.tableware_count,
      });
    }

    return (
      <div className="extra">
        <span className="title">{this.i18n.t("order.note")}</span>
        <div className="items">
          <span className="item">
            {this.i18n.t("order.noteUser", { note: notes })}
          </span>
          {!this.isBBShopMerchant() && (
            <span className="item">
              {this.i18n.t("order.tablewareCount", {
                count: tableWare,
              })}
            </span>
          )}
        </div>
      </div>
    );
  }

  _renderProductsDetailItems() {
    let details = this.props.order.details || [];

    return (
      <div className="details">
        {details.map((item, index) => {
          return (
            <div key={`order-detail-${index}`}>
              <div className="item">
                <Image src={item.product.images[0]} width={80} />
                <span className="name">
                  {this.i18n.getPropNameByLocale(item.product, "name")}
                </span>
                {item.selected_sku && (
                  <span className="price">
                    {this.i18n.getPropNameByLocale(item.selected_sku, "name")}
                  </span>
                )}
                <span className="price">
                  {this.util.getFormattedPrice(item.price, "￥")}
                </span>
                <span className="quantity">x{item.amount}</span>
                <span className="item_total">
                  {this.util.getFormattedPrice(item.amount * item.price, "￥")}
                </span>
              </div>
              {item.gifts &&
                item.gifts.length > 0 &&
                this._renderGifts(item.gifts)}
            </div>
          );
        })}
      </div>
    );
  }
  _renderGifts(gifts) {
    return (
      <div className="gifts">
        {gifts.map((item, index) => {
          return (
            <div key={`gift-${index}`} className="gift-item">
              <span>{this.i18n.t("order.giftLabel")}</span>
              <Image width={40} src={item.images && item.images[0]} />
              <span className="name">
                {this.i18n.getPropNameByLocale(item, "name")}
              </span>
              <span className="quantity">x{item.amount}</span>
            </div>
          );
        })}
      </div>
    );
  }
  _renderProductsDetailPackPrice() {
    let items = [];

    items.push(
      <div key={`${this.props.order.id}-pack-price`}>
        <span className="title">{this.i18n.t("order.packingFee")}</span>
        <span className="price">
          {this.util.getFormattedPrice(this.props.order.total_pack_price, "￥")}
        </span>
      </div>
    );

    if (this.props.order.deliver_by_us !== 1 || this.isBBShopMerchant()) {
      items.push(
        <div key={`${this.props.order.id}-ship-price`}>
          <span className="title">{this.i18n.t("order.shippingFee")}</span>
          <span className="price">
            {this.util.getFormattedPrice(this.props.order.ship_price, "￥")}
          </span>
        </div>
      );
    }

    return <div className="dishware-fee">{items}</div>;
  }

  _renderProductsDetailSubtotal() {
    let subTotal =
      this.props.order.original_price + this.props.order.total_pack_price;

    let merchantPlacement = this.i18n.isLocaleRTL()
      ? "bottomRight"
      : "bottomLeft";
    let platformPlacement = this.i18n.isLocaleRTL()
      ? "bottomRight"
      : "bottomLeft";

    const expence = this.isBBShopMerchant()
      ? this.props.order.commission
      : this.props.order.merchant_expenses;

    return (
      <div className="subtotal">
        <div className="subtotal-header">
          <span className="title">{this.i18n.t("order.subTotal")}</span>
          <span className="price">
            {this.util.getFormattedPrice(subTotal, "￥")}
          </span>
        </div>
        <div>
          <span>
            <span className="title">
              {this.i18n.t("order.merchantExpence")}
            </span>
            <Popover
              content={subTotalMerchantPopoverContent(
                this.i18n,
                this.util,
                expence,
                this.isBBShopMerchant()
              )}
              placement={merchantPlacement}
            >
              <QuestionCircleOutlined />
            </Popover>
          </span>
          <span className="price">
            -
            {this.util.getFormattedPrice(
              this.props.order.merchant_expenses.amount,
              "￥"
            )}
          </span>
        </div>
        {!this.isBBShopMerchant() && (
          <div>
            <span>
              <span className="title">
                {this.i18n.t("order.platformIncome")}
              </span>
              <Popover
                content={subTotalPlatformPopoverContent(
                  this.i18n,
                  this.props.order.platform_rate
                )}
                placement={platformPlacement}
              >
                <QuestionCircleOutlined />
              </Popover>
            </span>
            <span className="price">
              -
              {this.util.getFormattedPrice(this.props.order.platform_fee, "￥")}
            </span>
          </div>
        )}
      </div>
    );
  }

  _renderProductsDetailTotal() {
    return (
      <div className="total">
        <div className="merchant-total">
          <span className="title">{this.i18n.t("order.merchantIncome")}</span>
          <span className="price">
            {this.util.getFormattedPrice(
              this.props.order.merchant_income,
              "￥"
            )}
          </span>
        </div>
        <div className="user-total">
          <span className="title">{this.i18n.t("order.userPayed")}</span>
          <span className="price">
            {this.util.getFormattedPrice(this.props.order.total, "￥")}
          </span>
          <span className="status">{this.i18n.t("order.statusPayed")}</span>
          <span className="paytype">
            {this.props.order.paytype === 10 ? (
              <MoneyCollectFilled />
            ) : (
              <WechatFilled />
            )}
          </span>
        </div>
      </div>
    );
  }

  _renderFooter() {
    return (
      <div className="box-footer">
        <span className="time">
          {this.i18n.t("order.orderTime")}
          <span className="time-value">
            {this.moment(this.props.order.created_at).format(
              "YYYY-MM-DD HH:mm"
            )}
          </span>
        </span>
        <Divider type="vertical" />
        <span className="track-number">
          {this.i18n.t("order.orderNo")}
          <Text copyable>{this.props.order.order_no}</Text>
        </span>
        {this.isJdShop() && <Divider type="vertical" />}
        {this.isJdShop() && (
          <span className="track-number">
            {this.i18n.t("order.jdOrderNo")}
            <Text copyable>{this.props.order.jd_order_no}</Text>
          </span>
        )}
        <Divider type="vertical" />
        <span className="track-number">
          {this.i18n.t("order.bbOrderNo")}
          <Text copyable>{this.props.order.id}</Text>
        </span>
        {this.isBBShopMerchant() && this._renderBBShopShipNumber()}
      </div>
    );
  }

  _renderBBShopShipNumber() {
    let trackData = this.props.order.tracking_data;

    if (!trackData || !trackData.length) {
      return null;
    }

    let items = [];

    trackData.forEach((item) => {
      items.push(<Divider key={`track-divider-${item.id}`} type="vertical" />);
      items.push(
        <span key={`track-number-${item.id}`} className="track-number">
          {this.i18n.t("order.orderShipNo")}
          <Text
            copyable={{
              text: item.tracking_number,
              tooltips: false,
            }}
          >
            <Text
              style={{ color: "#419cb4" }}
              onClick={() =>
                this.fireAction(OrderListItem.BB_SHOP_ACTION_KUAIDI_100, {
                  ...item,
                })
              }
            >
              {item.tracking_name}
            </Text>
            ({item.tracking_number}
            {this.util.getFormattedPrice(item.shipping_price, "￥")})
          </Text>
        </span>
      );
    });

    return items;
  }

  isBBShopMerchant() {
    return this.props.bbshop;
  }

  isJdShop() {
    return this.props.merchant.id === 380;
  }

  static BB_SHOP_ACTION_ACCEPT = "bb-action-accept";
  static BB_SHOP_ACTION_PRINT = "bb-action-print";
  static BB_SHOP_ACTION_PRINT_BY_LOCAL_PRINTER =
    "bb-action-print_by_local_printer";
  static BB_SHOP_ACTION_CANCEL = "bb-action-cancel";
  static BB_SHOP_ACTION_REFUND = "bb-action-refund";
  static BB_SHOP_ACTION_REFUND_CHECK = "bb-action-refund-check";
  static BB_SHOP_ACTION_REJECT = "bb-action-reject";
  static BB_SHOP_ACTION_PREPARED = "bb-action-prepared";
  static BB_SHOP_ACTION_DELIVERED = "bb-action-delivered";
  static BB_SHOP_ACTION_SEND = "bb-action-send";
  static BB_SHOP_ACTION_SEND_CHANGE = "bb-action-send-change";
  static BB_SHOP_ACTION_SEND_LBS = "bb-action-send-lbs";
  static BB_SHOP_ACTION_SEND_LBS_CHANGE = "bb-action-send-lbs-change";
  static BB_SHOP_ACTION_KUAIDI_100 = "bb-action-100";
  static BB_SHOP_ACTION_COPY_SHIP_NUM = "bb-action-copy-ship-num";
  static CALCULATE_COMMISSION = "calculate-commission";

  getOrderActions() {
    let items = [];
    const { status } = this.props.order;
    if ([5, 7].includes(status)) {
      items.push(
        <Button
          key={OrderListItem.BB_SHOP_ACTION_REFUND_CHECK}
          onClick={() =>
            this.fireAction(OrderListItem.BB_SHOP_ACTION_REFUND_CHECK)
          }
        >
          {this.i18n.t("order.refundCheck")}
        </Button>
      );
    }

    return items;
  }

  getOrderForceActions() {
    let items = [];
    const { status, id, distribute_type } = this.props.order;
    const { onForceAction } = this.props;
    if (status === 3) {
      items.push(
        <Button
          type="primary"
          key="finish"
          onClick={() => onForceAction(id, "finish")}
        >
          {this.i18n.t("commissionList.confirmFinished")}
        </Button>
      );
    }
    if (status === 2) {
      items.push(
        <Space direction="horizontal" key="delivered">
          <Button type="primary" onClick={() => onForceAction(id, "update")}>
            {this.i18n.t("commissionList.update")}
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => onForceAction(id, "delivered")}
          >
            {this.i18n.t("commissionList.confirmReceived")}
          </Button>
        </Space>
      );
    }
    if (status === 9) {
      items.push(
        <Button
          type="primary"
          key="send"
          onClick={() => onForceAction(id, "send")}
        >
          {this.i18n.t("commissionList.confirmSend")}
        </Button>
      );
      if (distribute_type === 3) {
        items.push(
          <Button
            type="primary"
            key="warehouse"
            onClick={() => onForceAction(id, "warehouse")}
          >
            {this.i18n.t("commissionList.sendWarehouse")}
          </Button>
        );
      }
    }
    return items;
  }

  getOrderBBShopActions() {
    let items = [];
    let status = this.props.order.status;
    const merchant = this.props.order.merchant;
    let statusHistory = this.props.order.status_history;
    let { should_calculate_commission } = this.props.order;

    if (![5, 6, 7, 8, 13, 14].includes(status)) {
      items.push(
        <Button
          key={OrderListItem.BB_SHOP_ACTION_CANCEL}
          onClick={() => this.fireAction(OrderListItem.BB_SHOP_ACTION_CANCEL)}
        >
          {this.i18n.t("order.cancel")}
        </Button>
      );
    }

    if ([5, 7].includes(status)) {
      items.push(
        <Button
          key={OrderListItem.BB_SHOP_ACTION_REFUND_CHECK}
          onClick={() =>
            this.fireAction(OrderListItem.BB_SHOP_ACTION_REFUND_CHECK)
          }
        >
          {this.i18n.t("order.refundCheck")}
        </Button>
      );
    }

    //用户支付完成，待处理状态
    if (status === 1 || status === 10) {
      items.push(
        <Button
          key={OrderListItem.BB_SHOP_ACTION_ACCEPT}
          onClick={() => this.fireAction(OrderListItem.BB_SHOP_ACTION_ACCEPT)}
        >
          {this.i18n.t("order.accept")}
        </Button>
      );
    }

    // 商家接受订单，待发货状态
    if (status === 1 || status === 10 || status === 9 || status === 17) {
      if (merchant && merchant.is_lbs) {
        items.push(
          <Button
            key={OrderListItem.BB_SHOP_ACTION_SEND_LBS}
            onClick={() =>
              this.fireAction(OrderListItem.BB_SHOP_ACTION_SEND_LBS)
            }
          >
            {this.i18n.t("order.send")}
          </Button>
        );
      } else {
        items.push(
          <Button
            key={OrderListItem.BB_SHOP_ACTION_SEND}
            onClick={() => this.fireAction(OrderListItem.BB_SHOP_ACTION_SEND)}
          >
            {this.i18n.t("order.send")}
          </Button>
        );
      }
    }

    // 商家已发货，待确认收货状态
    if (
      status !== 3 &&
      status !== 4 &&
      statusHistory.findIndex((item) => item.status === 2) >= 0
    ) {
      if (merchant && merchant.is_lbs) {
        items.push(
          <Button
            key={OrderListItem.BB_SHOP_ACTION_SEND_LBS_CHANGE}
            onClick={() =>
              this.fireAction(OrderListItem.BB_SHOP_ACTION_SEND_LBS_CHANGE)
            }
          >
            {this.i18n.t("order.sendChange")}
          </Button>
        );
      } else {
        items.push(
          <Button
            key={OrderListItem.BB_SHOP_ACTION_SEND_CHANGE}
            onClick={() =>
              this.fireAction(OrderListItem.BB_SHOP_ACTION_SEND_CHANGE)
            }
          >
            {this.i18n.t("order.sendChange")}
          </Button>
        );
      }
    }

    if (should_calculate_commission) {
      items.push(
        <Button
          key={OrderListItem.CALCULATE_COMMISSION}
          onClick={() => this.fireAction(OrderListItem.CALCULATE_COMMISSION)}
        >
          {this.i18n.t("order.calculate")}
        </Button>
      );
    }
    if (
      this.props.merchant &&
      this.props.merchant.printer &&
      this.props.merchant.printer["sn"]
    ) {
      items.push(
        <Button
          key={OrderListItem.BB_SHOP_ACTION_PRINT}
          onClick={() => this.fireAction(OrderListItem.BB_SHOP_ACTION_PRINT)}
        >
          {this.i18n.t("order.print")}
        </Button>
      );
    } else {
      items.push(
        <Button
          key={OrderListItem.BB_SHOP_ACTION_PRINT_BY_LOCAL_PRINTER}
          onClick={() =>
            this.fireAction(OrderListItem.BB_SHOP_ACTION_PRINT_BY_LOCAL_PRINTER)
          }
        >
          {this.i18n.t("order.print")}
        </Button>
      );
    }

    if ([2, 3, 4].includes(status)) {
      items.push(
        <Button
          key={OrderListItem.BB_SHOP_ACTION_REFUND}
          onClick={() => this.fireAction(OrderListItem.BB_SHOP_ACTION_REFUND)}
        >
          {this.i18n.t("order.refund")}
        </Button>
      );
    }

    return items;
  }

  fireAction = (action, data) => {
    if (this.props.onAction) {
      this.props.onAction(action, data);
    }
  };
}

const statusPopoverContent = (statusHistory, moment) => {
  let steps = [];
  for (let i = statusHistory.length - 1; i >= 0; i--) {
    let status = statusHistory[i];
    steps.push(
      <Steps.Step
        key={`status-step-${i}`}
        icon="profile"
        title={`[${status.changed_at}] ${status.status_label}`}
      />
    );
  }

  return (
    <div id="route-order-list-item-status-popover">
      <Steps direction="vertical" size="small" current={0}>
        {steps}
      </Steps>
    </div>
  );
};

const subTotalMerchantPopoverContent = (
  i18n,
  util,
  expence,
  isBBShopMerchant
) => {
  if (!expence) return null;

  if (isBBShopMerchant && expence.length > 0) {
    return (
      <div id="route-order-list-item-subtotal-merchant-popover">
        <div>
          {i18n.t("order.parentCommission", {
            price: util.getFormattedPrice(
              expence[0] ? expence[0].commission : 0
            ),
          })}
        </div>
        <div>
          {i18n.t("order.childCommission", {
            price: util.getFormattedPrice(
              expence[1] ? expence[1].commission : 0
            ),
          })}
        </div>
      </div>
    );
  }

  return (
    <div id="route-order-list-item-subtotal-merchant-popover">
      <div>
        {i18n.t("order.merchantExpenceDisocunt", {
          price: util.getFormattedPrice(expence.discount),
        })}
      </div>
      <div>
        {i18n.t("order.merchantExpenceCoupon", {
          price: util.getFormattedPrice(expence.coupon),
        })}
      </div>
    </div>
  );
};

const subTotalPlatformPopoverContent = (i18n, rate) => {
  return (
    <div id="route-order-list-item-subtotal-platform-popover">
      <span>{i18n.t("order.platformRate", { rate: rate })}</span>
    </div>
  );
};
