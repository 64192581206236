import React from "react";

import {
  Checkbox,
  Input,
  Button,
  InputNumber,
  Col,
  Row,
  Statistic,
  Image,
  Switch,
  Space,
} from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import Base from "../Base";

const { Countdown } = Statistic;

export default class ProductListItem extends Base {
  static Actions = {
    Select: "SELECT",
    AddTag: "ADD_TAG",
    OnShell: "ON_SHELL",
    OffShel: "OFF_SHELL",
    Edit: "EDIT",
    EditConfirm: "EDIT_CONFIRM",
    Delete: "DELETE",
    AddDiscount: "ADD_DISCOUNT",
    RemoveDiscount: "REMOVE_DISCOUNT",
    Copy: "COPY",
    Clone: "CLONE",
    OnFreeShipping: "OnFreeShipping",
    OffFreeShipping: "OffFreeShipping",
    ShowDouyinOrder: "ShowDouyinOrder",
  };

  constructor(props) {
    super(props);
    this.state = {
      inEditMode: false,
      editName: "",
      editPrice: "",
      editInPrice: "",
      editQuantity: "",
      editOrder: "",
      editNumber: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.product.id !== this.props.product.id) {
      this.onActionCancelClick();
    }
  }

  render() {
    if (this.props.product) {
      let className = "product-list-item";
      if (this.isProductOffShell()) {
        className += " offShelf";
      }
      if (this.state.inEditMode) {
        className += " editmode";
      }

      return (
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className={className}>
            {this._renderInfo()}
            {this._renderSkuFlag()}
          </div>
        </Col>
      );
    }

    return null;
  }

  _renderInfo() {
    return (
      <div className="item-row">
        {this._renderInfoImage()}
        <div className="item-main">
          {this._renderInfoName()}
          <Row className="item-price">
            {this._renderInfoProductNumber()}
            {this._renderInfoQuantity()}
            {this._renderInfoOrder()}
            {this._rendeBarcode()}
          </Row>
          {this.renderPriceInfo()}
          {this._renderActions()}
        </div>
      </div>
    );
  }

  _renderInfoImage() {
    const { images } = this.props.product;
    if (Array.isArray(images) && images.length > 0) {
      return (
        <div className="item-image">
          <div>
            <Image alt="" src={images[0].url} />
            {this.isProductOffShell() ? (
              <div className="offShelf-mask">
                {this.i18n.t("product.productItem.offShelf")}
              </div>
            ) : null}
            <Checkbox
              checked={this.isProductSelected()}
              onChange={this.onCheckChange}
            />
          </div>
          <div className="item-price">
            {this._renderInPrice()}
            {this.renderInfoPrice()}
            {this._renderProductId()}
          </div>
        </div>
      );
    }
    return null;
  }

  _renderInfoName() {
    let name = this.i18n.getPropNameByLocale(this.props.product, "name");

    if (this.state.inEditMode) {
      return (
        <Input
          size="small"
          defaultValue={name}
          disabled={this.hasProductSku()}
          onChange={this.onNameChange}
        />
      );
    }

    return (
      <span className="item-name" onClick={this.onEditTriggerClick}>
        {name}
      </span>
    );
  }

  _renderInfoSales() {
    return (
      <div className="item-sales">
        <span>
          {this.i18n.t("product.productItem.monthySale", { count: "--" })}
        </span>
        <span>{this.i18n.t("product.productItem.likes", { count: "--" })}</span>
      </div>
    );
  }

  renderPriceInfo() {
    if (this.showExtraContent() || this.isBBShopMerchant()) {
      const { product, merchantSettings } = this.props;
      let purchasePrice = product.purchase_price;
      let salePrice = product.price;
      let isJdShop = this.isJdShop();
      const originalGross = (1 - product.purchase_price / salePrice) * 100;
      let warehouseCost =
        merchantSettings && merchantSettings.warehouse_cost > 0
          ? merchantSettings.warehouse_cost
          : 0;
      if (isJdShop) {
        if (product.min_buy_limit > 1) {
          purchasePrice = purchasePrice * product.min_buy_limit;
          salePrice = salePrice * product.min_buy_limit;
        }
        purchasePrice = this.util.getFreeShppingPurchase(
          purchasePrice,
          isJdShop
        );
      }
      const gross = (1 - purchasePrice / salePrice) * 100;
      let originalNet =
        originalGross -
        product.commission_percent -
        product.parent_commission_percent;
      let net = 0;
      if (!isJdShop) {
        net -= warehouseCost;
        originalNet -= warehouseCost;
      } else {
        net =
          gross -
          product.commission_percent -
          product.parent_commission_percent;
      }
      const originalColor = this.util.getColor4Net(originalNet);
      const color = this.util.getColor4Net(net);
      return (
        <Row gutter={2} className="price-stats">
          <Col span={6}>
            <Statistic
              className="percents"
              title="毛利率"
              value={Number.isNaN(originalGross) ? 0 : originalGross}
              precision={1}
              suffix="%"
            />
          </Col>
          {isJdShop && (
            <Col span={6}>
              <Statistic
                className="percents"
                title="包邮毛利率"
                value={Number.isNaN(gross) ? 0 : gross}
                precision={1}
                suffix="%"
              />
            </Col>
          )}
          <Col span={6}>
            <Statistic
              className="percents"
              title="佣金比例"
              value={product.commission_percent ?? 0}
              precision={1}
              suffix="%"
            />
          </Col>
          <Col span={6}>
            <Statistic
              className="percents"
              title="父代佣金"
              value={product.parent_commission_percent ?? 0}
              precision={1}
              suffix="%"
            />
          </Col>
          {merchantSettings &&
          merchantSettings.warehouse_cost &&
          merchantSettings.warehouse_cost > 0 ? (
            <Col span={6}>
              <Statistic
                className="percents"
                title="仓储物流"
                value={merchantSettings.warehouse_cost}
                precision={1}
                suffix="%"
              />
            </Col>
          ) : null}
          <Col span={isJdShop ? 12 : 24}>
            <Statistic
              className="percents net"
              title="利润率"
              value={Number.isNaN(originalNet) ? 0 : originalNet}
              precision={1}
              valueStyle={{ color: originalColor }}
              suffix="%"
            />
          </Col>
          {isJdShop && (
            <Col span={12}>
              <Statistic
                className="percents net"
                title="包邮后利润率"
                value={Number.isNaN(net) ? 0 : net}
                precision={1}
                valueStyle={{ color }}
                suffix="%"
              />
            </Col>
          )}
        </Row>
      );
    }

    return null;
  }

  _renderInPrice() {
    if (!this.props.product.purchase_price) return null;

    let price = this.util.getPrice(this.props.product.purchase_price);

    return (
      <span className="input-block price">
        <span>{this.i18n.t("productEdit.inPriceLabelShort")}</span>
        <span className="item-price-input">￥{price}</span>
      </span>
    );
  }

  _renderProductId() {
    return (
      <span className="input-block price">
        <span>ID:</span>
        <span className="item-price-input">{this.props.product.id}</span>
      </span>
    );
  }

  renderInfoPrice() {
    const { product } = this.props;
    let price = this.util.getPrice(product.price);
    let originalPrice = this.util.getPrice(product.original_price);
    let priceWrapper = "";
    let douyinOrderBtn = "";
    if (this.isJdShop()) {
      douyinOrderBtn = (
        <Button
          type="primary"
          size="small"
          className="stop-discount"
          onClick={() => this.onShowDouyinOrder(product)}
        >
          {this.i18n.t("productEdit.douyinOrder")}
        </Button>
      );
    }
    if (product.original_price) {
      priceWrapper = (
        <div>
          <div>
            <span>{this.i18n.t("productEdit.outPriceLabelShort")}</span>
            <span className="item-original-price-input">￥{originalPrice}</span>
            <span className="item-price-input real-price">￥{price}</span>
          </div>
          <div>
            {product.end_time && (
              <Countdown
                value={this.moment(product.end_time)}
                format="D:H:mm:ss"
                valueStyle={{ fontSize: 14, color: "#fff" }}
                style={{ backgroundColor: "#666", textAlign: "center" }}
              />
            )}
          </div>
          <Space>
            {!product.is_flash_sale && (
              <Button
                type="primary"
                size="small"
                className="stop-discount"
                onClick={() => this.onActionRemoveDiscount(product.id)}
              >
                {this.i18n.t("productEdit.restorePrice")}
              </Button>
            )}
            {douyinOrderBtn}
          </Space>
        </div>
      );
    } else {
      priceWrapper = (
        <div>
          <div>
            <span>{this.i18n.t("productEdit.outPriceLabelShort")}</span>
            <span className="item-price-input real-price">￥{price}</span>
          </div>
          <Space>
            <Button
              type="primary"
              size="small"
              className="stop-discount"
              onClick={() => this.onActionAddDiscount(product)}
            >
              {this.i18n.t("productEdit.priceDiscount")}
            </Button>
            {douyinOrderBtn}
          </Space>
        </div>
      );
    }

    return <span className="input-block price">{priceWrapper}</span>;
  }

  _renderInfoQuantity() {
    let quantity = this.i18n.t("product.productItem.quantityLimitless");
    if (this.props.product.quantity > 0) {
      quantity = this.props.product.quantity;
    }

    if (this.state.inEditMode) {
      return (
        <span className="input-block">
          <span>{this.i18n.t("product.productItem.quantity")}</span>
          <InputNumber
            size="small"
            min={0}
            defaultValue={quantity}
            onChange={this.onQuantityChange}
          />
        </span>
      );
    }

    return (
      <span className="input-block">
        <span>{this.i18n.t("product.productItem.quantity")}</span>
        <span
          className={`item-quantity-input ${
            this.props.product.quantity === 0 && "red"
          }`}
          onClick={this.onEditTriggerClick}
        >
          {quantity}
        </span>
      </span>
    );
  }

  _renderInfoOrder() {
    const { order } = this.props.product;
    if (!order || !order < 0) return null;

    if (this.state.inEditMode) {
      return (
        <span className="input-block">
          <span>{this.i18n.t("productEdit.orderLabel")}</span>
          <InputNumber
            size="small"
            min={0}
            defaultValue={order}
            onChange={this.onOrderChange}
          />
        </span>
      );
    }

    return (
      <span className="input-block">
        <span>{this.i18n.t("productEdit.orderLabel")}</span>
        <span className="item-quantity-input" onClick={this.onEditTriggerClick}>
          {order}
        </span>
      </span>
    );
  }

  _rendeBarcode() {
    const { barcode } = this.props.product;
    if (barcode) {
      return (
        <span className="input-block">
          <span>{this.i18n.t("productEdit.barcodeLabel")}</span>
          <span className="item-quantity-input red borderless">{barcode}</span>
        </span>
      );
    }
    return null;
  }

  _renderInfoProductNumber() {
    if (this.isBBShopMerchant() || this.isThirdPartyShop()) {
      const { product_no, jd_sku } = this.props.product;
      if (this.state.inEditMode) {
        return (
          <span className="input-block">
            <span>{this.i18n.t("productEdit.numberLabel")}</span>
            <Input
              size="small"
              defaultValue={product_no}
              onChange={this.onProductNumberChange}
            />
          </span>
        );
      }

      return (
        <span className="input-block">
          <span>{this.i18n.t("productEdit.numberLabel")}</span>
          <span
            className="item-product-number-input"
            onClick={this.onEditTriggerClick}
          >
            {product_no ?? jd_sku ?? "000000"}
          </span>
        </span>
      );
    }
    return null;
  }

  _renderActions() {
    let mainAction = (
      <Button size="small" onClick={this.onActionOffClick}>
        {this.i18n.t("product.productItem.actionDown")}
      </Button>
    );
    if (this.isProductOffShell()) {
      mainAction = (
        <Button size="small" type="primary" onClick={this.onActionOnClick}>
          {this.i18n.t("product.productItem.actionUp")}
        </Button>
      );
    }
    if (this.state.inEditMode) {
      mainAction = (
        <Button
          size="small"
          style={{ borderWidth: 0 }}
          type="primary"
          onClick={this.onActionConfirmClick}
        >
          {this.i18n.t("product.productItem.actionConfirm")}
        </Button>
      );
    }

    let actions = [];
    if (!this.state.inEditMode) {
      if (this.isBBShopMerchant() || this.isThirdPartyShop()) {
        actions.push(
          <Button
            size="small"
            style={{ borderWidth: 0, backgroundColor: "transparent" }}
            key="action-copy"
            onClick={() => this.onActionCopyClick(this.props.product)}
          >
            <EditOutlined />
            {this.i18n.t("product.productItem.oneKeyCopy")}
          </Button>
        );
      }
      if (!this.isJdShop()) {
        actions.push(
          <Button
            size="small"
            style={{ borderWidth: 0, backgroundColor: "transparent" }}
            key="action-clone"
            onClick={this.onActionCloneClick}
          >
            <CopyOutlined />
            {this.i18n.t("product.productItem.actionClone")}
          </Button>
        );
      }
      actions.push(
        <Button
          size="small"
          style={{ borderWidth: 0, backgroundColor: "transparent" }}
          key="action-delete"
          onClick={this.onActionDeleteClick}
        >
          <DeleteOutlined />
          {this.i18n.t("product.productItem.actionDelete")}
        </Button>
      );
      actions.push(
        <Button
          size="small"
          style={{ borderWidth: 0, backgroundColor: "transparent" }}
          key="action-edit"
          onClick={this.onActionEditClick}
        >
          <EditOutlined />
          {this.i18n.t("product.productItem.actionEdit")}
        </Button>
      );
    } else {
      actions.push(
        <Button
          size="small"
          style={{ borderWidth: 0 }}
          key="action-cancel"
          onClick={this.onActionCancelClick}
        >
          {this.i18n.t("product.productItem.actionCancel")}
        </Button>
      );
    }

    return (
      <Row className="item-row actions">
        {actions}
        {mainAction}
        <Switch
          checkedChildren={this.i18n.t("productEdit.freeShipping")}
          unCheckedChildren={this.i18n.t("productEdit.notFreeShipping")}
          checked={this.isProductFreeShipping()}
          style={{ margin: "0 10px" }}
          onChange={(value) => {
            if (value) {
              this.onActionFreeShipping();
            } else {
              this.onActionOffFreeShipping();
            }
          }}
        />
      </Row>
    );
  }

  _renderSkuFlag() {
    if (this.hasProductSku()) {
      return (
        <img
          className="sku-img"
          alt=""
          src={
            process.env.PUBLIC_URL + this.i18n.t("product.productItem.skuImage")
          }
        />
      );
    }

    return null;
  }

  onCheckChange = (e) => {
    this.fireActionToParent(ProductListItem.Actions.Select, e.target.checked);
  };

  onEditTriggerClick = () => {
    let state = {
      inEditMode: true,
      editPrice: this.props.product.display_price / 100,
      editInPrice: this.props.product.purchase_price / 100,
      editQuantity: this.props.product.quantity,
      editOrder: this.props.product.order,
      editNumber: this.props.product.product_no,
    };

    if (!this.hasProductSku()) {
      state.editName = this.i18n.getPropNameByLocale(
        this.props.product,
        "name"
      );
    }

    this.setState(state);
  };

  onNameChange = (e) => {
    this.setState({ editName: e.target.value });
  };

  onPriceChange = (e) => {
    this.setState({ editPrice: e });
  };

  onInPriceChange = (e) => {
    this.setState({ editInPrice: e });
  };

  onQuantityChange = (e) => {
    this.setState({ editQuantity: e });
  };

  onOrderChange = (e) => {
    this.setState({ editOrder: e });
  };

  onProductNumberChange = (e) => {
    this.setState({ editNumber: e });
  };

  onAddTagClick = () => {
    this.fireActionToParent(ProductListItem.Actions.AddTag);
  };

  onActionOffClick = () => {
    this.fireActionToParent(ProductListItem.Actions.OffShel);
  };

  onActionOnClick = () => {
    this.fireActionToParent(ProductListItem.Actions.OnShell);
  };

  onActionFreeShipping = () => {
    this.fireActionToParent(ProductListItem.Actions.OnFreeShipping);
  };

  onActionOffFreeShipping = () => {
    this.fireActionToParent(ProductListItem.Actions.OffFreeShipping);
  };

  onActionEditClick = () => {
    this.fireActionToParent(ProductListItem.Actions.Edit);
  };

  onActionCopyClick(product) {
    this.fireActionToParent(ProductListItem.Actions.Copy, product);
  }

  onActionDeleteClick = () => {
    this.fireActionToParent(ProductListItem.Actions.Delete);
  };

  onActionCloneClick = () => {
    this.fireActionToParent(ProductListItem.Actions.Clone);
  };

  onShowDouyinOrder = (product) => {
    this.fireActionToParent(ProductListItem.Actions.ShowDouyinOrder, product);
  };

  onActionAddDiscount = (product) => {
    this.fireActionToParent(ProductListItem.Actions.AddDiscount, product);
  };

  onActionRemoveDiscount = (id) => {
    this.fireActionToParent(ProductListItem.Actions.RemoveDiscount, id);
  };

  onActionConfirmClick = () => {
    this.setState({ inEditMode: false });
    let data = {
      price: this.state.editPrice * 100,
      purchase_price: this.state.editInPrice * 100,
      quantity: this.state.editQuantity,
      order: this.state.editOrder,
      product_no: this.state.editNumber,
    };
    if (!this.hasProductSku()) {
      data.name = this.state.editName;
    }
    this.fireActionToParent(ProductListItem.Actions.EditConfirm, data);
  };

  onActionCancelClick = () => {
    this.setState({
      inEditMode: false,
      editName: "",
      editPrice: "",
      editInPrice: "",
      editQuantity: "",
      editOrder: "",
      editNumber: "",
    });
  };

  /* custom method */

  isProductOffShell() {
    return this.props.product ? this.props.product.status === 0 : false;
  }

  isProductFreeShipping() {
    return this.props.product && this.props.product.is_free_shipping;
  }

  isProductSelected() {
    return this.props.product ? this.props.product.selected : false;
  }

  fireActionToParent(action, data) {
    if (this.props.onActionClick) {
      this.props.onActionClick(action, data);
    }
  }

  hasProductSku() {
    return this.props.product ? this.props.product.sku_id > 0 : false;
  }

  isBBShopMerchant() {
    return this.props.bbShopMerchant;
  }

  isThirdPartyShop() {
    return this.props.isThirdPartyShop;
  }

  showExtraContent() {
    return this.props.showExtraContent;
  }

  isJdShop() {
    return this.props.isJdShop;
  }

  isOnlineShops() {
    return this.isBBShopMerchant();
  }
}
