import { Button, Result, Typography } from "antd";
import React, { Component } from "react";
import { Locale } from "./utils";

const i18n = Locale.getInstance();
const { Paragraph, Text } = Typography;

class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      // Error path
      return (
        <Result
          status="error"
          title={error && error.toString()}
          extra={[
            <Button
              type="primary"
              key="home"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              {i18n.t("notFound.backToHome")}
            </Button>,
            <Button
              type="primary"
              key="refresh"
              onClick={() => {
                window.location.reload();
              }}
            >
              {i18n.t("notFound.refresh")}
            </Button>,
            <Button
              key="prev"
              onClick={() => {
                this.setState({ error: null, errorInfo: null }, () => {
                  window.history.go(-1);
                });
              }}
            >
              {i18n.t("notFound.backToPrev")}
            </Button>,
          ]}
        >
          <div style={{ whiteSpace: "pre-wrap" }}>
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                component stack:
              </Text>
            </Paragraph>
            {errorInfo.componentStack}
          </div>
        </Result>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
