export default {
  common: {
    appName: "布鲁街商家中心",
    example: "示例",
    cancelText: "取消",
    developingFeature: "此功能正在开发中，敬请期待...",
    yuan: "元",
    day: {
      1: "周一",
      2: "周二",
      3: "周三",
      4: "周四",
      5: "周五",
      6: "周六",
      7: "周日",
    },
  },
  base: {
    modal: {
      successTitle: "操作成功提示",
      errorTitle: "操作失败提示",
      warningTitle: "警告提示",
      infoTitle: "一般提示",
      confirmTitle: "是否确定继续当前的操作？",
      knowText: "知道了",
      okText: "确定",
      cancelText: "取消",
    },
    api: {
      err401:
        "抱歉，您的登录信息已经过期，为了保证您以后的操作的正常进行，请您重新登录更新您的登录信息",
      err408: "加载数据超时，请重试您的操作或者刷新该页面再试试",
      err422Title: "提供的数据不合法",
      err422Empty: "提供的数据不合法，请检查您的数据之后再试试",
      err429: "您的操作太频繁导致提供数据的服务器压力山大，请稍后重新操作试试",
      err500: "抱歉，服务器出现错误，请稍后再试试",
      err503: "抱歉，服务器出现错误，请稍后再试试",
      errUnknown: "加载数据出现错误，请重试您的操作或者刷新该页面再试试",
    },
  },
  notFound: {
    backToHome: "返回首页",
    backToPrev: "返回上一页",
    refresh: "刷新页面",
  },
  login: {
    titleImage: "/assets/images/login-title.png",
    phoneHint: "请输入手机号",
    phoneHintRequired: "请输入手机号",
    phoneHintPrefix: "手机号必须以1开头",
    phoneHintCount: "手机号必须是11位",
    parolHint: "请输入密码",
    parolHintRequired: "请输入密码",
    parolHintMin: "密码不能少于6位",
    parolHintMax: "密码不能多于16位",
    login: "登录",
    loggingin: "正在登录...",
    loginSuccess: "登录成功",
    title: "布鲁街商户中心",
    selectShop: "请选择店铺",
    enter: "进入",
    insertShopName: "请输入店铺名",
    password: "密码",
    reset: "重置密码",
    pass: "新密码",
    passAgain: "再次输入",
    notMatch: "两次输入不一致",
    forget: "忘记密码",
    codeHint: "请输入验证码",
    getCode: "获取验证码",
  },
  home: {
    chartTime: {
      week: "近7日",
      month: "近30天",
      season: "近90日",
    },
    loadingData: "正在加载统计数据...",
    loadingDataWarning: "正在加载统计数据，请稍后操作",
    numbers: {
      qrcode: "小程序二维码",
      dailyOrderCount: "今日有效订单数量",
      dailyOrderCountUnit: "单",
      yesterdayOrderCount: "昨日%{count}单",
      dailyOrderIncome: "今日订单收入",
      yesterdayOrderIncome: "昨日%{income}元",
      dailyOrderTurnover: "今日营业额",
      yesterdayOrderTurnover: "昨日%{income}元",
      monthlyOrderCount: "本月有效订单数量",
      monthlyOrderCountUnit: "单",
      prevMonthOrderCount: "上月%{count}单",
      monthlyOrderIncome: "本月订单收入",
      prevMonthOrderIncome: "上月%{income}元",
    },
    chart: {
      countTitle: "订单数量趋势",
      dateAlias: "日期",
      countAlias: "订单数量",
      incomeTitle: "订单营业额趋势",
      incomeAlias: "订单营业额",
    },
  },
  main: {
    sideMenus: {
      home: "商家首页",
      delivery: "配送管理",
      order: "订单管理",
      orderList: "历史订单",
      refundList: "退款订单",
      downloadList: "导出表格",
      import: "导入表格",
      product: "商品管理",
      productList: "商品列表",
      manageAds: "广告管理",
      sales: "营销管理",
      coupons: "优惠券",
      holiday: "折扣页面",
      flash: "限时折扣",
      groupon: "抢购",
      gift: "赠品管理",
      ads: "滑动广告",
      navs: "首页导航",
      popups: "跳出广告",
      splash: "首屏广告",
      homeAd: "首页广告",
      mini: "小程序",
      miniSetting: "小程序配置",
      merchantSetting: "商家信息配置",
      wechatMerchantApplymentInfo: "微信支付平台设置情况",
      serviceArea: "服务区",
      serviceAreaSetting: "服务区管理",
      review: "评价管理",
      reviewList: "评价列表",
      finance: "财务管理",
      financeHome: "对账首页",
      financeCheck: "账单对账",
      financeAccount: "账户管理",
      financeDownload: "下载专区",
      promotion: "营销活动",
      promotionShop: "店铺活动",
      proxy: "代理管理",
      proxyList: "代理列表",
      agentLevel: "代理等级",
      commissionList: "佣金列表",
      withdrawList: "提现记录",
      withdrawRequestList: "提现申请记录",
      withdrawCommissionList: "相关佣金列表",
      sendMoney: "打款记录",
      users: "用户管理",
      usersList: "用户列表",
      balance: "余额记录",
      topup: "充值记录",
      spend: "消费记录",
      shows: "达人秀",
      showsList: "列表",
      setting: "系统设置",
      settingShop: "店铺设置",
      selectLang: "选择语言",
      live: "直播管理",
      liveList: "直播列表",
      lottery: "抽奖管理",
      lotteryList: "抽奖列表",
      lotteryTheme: "抽奖样板",
      redPacket: "红包管理",
      lotteryEdit: "编辑抽奖",
      article: "文章管理",
      manageWeapp: "小程序管理",
      members: "会员制度",
      membersList: "会员列表",
      membersCard: "会员卡",
      membersOrder: "会员订单",
    },
    userMenu: {
      logout: "退出",
    },
    statusMenu: {
      business: "营业",
      noBusiness: "打烊",
      statusBusiness: "正在营业",
      statusNoBusiness: "已打烊",
    },
    search: "搜索订单",
    searchHint: "请输入订单号、订单序号、手机号",
    alreadyInBusiness: "店铺已经是营业状态，不需要更新",
    alreadyNoBusiness: "店铺已经是打烊状态，不需要更新",
    updatingBusinessStatus: "正在更新店铺状态...",
    updateBusinessStatusSuccess: "成功更新店铺状态",
    noPermission: "抱歉、您无权访问此功能模块",
  },
  order: {
    eat: "堂食",
    take: "外带",
    print: "打印订单",
    new: "新订单提醒",
    newDesc: "您有新的订单请及时处理",
    printView: {
      price: "单价",
      total: "合计",
      amount: "数量",
    },
    isFromBB: "来自布鲁街",
    isDouyin: "抖音订单",
    payTypes: {
      all: "全部",
      wechat: "微信支付",
      cash: "货到付款",
      balance: "余额付款",
    },
    orderTypes: {
      all: "全部",
      valid: "有效",
      invalid: "无效",
      unsend: "未发货",
      waiting: "待处理",
      sent: "已发货",
      delivered: "已送达",
      completed: "已签收",
    },
    refundTypes: {
      all: "全部",
      waiting: "待处理",
      accepted: "已审核",
      refunding: "正在退款",
      refunded: "已退款",
      refused: "已拒绝",
      failed: "已失败",
    },
    sortTypes: {
      created_at_desc: "下单时间（降序）",
      created_at_asc: "下单时间（升序）",
      sequence: "订单序号",
    },
    loadingData: "正在加载订单列表...",
    printingOrder: "正在打印订单...",
    updatingData: "正在更新订单列表...",
    payTypesTitle: "支付类型",
    orderTypesTitle: "订单状态",
    sortTypesTitle: "订单排序",
    rangeTimeTitle: "选择时间",
    ordersTitleCount: "总共 %{total} 个订单",
    ordersTitle: "订单列表",
    loadingOrder: "正在读取订单列表...",
    loadingOrderEmpty: "暂无相关订单数据",
    todaysTitle: "今日订单概况",
    todaysLoading: "正在读取数据...",
    todaysEmpty: "暂无数据",
    todaysCount: "已接订单：%{orderCount} 笔",
    todaysTotal: "营业总额：%{totalPrice} 元",
    productInfo: "商品信息",
    productInfoCollapse: "收起",
    productInfoExpand: "展开",
    note: "用户备注：",
    noteUser: "[用户要求] %{note}；",
    noteNo: "无特殊要求",
    tablewareCount: "[餐具数量] %{count}；",
    tablewareNo: "自备",
    tablewarePeople: "%{count}人",
    packingFee: "包装费",
    giftLabel: "赠品:",
    shippingFee: "配送费",
    subTotal: "小计",
    merchantExpence: "商家活动支出",
    merchantExpenceDisocunt: "商家折扣总%{price}元",
    merchantExpenceCoupon: "商家优惠券总%{price}元",
    platformIncome: "平台服务费",
    platformRate: "平台服务费为%{rate}%",
    merchantIncome: "本单预计收入",
    userPayed: "用户实际支付金额:",
    statusPayed: "(已支付)",
    orderTime: "下单时间：",
    orderNo: "订单编号：",
    jdOrderNo: "京东订单编号：",
    bbOrderNo: "订单ID：",
    orderShipNo: "快递单号：",
    copyShipNo: "复制快递单号",
    copyShipNoSuccess: "复制成功",
    copyShipNoFailed: "复制失败",
    accept: "接受订单",
    acceptHint: "是否确定接受此订单？",
    accepting: "正在接受订单...",
    cancel: "取消订单",
    cancelHint: "是否确定取消此订单？",
    canceling: "正在取消订单...",
    send: "快递发货",
    prepared: "备餐完成",
    delivered: "已送达",
    sendTitle: "订单发货",
    discountTitle: "产品折扣促销",
    sendTrackName: "快递名称",
    sendTrackDate: "快递发货时间",
    sendTrackReciet: "快递运单凭证",
    sendTrackNameHint: "请选择快递名称",
    sendTrackReason: "备注",
    sendTrackNumber: "快递单号",
    sendTrackNumberHint: "请输入快递单号",
    sendShipWeight: "快递重量",
    sendShipWeightUnit: "千克",
    sendShipWeightHint: "请输入快递重量",
    sendShipPrice: "运输费用",
    sendShipReceipt: "运单票据",
    sendShipPriceUnit: "元",
    sendShipPriceHint: "请输入运输费用",
    sending: "正在进行发货...",
    sendChange: "修改快递",
    sendChangeTitle: "修改订单快递",
    sendChanging: "正在修改订单快递...",
    user: "下单用户：(%{user})",
    kuaidi100Title: "快递查询",
    addForm: "添加",
    deleteForm: "删除",
    importErrorCheck: "查看错误原因",
    calculate: "佣金计算",
    parentCommission: "父代佣金%{price}元",
    childCommission: "代理佣金%{price}元",
    refund: "退款",
    refundCount: "退款数量",
    refundAmount: "已退数量%{amount}个",
    refundPrice: "已退金额%{price}元",
    refundMax: "至多能退%{max}个",
    refundConfirm: "确认退款吗？",
    atLeastRefund: "至少选择一个退款的商品",
    refundReasonWarning: "退款原因不能为空",
    refundReason: "请填写退款原因",
    refundCheck: "查询退款情况",
  },
  shows: {
    type: {
      all: "全部",
      text: "文字",
      image: "图片",
      video: "视频",
    },
    status: {
      all: "全部",
      valid: "审核通过",
      invalid: "未通过",
    },
    sortTypes: {
      created_at_desc: "上传时间（降序）",
      created_at_asc: "上传时间（升序）",
    },
    noTags: "未设置标签",
    mediaTypesTitle: "媒体",
    orderTypesTitle: "状态",
    sortTypesTitle: "排序",
    showsTitle: "秀列表",
    loadingShow: "正在读取秀列表...",
    loadingShowEmpty: "暂无秀",
    showsTitleCount: "总共 %{total} 个秀",
    deleteConfirm: "真要删除此秀？",
    categories: "分类",
    add: "添加新秀",
  },
  showsEdit: {
    videoCover: "封面",
    headerTitle: "新建秀",
    headerTitleEdit: "编辑秀",
    creatingShowSuccess: "成功创建秀",
    updatingProductSuccess: "成功更新秀",
    catLabel: "分类",
    nameLabel: "内容",
    nameHint: "请填写你要输入的内容",
    nameRequired: "内容不能为空",
    imagesLabel: "图片",
    tagLabel: "商品标签",
    tagHint: "请选择商品标签",
    selectProduct: "请选择商品",
    bindProduct: "绑定商品",
    statusLabel: "媒体",
    languageLabel: "语言",
    productLabel: "标签",
    status: {
      image: "图片",
      video: "视频",
      text: "文字",
    },
    orderLabel: "顺序",
    addTag: "添加标签",
    orderIdLabel: "订单号",
  },
  product: {
    filter: {
      all: "全部",
      onShelf: "售卖中",
      offShelf: "已下架",
      discounted: "特价产品",
      order_desc: "利润降序",
      order_asc: "利润升序",
      price_start: "最低价",
      price_end: "最高价",
      quantity_desc: "库存降序",
      quantity_asc: "库存升序",
    },
    search: {
      product_no: "商品编码",
      name: "商品名称",
    },
    discount: {
      title: "产品促销",
      priceLabel: "促销价",
      discountedPriceHint: "请输入促销价",
      discounting: "正在进行打折...",
    },
    loadingCatsData: "正在加载商品分类数据...",
    loadingProductsData: "正在加载商品列表数据...",
    headerCreate: "新建商品",
    headerSeatchHint: "请输入",
    catsTitle: "分类列表",
    catsManage: "管理分类",
    catsLoading: "加载分类数据...",
    catsNull: "暂无分类数据",
    catsEmpty: "尚未添加分类",
    selectAll: "全选本页",
    batchOnShelf: "批量上架",
    batchOffShelf: "批量下架",
    productsEmpty: "暂无相关的商品数据",
    catsModalTitle: "分类管理",
    catsModalOkText: "确定",
    catsModalCancelText: "取消",
    catsModalAvailable: "可选分类",
    catsModalSelected: "本店分类",
    catsModalAdd: "添加",
    catsModalRemove: "移除",
    catsModalInclude: " (含有%{count}个商品)",
    catsModalIncludeWarning: "不能移除含有商品的分类",
    updatingProduct: "正在更新商品数据...",
    updatingProductSuccess: "成功更新商品数据，正在刷新列表...",
    priceRangeAlert: "最低价大于最高价",
    productItem: {
      offShelf: "已下架",
      monthySale: "月销 %{count}",
      likes: "赞 %{count}",
      quantity: "库存",
      quantityLimitless: "无限",
      actionUp: "上架",
      actionDown: "下架",
      actionConfirm: "确定",
      actionDelete: "删除",
      actionEdit: "编辑",
      actionCancel: "取消",
      oneKeyCopy: "获取直播链接",
      skuImage: "/assets/images/product-item-sku.png",
      actionClone: "复制",
    },
    cats: {
      actions: "操作",
      addChild: "添加子分类",
      edit: "编辑此分类",
      delete: "删除此分类",
      search: "请输入您想搜索的分类名称",
      add: "添加新分类",
      deleteWarning: "确定删除此分类吗？",
      catWarning: "该店铺还没添加任何分类，不能新建商品",
      catEditWarning: "该店铺还没添加任何分类，不能新建商品",
      zhName: "分类名称（中文）",
      ugName: "分类名称（维语）",
      save: "保存",
      cancel: "取消",
    },
  },
  productEdit: {
    status: {
      onShelf: "上架",
      offShelf: "下架",
    },
    suggest: {
      on: "推荐",
      off: "不推荐",
    },
    headerTitle: "新建商品",
    headerTitleEdit: "编辑商品",
    headerBack: "返回",
    catLabel: "商品分类",
    catRequired: "商品分类不能为空",
    nameLabel: "商品名称(中文)",
    nameHint: "请输入商品中文名称",
    numberLabel: "商品编码",
    numberHint: "请输入商品编码",
    numberRequired: "商品编码不能为空",
    valid_date: "保质期",
    validHint: "请输入保质期",
    manufactured_date: "生产日期",
    recommended_price: "建议零售价",
    recommendedHint: "请输入建议零售价",
    basic_sales: "基础销量",
    basicHint: "请输入基础销量",
    barcodeLabel: "条形码",
    barcodeHint: "请输入条形码",
    nameRequired: "商品中文名称不能为空",
    nameUgLabel: "商品名称(维文)",
    nameUgHint: "请输入商品维文名称",
    imagesLabel: "商品图片",
    imagesUpload: "添加",
    imagesRequired: "请确保至少有一张商品图片",
    detailLabel: "商品详情",
    descLabel: "商品详情(中文)",
    descHint: "请输入商品中文描述，限400字",
    descUgLabel: "商品详情(维文)",
    detailDescLabel: "商品详情描述(中文)",
    detailDescLabelHint: "请输入该商品盒/袋/箱信息",
    detailUgDescLabelHint: "请输入该商品盒/袋/箱信息 (维文)",
    detailDescUgLabel: "商品详情描述(维文)",
    descUgHint: "请输入商品维文描述，限400字",
    standardLabel: "商品价格：",
    propertyLabel: "商品规格",
    inPriceLabel: "进货价格：",
    inPriceLabelShort: "进：",
    outPriceLabel: "销售价格：",
    outPriceLabelShort: "售：",
    commission: "佣金比例",
    parentCommission: "父佣金比例",
    quantityLabel: "库存数量：",
    packPriceLabel: "包装价格：",
    packCountLabel: "包装数量：",
    standardRequired: "不能为空",
    statusLabel: "商品状态：",
    orderLabel: "商品顺序",
    suggestLabel: "是否推荐商品",
    skuImage: "/assets/images/product-edit-sku.png",
    saveAndBack: "保存并返回",
    save: "保存",
    saveAndContinue: "保存并继续创建",
    cancel: "取消",
    skuWarning: "平台商品不能编辑图片",
    creatingProduct: "正在创建商品...",
    creatingProductSuccess: "成功创建商品",
    updatingProduct: "正在更新商品...",
    updatingProductSuccess: "成功更新商品",
    uploadingImages: "正在上传图片...",
    uploadImageTypeWarning: "图片格式必须是 .jpg/.jpeg/.png ！",
    uploadImageSizeWarning: "图片大小不能超过1MB ！",
    uploadNavImageSizeWarning: "图片大小不能超过200KB ！",
    uploadVideoSizeWarning: "视频大小不能超过3MB ！",
    delete: "确认删除此商品吗？",
    translate: "翻译",
    refetchImgs: "更新图片",
    refetchDesc: "更新详情",
    restorePrice: "恢复原价",
    priceDiscount: "产品打折",
    basicInfo: "基本信息",
    priceInfo: "价格信息",
    otherInfo: "其他信息",
    commissionInfo: "佣金信息",
    shipPriceLabel: "运费: ",
    maxBuyLimitLabel: "最大限购数量",
    minBuyLimitLabel: "最小限购数量",
    isFreeShipping: "是否免运费",
    freeShipping: "包邮",
    notFreeShipping: "不包邮",
    imagesZhLabel: "中文标签图",
    imagesUgLabel: "维文标签图",
    douyinOrder: "抖音下单",
  },
  review: {
    status: {
      all: "全部",
      reviewed: "已回复",
      unReviewed: "未回复",
    },
    satisfaction: {
      all: "全部",
      best: "好评",
      normal: "中评",
      worst: "差评",
    },
    content: {
      all: "全部",
      noContent: "有内容",
    },
    loadingData: "正在读取评价列表数据...",
    loadingDataEpmty: "暂无数据",
    updatingData: "正在更新评价列表数据...",
    headerTitle: "最近30天评价统计：",
    headerMerchant: "商家评分",
    headerClient: "客户满意度",
    filterStatus: "评价状态",
    filterSatisfaction: "满意程度",
    filterContent: "有无内容",
    filterDate: "选择时间",
    listTitle: "符合条件的评价: %{count}条",
    replyValidWarning: "请输入回复内容",
    replyLengthWarning: "回复内容不能超过300字",
    replyCommitting: "正在提交回复",
    replyCommittingSuccess: "回复成功, 正在更新数据...",
    reviewItem: {
      score5: "很棒",
      score4: "很好",
      score3: "可以",
      score2: "偏差",
      score1: "很差",
      merchantScore: "商家评分%{score}星：%{scoreText}",
      merchantText: "商家",
      deliveryText: "配送",
      ovewrallText: "总体",
      overallOk: "满意",
      overallNotOk: "不满意",
      noContent: "无内容",
      noImage: "无图片",
      replyText: "回复：",
      noReply: "无回复",
      actionReply: "回复",
      replyHint: "险300字，请勿恶意回复，若被发现将严重处理",
      replyConfirm: "确定",
      replyCancel: "取消",
      detailLabel: "订单详情",
    },
  },
  financeHome: {
    loadingData: "正在加载结算数据...",
    loadingEmpty: "暂无数据",
    headerTitle: "账单信息",
    headerAllBills: "全部账单",
    listTitle: "已结算账单",
    listTitleUnsettled: "待结算账单",
    listHeaderDate: "日期",
    listHeaderSum: "账单金额",
    listHeaderActions: "操作",
    listHeaderDetails: "账单信息",
    listItemActionDetails: "查看",
    listItemActionDownload: "下载",
    listGroupTime: "%{start_date}至%{end_date}的账单",
    listGroupInfo: "已汇入余额>",
    listGroupInfoUnsettled: "预计%{sign_date}入账",
  },
  financeCheck: {
    selectTime: "选择时间",
    allTime: "所有时间",
    loadingSettledData: "正在获取已结算账单信息...",
    loadingUnsettledData: "正在获取待结算账单信息...",
  },
  financeAccount: {
    times: {
      month: "近一月",
      season: "近一季",
      custom: "自定义",
    },
    settlementTypes: {
      cycle: "周期结算",
    },
    loadingData: "正在加载结算账户数据...",
    loadingEmpty: "暂无数据",
    ballanceInfoTitle: "结算信息",
    ballanceInfoCycle: "结算周期",
    ballanceInfoCycleValue: "%{day}天周期结算",
    ballanceInfoCycleType: "结算方式",
    ballanceInfoMin: "最低结算金额",
    ballanceInfoValue: "%{amount}元",
    ballanceInfoContact: "商家财务联系人",
    ballanceInfoContactPhone: "联系人电话",
    bankInfoTitle: "银行卡信息",
    bankInfoType: "账户类型",
    bankInfoName: "开户银行(简称)",
    bankInfoNameFull: "开户银行(全称)",
    bankInfoBankTypeCompany: "对公",
    bankInfoBankTypePrivate: "对私",
    bankInfoAddressCode: "省市区编号",
    bankInfoBranchId: "联行号",
    bankInfoAccount: "银行卡卡号",
    bankInfoAccountName: "银行卡户名",
    organizationType: "主体类型",
    organizationTypeMicro: "小微商户",
    organizationTypePersonal: "个人卖家",
    organizationTypeIndividual: "个体商户",
    organizationTypeEnterprise: "企业",
    organizationTypeGovernment: "党政",
    organizationTypeOther: "其他组织",
    contactType: "联系人类型",
    contactTypeLegal: "经营者/法人",
    contactTypePersonInCharge: "负责人",
    contact_email: "邮箱",
    listTitle: "账单流水记录",
    listFilterTime: "选择时间",
    listHeaderDate: "日期",
    listHeaderName: "账单名称",
    listHeaderAmount: "金额",
    listHeaderStatus: "状态",
    listHeaderAction: "操作",
    listItemName: "%{start_date}至%{end_date}的账单",
    listItemAmount: "%{amount}元",
    listItemStatusSuccess: "交易成功",
    listItemActionDetails: "详情",
    basicInfo: "商家信息",
    merchantAccountInfo: "商家银行信息",
    merchantInfo: "商家主体信息",
    licenceNumber: "证件注册号",
    licenceName: "商户名称",
    licenceImage: "证件扫描件",
    licenceLegal: "法人",
    licenceIdCardFront: "身份证人像面",
    licenceIdCardBack: "身份证国徽面",
    licenceIdName: "身份证姓名",
    licenceIdNumber: "身份证号码",
    licenceIdStartDate: "身份证有效期开始日期",
    licenceIdEndDate: "身份证有效期结束日期",
    idLongTerm: "长期",
    merchantAbbr: "商家简称",
    customerNumber: "售后电话",
    logo: "店铺logo",
  },
  financeDownload: {
    tabs: {
      generate: "选择账单日期",
      history: "生成账单历史",
    },
    times: {
      month: "近一天",
      season: "近七天",
      custom: "自定义",
    },
    loadingData: "正在获取账单列表...",
    loadingEmpty: "暂无数据",
    generateFilterTime: "选择时间",
    generateActionGenerate: "生成账单",
    historyHeaderNo: "序号",
    historyHeaderName: "文件名",
    historyHeaderStatus: "状态",
    historyHeaderActions: "操作",
    listItemName: "%{start_date}至%{end_date}的账单",
    historyItemStatusSuccess: "生成成功",
    historyItemStatusGenerating: "正在生成...",
    historyItemStatusFailed: "生成失败",
    historyItemActionDownload: "下载",
    historyItemActionDelete: "删除",
    historyItemActionDeleteWarning: "确认要删除此帐单吗？",
    historyItemActionDeleteWarningOk: "是的",
    historyItemActionDeleteWarningCancel: "取消",
    generating: "正在生成账单...",
    generatingWarning: "正在生成账单，请稍后操作！",
    deleting: "正在删除账单...",
    generateSuccess: "账单已提交，正在更新列表数据...",
    updatingList: "正在更新列表数...",
    deleteSuccess: "账单已成功删除，正在更新列表数据...",
    range: "日期差距最多为15天，已为你调整",
  },
  orderDownload: {
    tabs: {
      generate: "选择订单日期",
      history: "导出历史",
    },
    generateActionGenerate: "导出订单列表",
    range: "日期差距最多为两天，已为你调整",
    date: "日期",
    orderCount: "订单数",
  },

  financeBillDetail: {
    loadingData: "正在加载账单详情...",
    loadingEmpty: "暂无数据",
    headerBack: "返回",
    headerTitle: "结算账单详情",
    billAmount: "收入金额",
    billAmountValue: "%{amount}元",
    billAccount: "汇入账号",
    billType: "类型",
    billTime: "打款时间",
  },
  financeCheckDaily: {
    tabs: {
      order: {
        name: "订单类",
        tabs: {
          all: "全部",
          normal: "外卖订单",
          refund: "退款订单",
        },
      },
      other: {
        name: "订单类",
        tabs: {
          all: "全部",
          ship_fee: "配送费用",
          bad_food: "餐损赔付",
          serve_return: "服务费返还",
          part_refund: "部分退款",
          refund_neutralize: "部分退款冲抵",
        },
      },
    },
    loadingData: "正在加载日账单详情...",
    loadingEmpty: "暂无数据",
    headerBack: "返回",
    headerDate: "%{date} 日账单",
    headerInfo: "已汇入余额",
    headerInfoUnsettled: "预计%{sign_date}入账",
    listUnit: "单位：元",
    listHeaderOrderDate: "日期&订单编号",
    listHeaderOrderType: "类型",
    listHeaderOrderPrice: "商品原价",
    listHeaderOrderPacking: "包装费用",
    listHeaderOrderEvent: "商家活动支出",
    listHeaderOrderPlatform: "平台服务费",
    listHeaderOrderShip: "配送费",
    listHeaderOrderDonate: "公益捐款",
    listHeaderOrderTotal: "结算金额",
    listHeaderOrderAction: "操作",
    listHeaderOtherDate: "日期",
    listHeaderOtherType: "类型",
    listHeaderOtherDetails: "详细类型",
    listHeaderOtherTotal: "结算金额",
    listHeaderOtherAction: "操作",
    listItemActionDetail: "详情",
    listFooterTotal: "总计 (共%{order_count}笔)",
  },
  financeOrderDetail: {
    headerBack: "返回",
    headerTitle: "订单详情",
    detailProducts: "商品",
    detailMerchant: "商家活动",
    detailPlatform: "平台抽成",
    detailShip: "配送费用",
    detailDonation: "公益捐款",
    detailTotal: "已结算金额",
    footerSequence: "订单序号：#%{sequence}",
    footerNo: "订单编号：#%{no}",
    footerTime: "下单时间：#%{time}",
    footerTimeFinish: "完成时间：#%{time}",
  },
  proxy: {
    loadingData: "正在读取代理列表数据...",
    updatingData: "正在更新代理列表数据...",
    searchFromParent: "搜索父代",
    filterMobile: "手机号码",
    filterMobileHint: "请输入代理手机号或昵称",
    filterLevel: "代理等级",
    filterOwner: "有无代理",
    filterStatus: "审核状态",
    addProxy: "添加代理",
    listTitle: "符合条件的代理：%{count}个",
    listItemLevel: "等级",
    listItemMobile: "手机",
    listItemPercent: "优惠",
    listItemParent: "父代",
    listItemParentNone: "无",
    listItemEdit: "编辑",
    listItemDelete: "删除",
    listNoData: "暂无数据",
    deleteHint: "是否要删除该代理？",
    deleting: "正在删除代理...",
    updating: "正在更新代理...",
    adding: "正在添加代理...",
    editMobile: "代理手机",
    editMobileHint: "请输入代理手机号",
    editMobileLength: "请输入11位的手机号",
    editLevel: "代理等级",
    editLevelHint: "请选择代理等级",
    editPercent: "代理优惠",
    editProxySwitch: "可否招代理",
    editParentMobile: "父级手机",
    editParentMobileHint: "请输入父级代理手机号",
    editParantPercent: "父级分成",
    editTitle: "添加代理",
    editTitleEdit: "编辑代理",
    editOk: "确定",
    editCancel: "取消",
    all: "全部",
    owner: "有代理",
    notOwner: "无代理",
    reviewing: "审核中",
    reviewed: "已审核",
    editProxyStatusSwitch: "审核通过",
    registerDate: "申请时间",
    listProxyTeam: "团队数量",
    listProxyClient: "客户数量",
    listProxyOrders: "订单数量",
    apply: "代理申请页面",
  },
  commissionList: {
    mobile: "手机号",
    order_no: "订单号",
    original: "订单原价",
    price: "订单金额",
    ship: "运费",
    real: "实付款",
    receiver: "收货人",
    tel: "收货人手机号",
    address: "收货地址",
    comm: "佣金",
    channel: "渠道",
    status: "状态",
    order_id: "订单ID",
    unfreeze: "解冻",
    confirmFinished: "确认完成",
    update: "更新物流",
    confirmReceived: "确认送达",
    confirmSend: "确认发货",
    confirm: "审核",
    reject: "拒绝",
    appliedAmount: "申请提现佣金",
    appliedDate: "申请时间：%{date}",
    sendWarehouse: "发给仓库",
  },
  selectLang: {
    zh: "简体中文",
    ug: "ئۇيغۇرچە",
    currentTitle: "当前语言",
    availableTitle: "可选语言",
    switchConfirm: "确定要选择该语言吗？",
  },
  importExecl: {
    importing: "正在导入表格...",
    historyItemStatusSuccess: "导入成功",
    historyItemStatusFailed: "导入失败",
    errorTitle: "错误原因",
  },
  sales: {
    upload: "上传",
    status: "开启状态",
    submit: "提交表单",
    generate: "生成小程序",
    deleteWarning: "确定删除吗？",
    zhTitleWarning: "请填写中文标题",
    zhTitle: "中文标题",
    ugTitleWarning: "请填写维文标题",
    ugTitle: "维文标题",
    save: "保存草稿",
    localOk: "继续编辑",
    localNew: "重新开始",
    localWarning: "您有未上传的活动信息，是否继续？",
    ad: {
      lottery: "转盘",
      live: "直播",
      webview: "网页",
      add: "添加广告",
      weapp: "小程序",
      appId: "小程序APP_ID",
      typeWarning: "请选择广告类型",
      product: "商品",
      holiday: "活动",
      market: "店铺",
      groupon: "抢购",
      title: "广告编辑",
      order: "广告顺序",
      orderPlace: "数值越大越靠前",
      zhImg: "中文图片",
      ugImg: "维文图片",
      imgWarning: "缺少图片",
      type: "广告类型",
      target: "请输入广告目的地",
      show: "纯展示",
      swiperTip: "首页滑动广告位，尺寸为408px*1040px",
      splashTip: "打开小程序时首屏满屏广告",
      popupTip: "打开小程序时首页展示的跳出广告",
      homeAdTip:
        "首页商品列表上方的图片广告位，支持透明背景图片，尺寸为180px*576px",
    },
    activity: {
      add: "添加活动",
      addTheme: "添加模版",
      product: "添加商品",
      title: "活动编辑",
      zhImg: "中文图片",
      ugImg: "维文图片",
      imgWarning: "缺少图片",
      edit: "编辑活动",
      delete: "删除",
      today: "今天",
      start_date: "开始",
      end_date: "结束",
      add_product: "添加商品",
      time: "活动时间",
      product_list: "商品列表",
      discount_price: "活动价（元）",
      discountWarning: "请输入活动价",
      min: "最低限购",
      minWarning: "请输入最低限购",
      max: "最高限购",
      maxWarning: "请输入最高限购",
    },
    groupon: {
      add: "添加抢购",
      edit: "编辑抢购",
    },
    nav: {
      add: "添加导航",
      title: "导航编辑",
      order: "显示顺序",
      orderPlace: "数值越大显示越靠前",
      target: "链接页面",
      targetWarning: "请选择链接页面",
      img: "图片",
      imgWarning: "缺少图片",
      coupon: "优惠券页面",
      product: "商品分类页面",
      childTab: "分类",
      orders: "全部订单页面",
      discount: "特价商品页面",
      liveList: "直播房间列表页面",
      liveRoom: "直播房间页面",
      mediaUrl: "获取回放地址",
      roomId: "房间号",
      roomIdWarning: "请输入房间号",
      couponIdWarning: "请选择优惠券",
      imgTip:
        "首页滑动广告下面的分类入口，支持透明背景图片，尺寸为 300px*300px",
    },
    mini: {
      name: "小程序名称",
      shortName: "小程序简称",
      description: "小程序简介",
      img: "小程序头像",
      primary: "主颜色",
      secondary: "副颜色",
      testTitle: "测试标题",
      testBtnTitle: "测试按钮",
      nameWarning: "名称不能为空",
      appIdWarning: "AppId不能为空",
      appSecretWarning: "AppSecret不能为空",
      tokenWarning: "Token不能为空",
      aesKeyWarning: "AESKey不能为空",
      descriptionWarning: "小程序简介不能为空",
      imgWarning: "头像不能为空",
      mainConfig: "小程序配置",
      secondConfig: "小程序设置",
      paymentConfig: "支付设置",
      zhTitle: "中文标题",
      ugTitle: "维文标题",
      language: "首选语言",
      live: "直播开关",
      ug: "维语",
      zh: "中文",
      settingWarning: "请完成小程序配置并提交以后在设置小程序设置",
    },
    coupons: {
      add: "添加优惠券",
      send: "发放",
      title: "优惠券编辑",
      price: "优惠金额（元）",
      limit: "满多少（元）",
      range: "发放日期",
      experation: "过期日期",
      priceWarning: "请输入优惠金额",
      limitWarning: "请输入订单总额",
      rangeWarning: "请选择发放日期",
      experationWarning: "请选择过期日期",
      select: "选择",
      selected: "已选择",
      quantity: "发放数量",
      quantityWarning: "请填写数量",
      status: "状态",
      total: "全部数量%{count}张",
      left: "剩余数量%{count}张",
      start: "满%{price}元使用",
      face_price: "优惠%{price}元",
      no_limit: "无门槛",
      all: "全部",
      used: "已使用",
      unused: "未使用",
      distributedDate: "发放日期",
      distributedList: "发放记录",
      usedDate: "使用日期",
      orderNo: "订单编号",
      isPublic: "是否公开",
    },
    gift: {
      add: "添加赠品",
      edit: "编辑赠品",
      product: "指定商品",
      order: "订单金额",
      type: "类型",
      orderAmount: "订单金额",
      selected: "已选商品",
      deleteAll: "全部删除",
      rangeWarning: "请选择日期",
      gift: "赠品",
      typeWarning: "请选择赠送类型",
    },
  },
  property: {
    addChild: "添加规格",
    delete: "删除规格组",
    addGroup: "添加规格组",
    submit: "提交",
    groupZh: "规格组中文名称",
    groupUg: "规格组维文名称",
    childZh: "规格中文名称",
    childUg: "规格维文名称",
    number: "货号",
    quantity: "库存",
    must: "必填",
  },
  payment: {
    merchantId: "商户号",
    secret: "支付密钥",
  },
  agentLevel: {
    add: "添加等级",
    zh: "中文名称",
    ug: "维文名称",
    percent: "佣金比例",
  },
  users: {
    name: "名称",
    avatar: "头像",
    tags: "是否注册",
    actions: "操作",
    unbind: "解绑微信",
    registered: "已注册",
    unregistered: "未注册",
    order: "订单数量",
    searchPlace: "请输入用户名或者手机号来搜索",
    balance: "余额",
    balanceSum: "全部余额:",
    date: "创建日期",
    unbindWarning: "确定要解除绑定吗？",
    currentBalance: "当前余额",
    topupWarning: "确定充值当前用户吗？",
    topup: "充值",
    recall: "撤销",
    recallWarning: "确定要撤销吗？",
    operator: "操作员",
    appoint: "指定用户",
    unappoint: "取消指定用户",
  },
  live: {
    edit: "编辑直播间",
    sync: "同步直播列表",
    ugTitle: "维文标题",
    zhTitle: "中文标题",
    order: "序列",
    zh_poster_image: "中文海报图",
    ug_poster_image: "维文海报图",
    rec: "已推荐",
    zh_recommended_slider_image: "中文推荐图片",
    ug_recommended_slider_image: "维文推荐图片",
  },
  deliveryman: {
    title: "配送员管理",
    edit: "编辑配送员信息",
    add: "添加配送员",
    zhNameHint: "请填写配送员的中文名",
    zhName: "中文名",
    ugNameHint: "请填写配送员的维语名",
    ugName: "维语名",
    mobile: "手机号",
    mobileHint: "请准确输入手机号",
    mobileHolder: "请输入手机号",
    deleteWarning: "真的要删除吗？",
    atleast: "至少选择一个要绑定的小区",
  },
  community: {
    title: "区域管理",
    edit: "编辑区域信息",
    add: "添加区域",
    zhNameHint: "请填写区域的中文名",
    zhName: "中文名",
    ugNameHint: "请填写区域的维语名",
    ugName: "维语名",
    zhAddressHint: "请填写区域的中文地址",
    zhAddress: "中文地址",
    ugAddressHint: "请填写区域的维语地址",
    ugAddress: "维语地址",
    position: "坐标",
    positionHint: "请输入坐标",
    population: "人口数量",
    order: "排序",
    addMany: "批量添加小区",
    polygon: "选择区域",
    success: "成功添加小区",
  },
  lottery: {
    wheel: "大转盘",
    nine: "九宫格",
    fruit: "水果机",
    product: "商品",
    red: "红包",
    thanks: "谢谢参与",
    itemType: "奖品类型",
    itemTypeWarning: "请选择奖品类型",
    percent: "概率",
    remainError: "还剩%{remain}%",
    percentMessage: "概率总和一定为100%",
    add: "添加奖品",
    type: "抽奖类型",
    empty: "请添加奖品",
    redWarning: "请填写红包金额",
    prizes: "奖品池",
    wheelWarning: "大转盘奖品数目必须为六个",
    fruitWarning: "水果机奖品数目必须为八个",
    themeWarning: "请选择抽奖模版",
    validTime: "领奖有效天数",
    validWarning: "请填写领奖有效日期",
    validRule: "自获奖日期起算，过期奖品无效",
    ticketCount: "票数",
    currentCount: "票数记录",
    prizeCount: "抽奖记录",
    transCount: "转移记录",
    reduce: "减少票数",
    revert: "撤销转移",
    zhStartImg: "中文开始按钮",
    ugStartImg: "维文开始按钮",
    zhHeaderImg: "中文活动标题",
    ugHeaderImg: "维文活动标题",
    plateBack: "转盘背景",
    ugDesc: "维文活动规则说明",
    zhDesc: "中文活动规则说明",
    zhAwardImg: "中文获奖图片",
    ugAwardImg: "维文获奖图片",
    thumb: "整体照片",
    zhImg: "主页中文背景",
    ugImg: "主页维文背景",
    backColor: "主页背景颜色",
    imgRule: "建议图片尺寸为%{pixel}类型为%{type}",
    backColorRule: "背景颜色应选取背景图片的底色",
    sender: "发送者",
    receiver: "接受者",
    drew_at: "抽奖时间",
    exchanged_at: "兑现时间",
    prize: "奖品",
    draw: "已抽奖",
    exchange: "已兑换",
    organSend: "官方发放",
    userTickets: "用户票数",
    ticketPrice: "票价(元)",
    ticketWarning: "请填写票价",
    toExchange: "兑换",
    exchangeAlert: "确定兑换码？",
    selfBuy: "用户是否可以自购票",
    onSite: "是否门店兑换",
    selfBuyCount: "用户自购",
    buyHistory: "购票记录",
    select: "选择",
    left: "剩余",
    prePrize: "奖品预设",
    addPrePrize: "添加预设",
    selectManager: "指定管理员",
    managers: "管理员列表",
    opened: "已开启",
  },
  redPacket: {
    zhImg: "中文红包图片",
    ugImg: "维文红包图片",
    price: "红包金额（元）",
    count: "发放人数",
    add: "添加红包",
    update: "编辑红包",
    atLeast: "至少需要%{price}元",
    priceWarning: "请输入红包金额",
    countWarning: "请填写人数",
    list: "红包记录",
    user: "获奖用户",
    retry: "重新生成",
  },
  article: {
    add: "添加文章",
    cat: "文章分类",
    addCatPlz: "请先添加文章分类",
    title: "文章",
    all: "全部",
    opened: "已开放",
    closed: "已关闭",
    selectWarning: "请选择文章",
  },
  weapp: {
    add: "发布新版本",
    version: "版本",
    type: "类型",
    build: "zip文件",
    standard: "标准版",
    lottery: "抽奖版",
    drag: "请点击选择或者把zip文件拖到这块区域",
  },
  transfer: {
    new: "打款",
    reason: "请填写备注",
  },
  applyment: {
    apply: "申请",
    applyAgain: "再次申请",
    query: "查询",
    applyDescription:
      "1.上传营业执照等证照信息 \n 2. 上传银行信息 \n 3. 填写商户基本信息 \n 4.最后申请入驻",
    procesing: "处理中",
    processingDescription: "",
    finishDescription: "",
    title: "申请微信支付商户",
    id: "申请编号",
    status: "申请状态",
    merchantId: "商家编号",
    subMerchantId: "微信支付商户编号",
    createdDate: "创建日期",
    updatedDate: "更新日期",
    checking: "资料校验中",
    accountNeedVerify: "待账户验证",
    auditing: "审核中",
    rejected: "已驳回",
    needSign: "待签约",
    finish: "完成",
    frozen: "已冻结",
  },
  member: {
    info: "会员信息",
    number: "会员编号",
    joined_at: "成为会员日期",
    expired_at: "会员到期日期",
    saved: "总共节省（元）",
    add: "新增会员",
    duration: "会员期限",
    type: "会员类型",
    user: "会员用户",
    selectUser: "选择用户",
    reselect: "重新选择",
    durationWarning: "请选择会员期限",
    cardWarning: "请选择会员类型",
    zhDesc: "中文说明",
    ugDesc: "维文说明",
    price: "会员费",
    points: "积分",
    discount: "折扣",
    multiple: "积分倍数",
    addCard: "新增会员卡",
    paidAt: "付费时间",
  },
};
