import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Layout,
  Input,
  Button,
  Row,
  Col,
  Modal,
  List,
  Avatar,
  Form,
  Card,
  Space,
  Typography,
  AutoComplete,
} from "antd";

import Base from "../Base";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { setUserInfo } from "../../utils/redux/actions/User";

class Login extends Base {
  /* lifcyle methods */
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isLoggingin: false,
      merchants: null,
      resetPassword: false,
      disableCodeBtn: false,
    };
  }

  formRef = React.createRef();
  resetFormRef = React.createRef();

  /* render methods */

  render() {
    const { merchants, isLoggingin } = this.state;
    if (merchants) {
      return (
        <Modal
          bodyStyle={{ maxHeight: "80vh", overflowY: "scroll" }}
          width={1020}
          footer={null}
          title={
            <Space>
              {this.i18n.t("login.selectShop")}
              {this.renderShopAutoCompelete()}
            </Space>
          }
          visible
        >
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: 3,
            }}
            loading={isLoggingin}
            itemLayout="vertical"
            dataSource={merchants}
            renderItem={(item) => (
              <List.Item>
                <Card hoverable onClick={() => this.onSelectShop(item)}>
                  <Space direction="horizontal" align="center" size={4}>
                    <Avatar src={item.avatar} shape="circle" size="large" />
                    <Typography.Text>
                      {this.i18n.getPropNameByLocale(item, "name")}
                    </Typography.Text>
                  </Space>
                </Card>
              </List.Item>
            )}
          />
        </Modal>
      );
    }
    return (
      <Layout id="route-login" style={{ overflow: "scroll" }}>
        <Row className="login-box-wrapper">
          <img
            src="https://bulutbazar.com/assets/mobile/images/bulutbazar-logo.png"
            alt=""
          />
          <Col className="login-box">
            {this._renderTitle()}
            {this._renderFrom()}
          </Col>
        </Row>
      </Layout>
    );
  }

  _renderTitle() {
    return (
      <div className="login-title">
        <span>{this.i18n.t("login.title")}</span>
      </div>
    );
  }

  _renderFrom() {
    const { resetPassword, disableCodeBtn } = this.state;
    if (resetPassword) {
      return (
        <Form
          className="login-form"
          ref={this.resetFormRef}
          onFinish={this.onResetPassword}
        >
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: this.i18n.t("login.phoneHintRequired"),
              },
              {
                max: 11,
                message: this.i18n.t("login.phoneHintCount"),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={this.i18n.t("login.phoneHint")}
              allowClear
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name="vcode"
                  rules={[
                    {
                      required: true,
                      message: this.i18n.t("login.codeHint"),
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder={this.i18n.t("login.codeHint")}
                    allowClear
                    addonAfter={
                      <Button
                        type="link"
                        onClick={() => this.onGetCode(getFieldValue("phone"))}
                        disabled={disableCodeBtn}
                      >
                        {this.i18n.t("login.getCode")}
                      </Button>
                    }
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            name="parol"
            rules={[
              {
                required: true,
                message: this.i18n.t("login.parolHintRequired"),
              },
              {
                min: 6,
                message: this.i18n.t("login.parolHintMin"),
              },
              {
                max: 16,
                message: this.i18n.t("login.parolHintMax"),
              },
            ]}
          >
            <Input
              prefix={<LockOutlined />}
              placeholder={this.i18n.t("login.parolHint")}
              type="password"
              allowClear
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 9 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                {this.i18n.t("property.submit")}
              </Button>
              <Button
                type="link"
                onClick={() => this.setState({ resetPassword: false })}
              >
                {this.i18n.t("common.cancelText")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      );
    }
    return (
      <Form
        ref={this.formRef}
        className="login-form"
        onFinish={this.onFormSubmit}
      >
        {this._renderNameField()}
        {this._renderPasswordField()}
        {this._renderLoginField()}
      </Form>
    );
  }

  renderShopAutoCompelete() {
    const { merchants } = this.state;
    if (Array.isArray(merchants) && merchants.length > 0) {
      return (
        <AutoComplete
          placeholder={this.i18n.t("login.insertShopName")}
          options={merchants.map((m) => ({
            value: `${m.id}.${this.i18n.getPropNameByLocale(m, "name")}`,
            id: m.id,
          }))}
          filterOption={(inputValue, option) =>
            option.value
              .toLocaleLowerCase()
              .indexOf(inputValue.toLocaleLowerCase()) !== -1
          }
          style={{ width: 180 }}
          onSelect={(value, option) => {
            const merchant = merchants.find((i) => i.id === option.id);
            this.onSelectShop(merchant);
          }}
        />
      );
    }
  }

  _renderNameField() {
    const nameInput = (
      <Input
        prefix={<UserOutlined />}
        placeholder={this.i18n.t("login.phoneHint")}
        disabled={this.state.isLoggingin}
        allowClear
      />
    );

    return (
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: this.i18n.t("login.phoneHintRequired"),
          },
          {
            max: 11,
            message: this.i18n.t("login.phoneHintCount"),
          },
        ]}
      >
        {nameInput}
      </Form.Item>
    );
  }

  _renderPasswordField() {
    const passwordInput = (
      <Input
        prefix={<LockOutlined />}
        placeholder={this.i18n.t("login.parolHint")}
        type="password"
        disabled={this.state.isLoggingin}
        onPressEnter={this.onFormSubmit}
        allowClear
      />
    );

    return (
      <Form.Item
        name="parol"
        rules={[
          {
            required: true,
            message: this.i18n.t("login.parolHintRequired"),
          },
          {
            min: 6,
            message: this.i18n.t("login.parolHintMin"),
          },
          {
            max: 16,
            message: this.i18n.t("login.parolHintMax"),
          },
        ]}
      >
        {passwordInput}
      </Form.Item>
    );
  }

  _renderLoginField() {
    let buttonText = this.i18n.t("login.login");
    if (this.state.isLoggingin) {
      buttonText = this.i18n.t("login.loggingin");
    }
    return (
      <Form.Item wrapperCol={{ offset: 7 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            disabled={this.state.isLoggingin}
          >
            {buttonText}
          </Button>
          <Button
            type="link"
            disabled={this.state.isLoggingin}
            onClick={() => this.setState({ resetPassword: true })}
          >
            {this.i18n.t("login.forget")}
          </Button>
        </Space>
      </Form.Item>
    );
  }

  /* user methods */

  onFormSubmit = () => {
    this.formRef.current
      .validateFields()
      .then((values) => {
        this.login(values);
      })
      .catch((err) => {
        console.log("Login -> onFormSubmit -> err", err);
      });
  };

  /* server methods */

  async login(values) {
    if (!values) return;

    const { phone, parol } = values;
    if (!phone || !parol) return;

    let api = this.api.user.login;
    let apiConfig = {
      data: {
        client_id: "jcaf7DdWBUP23z0c",
        client_secret: "SrBxVWmWLMkLxxwlPsRWBXoe8AEcvHQw",
        grant_type: "password",
        username: phone,
        password: parol,
      },
    };

    this.setState({ isLoggingin: true });
    try {
      let res = await api.run(apiConfig);
      if (!this.mounted) return;
      if (res.result) {
        this.showMessage(
          this.i18n.t("login.loginSuccess"),
          Base.Message.success
        );
        this.onLoginSuccess(res.data);
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ isLoggingin: false });
    }
  }

  onGetCode = async (mobile) => {
    if (typeof mobile === "string" && mobile.trim()) {
      this.setState({ disableCodeBtn: true });
      const api = this.api.user.sendCode;
      const apiConfig = {
        data: {
          mobile,
          target: "reset-pass",
        },
      };
      try {
        const res = await api.run(apiConfig);
        let timer = 60;
        let interval = setInterval(() => {
          if (timer < 1) {
            this.setState({ disableCodeBtn: false });
            clearInterval(interval);
          }
          timer -= 1;
        }, 1000);
        this.showMessage(
          res.message,
          res.resutl ? Base.Message.success : Base.Message.warning
        );
      } catch (error) {
        this.handleApiError(api, error);
        if (this.mounted) {
          this.setState({ disableCodeBtn: false });
        }
      }
    }
  };

  onResetPassword = async (values) => {
    const api = this.api.user.resetLoginPassword;
    const apiConfig = {
      data: {
        mobile: values.phone,
        password: values.parol,
        verification_code: values.vcode,
      },
    };
    try {
      const res = await api.run(apiConfig);
      this.showMessage(
        res.message,
        res.resutl ? Base.Message.success : Base.Message.warning
      );
      if (res.result) {
        this.setState({ resetPassword: false });
      }
    } catch (error) {
      this.handleApiError(api, error);
      if (this.mounted) {
        this.setState({ disableCodeBtn: false });
      }
    }
  };

  /* custom methods */

  async onSelectShop(merchant) {
    try {
      const api = this.api.user.selectMerchant;

      const { user, token } = this.userInfo;
      const apiConfig = {
        token: `${token.token_type} ${token.access_token}`,
        data: {
          merchant_id: merchant.id,
        },
      };

      this.setState({ isLoggingin: true });
      const res = await api.run(apiConfig);
      if (res.result) {
        this.afterLoginSuccess({ user: { ...user, merchant }, token });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      this.handleApiError(error);
    }
  }

  afterLoginSuccess(userInfo) {
    this.props.setUserInfo(userInfo);

    let from = this.getFromRouterState("from");
    if (from) {
      this.props.router.replace(from);
    } else {
      this.props.router.replace({ pathname: "/" });
    }
  }

  onLoginSuccess(userInfo) {
    if (!this.mounted) return;
    const { user } = userInfo;
    if (user && user.merchants && user.merchants.length > 0) {
      this.userInfo = userInfo;
      this.setState({ merchants: user.merchants });
    } else {
      this.afterLoginSuccess(userInfo);
    }
  }
}

export default connect(
  (state) => {
    return {
      user: getUserInfo(state),
    };
  },
  { setUserInfo }
)(Login);
