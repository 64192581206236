import React from "react";

import {
  DownOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Button,
  Divider,
  Input,
  Checkbox,
  Pagination,
  Modal,
  Transfer,
  Spin,
  Menu,
  Row,
  Tree,
  Dropdown,
  Popover,
  Col,
  Form,
  Upload,
  message,
  Space,
  Avatar,
  Typography,
  InputNumber,
  Switch,
} from "antd";

import { connect } from "react-redux";

import Base from "../Base";

import ProductListItem from "../../components/product/ProductListItem";
import DiscountProductModal from "../../components/product/DiscountProductModal";

import { Locale, Request } from "../../utils/";
//redux
import { getUserInfo } from "../../utils/redux/reducers/User";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";
import { setUserMerchantCategories } from "../../utils/redux/actions/User";
import { setMerchantSettings } from "../../utils/redux/actions/Merchant";

import TranslateBtn from "../../components/product/TranslateBtn";

const { Search } = Input;
const { SubMenu } = Menu;
const { Text } = Typography;
const i18n = Locale.getInstance();

const AllCat = {
  key: "-1",
  id: -1,
  ug_name: "ھەممىسى",
  zh_name: "全部",
};

function onBeforeUpload(file) {
  const isLt200KB = file.size / 1024 < 200;
  if (!isLt200KB) {
    message.error(i18n.t("productEdit.uploadNavImageSizeWarning"));
  }
  return isLt200KB;
}

class ProductList extends Base {
  static ContentFilters = [
    { key: "-1", name: "product.filter.all" },
    { key: "1", name: "product.filter.onShelf" },
    { key: "0", name: "product.filter.offShelf" },
    { key: "2", name: "product.filter.discounted" },
    { key: "3", name: "productEdit.freeShipping" },
  ];

  static RevenueFilters = [
    { key: "", name: "product.filter.all" },
    { key: "revenue@desc", name: "product.filter.order_desc" },
    { key: "revenue@asc", name: "product.filter.order_asc" },
    { key: "quantity@desc", name: "product.filter.quantity_desc" },
    { key: "quantity@asc", name: "product.filter.quantity_asc" },
  ];

  catInputForm = React.createRef();
  formRef = React.createRef();

  /* lifcycle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      headerSearchKey: this.getRouterQueryKeyword(),
      searchType: this.getRouterQuerySearchType(),

      allCats: null,
      shopCats: null,
      currentCat: null,
      editCat: {
        id: null,
        parentId: null,
      },
      loadingCats: false,

      showCatModal: false,
      shopCatsKeys: null,
      updatingCats: false,

      currentFilter: this.getRouterQueryItemByKey(
        ProductList.ContentFilters,
        "filter"
      ),
      currentSort: this.getRouterQueryItemByKey(
        ProductList.RevenueFilters,
        "sort"
      ),
      startPrice: this.getRouterQueryStartPrice(),
      endPrice: this.getRouterQueryEndPrice(),

      products: null,
      loadingProducts: false,
      updatingProducts: false,
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(),
      showDiscountProductModal: false,
      selectedProduct: null,
      discountingProduct: false,
      jdCategories: null,
      openKeys: [],
      showAddJdProductModal: false,
      showDouyinOrderProduct: false,
      takingDouyinOrder: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.getUser()) {
      this.showMessage(
        this.i18n.t("product.loadingCatsData"),
        Base.Message.loading
      );
      this.loadCategories();
      this.getMerchantSettings();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      (!prevState.currentCat && this.state.currentCat) ||
      (prevState.currentCat &&
        prevState.currentCat.id !== this.state.currentCat.id) ||
      prevState.currentFilter.key !== this.state.currentFilter.key ||
      prevState.currentSort.key !== this.state.currentSort.key ||
      prevState.currentPage !== this.state.currentPage ||
      (prevState.startPrice !== this.state.startPrice &&
        !this.state.startPrice) ||
      (prevState.endPrice !== this.state.endPrice && !this.state.endPrice) ||
      prevState.currentPageSize !== this.state.currentPageSize ||
      (prevState.headerSearchKey !== this.state.headerSearchKey &&
        !this.state.headerSearchKey)
    ) {
      this.showMessage(
        this.i18n.t("product.loadingProductsData"),
        Base.Message.loading
      );
      this.loadProducts();
      this.setRouterQuery({
        keyword: this.state.headerSearchKey,
        cat: this.state.currentCat && this.state.currentCat.key,
        filter: this.state.currentFilter.key,
        sort: this.state.currentSort.key,
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
        price_start: this.state.startPrice,
        price_end: this.state.endPrice,
      });
    }

    if (prevState.currentCat !== this.state.currentCat) {
      let el = document.getElementById("main-scroll-component");
      if (el && typeof el.scrollTo === "function") {
        el.scrollTo(0, 0);
      }
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-product-list">
        {this._renderHeader()}
        {this._renderMain()}
        {this._renderModals()}
      </Layout>
    );
  }

  _renderHeader() {
    const menu = (
      <Menu onClick={({ key }) => this.onHeaderSearchTypeChange(key)}>
        <Menu.Item key="product_no">
          {this.i18n.t("product.search.product_no")}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="name">{this.i18n.t("product.search.name")}</Menu.Item>
      </Menu>
    );

    return (
      <Layout.Header className={`list-header ${this.isJdShop() && "jd"}`}>
        <div className="header-actions">
          <Button type="primary" onClick={this.onHeaderCreateClick}>
            {this.i18n.t("product.headerCreate")}
          </Button>
        </div>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="link">
            {this.i18n.t(`product.search.${this.state.searchType}`)}
            <DownOutlined />
          </Button>
        </Dropdown>
        <Input.Search
          className="header-search"
          placeholder={this.i18n.t("product.headerSeatchHint")}
          onChange={this.onHeaderSearchChange}
          onSearch={this.onHeaderSearchClick}
          onPressEnter={this.onHeaderSearchClick}
          value={this.state.headerSearchKey}
          enterButton
        />
      </Layout.Header>
    );
  }

  _renderDiscountProductModal() {
    if (!this.state.showDiscountProductModal) return null;
    let confirmLoading = this.state.discountingProduct;
    return (
      <DiscountProductModal
        key="discount-product-modal"
        product={this.state.selectedProduct}
        onCancel={this.handleDiscountProductModalCancel}
        onConfirm={this.handleDiscountProductModalConfirm}
        confirmLoading={confirmLoading}
      />
    );
  }

  renderAddJdProductModal() {
    const { showAddJdProductModal } = this.state;
    return (
      <Modal
        key="add-jd-product"
        visible={showAddJdProductModal}
        onCancel={() => {
          this.setState({ showAddJdProductModal: false });
        }}
        footer={null}
      >
        <Search
          placeholder={this.i18n.t("productEdit.numberHint")}
          onSearch={this.onAddJdProduct}
          style={{ width: 300 }}
          size="large"
        />
      </Modal>
    );
  }

  renderDouyinOrderModal() {
    const { showDouyinOrderProduct, takingDouyinOrder } = this.state;
    return (
      <Modal
        key="douyin-order"
        visible={showDouyinOrderProduct}
        title={showDouyinOrderProduct?.zh_name}
        width={800}
        onOk={this.onTakeDouyinOrder}
        onCancel={() => {
          this.setState({ showDouyinOrderProduct: false });
        }}
        confirmLoading={takingDouyinOrder}
      >
        <Form ref={this.formRef}>
          <Form.Item
            label="抖音ID"
            name="douyinorder_id"
            rules={[
              {
                required: true,
                message: "请输入抖音ID",
              },
            ]}
          >
            <Input style={{ width: 200 }} placeholder="请输入抖音ID" />
          </Form.Item>
          <Form.Item
            label="地址"
            name="address"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="数量"
            name="quantity"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={1}
          >
            <InputNumber min={1} step={1} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  _renderMain() {
    return (
      <Layout className={`list-main ${this.isJdShop() && "jd"}`}>
        {this._renderMainSider()}
        {this._renderMainJDSider()}
        {this._renderMainContent()}
      </Layout>
    );
  }

  _renderMainJDSider() {
    if (!this.isJdShop()) return null;
    const { windowSizes } = this.props;
    if (windowSizes?.width < 768) return null;
    const { jdCategories, openKeys, currentCat } = this.state;
    if (!jdCategories || !jdCategories.length) {
      return <Layout.Sider width={240} />;
    }

    const keyPath = this.getSelectedKeyPath();
    const hilightStyle =
      currentCat && currentCat.id === "0"
        ? { color: "#fff", backgroundColor: "#419cb4" }
        : {};

    const siderStyle = {
      height: "80vh",
      position: "fixed",
      zIndex: 100,
      overflowY: "scroll",
      top: 132,
    };

    if (this.isLocaleZH()) {
      siderStyle.left = 248;
    } else {
      siderStyle.right = 248;
    }

    const allCount = jdCategories.reduce((sum, cat) => {
      return (sum += cat.count_all);
    }, 0);
    const allOnSaleCount = jdCategories.reduce((sum, cat) => {
      return (sum += cat.count_onsale);
    }, 0);

    return (
      <Layout.Sider width={240} style={siderStyle}>
        <Menu
          defaultOpenKeys={keyPath}
          defaultSelectedKeys={keyPath}
          openKeys={openKeys}
          onOpenChange={this.onOpenChange}
          mode="inline"
          theme="dark"
          onClick={this.onClickJdCat}
        >
          <Menu.SubMenu
            key="0"
            title={
              <span>
                <span>{this.i18n.t("proxy.all")}</span>
                <span>{`(${allOnSaleCount}/${allCount})`}</span>
              </span>
            }
            style={hilightStyle}
            onTitleClick={this.onClickAllCat}
          />
          {jdCategories.map((i) => (
            <Menu.SubMenu
              key={`${i.id}`}
              title={
                <span>
                  <span>{this.i18n.getPropNameByLocale(i, "name")}</span>
                  <span>{`(${i.count_onsale}/${i.count_all})`}</span>
                </span>
              }
              onTitleClick={this.onTitleClick}
            >
              {i.sub_categories.map((sub) => (
                <Menu.SubMenu
                  key={`${sub.id}`}
                  title={
                    <span>
                      <span>{this.i18n.getPropNameByLocale(sub, "name")}</span>
                    </span>
                  }
                  onTitleClick={() =>
                    this.onClickJdCat({
                      key: sub.id,
                      keyPath: [`${sub.id}`, `${i.id}`],
                    })
                  }
                >
                  {sub.sub_categories.map((subb) => (
                    <Menu.Item key={`${subb.id}`}>
                      {this.i18n.getPropNameByLocale(subb, "name")}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ))}
            </Menu.SubMenu>
          ))}
        </Menu>
      </Layout.Sider>
    );
  }

  _renderMainSider() {
    if (this.isJdShop()) return null;

    let { allCats, shopCats, currentCat } = this.state;
    if (allCats) {
      return (
        <Layout.Sider
          className="list-main-sider"
          breakpoint="lg"
          collapsedWidth="30"
        >
          <div className="cats-header">
            <span className="text">{this.i18n.t("product.catsTitle")}</span>
            <Button
              icon={<SettingOutlined />}
              onClick={this.onSideCatManageClick}
            >
              {this.i18n.t("product.catsManage")}
            </Button>
          </div>
          <div className="cats-list">
            {shopCats && shopCats.length > 0 ? (
              <Menu
                onClick={this.onSideCatClick}
                mode="inline"
                defaultSelectedKeys={[currentCat.key]}
                selectedKeys={[currentCat.key]}
              >
                {shopCats.map((i) => {
                  if (
                    Array.isArray(i.sub_categories) &&
                    i.sub_categories.length > 0
                  ) {
                    return (
                      <SubMenu
                        key={`${i.id}`}
                        style={{
                          color:
                            currentCat.key === `${i.id}`
                              ? "#419cb4"
                              : "rgba(0, 0, 0, 0.65)",
                        }}
                        title={this.i18n.getPropNameByLocale(i, "name")}
                        onTitleClick={() =>
                          this.onSideCatClick({ key: `${i.id}` })
                        }
                      >
                        {i.sub_categories.map((s) => (
                          <Menu.Item key={`${s.id}`}>
                            {this.i18n.getPropNameByLocale(s, "name")}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={`${i.id}`}>
                      {this.i18n.getPropNameByLocale(i, "name")}
                    </Menu.Item>
                  );
                })}
              </Menu>
            ) : (
              <div className="cats-message">
                {this.i18n.t("product.catsEmpty")}
              </div>
            )}
          </div>
        </Layout.Sider>
      );
    } else {
      if (this.state.loadingCats) {
        return (
          <Layout.Sider className="list-main-sider" width={240}>
            <div className="cats-message">
              {this.i18n.t("product.catsLoading")}
            </div>
          </Layout.Sider>
        );
      }

      return (
        <Layout.Sider className="list-main-sider" width={240}>
          <div className="cats-message">{this.i18n.t("product.catsNull")}</div>
        </Layout.Sider>
      );
    }
  }

  _renderMainContent() {
    return (
      <Layout className="list-main-content">
        {this._renderMainContentFilter()}
        {this._renderMainContentRevenueFilter()}
        {this._renderMainContentPriceFilter()}
        {this._renderMainContentActions()}
        {this._renderMainContentData()}
      </Layout>
    );
  }

  _renderMainContentFilter() {
    let filters = ProductList.ContentFilters;

    return (
      <Layout.Header className="list-main-content-filter">
        {filters.map((filter, index) => {
          let items = [];

          let className =
            filter.key === this.state.currentFilter.key ? "primary" : "default";
          if (filter.key.indexOf("revenue") !== -1) {
            className =
              filter.key === this.state.currentSort.key ? "primary" : "default";
          }
          items.push(
            <Button
              size="small"
              style={{ borderWidth: 0, boxShadow: "none" }}
              key={`filter-${index}`}
              type={className}
              onClick={() => this.onContentFilterClick(filter)}
            >
              {this.i18n.t(filter.name)}
            </Button>
          );

          if (index !== filters.length - 1) {
            items.push(
              <Divider key={`filter-divider-${index}`} type="vertical" />
            );
          }

          return items;
        })}
      </Layout.Header>
    );
  }

  _renderMainContentRevenueFilter() {
    if (
      !this.isBBShopMerchant() &&
      !this.isThirdPartyShopHasWeapp() &&
      !this.isDabazaShop()
    )
      return null;
    let filters = ProductList.RevenueFilters;

    return (
      <Layout.Header className="list-main-content-filter">
        {filters.map((filter, index) => {
          let items = [];
          const className =
            filter.key === this.state.currentSort.key ? "primary" : "default";
          items.push(
            <Button
              size="small"
              style={{ borderWidth: 0, boxShadow: "none" }}
              key={`filter-${index}`}
              type={className}
              onClick={() => this.onContentSortClick(filter)}
            >
              {this.i18n.t(filter.name)}
            </Button>
          );

          if (index !== filters.length - 1) {
            items.push(
              <Divider key={`filter-divider-${index}`} type="vertical" />
            );
          }

          return items;
        })}
      </Layout.Header>
    );
  }

  _renderMainContentPriceFilter() {
    if (!this.isBBShopMerchant()) return null;

    const { startPrice, endPrice } = this.state;
    return (
      <Layout.Header className="list-main-content-filter">
        {this.i18n.t("product.filter.price_start")}
        <Input
          value={startPrice}
          onChange={this.onChangeStartPrice}
          style={{ width: "100px" }}
          onPressEnter={this.onBlurPriceInput}
          allowClear
        />
        <span>-</span>
        {this.i18n.t("product.filter.price_end")}
        <Input
          value={endPrice}
          onChange={this.onChangeEndPrice}
          style={{ width: "100px" }}
          onPressEnter={this.onBlurPriceInput}
          allowClear
        />
      </Layout.Header>
    );
  }

  _renderMainContentActions() {
    let batchAvailable = this.isBatchActionAvailable();

    return (
      <Layout.Header className="list-main-content-actions">
        <Checkbox
          onChange={this.onContentActionCheckAllChange}
          checked={this.isAllSelected()}
        >
          {this.i18n.t("product.selectAll")}
        </Checkbox>
        <Button
          size="small"
          disabled={!batchAvailable}
          onClick={() => this.onContentActionClick("BATCH_UP")}
        >
          {this.i18n.t("product.batchOnShelf")}
        </Button>
        <Button
          size="small"
          disabled={!batchAvailable}
          onClick={() => this.onContentActionClick("BATCH_DOWN")}
        >
          {this.i18n.t("product.batchOffShelf")}
        </Button>
      </Layout.Header>
    );
  }

  _renderMainContentData() {
    return (
      <Layout.Content className="list-main-content-data jd">
        {this._renderMainContentDataProducts()}
        {this._renderMainContentDataPagination()}
      </Layout.Content>
    );
  }

  _renderMainContentDataProducts() {
    let products = this.state.products;
    if (products) {
      let productsData = products.data;
      if (productsData && productsData.length > 0) {
        return (
          <Spin
            spinning={this.state.loadingProducts || this.state.updatingProducts}
          >
            <div className="content-data-wrap">
              <Row>
                {productsData.map((product, index) => {
                  return (
                    <ProductListItem
                      key={`product-${index}`}
                      bbShopMerchant={this.isBBShopMerchant()}
                      isJdShop={this.isJdShop()}
                      showExtraContent={
                        (this.isThirdPartyShopHasWeapp() &&
                          this.shopHasAgents()) ||
                        this.isAdminWithDabazaShop()
                      }
                      isThirdPartyShop={
                        this.isThirdPartyShop() || this.isDabazaShop()
                      }
                      merchantSettings={this.props.settings}
                      product={product}
                      onActionClick={(action, data) =>
                        this.onProductItemActionClick(index, action, data)
                      }
                    />
                  );
                })}
              </Row>
            </div>
          </Spin>
        );
      } else {
        return (
          <div className="content-data-wrap">
            <div className="content-data-wrap-message">
              {this.i18n.t("product.productsEmpty")}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.loadingProducts) {
        return (
          <div className="content-data-wrap">
            <div className="content-data-wrap-message">
              {this.i18n.t("product.loadingProductsData")}
            </div>
          </div>
        );
      }

      return (
        <div className="content-data-wrap">
          <div className="content-data-wrap-message">
            {this.i18n.t("product.productsEmpty")}
          </div>
        </div>
      );
    }
  }

  _renderMainContentDataPagination() {
    let products = this.state.products;
    if (products && products.data && products.data.length > 0) {
      const countPerPage = this.isJdShop()
        ? ["20", "50", "100"]
        : ["10", "20", "30"];
      return (
        <div className="content-data-pagination">
          <Pagination
            showSizeChanger
            showQuickJumper
            total={products.total}
            current={this.state.currentPage}
            pageSizeOptions={countPerPage}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  _renderModals() {
    let items = [];
    items.push(this._renderCategoryModal());
    items.push(this._renderDiscountProductModal());
    items.push(this.renderAddJdProductModal());
    items.push(this.renderDouyinOrderModal());
    return items;
  }

  renderCategoryInput() {
    const { editCat } = this.state;
    return (
      <Modal
        visible={editCat.id || editCat.parentId}
        okText={this.i18n.t("product.cats.save")}
        cancelText={this.i18n.t("product.cats.cancel")}
        onOk={this.onSubmitCatEdit}
        onCancel={this.onCancelCatEdit}
        title={this.getCatInputTitle()}
        style={{ zIndex: 1050 }}
        key="cat-modal"
        forceRender
      >
        <Form ref={this.catInputForm}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={this.i18n.t("product.cats.zhName")}
                name="cat_zh_name"
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("cat_ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          cat_zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="cat_ug_name"
                label={this.i18n.t("product.cats.ugName")}
              >
                <Input
                  dir="rtl"
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("cat_zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          cat_ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item
                label={this.i18n.t("shows.type.image")}
                name="image"
                valuePropName="fileList"
                getValueFromEvent={(e) => this.util.normFile4Img(e)}
              >
                <Upload
                  accept="image/*"
                  multiple={false}
                  listType="picture-card"
                  beforeUpload={onBeforeUpload}
                  customRequest={this.uploadImages}
                >
                  {(!getFieldValue("image") ||
                    !getFieldValue("image").length) && (
                    <span>
                      <UploadOutlined /> {i18n.t("sales.upload")}
                    </span>
                  )}
                </Upload>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label={this.i18n.t("showsEdit.orderLabel")}
            name="order"
            extra={this.i18n.t("sales.ad.orderPlace")}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label={this.i18n.t("sales.coupons.status")}
            name="status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  _renderCategoryModal() {
    if (this.state.showCatModal) {
      let modalContent = (
        <Transfer
          titles={[
            this.i18n.t("product.catsModalAvailable"),
            this.i18n.t("product.catsModalSelected"),
          ]}
          dataSource={this.state.allCats}
          targetKeys={this.state.shopCatsKeys}
          operations={[
            this.i18n.t("product.catsModalAdd"),
            this.i18n.t("product.catsModalRemove"),
          ]}
          rowKey={(record) => record.id}
          render={(item) => {
            let inShopCat = this.state.shopCats.find(
              (cat) => item.id === cat.id
            );
            let productsCount = 0;
            if (inShopCat) {
              productsCount = inShopCat.products_count;
            }
            productsCount =
              productsCount > 0
                ? this.i18n.t("product.catsModalInclude", {
                    count: productsCount,
                  })
                : "";

            return `${this.i18n.getPropNameByLocale(
              item,
              "name"
            )}${productsCount}`;
          }}
          onSelectChange={this.onTransferSelectChange}
          onChange={this.onTransferChange}
        />
      );
      if (this.isThirdPartyShop() || this.isDabazaShop()) {
        const { shopCats } = this.state;
        const [allCat, ...otherCats] = shopCats; //eslint-disable-line

        const treeData = otherCats.map((i, idx) => ({
          key: `${i.id}`,
          title: (
            <Col>
              <Popover
                placement={this.i18n.isLocaleRTL() ? "left" : "right"}
                title={this.i18n.t("product.cats.actions")}
                content={
                  <Row>
                    <Button onClick={() => this.onAddChildCatClicked(i.id)}>
                      {this.i18n.t("product.cats.addChild")}
                    </Button>
                    <Button
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      onClick={() => this.onEditChildCatClicked(i)}
                    >
                      {this.i18n.t("product.cats.edit")}
                    </Button>
                    <Button
                      type="danger"
                      onClick={() => this.onDeleteChildCatClicked(i.id)}
                    >
                      {this.i18n.t("product.cats.delete")}
                    </Button>
                  </Row>
                }
              >
                <Space
                  direction="horizontal"
                  style={{ backgroundColor: i.status === 1 ? "" : "#eeeeee" }}
                >
                  {i.image && (
                    <Avatar src={i.image} size="small" shape="circle" />
                  )}
                  <Text>
                    {i.zh_name}/{i.ug_name}
                  </Text>
                </Space>
              </Popover>
            </Col>
          ),
          children:
            otherCats[idx].sub_categories &&
            otherCats[idx].sub_categories.map((i) => ({
              key: `${i.id}`,
              title: (
                <Col>
                  <Popover
                    placement={this.i18n.isLocaleRTL() ? "left" : "right"}
                    title={this.i18n.t("product.cats.actions")}
                    content={
                      <Row>
                        <Button
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          onClick={() => this.onEditChildCatClicked(i)}
                        >
                          {this.i18n.t("product.cats.edit")}
                        </Button>
                        <Button
                          type="danger"
                          onClick={() => this.onDeleteChildCatClicked(i.id)}
                        >
                          {this.i18n.t("product.cats.delete")}
                        </Button>
                      </Row>
                    }
                  >
                    <Space direction="horizontal">
                      {i.image && (
                        <Avatar src={i.image} size="small" shape="circle" />
                      )}
                      <Text>
                        {i.zh_name}/{i.ug_name}
                      </Text>
                    </Space>
                  </Popover>
                </Col>
              ),
            })),
        }));
        modalContent = (
          <div>
            <Row gutter={18} style={{ marginTop: "30px" }}>
              <Col span={12}>
                <Search
                  placeholder={this.i18n.t("product.cats.search")}
                  onSearch={(value) => console.log(value)}
                  enterButton
                />
              </Col>
              <Col span={6}>
                <Button type="primary" onClick={() => this.onAddMainCatInput()}>
                  {this.i18n.t("product.cats.add")}
                </Button>
              </Col>
            </Row>
            <Tree
              showLine
              defaultExpandAll
              treeData={treeData}
              style={{ marginTop: "10px" }}
            />
          </div>
        );
      }

      return (
        <Modal
          className="route-product-list-modal-category"
          key="route-product-list-modal-category"
          visible={this.state.showCatModal}
          confirmLoading={this.state.updatingCats}
          okText={this.i18n.t("product.catsModalOkText")}
          onOk={
            this.isThirdPartyShop() ? this.onCatModalCancel : this.onCatModalOk
          }
          cancelText={this.i18n.t("product.catsModalCancelText")}
          onCancel={this.onCatModalCancel}
          title={this.i18n.t("product.catsModalTitle")}
        >
          {modalContent}
          {this.renderCategoryInput()}
        </Modal>
      );
    }

    return null;
  }

  /* user methods */

  onAddMainCatInput() {
    this.setState({
      editCat: {
        id: -1,
        parentId: null,
      },
    });
  }

  onAddChildCatClicked(id) {
    if (this.catInputForm && this.catInputForm.current) {
      this.catInputForm.current.resetFields();
    }
    this.setState({
      editCat: {
        parentId: id,
        id: null,
      },
    });
  }

  onEditChildCatClicked(editCat) {
    this.catInputForm &&
      this.catInputForm.current.setFieldsValue({
        cat_ug_name: editCat.ug_name,
        cat_zh_name: editCat.zh_name,
        image: this.util.getDefaultImages(editCat.image),
        status: editCat.status === 1,
        order: editCat.order ?? 0,
      });
    this.setState({
      editCat: {
        id: editCat.id,
        parentId: editCat.parent_id,
      },
    });
  }

  onAddJdProduct = async (value) => {
    const url = `/jd/product/${value}`;
    try {
      const res = Request.get(url);
      if (res.result) {
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      console.log("ProductList -> onAddJdProduct -> error", error);
      this.showMessage(error.message, Base.Message.error);
    }
  };

  onDeleteChildCatClicked(id) {
    if (!id) return;
    this.showModal(
      this.i18n.t("product.cats.deleteWarning"),
      "confirm",
      async () => {
        const token = this.getUserToken();
        if (!token) return;
        const api = this.api.product.deleteMerchantCategories;
        const apiConfig = {
          token,
          path: {
            id,
          },
        };
        try {
          const res = await api.run(apiConfig);
          this.showMessage(res.message);
          if (res.result) {
            this.loadCategories();
          }
        } catch (error) {
          console.log("onDeleteChildCatClicked -> error", error);
        }
      }
    );
  }

  onCancelCatEdit = () => {
    if (this.catInputForm) {
      this.catInputForm.current.resetFields();
    }
    this.setState({
      editCat: {
        id: null,
        parentId: null,
      },
    });
  };

  uploadImages = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");

    const token = this.getUserToken();
    if (!token) onError("no user token");

    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "main-page-nav",
    };

    try {
      const res = await this.api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  onSubmitCatEdit = () => {
    const token = this.getUserToken();
    if (!token) return;
    let api = this.api.product.addMerchantCategories;
    this.catInputForm.current.validateFields().then(async (values) => {
      const { editCat } = this.state;
      const apiConfig = {
        token,
        data: {
          id: editCat.id && editCat.id !== -1 ? editCat.id : null,
          ug_name: values.cat_ug_name,
          zh_name: values.cat_zh_name,
          parent_id: editCat.parentId,
          status: values.status ? 1 : 0,
          order: values.order,
        },
      };

      if (editCat.id && editCat.id !== -1) {
        api = this.api.product.updateMerchantCategories;
        apiConfig.path = { id: editCat.id };
      }

      const { image } = values;
      if (!image || !image[0]) {
        apiConfig.data.image = "";
      } else {
        if (image[0] && image[0].uid !== -1 && image[0].response) {
          apiConfig.data.image = image[0].response.path;
        }
      }

      try {
        const res = await api.run(apiConfig);
        this.showMessage(res.message);
        if (res.result) {
          this.loadCategories();
          this.setState({
            editCat: {
              id: null,
              parentId: null,
            },
          });
        }
      } catch (error) {
        console.log("onDeleteChildCatClicked -> error", error);
        this.handleApiError(api, error);
      }
    });
  };

  //抖音下单
  onTakeDouyinOrder = () => {
    const token = this.getUserToken();
    if (!token) return;
    let api = this.api.douyin.addDouyinOrder;
    this.formRef.current.validateFields().then(async (values) => {
      const { showDouyinOrderProduct } = this.state;
      const apiConfig = {
        token,
        data: {
          douyinorder_id: values.douyinorder_id,
          address: values.address,
          details: [
            {
              original_amount: values.quantity,
              product_id: showDouyinOrderProduct.id,
            },
          ],
        },
      };

      try {
        this.setState({ takingDouyinOrder: true });
        const res = await api.run(apiConfig);
        this.setState({ takingDouyinOrder: false });
        this.showMessage(res.message);
        if (res.result) {
          this.setState({
            showDouyinOrderProduct: false,
          });
          this.formRef.current.resetFields();
        }
      } catch (error) {
        this.setState({ takingDouyinOrder: false });
        console.log("onTakeDouyinOrder -> error", error);
        this.handleApiError(api, error);
      }
    });
  };

  getCatInputTitle() {
    const { editCat } = this.state;
    if (editCat.id === -1 && !editCat.parentId)
      return this.i18n.t("product.cats.add");
    if (!editCat.id && editCat.parentId)
      return this.i18n.t("product.cats.addChild");
    if (editCat.id !== -1) return this.i18n.t("product.cats.edit");
    return "";
  }

  onHeaderCreateClick = () => {
    if (this.isJdShop()) {
      this.setState({ showAddJdProductModal: true });
    } else {
      let cats = this.state.shopCats;
      cats && cats.shift();
      if (!cats || cats.length <= 0) {
        this.showModal(
          this.i18n.t("product.cats.catWarning"),
          Base.Modal.warning
        );
        return;
      }
      this.props.router.push({
        pathname: "/product/edit",
        state: {
          categories: cats,
          currentCat: this.state.currentCat.id,
        },
      });
    }
  };

  onHeaderSearchChange = (e) => {
    this.setState({ headerSearchKey: e.target.value });
  };

  onChangeStartPrice = (e) => {
    this.setState({ startPrice: e.target.value });
  };

  onChangeEndPrice = (e) => {
    this.setState({ endPrice: e.target.value });
  };

  onHeaderSearchTypeChange = (type) => {
    this.setRouterQuery({ searchType: type });
    this.setState({ searchType: type });
  };

  onHeaderSearchClick = () => {
    if (!this.state.headerSearchKey.trim()) return;
    this.setRouterQuery({
      keyword: this.state.headerSearchKey,
    });
    if (this.state.currentPage === 1) {
      this.loadProducts();
    } else {
      this.setState({ currentPage: 1 });
    }
  };

  onBlurPriceInput = () => {
    const { startPrice, endPrice } = this.state;

    if (startPrice) {
      this.setRouterQuery({
        price_start: startPrice,
      });
    }

    if (endPrice) {
      this.setRouterQuery({
        price_end: endPrice,
      });
    }

    if (startPrice && endPrice && Number(startPrice) > Number(endPrice)) {
      this.showMessage(this.i18n.t("product.priceRangeAlert"));
      return;
    }

    if (this.state.currentPage === 1) {
      this.loadProducts();
    } else {
      this.setState({ currentPage: 1 });
    }
  };

  onSideCatManageClick = () => {
    this.setState({ showCatModal: true });
  };

  onTransferSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    if (this.transferSelectedCatsHasProducts(targetSelectedKeys)) {
      this.showMessage(
        this.i18n.t("product.catsModalIncludeWarning"),
        Base.Message.warning
      );
    }
  };

  onTransferChange = (nextTargetKeys, direction, moveKeys) => {
    if (
      direction === "left" &&
      this.transferSelectedCatsHasProducts(moveKeys)
    ) {
      this.showMessage(
        this.i18n.t("product.catsModalIncludeWarning"),
        Base.Message.error
      );
      return;
    }

    this.setState({ shopCatsKeys: nextTargetKeys });
  };

  onCatModalOk = () => {
    this.updateShopCats();
  };

  onCatModalCancel = () => {
    this.setState({ showCatModal: false });
  };

  onSideCatClick = (cat) => {
    this.setState({
      currentCat: { key: cat.key, id: cat.key },
      currentPage: 1,
    });
  };

  onContentFilterClick = (filter) => {
    if (!this.state.currentCat) return;
    this.setState({ currentFilter: filter, currentPage: 1 });
  };

  onContentSortClick = (filter) => {
    if (!this.state.currentCat) return;
    this.setState({ currentSort: filter, currentPage: 1 });
  };

  onContentActionCheckAllChange = (e) => {
    if (!this.state.currentCat) return;
    this.toggleAllSelectedState();
  };

  onContentActionClick = (type) => {
    if (!this.state.currentCat) return;

    switch (type) {
      case "BATCH_UP": {
        this.batchUpDown(true);
        break;
      }
      case "BATCH_DOWN": {
        this.batchUpDown(false);
        break;
      }
      case "BATCH_DELETE": {
        break;
      }
      default: {
        break;
      }
    }
  };

  onProductItemActionClick(index, action, data) {
    if (index < 0 || index >= this.state.pageSize) return;

    let products = this.state.products;
    let productsData = products ? products.data : null;
    let product = productsData ? productsData[index] : null;

    if (!product) return;

    switch (action) {
      case ProductListItem.Actions.Select: {
        this.toggleProductSelectState(index, data);
        break;
      }
      case ProductListItem.Actions.AddTag: {
        break;
      }
      case ProductListItem.Actions.OnShell: {
        this.updateCustomProduct({ ...product, status: 1 });
        break;
      }
      case ProductListItem.Actions.OffShel: {
        this.updateCustomProduct({ ...product, status: 0 });
        break;
      }
      case ProductListItem.Actions.OnFreeShipping: {
        this.updateCustomProduct({ ...product, is_free_shipping: true });
        break;
      }
      case ProductListItem.Actions.OffFreeShipping: {
        this.updateCustomProduct({ ...product, is_free_shipping: false });
        break;
      }
      case ProductListItem.Actions.Edit: {
        if (this.isJdShop()) {
          this.props.router.push({
            pathname: "/product/edit",
            state: {
              edit: true,
              product,
            },
          });
          return;
        }

        let cats = this.state.shopCats;
        cats.shift();

        if (!cats || cats.length <= 0) {
          this.showModal(
            this.i18n.t("product.cats.catEditWarning"),
            Base.Modal.warning
          );
          return;
        }

        this.props.router.push({
          pathname: "/product/edit",
          state: {
            edit: true,
            categories: cats,
            product: product,
          },
        });
        break;
      }
      case ProductListItem.Actions.EditConfirm: {
        this.updateCustomProduct({
          ...product,
          [`${this.isLocaleZH() ? "zh" : "ug"}_name`]: data.name,
          price: data.price,
          purchase_price: data.purchase_price,
          quantity: data.quantity,
          order: data.order,
          product_no: data.product_no,
        });
        break;
      }
      case ProductListItem.Actions.Delete: {
        this.deleteCustomProduct(product);
        break;
      }
      case ProductListItem.Actions.RemoveDiscount: {
        this.removeDiscount(data);
        break;
      }
      case ProductListItem.Actions.AddDiscount: {
        this.showDiscount(data);
        break;
      }
      case ProductListItem.Actions.ShowDouyinOrder: {
        this.showDouyinOrder(data);
        break;
      }
      case ProductListItem.Actions.Copy: {
        this.copyWeappAddress(data);
        break;
      }
      case ProductListItem.Actions.Clone: {
        let cats = this.state.shopCats;
        cats.shift();
        if (!cats || cats.length <= 0) {
          this.showModal(
            this.i18n.t("product.cats.catEditWarning"),
            Base.Modal.warning
          );
          return;
        }
        this.props.router.push({
          pathname: "/product/edit",
          state: {
            edit: true,
            clone: true,
            categories: cats,
            product,
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  onClickJdCat = ({ key, keyPath }) => {
    this.setRouterQuery({ jdKeyPath: keyPath });
    this.setState({
      currentCat: { id: key },
      currentPage: 1,
    });
  };

  onClickAllCat = () => {
    this.setRouterQuery({ jdKeyPath: ["0"] });
    this.setState({
      currentCat: { id: "0" },
      currentPage: 1,
    });
  };

  onTitleClick = ({ key }) => {
    this.setRouterQuery({ jdKeyPath: [key] });
    this.setState({
      currentCat: { id: key },
      currentPage: 1,
    });
  };

  onOpenChange = (openKeys) => {
    const { jdCategories } = this.state;
    const rootKeys = jdCategories.map((i) => `${i.id}`);

    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (["0"].concat(rootKeys).indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  /* server methods */

  async loadJdCategories() {
    const api = this.api.product.jdCategories;
    const token = this.getUserToken();
    const apiConfig = {
      token,
    };

    this.setState({ loadingCats: true });
    try {
      const res = await api.run(apiConfig);
      if (!this.mounted) return;

      if (res.result) {
        const keyPath = this.getSelectedKeyPath();
        this.setState({
          jdCategories: res.data,
          currentCat: { id: keyPath[0] },
          openKeys: keyPath,
        });
      } else {
        this.showMessage(res.message);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingCats: false });
    }
  }
  async getMerchantSettings() {
    try {
      const { setMerchantSettings, user } = this.props;
      const token = this.getUserToken();
      if (!token) return;

      const api = this.api.user.merchantSettings;
      const apiConfig = {
        token,
        path: {
          merchant_id: user.merchant.id,
        },
      };
      requestAnimationFrame(() => {
        this.setState({ drawerVisible: false });
      });

      const res = await api.run(apiConfig);
      if (res.result) {
        setMerchantSettings(res.data);
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      this.handleApiError(error);
    }
  }
  async loadCategories() {
    if (this.state.loadingCats) return;

    let token = this.getUserToken();
    if (!token) return;

    if (this.isJdShop()) {
      this.loadJdCategories();
      return;
    }

    let api = this.api.product.categories;
    if (this.isThirdPartyShop()) {
      api = this.api.product.merchantCategories;
    }

    let apiConfig = {
      token: token,
    };

    this.setState({ loadingCats: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        let allCats = res.data.all_categories;
        let shopCats = res.data.merchant_categories;

        if (this.isThirdPartyShop()) {
          shopCats = res.data;
          allCats = [];
        }

        let currentCat = null;
        let shopCatsKeys = null;
        if (shopCats && shopCats.length > 0) {
          shopCatsKeys = [];
          let totalProducts = 0;
          for (let i = 0; i < shopCats.length; i++) {
            shopCats[i].key = `${shopCats[i].id}`;
            shopCatsKeys[i] = shopCats[i].id;
            if (shopCats[i].products_count) {
              totalProducts += shopCats[i].products_count;
            }
          }

          shopCats = [{ ...AllCat, products_count: totalProducts }].concat(
            shopCats
          );
          currentCat = this.getRouterQueryItemByKey(shopCats, "cat");
        } else {
          shopCats = [{ ...AllCat, products_count: 0 }];
          currentCat = this.getRouterQueryItemByKey(shopCats, "cat");
          shopCatsKeys = [];
        }

        this.setState({
          allCats: allCats,
          shopCats: shopCats,
          currentCat: currentCat,
          shopCatsKeys: shopCatsKeys,
        });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ loadingCats: false });
    }
  }

  async loadProducts() {
    if (this.state.loadingProducts) return;

    let token = this.getUserToken();
    if (!token) return;

    if (!this.state.currentCat) return;

    let api = this.api.product.products;
    if (this.isJdShop()) {
      api = this.api.product.jdProducts;
    }
    let apiConfig = {
      token: token,
      query: {
        category_id: this.state.currentCat.id,
        page: this.state.currentPage,
        count: this.state.currentPageSize,
        keyword: this.state.headerSearchKey,
        search_type: this.state.searchType,
        sort: this.state.currentSort.key,
        status: this.state.currentFilter.key,
        price_start: this.state.startPrice > 0 ? this.state.startPrice : "",
        price_end: this.state.endPrice > 0 ? this.state.endPrice : "",
      },
    };

    this.setState({ loadingProducts: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ products: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ loadingProducts: false });
    }
  }

  showDiscount(product) {
    this.setState({
      showDiscountProductModal: true,
      selectedProduct: product,
    });
  }

  showDouyinOrder(product) {
    this.setState({
      showDouyinOrderProduct: product,
    });
  }

  copyWeappAddress(product) {
    const user = this.getUser();
    const number = `pages/product-detail/index?merchantId=${user.merchant.id}&productId=${product.id}&fromLive=true`;
    if (!number) return;

    let target = document.createElement("div");
    target.id = "shipNumberCopy";
    target.style.opacity = "0";
    target.innerText = number;
    document.body.appendChild(target);

    try {
      let range = document.createRange();
      range.selectNode(target);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      this.showMessage(
        this.i18n.t("order.copyShipNoSuccess"),
        Base.Message.success
      );
    } catch (error) {
      this.showMessage(
        this.i18n.t("order.copyShipNoFailed"),
        Base.Message.error
      );
    } finally {
      target.parentElement.removeChild(target);
    }
  }
  async removeDiscount(id) {
    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.removeDiscount;
    let apiConfig = {
      token: token,
      path: {
        id: id,
      },
    };

    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.loadProducts();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
    }
  }

  async addDiscount(id, discountedPrice) {
    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.addDiscount;
    let apiConfig = {
      token: token,
      data: {
        product_id: id,
        discounted_price: discountedPrice * 100,
      },
    };

    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ showDiscountProductModal: false });
        this.loadProducts();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
    }
  }

  async saveShopCatsOrder(order) {
    if (this.state.updatingCats) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.saveCategoriesOrder;
    let apiConfig = {
      token: token,
      data: {
        order: order,
      },
    };

    this.setState({ updatingCats: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({
          showCatModal: false,
          updatingCats: false,
        });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ updatingCats: false });
    }
  }

  async updateShopCats() {
    if (this.state.updatingCats) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.updateCategories;
    let apiConfig = {
      token: token,
      data: {
        categories: this.state.shopCatsKeys,
      },
    };

    this.setState({ updatingCats: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        let shopCats = res.data;

        let totalProducts = 0;
        for (let i = 0; i < shopCats.length; i++) {
          totalProducts += shopCats[i].products_count;
        }

        shopCats = [{ ...AllCat, products_count: totalProducts }].concat(
          shopCats
        );
        let currentCat = shopCats.find(
          (cat) => cat.id === this.state.currentCat.id
        );
        if (!currentCat) {
          currentCat = shopCats[0];
        }

        this.setState({
          shopCats: shopCats,
          currentCat: currentCat,

          showCatModal: false,
          updatingCats: false,
        });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ updatingCats: false });
    }
  }

  deleteCustomProduct(product) {
    this.showModal(
      this.i18n.t("productEdit.delete"),
      Base.Modal.confirm,
      async () => {
        if (!product) return;

        if (this.state.updatingProduct) return;

        let token = this.getUserToken();
        if (!token) return;

        let api = this.api.product.jdCustomDelete;
        let apiConfig = {
          token: token,
          path: {
            id: product.id,
          },
        };

        try {
          this.showMessage(
            this.i18n.t("productEdit.updatingProductSuccess"),
            Base.Message.loading
          );
          this.setState({ updatingProduct: true });

          let res = await api.run(apiConfig);

          if (!this.mounted) return;

          if (res.result) {
            this.showMessage(res.message, Base.Message.success);
            this.loadProducts();
          } else {
            this.showMessage(res.message, Base.Message.error);
          }
        } catch (error) {
          if (!this.mounted) return;
          this.handleApiError(api, error);
        } finally {
          if (!this.mounted) return;
          this.setState({ updatingProduct: false });
        }
      }
    );
  }

  async updateCustomProduct(product) {
    if (!product) return;

    if (this.state.updatingProducts) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.customUpdate;
    if (this.isJdShop()) {
      api = this.api.product.jdCustomUpdate;
    }

    let apiConfig = {
      token: token,
      path: {
        id: product.id,
      },
      data: {
        zh_name: product.zh_name,
        ug_name: product.ug_name,
        ug_detail_description: product.ug_detail_description,
        zh_detail_description: product.zh_detail_description,
        category_id: product.category_id,
        quantity: product.quantity,
        price: product.is_flash_sale ? product.original_price : product.price,
        purchase_price: product.purchase_price,
        status: product.status,
        is_free_shipping: product.is_free_shipping,
        recommended: product.recommended ? 1 : 0,
        order: product.order,
        product_no: product.product_no,
        commission_percent: product.commission_percent,
        parent_commission_percent: product.parent_commission_percent,
      },
    };

    apiConfig.data.images = product.images.map((image) => image.path);

    this.showMessage(
      this.i18n.t("product.updatingProduct"),
      Base.Message.loading
    );
    this.setState({ updatingProducts: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("product.updatingProductSuccess"),
          Base.Message.loading
        );
        this.loadProducts();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ updatingProducts: false });
    }
  }

  async batchUpDown(isUP = false) {
    if (this.state.updatingProducts) return;

    let products = this.getSelectedProductIds();
    if (!products || products.length <= 0) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.batchUpDown;
    let apiConfig = {
      token: token,
      data: {
        products: products,
        status: isUP ? 1 : 0,
      },
    };

    this.showMessage(
      this.i18n.t("product.updatingProduct"),
      Base.Message.loading
    );
    this.setState({ updatingProducts: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("product.updatingProductSuccess"),
          Base.Message.loading
        );
        this.loadProducts();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ updatingProducts: false });
    }
  }

  /* custom methods */

  transferSelectedCatsHasProducts(selectedKeys = []) {
    let shopCats = this.state.shopCats || [];

    for (let i = 0; i < selectedKeys.length; i++) {
      let selectedCat = shopCats.find((cat) => cat.id === selectedKeys[i]);
      if (selectedCat && selectedCat.products_count > 0) return true;
    }

    return false;
  }

  toggleProductSelectState(index, isSelected) {
    let products = this.state.products;
    let productsData = products ? products.data : null;
    let product =
      productsData && productsData.length > 0 ? productsData[index] : null;
    if (product) {
      product.selected = isSelected;
    }

    this.setState({ products: products });
  }

  isAllSelected() {
    let products = this.state.products;
    let productsData = products ? products.data : null;
    if (productsData && productsData.length > 0) {
      let index = 0;
      for (; index < productsData.length; index++) {
        if (!productsData[index].selected) {
          break;
        }
      }

      return index >= productsData.length;
    }
    return false;
  }

  isBatchActionAvailable() {
    let products = this.state.products;
    let productsData = products ? products.data : null;
    if (productsData && productsData.length > 0) {
      let count = 0;
      for (let i = 0; i < productsData.length; i++) {
        if (productsData[i].selected) {
          count++;
        }

        if (count >= 2) {
          return true;
        }
      }
    }
    return false;
  }

  toggleAllSelectedState() {
    let isAllSelected = this.isAllSelected();

    let products = this.state.products;
    let productsData = products ? products.data : null;
    if (productsData && productsData.length > 0) {
      for (let i = 0; i < productsData.length; i++) {
        productsData[i].selected = !isAllSelected;
      }
    }

    this.setState({ products: products });
  }

  getSelectedProductIds() {
    let ids = null;

    let products = this.state.products;
    let productsData = products ? products.data : null;
    if (productsData && productsData.length > 0) {
      for (let i = 0; i < productsData.length; i++) {
        if (productsData[i].selected) {
          if (!ids) ids = [];
          ids.push(productsData[i].id);
        }
      }
    }

    return ids;
  }

  getSelectedKeyPath() {
    const routerKeyPath = this.getFromRouterQuery("jdKeyPath");
    if (routerKeyPath && Array.isArray(routerKeyPath)) {
      return routerKeyPath;
    }
    if (routerKeyPath && typeof routerKeyPath === "string") {
      return [routerKeyPath];
    }
    return ["0"];
  }

  handleDiscountProductModalCancel = () => {
    this.setState({
      showDiscountProductModal: false,
    });
  };

  handleDiscountProductModalConfirm = (id, discountedPrice) => {
    if (!discountedPrice || !id) return;

    this.showMessage(
      this.i18n.t("product.discount.discounting"),
      Base.Message.loading
    );
    this.addDiscount(id, discountedPrice);
  };
  isLocaleZH() {
    return window.location.href.indexOf("/ug/") === -1;
  }
}

export default connect(
  (state) => {
    return {
      user: getUserInfo(state),
      settings: getMerchantSettings(state),
    };
  },
  { setUserMerchantCategories, setMerchantSettings }
)(ProductList);
