import { Button, Image, message, Statistic, Table, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Locale, Request } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";

const i18n = Locale.getInstance();
const { Text } = Typography;

function MembersCard({ router }) {
  const user = useSelector((state) => getUserInfo(state));
  const fetchCards = useRef();
  const [cards, setCards] = useState([]);
  fetchCards.current = async () => {
    try {
      const url = "/member/api/v6/merchant/member-card";
      const res = await Request.get(url, user.token);
      if (res.result) {
        setCards(res.data.data);
      } else {
        message.warn(res.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchCards.current();
  }, []);
  const columns = [
    { key: "zh_name", title: i18n.t("agentLevel.zh"), dataIndex: "zh_name" },
    {
      key: "ug_name",
      title: i18n.t("agentLevel.ug"),
      render: (text, record) => (
        <Text style={{ fontFamily: "Alp Ekran" }}>{record.ug_name}</Text>
      ),
    },
    {
      key: "zh_image",
      title: i18n.t("sales.ad.zhImg"),
      render: (text, record) => (
        <Image src={record.zh_card_image} width={100} height={50} />
      ),
    },
    {
      key: "ug_image",
      title: i18n.t("sales.ad.ugImg"),
      render: (text, record) => (
        <Image src={record.ug_card_image} width={100} height={50} />
      ),
    },
    {
      key: "price",
      title: i18n.t("member.price"),
      render: (text, record) => (
        <Statistic prefix="¥" value={record.price / 100} />
      ),
    },
    {
      key: "points",
      title: i18n.t("member.points"),
      render: (text, record) => <Statistic value={record.points ?? 0} />,
    },
    {
      key: "multiple",
      title: i18n.t("member.multiple"),
      render: (text, record) => (
        <Statistic prefix="x" value={record.points_multiple_by} />
      ),
    },
    {
      key: "discount",
      title: i18n.t("member.discount"),
      render: (text, record) => (
        <Statistic suffix="%" value={record.discount_percent} />
      ),
    },
    {
      key: "actions",
      title: i18n.t("product.cats.actions"),
      align: "center",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            router.push({
              pathname: "/members/editCard",
              state: {
                cardData: record,
              },
            });
          }}
        >
          {i18n.t("product.productItem.actionEdit")}
        </Button>
      ),
    },
  ];
  return (
    <>
      <Button
        onClick={() => {
          router.push({ pathname: "/members/editCard" });
        }}
        type="primary"
        style={{ marginBottom: 20 }}
      >
        {i18n.t("member.addCard")}
      </Button>
      <Table
        dataSource={cards}
        columns={columns}
        rowKey={(record) => `card-${record.id}`}
      />
    </>
  );
}

export default MembersCard;
