export default {
  list: {
    name: "gift list api",
    version: 6,
    url: "gift",
    method: "GET",
  },
  add: {
    name: "add gift api",
    version: 6,
    url: "gift",
    method: "POST",
  },
  update: {
    name: "update gift api",
    version: 6,
    url: "gift/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete gift api",
    version: 6,
    url: "gift/%{id}",
    method: "DELETE",
  },
};
