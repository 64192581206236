export default {
  list: {
    name: "shows list api",
    version: 6,
    url: "damdam",
    method: "GET",
  },
  category: {
    name: "shows cat api",
    version: 6,
    url: "damdam/categories",
    method: "GET",
  },
  add: {
    name: "add shows api",
    version: 6,
    url: "damdam",
    method: "POST",
  },
  update: {
    name: "update shows api",
    version: 6,
    url: "damdam/%{showsId}",
    method: "PUT",
  },
  delete: {
    name: "delete shows api",
    version: 6,
    url: "damdam/%{showsId}",
    method: "DELETE",
  },
};
