import React, { useState, useCallback, useEffect } from "react";
import {
  message,
  List,
  Button,
  Avatar,
  Space,
  Typography,
  Divider,
  Tag,
  Modal,
  Form,
  Input,
  InputNumber,
  Upload,
  Switch,
} from "antd";
import { connect } from "react-redux";
import { SyncOutlined, UploadOutlined } from "@ant-design/icons";
import { ApiManager, Locale, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Search from "antd/lib/input/Search";
import TranslateBtn from "../../components/product/TranslateBtn";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();
const { Text } = Typography;

function onBeforeUpload(file) {
  if (file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error(i18n.t("productEdit.uploadImageSizeWarning"));
    }
    return isLt1M;
  }
  return false;
}

function getTagColor(item) {
  let color;
  //	直播间状态。101：直播中，102：未开始，103已结束，104禁播，105：暂停，106：异常，107：已过期
  switch (item.status) {
    case 101:
      color = "green";
      break;
    case 102:
      color = "red";
      break;
    case 103:
      color = "default";
      break;
    default:
      color = "processing";
      break;
  }

  return color;
}

function LiveList({ user }) {
  const [listData, setListData] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [form] = Form.useForm();

  const fetchList = useCallback(
    async (page = 1, keyword = "") => {
      const { token } = user;
      if (token) {
        try {
          const apiUrl = api.live.list;
          const apiConfig = {
            token,
            query: { page, count: 10, keyword },
          };

          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setListData(res.data);
          } else {
            message.warn(res.message);
          }
        } catch (error) {
          message.error(error.message);
        }
      }
    },
    [user]
  );

  const uploadImages = useCallback(
    async ({ file, onSuccess, onError }) => {
      if (!file) onError("invalid images");

      const { token } = user;
      if (!token) onError("no user token");

      const uploadFiles = [{ file }];
      const apiConfig = {
        token,
        uploadFiles,
        uploadType: "room-poster-img",
      };

      try {
        const res = await api.common.uploadImages.run(apiConfig);
        if (res.result) {
          onSuccess(res.data[0]);
        } else {
          onError(res.message);
        }
      } catch (error) {
        onError(error);
      }
    },
    [user]
  );

  async function onGetMediaUrl(item) {
    if (user.token) {
      try {
        const apiUrl = api.live.url;
        const apiConfig = {
          token: user.token,
          path: {
            id: item.id,
          },
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          Modal.success({
            title: i18n.getPropNameByLocale(item, "name"),
            content: <Text copyable>{res.data.url}</Text>,
          });
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }

  const onSyncLiveList = useCallback(async () => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.live.sync;
        const apiConfig = {
          token,
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          message.success(res.message);
          fetchList();
        } else {
          message.warn(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [user, fetchList]);

  const onEditRoom = useCallback(() => {
    const { token } = user;
    if (token) {
      form.validateFields().then(async (values) => {
        const apiUrl = api.live.update;
        const {
          zh_poster_image,
          ug_poster_image,
          zh_recommended_slider_image,
          ug_recommended_slider_image,
          recommended,
          ...otherEl
        } = values;
        const apiConfig = {
          token,
          path: { id: editItem.id },
          data: {
            ...editItem,
            ...otherEl,
            recommended: recommended ? 1 : 0,
          },
        };

        if (
          values.zh_poster_image &&
          values.zh_poster_image[0].uid !== -1 &&
          values.zh_poster_image[0].response
        ) {
          apiConfig.data.zh_poster_image =
            values.zh_poster_image[0].response.path;
        }
        if (
          values.ug_poster_image &&
          values.ug_poster_image[0].uid !== -1 &&
          values.ug_poster_image[0].response
        ) {
          apiConfig.data.ug_poster_image =
            values.ug_poster_image[0].response.path;
        }
        if (
          values.zh_recommended_slider_image &&
          values.zh_recommended_slider_image[0].uid !== -1 &&
          values.zh_recommended_slider_image[0].response
        ) {
          apiConfig.data.zh_recommended_slider_image =
            values.zh_recommended_slider_image[0].response.path;
        }
        if (
          values.ug_recommended_slider_image &&
          values.ug_recommended_slider_image[0].uid !== -1 &&
          values.ug_recommended_slider_image[0].response
        ) {
          apiConfig.data.ug_recommended_slider_image =
            values.ug_recommended_slider_image[0].response.path;
        }

        const res = await apiUrl.run(apiConfig);
        try {
          if (res.result) {
            message.success(res.message);
            setEditItem(null);
            fetchList();
          } else {
            message(res.message);
          }
        } catch (error) {
          message.error(error.message);
        }
      });
    }
  }, [user, fetchList, editItem, form]);

  const onDeleteRoom = useCallback(
    (id) => {
      Modal.confirm({
        title: i18n.t("sales.deleteWarning"),
        okText: i18n.t("base.modal.okText"),
        cancelText: i18n.t("base.modal.cancelText"),
        onOk: async () => {
          const { token } = user;
          if (token) {
            try {
              const apiUrl = api.live.delete;
              const apiConfig = {
                token,
                path: {
                  id,
                },
              };
              const res = await apiUrl.run(apiConfig);
              if (res.result) {
                message.success(res.message);
                fetchList();
              } else {
                message.warning(res.message);
              }
            } catch (error) {
              message.error(error.message);
            }
          }
        },
      });
    },
    [fetchList, user]
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    if (editItem) {
      form && form.resetFields();
    }
  }, [editItem, form]);

  return (
    <>
      <List
        bordered
        loading={!listData}
        pagination={{
          current: listData ? listData.current_page : 1,
          total: listData ? listData.total : 0,
          pageSize: 10,
          onChange: (page) => fetchList(page),
          showQuickJumper: true,
        }}
        style={{ backgroundColor: "#fff" }}
        dataSource={listData ? listData.data : []}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={[
              <Button type="link" onClick={() => onGetMediaUrl(item)}>
                {i18n.t("sales.nav.mediaUrl")}
              </Button>,
              <Button type="link" onClick={() => setEditItem(item)}>
                {i18n.t("product.productItem.actionEdit")}
              </Button>,
              <Button type="link" danger onClick={() => onDeleteRoom(item.id)}>
                {i18n.t("product.productItem.actionDelete")}
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  src={i18n.getPropNameByLocale(item, "share_image")}
                  size="large"
                  shape="square"
                />
              }
              title={i18n.getPropNameByLocale(item, "name")}
              description={
                <Space direction="horizontal">
                  <Text>{i18n.getPropNameByLocale(item, "anchor_name")}</Text>
                  <Divider type="vertical" />
                  <Tag color={getTagColor(item)}>{item.status_label}</Tag>
                  {item.recommended === 1 && (
                    <Tag color="green">{i18n.t("live.rec")}</Tag>
                  )}
                </Space>
              }
            />
          </List.Item>
        )}
        header={
          <Space>
            <Search onSearch={(keyword) => fetchList(1, keyword)} enterButton />
            <Button
              icon={<SyncOutlined />}
              type="primary"
              onClick={onSyncLiveList}
            >
              {i18n.t("live.sync")}
            </Button>
          </Space>
        }
      />
      <Modal
        forceRender
        visible={editItem}
        title={i18n.t("live.edit")}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        onOk={() => onEditRoom(editItem)}
        onCancel={() => {
          setEditItem(null);
        }}
      >
        <Form form={form} labelCol={{ span: 6 }}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="zh_name"
                label={i18n.t("live.zhTitle")}
                initialValue={editItem && editItem.zh_name}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="ug_name"
                label={i18n.t("live.ugTitle")}
                initialValue={editItem && editItem.ug_name}
              >
                <Input
                  dir="rtl"
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            name="order"
            label={i18n.t("live.order")}
            initialValue={editItem && editItem.order}
          >
            <InputNumber />
          </Form.Item>
          {["zh_poster_image", "ug_poster_image"].map((imgKey) => (
            <Form.Item noStyle shouldUpdate key={imgKey}>
              {({ getFieldValue }) => (
                <Form.Item
                  name={imgKey}
                  label={i18n.t(`live.${imgKey}`)}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.ad.imgWarning"),
                    },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => util.normFile4Img(e)}
                  initialValue={
                    editItem && util.getDefaultImages(editItem[imgKey])
                  }
                >
                  <Upload
                    accept="image/*"
                    multiple={false}
                    listType="picture-card"
                    beforeUpload={onBeforeUpload}
                    customRequest={uploadImages}
                  >
                    {getFieldValue(imgKey) && !getFieldValue(imgKey).length && (
                      <UploadOutlined />
                    )}
                  </Upload>
                </Form.Item>
              )}
            </Form.Item>
          ))}
          <Form.Item
            label={i18n.t("live.rec")}
            name="recommended"
            valuePropName="checked"
            initialValue={editItem && editItem.recommended === 1}
          >
            <Switch />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              if (getFieldValue("recommended")) {
                return [
                  "zh_recommended_slider_image",
                  "ug_recommended_slider_image",
                ].map((imgKey) => (
                  <Form.Item
                    key={imgKey}
                    name={imgKey}
                    label={i18n.t(`live.${imgKey}`)}
                    rules={[
                      {
                        required: true,
                        message: i18n.t("sales.ad.imgWarning"),
                      },
                    ]}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => util.normFile4Img(e)}
                    initialValue={
                      editItem && util.getDefaultImages(editItem[imgKey])
                    }
                  >
                    <Upload
                      accept="image/*"
                      multiple={false}
                      listType="picture-card"
                      beforeUpload={onBeforeUpload}
                      customRequest={uploadImages}
                    >
                      {getFieldValue(imgKey) &&
                        !getFieldValue(imgKey).length && <UploadOutlined />}
                    </Upload>
                  </Form.Item>
                ));
              }
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(LiveList);
