import React from "react";

import { Layout, Input, Checkbox, Radio, DatePicker, Form } from "antd";

import Base from "../Base";

const Ticket = {
  name: {
    item: {
      colon: false,
      label: "优惠券名称（中文）",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      required: true,
    },
    input: {
      placeholder: "请输入优惠券中文名称",
    },
  },
  name_ug: {
    item: {
      colon: false,
      label: "优惠券名称（维文）",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    },
    input: {
      placeholder: "请输入优惠券维文名称",
    },
  },
  target: {
    item: {
      colon: false,
      label: "使用对象",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      required: true,
    },
    checkboxOptions: {
      list: [
        { label: "老用户", value: "old-user" },
        { label: "新用户", value: "new-user" },
      ],
      default: ["old-user", "new-user"],
    },
  },
  condition: {
    item: {
      colon: false,
      label: "使用门槛",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      required: true,
      className: "condition",
    },
    radioButtons: [
      { name: "无门槛", value: "-1" },
      { name: "有门槛：", value: "1" },
    ],
  },
  discount: {
    item: {
      colon: false,
      label: "优惠金额",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      required: true,
      className: "discount",
    },
    input: {
      placeholder: "0",
    },
  },
  amount: {
    item: {
      colon: false,
      label: "发放数量",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      required: true,
      className: "amount",
    },
    input: {
      placeholder: "0",
    },
  },
  giveTime: {
    item: {
      colon: false,
      label: "发放时间",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      required: true,
    },
  },
  validTime: {
    item: {
      colon: false,
      label: "有效时间",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      required: true,
    },
  },
  usage: {
    item: {
      colon: false,
      label: "使用说明（中文）",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    },
    input: {
      placeholder: "请输入优惠券中文使用方法，限200字",
      autosize: { minRows: 2, maxRows: 2 },
    },
  },
  usageUg: {
    item: {
      colon: false,
      label: "使用说明（维文）",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    },
    input: {
      placeholder: "请输入优惠券维文使用方法，限200字",
      autosize: { minRows: 2, maxRows: 2 },
    },
  },
  details: {
    item: {
      colon: false,
      label: "详细说明（中文）",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    },
    input: {
      placeholder: "请输入优惠券中文使用方法，限400字",
      autosize: { minRows: 4, maxRows: 4 },
    },
  },
  detailsUg: {
    item: {
      colon: false,
      label: "详细说明（维文）",
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    },
    input: {
      placeholder: "请输入优惠券维文使用方法，限400字",
      autosize: { minRows: 4, maxRows: 4 },
    },
  },
};

class PromotionShopTicketEdit extends Base {
  render() {
    return (
      <Layout className="ticket-edit-wrap">
        <Form>
          {this._renderFormName()}
          {this._renderFormNameUg()}
          {this._renderFormTarget()}
          {this._renderFormCondition()}
          {this._renderFormDiscount()}
          {this._renderFormAmount()}
          {this._renderFormGiveTime()}
          {this._renderFormValidTime()}
          {this._renderFormUsage()}
          {this._renderFormUsageUg()}
          {this._renderFormDetailes()}
          {this._renderFormDetailesUg()}
        </Form>
      </Layout>
    );
  }

  _renderFormName() {
    return (
      <Form.Item {...Ticket.name.item}>
        <Input {...Ticket.name.input} />
      </Form.Item>
    );
  }

  _renderFormNameUg() {
    return (
      <Form.Item {...Ticket.name_ug.item}>
        <Input {...Ticket.name_ug.input} />
      </Form.Item>
    );
  }

  _renderFormTarget() {
    return (
      <Form.Item {...Ticket.target.item}>
        <Checkbox.Group
          options={Ticket.target.checkboxOptions.list}
          defaultValue={Ticket.target.checkboxOptions.default}
        />
      </Form.Item>
    );
  }

  _renderFormCondition() {
    return (
      <Form.Item {...Ticket.condition.item}>
        <Radio.Group defaultValue={Ticket.condition.radioButtons[0].value}>
          <Radio {...Ticket.condition.radioButtons[0]}>
            {Ticket.condition.radioButtons[0].name}
          </Radio>
          <Radio {...Ticket.condition.radioButtons[1]}>
            {Ticket.condition.radioButtons[1].name}
            <Input disabled={true} />
            <span className="money-unit">元</span>
          </Radio>
        </Radio.Group>
      </Form.Item>
    );
  }

  _renderFormDiscount() {
    return (
      <Form.Item {...Ticket.discount.item}>
        <Input {...Ticket.discount.input} />
        <span className="money-unit">元</span>
      </Form.Item>
    );
  }

  _renderFormAmount() {
    return (
      <Form.Item {...Ticket.amount.item}>
        <Input {...Ticket.amount.input} />
        <span className="money-unit">个</span>
      </Form.Item>
    );
  }

  _renderFormGiveTime() {
    return (
      <Form.Item {...Ticket.giveTime.item}>
        <DatePicker.RangePicker allowClear={false} />
      </Form.Item>
    );
  }

  _renderFormValidTime() {
    return (
      <Form.Item {...Ticket.validTime.item}>
        <DatePicker.RangePicker allowClear={false} />
      </Form.Item>
    );
  }

  _renderFormUsage() {
    return (
      <Form.Item {...Ticket.usage.item}>
        <Input.TextArea {...Ticket.usage.input} />
      </Form.Item>
    );
  }

  _renderFormUsageUg() {
    return (
      <Form.Item {...Ticket.usageUg.item}>
        <Input.TextArea {...Ticket.usageUg.input} />
      </Form.Item>
    );
  }

  _renderFormDetailes() {
    return (
      <Form.Item {...Ticket.details.item}>
        <Input.TextArea {...Ticket.details.input} />
      </Form.Item>
    );
  }

  _renderFormDetailesUg() {
    return (
      <Form.Item {...Ticket.detailsUg.item}>
        <Input.TextArea {...Ticket.detailsUg.input} />
      </Form.Item>
    );
  }
}

export default PromotionShopTicketEdit;
