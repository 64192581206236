import React, { useEffect, useRef } from "react";
import { Modal, Input, Form, Button } from "antd";
import AMapLoader from "@amap/amap-jsapi-loader";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { AMAP_KEY, AMAP_VERSION, AMAP_ZOOM } from "../../constants";
import TranslateBtn from "../../components/product/TranslateBtn";

const i18n = Locale.getInstance();

const EditCommunityForm = ({ communityData, onCancel, onFinish, loading }) => {
  let map = useRef(null);
  const user = useSelector((state) => getUserInfo(state));
  const positionData =
    communityData && Object.keys(communityData).length > 0
      ? communityData
      : user.merchant;
  const [form] = Form.useForm();

  useEffect(() => {
    if (communityData && Object.keys(communityData).length > 0) {
      const {
        zh_name,
        ug_name,
        zh_address,
        ug_address,
        population,
        latitude,
        longitude,
        order,
      } = communityData;
      const fields = {
        zh_name,
        ug_name,
        zh_address,
        ug_address,
        population,
        latitude,
        longitude,
        order,
      };
      form.setFieldsValue(fields);
    }

    return () => {
      form.resetFields();
    };
  }, [communityData, form]);

  useEffect(() => {
    AMapLoader.load({
      key: AMAP_KEY,
      version: AMAP_VERSION,
      plugins: ["AMap.Marker", "AMap.LngLat"],
    })
      .then((AMap) => {
        const { longitude, latitude } = positionData;
        map.current = new AMap.Map("comm-map-container", {
          center: [longitude, latitude],
          zoom: AMAP_ZOOM,
        });
        const shopMarker = new AMap.Marker({
          position: new AMap.LngLat(longitude, latitude),
        });
        map.current.add(shopMarker);
        map.current.on("click", (e) => {
          const clickedPosition = {
            latitude: e.lnglat.lat,
            longitude: e.lnglat.lng,
          };
          shopMarker.setPosition(
            new AMap.LngLat(clickedPosition.longitude, clickedPosition.latitude)
          );
          form.setFieldsValue(clickedPosition);
        });
      })
      .catch((e) => {
        console.log("ServiceAreaSetting -> e", e);
      });
  }, [user.merchant, form, positionData]);

  return (
    <Modal
      title={
        communityData && communityData.zh_name
          ? i18n.t("community.edit")
          : i18n.t("community.add")
      }
      visible={!!communityData}
      onCancel={onCancel}
      footer={null}
      forceRender
    >
      <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              rules={[
                { required: true, message: i18n.t("community.zhNameHint") },
              ]}
              label={i18n.t("community.zhName")}
              name="zh_name"
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_name: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              rules={[
                { required: true, message: i18n.t("community.ugNameHint") },
              ]}
              label={i18n.t("community.ugName")}
              name="ug_name"
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_name: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              rules={[
                { required: true, message: i18n.t("community.zhAddressHint") },
              ]}
              label={i18n.t("community.zhAddress")}
              name="zh_address"
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_address")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_address: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              rules={[
                { required: true, message: i18n.t("community.ugAddressHint") },
              ]}
              label={i18n.t("community.ugAddress")}
              name="ug_address"
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_address")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_address: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item label={i18n.t("community.population")} name="population">
          <Input />
        </Form.Item>
        <Form.Item label={i18n.t("community.order")} name="order">
          <Input />
        </Form.Item>
        <div className="ant-row ant-form-item">
          <div>{i18n.t("community.position")}</div>
          <Form.Item hidden name="latitude">
            <Input />
          </Form.Item>
          <Form.Item hidden name="longitude">
            <Input />
          </Form.Item>
          <div
            style={{ width: "100%", height: "200px" }}
            id="comm-map-container"
          />
        </div>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCommunityForm;
