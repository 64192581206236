import React from "react";
import { connect } from "react-redux";
import { List, Button, Space, Typography, Divider, Tag, Radio } from "antd";
import moment from "dayjs";

import { getUserInfo } from "../../../utils/redux/reducers/User";
import Base from "../../Base";
import EditCouponForm from "./EditCouponForm";
import SendCouponForm from "./SendCouponForm";
import DistributedListModal from "./DistributedListModal";
import { FILTER_STATUS } from "../../../constants";

const { Text } = Typography;

class CouponsManagement extends Base {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: { data: [] },
      coupon: null,
      selectedCouponId: null,
      selectedCoupon: null,
      filterStatus: FILTER_STATUS.ALL.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetDataList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterStatus !== this.state.filterStatus) {
      this.onGetDataList();
    }
  }

  onShowAddCouponModal = () => {
    this.setState({
      coupon: { id: null },
    });
  };

  onHideCouponModal = () => {
    this.setState({ coupon: null });
  };

  onSendCoupon(selectedCoupon) {
    this.setState({ selectedCoupon });
  }
  onDistributedList(selectedCouponId) {
    this.setState({
      selectedCouponId,
    });
  }

  onEditCoupon(coupon) {
    this.setState({ coupon });
  }

  onEditCouponFinished = (values) => {
    const { coupon } = this.state;
    if (coupon && coupon.id) {
      this.onSubmitEditCoupon(values);
    } else {
      this.onAddCoupon(values);
    }
  };

  onSendCoupon2Users = async (users) => {
    const token = this.getUserToken();
    const api = this.api.coupons.send;
    const { selectedCoupon } = this.state;
    if (token) {
      try {
        const apiConfig = {
          token,
          path: { id: selectedCoupon.id },
          data: {
            users: Object.keys(users).map((id) => ({
              user_id: id,
              count: users[id],
            })),
          },
        };
        const res = await api.run(apiConfig);
        if (res.result) {
          this.showMessage(res.message, Base.Message.success);
          this.onHideSendForm();
          this.onGetDataList();
        } else {
          this.showMessage(res.message);
        }
      } catch (error) {
        this.handleApiError(api, error);
      }
    }
  };

  onHideSendForm = () => {
    this.setState({ selectedCoupon: null });
  };

  async onGetDataList(page = 1) {
    const api = this.api.coupons.list;
    if (this.mounted) {
      try {
        this.setState({ loading: true });
        const token = this.getUserToken();
        if (token) {
          const { filterStatus } = this.state;
          const apiConfig = {
            token,
            query: {
              page,
              count: 10,
              "filter[status]": filterStatus,
            },
          };
          const res = await api.run(apiConfig);
          if (res.result) {
            this.setState({
              data: res.data,
            });
          } else {
            this.showMessage(res.message);
          }
        }
      } catch (error) {
        this.handleApiError(api, error);
      } finally {
        if (this.mounted) {
          this.setState({ loading: false });
        }
      }
    }
  }

  async onAddCoupon(data) {
    const token = this.getUserToken();
    const api = this.api.coupons.add;

    if (token) {
      try {
        const apiConfig = {
          token,
          data: this.getCouponData(data),
        };
        const res = await api.run(apiConfig);
        if (res.result) {
          this.showMessage(res.message);
          this.onHideCouponModal();
          this.onGetDataList();
        } else {
          this.showMessage(res.message);
        }
      } catch (error) {
        this.handleApiError(api, error);
      }
    }
  }

  async onSubmitEditCoupon(data) {
    const token = this.getUserToken();
    const api = this.api.coupons.update;
    if (token) {
      try {
        const { coupon } = this.state;
        const apiConfig = {
          path: { id: coupon.id },
          token,
          data: this.getCouponData(data),
        };
        const res = await api.run(apiConfig);
        if (res.result) {
          const { data } = this.state;
          this.showMessage(res.message);
          this.onHideCouponModal();
          this.onGetDataList(data.current_page);
        } else {
          this.showMessage(res.message);
        }
      } catch (error) {
        this.handleApiError(api, error);
      }
    }
  }

  onDeleteCoupon(id) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const api = this.api.coupons.delete;
        if (this.mounted) {
          try {
            const token = this.getUserToken();
            if (token) {
              const apiConfig = {
                token,
                path: {
                  id,
                },
              };
              const res = await api.run(apiConfig);
              if (res.result) {
                this.showMessage(res.message, Base.Message.success);
                this.onGetDataList();
              } else {
                this.showMessage(res.message, Base.Message.warning);
              }
            }
          } catch (error) {
            this.handleApiError(api, error);
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  getFormattedDateString(date) {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD");
    }
    return null;
  }

  getCouponData(data) {
    const user = this.getUser();
    return {
      ...data,
      expire_date: this.getFormattedDateString(data.expire_date),
      start_date: this.getFormattedDateString(data.range[0]),
      end_date: this.getFormattedDateString(data.range[1]),
      use_merchant_id: user.merchant.id,
      merchant_type: user.merchant.type,
      face_price: data.face_price * 100,
      use_start_price: data.use_start_price * 100,
      start_price: data.use_start_price * 100,
      status: data.status ? 1 : 0,
      coupon_type: "discount",
    };
  }

  render() {
    const {
      loading,
      data,
      coupon,
      selectedCoupon,
      selectedCouponId,
      filterStatus,
    } = this.state;
    return (
      <>
        <List
          bordered
          size="large"
          style={{ backgroundColor: "#fff" }}
          header={
            <Space direction="horizontal">
              <Button type="primary" onClick={this.onShowAddCouponModal}>
                {this.i18n.t("sales.coupons.add")}
              </Button>
              <Radio.Group
                style={{ marginRight: 20, marginLeft: 20 }}
                onChange={(e) => {
                  this.setState({ filterStatus: e.target.value });
                }}
                value={filterStatus}
              >
                <Radio value={FILTER_STATUS.ALL.value}>
                  {FILTER_STATUS.ALL.name}
                </Radio>
                <Radio value={FILTER_STATUS.OPENED.value}>
                  {FILTER_STATUS.OPENED.name}
                </Radio>
                <Radio value={FILTER_STATUS.CLOSED.value}>
                  {FILTER_STATUS.CLOSED.name}
                </Radio>
              </Radio.Group>
            </Space>
          }
          itemLayout="horizontal"
          dataSource={data.data}
          loading={loading}
          pagination={{
            current: data.current_page,
            total: data.total,
            pageSize: 10,
            showQuickJumper: true,
            onChange: (page) => this.onGetDataList(page),
          }}
          renderItem={(item, idx) => (
            <List.Item
              style={{
                backgroundColor: item.status === 1 ? "#ffffff" : "#f1f1f1",
              }}
              actions={[
                <Button
                  type="link"
                  style={{ borderWidth: 0 }}
                  key="list-loadmore-send"
                  onClick={() => this.onSendCoupon(item)}
                >
                  {this.i18n.t("sales.coupons.send")}
                </Button>,
                <Button
                  type="link"
                  style={{ borderWidth: 0 }}
                  key="list-loadmore-send-list"
                  onClick={() => this.onDistributedList(item.id)}
                >
                  {this.i18n.t("sales.coupons.distributedList")}
                </Button>,
                <Button
                  type="link"
                  style={{ borderWidth: 0 }}
                  key="list-loadmore-edit"
                  onClick={() => this.onEditCoupon(item)}
                >
                  {this.i18n.t("product.productItem.actionEdit")}
                </Button>,
                <Button
                  type="link"
                  danger
                  style={{ borderWidth: 0 }}
                  key="list-loadmore-more"
                  onClick={() => this.onDeleteCoupon(item.id)}
                >
                  {this.i18n.t("sales.activity.delete")}
                </Button>,
              ]}
            >
              <Text>{item.id}</Text>
              <Divider type="vertical" style={{ height: 20 }} />
              <List.Item.Meta
                style={{ marginLeft: 20, marginRight: 20 }}
                title={this.i18n.getPropNameByLocale(item, "name")}
                description={`${this.getFormattedDateString(
                  item.start_date
                )}~${this.getFormattedDateString(item.end_date)}`}
              />
              <Space>
                <Space direction="horizontal">
                  <Text>
                    {this.i18n.t("sales.coupons.total", {
                      count: item.quantity,
                    })}
                  </Text>
                  <Tag color={item.amount_left ? "green" : "red"}>
                    {this.i18n.t("sales.coupons.left", {
                      count: item.amount_left,
                    })}
                  </Tag>
                </Space>
                <Space direction="horizontal">
                  <Text>
                    {item.start_price
                      ? this.i18n.t("sales.coupons.start", {
                          price: item.start_price / 100,
                        })
                      : this.i18n.t("sales.coupons.no_limit")}
                  </Text>
                  <Text type="danger">
                    {this.i18n.t("sales.coupons.face_price", {
                      price: item.face_price / 100,
                    })}
                  </Text>
                </Space>
              </Space>
            </List.Item>
          )}
        />
        <DistributedListModal
          key="distributed-list-modal"
          selectedCouponId={selectedCouponId}
          onCancel={() => {
            this.setState({
              selectedCouponId: null,
            });
          }}
        />
        <EditCouponForm
          visible={coupon !== null}
          onCancel={this.onHideCouponModal}
          onFinish={this.onEditCouponFinished}
          coupon={coupon}
        />
        <SendCouponForm
          selectedCoupon={selectedCoupon}
          onCancel={this.onHideSendForm}
          onFinish={this.onSendCoupon2Users}
          api={this.api.coupons.users}
        />
      </>
    );
  }
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(CouponsManagement);
