import React from "react";

import {
  CaretDownOutlined,
  MessageOutlined,
  FrownOutlined,
  SmileOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Rate, // Divider,
  Popover,
  Input,
  Button,
} from "antd";

import Base from "../Base";

const scoreMap = [
  "review.reviewItem.score5",
  "review.reviewItem.score4",
  "review.reviewItem.score3",
  "review.reviewItem.score2",
  "review.reviewItem.score1",
];

export default class ReviewItem extends Base {
  constructor(props) {
    super(props);

    this.state = {
      showReplyInput: false,
      replyInputValue: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (this.props.review.replies !== prevProps.review.replies) {
      this.setState({ showReplyInput: false });
    }
  }

  render() {
    if (!this.props.review) return null;

    return (
      <Row className="review-item">
        {this._renderScores()}
        {this._renderBrief()}
        {this._renderExtra()}
      </Row>
    );
  }

  _renderScores() {
    let score = this.props.review.order_score;
    let deliveryScore = this.props.review.deliveryman_score;

    return (
      <Col span={5} className="item-scores">
        <span className="user-name">{this.props.review.user.nickname}</span>
        <span className="merchant-score">
          {this.i18n.t("review.reviewItem.merchantScore", {
            score: score,
            scoreText: this.i18n.t(scoreMap[5 - score]),
          })}
        </span>
        <span className="merchant-rate">
          <span className="label">
            {this.i18n.t("review.reviewItem.merchantText")}
          </span>
          <Rate disabled={true} defaultValue={score} />
        </span>
        <span className="merchant-rate">
          <span className="label">
            {this.i18n.t("review.reviewItem.deliveryText")}
          </span>
          <Rate disabled={true} defaultValue={deliveryScore} />
        </span>
        <span className="merchant-rate delivery">
          <span className="label">
            {this.i18n.t("review.reviewItem.ovewrallText")}
          </span>
          {score < 3 ? <FrownOutlined /> : <SmileOutlined />}
          <span className="value">
            {score < 3
              ? this.i18n.t("review.reviewItem.overallNotOk")
              : this.i18n.t("review.reviewItem.overallOk")}
          </span>
        </span>
      </Col>
    );
  }

  _renderBrief() {
    let images = this.props.review.images;

    return (
      <Col span={15} className="item-brief">
        <div className="time">
          <span>
            {this.moment(this.props.review.created_at).format("YYYY-MM-DD")}
          </span>
        </div>
        <div className="content">
          {this.props.review.o_content ? (
            this.props.review.o_content
          ) : (
            <span className="no-content">
              {this.i18n.t("review.reviewItem.noContent")}
            </span>
          )}
        </div>
        <div className="images">
          {images && images.length > 0 ? (
            images.map((image, index) => {
              return (
                <img
                  key={`image-${index}`}
                  alt=""
                  src={image}
                  onClick={() => this.onImageClick(images, index)}
                ></img>
              );
            })
          ) : (
            <span className="no-image">
              {this.i18n.t("review.reviewItem.noImage")}
            </span>
          )}
        </div>
        <div className="replies">
          {this.props.review.replies && this.props.review.replies.length > 0 ? (
            `
							${this.i18n.t("review.reviewItem.replyText")}
							${this.props.review.replies[this.props.review.replies.length - 1]}
							`
          ) : (
            <span className="no-image">
              {this.i18n.t("review.reviewItem.noReply")}
            </span>
          )}
        </div>
        <div className="actions">
          {!this.props.review.replies ||
          this.props.review.replies.length <= 0 ? (
            <Button onClick={this.onReplyClick}>
              <MessageOutlined />
              {this.i18n.t("review.reviewItem.actionReply")}
            </Button>
          ) : null}
        </div>
        {this._renderReplyInput()}
      </Col>
    );
  }

  _renderReplyInput() {
    if (this.state.showReplyInput) {
      return (
        <div className="reply-wrap">
          <Input.TextArea
            placeholder={this.i18n.t("review.reviewItem.replyHint")}
            autosize={{ minRows: 3, maxRows: 3 }}
            autoFocus={true}
            value={this.state.replyInputValue}
            onChange={this.onReplyInputChange}
          />
          <div className="reply-actions">
            <Button
              type="primary"
              size="small"
              onClick={this.onReplyConfirmClick}
            >
              {this.i18n.t("review.reviewItem.replyConfirm")}
            </Button>
            <Button size="small" onClick={this.onReplyCancelClick}>
              {this.i18n.t("review.reviewItem.replyCancel")}
            </Button>
          </div>
        </div>
      );
    }

    return null;
  }

  _renderExtra() {
    let placement = this.i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight";

    return (
      <Col span={4} className="item-extra">
        <Popover
          placement={placement}
          content={itemDetailPopover(this.props.review.products, this.i18n)}
        >
          <Button type="text" className="action-detail">
            {this.i18n.t("review.reviewItem.detailLabel")}
            <CaretDownOutlined />
          </Button>
        </Popover>
      </Col>
    );
  }

  onImageClick = (images, index) => {
    if (this.props.actionClick) {
      this.props.actionClick("image", { images: images, index: index });
    }
  };

  onReplyClick = () => {
    this.setState({ showReplyInput: !this.state.showReplyInput });
  };

  onReplyInputChange = (e) => {
    this.setState({ replyInputValue: e.target.value });
  };

  onReplyConfirmClick = () => {
    if (this.props.actionClick) {
      this.props.actionClick("reply", this.state.replyInputValue);
    }
  };

  onReplyCancelClick = () => {
    this.setState({ showReplyInput: false });
  };

  onCouponClick = () => {};

  onReportClick = () => {};
}

const itemDetailPopover = (items, i18n) => {
  if (!items || items.length <= 0) return null;

  return (
    <div id="review-list-item-detail-popover">
      {items.map((item, index) => {
        return (
          <div key={`detail-row-${index}`} className="detail-row">
            <span className="name">
              {i18n.getPropNameByLocale(item, "name")}
            </span>
            <span className="amount">{`x${item.amount}`}</span>
          </div>
        );
      })}
    </div>
  );
};
