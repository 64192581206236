import React from "react";

import {
  UploadOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Button,
  Pagination,
  Spin,
  Row,
  Upload,
  Modal,
  List,
} from "antd";

import Base from "../Base";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { Locale } from "../../utils";

class ImportTrackNumber extends Base {
  /* lifcyle methods */

  constructor(props) {
    super(props);

    this.afterSomeSecond = 1;
    this.state = {
      ...this.state,

      list: null,
      loadingList: false,
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(),

      showError4Id: null,
      checkingZero: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(
        this.i18n.t("financeDownload.loadingData"),
        Base.Message.loading
      );
      this.loadList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.currentPageSize !== this.state.currentPageSize
    ) {
      this.showMessage(
        this.i18n.t("financeDownload.loadingData"),
        Base.Message.loading
      );
      this.loadList();

      this.setRouterQuery({
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
      });
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-finance-download">
        {this._renderHeader()}
        {this._renderHistoryContent()}
        {this._renderModal()}
      </Layout>
    );
  }

  _renderHeader() {
    let locale = Locale.getLocale();
    if (locale === Locale.Locales.ug.locale) {
      locale = ``;
    } else {
      locale = `/${locale}`;
    }

    const url = `${locale}/api/v4/merchant/order/import-excel`;

    const uploadProps = {
      accept: ".xls, .xlsx",
      action: url,
      headers: {
        authorization: this.getUserToken(),
      },
      onChange: (info) => {
        if (info.file.status !== "uploading") {
          this.showMessage(
            this.i18n.t("importExecl.importing"),
            Base.Message.loading
          );
        }

        if (info.file.status === "done") {
          const { response } = info.file;
          if (response.result) {
            this.checkStatusZero(response.data.id);
          }
        } else if (info.file.status === "error") {
          this.showMessage(info.file.response.message, Base.Message.error);
        }
      },
    };
    return (
      <Row
        gutter={10}
        style={{ marginLeft: 0, marginRight: 0, marginBottom: 10 }}
      >
        <Upload {...uploadProps}>
          <Button
            onClick={this.onImportFile}
            icon={<UploadOutlined />}
            type="primary"
          >
            {this.i18n.t("main.sideMenus.import")}
          </Button>
        </Upload>
      </Row>
    );
  }

  _renderHistoryContent() {
    return (
      <div className="history-wrap">
        {this._renderHistoryHeader()}
        {this._renderHistoryList()}
        {this._renderHistoryPagination()}
      </div>
    );
  }

  _renderHistoryHeader() {
    return (
      <div className="history-header">
        <span className="no">
          {this.i18n.t("financeDownload.historyHeaderNo")}
        </span>
        <span className="name">
          {this.i18n.t("financeDownload.historyHeaderName")}
        </span>
        <span className="date">{this.i18n.t("orderDownload.date")}</span>
        <span className="count">{this.i18n.t("orderDownload.orderCount")}</span>
        <span className="status">
          {this.i18n.t("financeDownload.historyHeaderStatus")}
        </span>
        <span className="actions">
          {this.i18n.t("financeDownload.historyHeaderActions")}
        </span>
      </div>
    );
  }

  _renderHistoryList() {
    let data = this.state.list;
    if (data) {
      let list = data ? data.data : null;
      if (list && list.length > 0) {
        return (
          <Spin spinning={this.state.loadingList || this.state.checkingZero}>
            <div className="history-list">
              {list.map((item, index) => {
                let statusClassName = "";
                let statusIconType = "";
                let statusText = "";
                let actions = [];

                if (item.status === 0) {
                  statusClassName = "status progress";
                  statusIconType = <ClockCircleOutlined />;
                  statusText = this.i18n.t(
                    "financeDownload.historyItemStatusGenerating"
                  );
                } else if (item.status === 1) {
                  statusClassName = "status";
                  statusIconType = <CheckCircleOutlined />;
                  statusText = this.i18n.t(
                    "importExecl.historyItemStatusSuccess"
                  );
                  actions.push(
                    <a
                      key="action-download"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.download_url}
                    >
                      {this.i18n.t("financeDownload.historyItemActionDownload")}
                    </a>
                  );
                } else if (item.status === 2) {
                  statusClassName = "status error";
                  statusIconType = <CloseCircleOutlined />;
                  statusText = this.i18n.t(
                    "importExecl.historyItemStatusFailed"
                  );
                  actions.push(
                    <a
                      key="action-download"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.download_url}
                    >
                      {this.i18n.t("financeDownload.historyItemActionDownload")}
                    </a>
                  );
                  actions.push(
                    <Button
                      key="action-check"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.onShowImportError(item.id)}
                      style={{ borderWidth: 0 }}
                    >
                      {this.i18n.t("order.importErrorCheck")}
                    </Button>
                  );
                }

                return (
                  <div
                    key={`list-item-${index}`}
                    className="history-header item"
                  >
                    <span className="no">{item.id}</span>
                    <span className="name">{item.file_name}</span>
                    <span className="date">{`${item.start_date}~${item.end_date}`}</span>
                    <span className="count">{item.orders_count}</span>
                    <span className={statusClassName}>
                      {statusIconType}
                      <span>{statusText}</span>
                    </span>
                    <span className="actions">{actions}</span>
                  </div>
                );
              })}
            </div>
          </Spin>
        );
      } else {
        return (
          <div className="history-list">
            <div className="list-message">
              {this.i18n.t("financeDownload.loadingEmpty")}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.loadingList) {
        return (
          <div className="history-list">
            <div className="list-message">
              {this.i18n.t("financeDownload.loadingData")}
            </div>
          </div>
        );
      }

      return (
        <div className="history-list">
          <div className="list-message">
            {this.i18n.t("financeDownload.loadingEmpty")}
          </div>
        </div>
      );
    }
  }

  _renderHistoryPagination() {
    let data = this.state.list;
    if (data && data.data && data.data.length > 0) {
      return (
        <div className="history-pagination">
          <Pagination
            total={data.total}
            current={this.state.currentPage}
            showSizeChanger={true}
            pageSizeOptions={["10", "20", "30"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
            showQuickJumper
          />
        </div>
      );
    } else {
      return null;
    }
  }

  _renderModal() {
    const { list, showError4Id } = this.state;
    if (!showError4Id) return null;

    const item = list.data.filter((item) => item.id === showError4Id);
    const renderData = !item.length ? null : item[0].extra;
    if (!renderData) return null;

    return (
      <Modal
        className="route-order-list-send-modal"
        visible={!!showError4Id}
        title={this.i18n.t("importExecl.errorTitle")}
        onOk={() => this.setState({ showError4Id: null })}
        onCancel={() => this.setState({ showError4Id: null })}
      >
        <Layout className="order-send-wrap">
          <List
            bordered
            itemLayout="vertical"
            dataSource={renderData}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Layout>
      </Modal>
    );
  }

  /* user methods */

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  onShowImportError = (id) => {
    this.setState({ showError4Id: id });
  };

  /* server methods */

  async loadList() {
    if (this.state.loadingList) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.order.listExecl;
    let apiConfig = {
      token: token,
      query: {
        page: this.state.currentPage,
        count: this.state.currentPageSize,
        "filter[type]": 1,
      },
    };

    this.setState({ loadingList: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        let data = res.data;
        this.setState({ list: data });

        let list = data ? data.data : null;
        if (list && list.length > 0) {
          let generatingBill = list.find((item) => item.status === 0);
          if (generatingBill) {
            setTimeout(() => {
              if (!this.mounted) return;

              this.showMessage(
                this.i18n.t("financeDownload.updatingList"),
                Base.Message.loading
              );
              this.loadList();
            }, 5000);
          }
        }
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingList: false });
    }
  }

  checkStatusZero = async (id) => {
    if (this.state.checkingZero) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.order.importDetail;
    let apiConfig = {
      token: token,
      path: {
        id,
      },
    };

    this.setState({ checkingZero: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        if (res.data.status === 0) {
          this.checkStatusZero(res.data.id);
        } else {
          this.loadList();
        }
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ checkingZero: false });
    }
  };
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(ImportTrackNumber);
