// import Pagination from 'rc-pagination/lib/locale/zh_CN';
import DatePicker from "antd/lib/date-picker/locale/zh_CN";
import TimePicker from "antd/lib/time-picker/locale/zh_CN";
import Calendar from "antd/lib/calendar/locale/zh_CN";

export default {
  locale: "ug-cn",
  Pagination: {
    // Options.jsx
    items_per_page: "تال / بەت",
    jump_to: "ئاتلاش",
    jump_to_confirm: "مۇقىملاش",
    page: "بەت",

    // Pagination.jsx
    prev_page: "ئالدىنقى",
    next_page: "كىيىنكى",
    prev_5: "ئالدىغا 5 بەت",
    next_5: "كەينىگە 5 بەت",
    prev_3: "ئالدىغا 3 بەت",
    next_3: "كەينىگە 3 بەت",
  },
  DatePicker: {
    ...DatePicker,
    lang: {
      ...DatePicker.lang,
      placeholder: "چېسلانى تاللاڭ",
      yearPlaceholder: "يىلنى تاللاڭ",
      quarterPlaceholder: "پەسىلنى تاللاڭ",
      monthPlaceholder: "ئاينى تاللاڭ",
      weekPlaceholder: "ھەپتىنى تاللاڭ",
      rangePlaceholder: ["باشلىنىش ۋاقتى", "ئاخىرلىشىش ۋاقتى"],
      rangeYearPlaceholder: ["باش يىل", "ئاياغ يىل"],
      rangeMonthPlaceholder: ["باش ئاي", "ئاياغ ئاي"],
      rangeWeekPlaceholder: ["باش ھەپتە", "ئاياغ ھەپتە"],
    },
  },
  TimePicker,
  Calendar,
  Table: {
    filterTitle: "سۈزۈش",
    filterConfirm: "مۇقىملاش",
    filterReset: "ئەسلىگە قايتۇرۇش",
    emptyText: "مەزمۇن يوق",
    selectAll: "ھەممىنى تاللاش",
    selectInvert: "تەتۈر تاللاش",
    sortTitle: "تىزىش",
  },
  Modal: {
    okText: "مۇقىملاش",
    cancelText: "قالدۇرۇش",
    justOkText: "بىلدىم",
  },
  Popconfirm: {
    cancelText: "قالدۇرۇش",
    okText: "مۇقىملاش",
  },
  Transfer: {
    notFoundContent: "مەزمۇن تېپىلمىدى",
    searchPlaceholder: "ئىزدىمەكچى بولغان مەزمۇننى كىرگۈزۈڭ",
    itemUnit: "تۈر",
    itemsUnit: "تۈر",
  },
  Select: {
    notFoundContent: "مەزمۇن تېپىلمىدى",
  },
  Upload: {
    uploading: "ھۆججەت يوللىنىۋاتىدۇ ...",
    removeFile: "ھۆججەت ئۆچۈرۈش",
    uploadError: "خاتالىق كۆرۈلدى",
    previewFile: "ھۆججەت كۆرۈش",
  },
};
