export default {
  list: {
    name: "commissions list api",
    version: 6,
    url: "commissions",
    method: "GET",
  },
  withdrawRequestList: {
    name: "withdraw request list api",
    version: 6,
    url: "withdrawal/request-list",
    method: "GET",
  },
  withdrawCommissionList: {
    name: "withdraw request list api",
    version: 6,
    url: "withdrawal/commissions/%{id}",
    method: "GET",
  },
  acceptWithdrawRequest: {
    name: "withdraw request list api",
    version: 6,
    url: "withdrawal/accept/%{id}",
    method: "post",
  },
  rejectWithdrawRequest: {
    name: "withdraw request list api",
    version: 6,
    url: "withdrawal/reject/%{id}",
    method: "post",
  },
  update: {
    name: "order traking update",
    version: 6,
    url: "order/%{orderId}/force-tracking",
    method: "POST",
  },
  unfreeze: {
    name: "unfreeze commission",
    version: 6,
    url: "commissions/%{orderId}/unfreeze",
    method: "POST",
  },
  send: {
    name: "force order status to send",
    version: 6,
    url: "order/%{orderId}/force-send",
    method: "POST",
  },
  finish: {
    name: "force order status to finish",
    version: 6,
    url: "order/%{orderId}/force-finish",
    method: "POST",
  },
  delivered: {
    name: "force order status to delivered",
    version: 6,
    url: "order/%{orderId}/force-delivered",
    method: "POST",
  },
  warehouse: {
    name: "send order to warehouse",
    version: 6,
    url: "order/%{orderId}/warehouse",
    method: "POST",
  },
};
