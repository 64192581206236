export default {
  list: {
    name: "ad list api",
    version: 6,
    url: "ad",
    method: "GET",
  },
  add: {
    name: "add ad api",
    version: 6,
    url: "ad",
    method: "POST",
  },
  update: {
    name: "update ad api",
    version: 6,
    url: "ad/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete ad api",
    version: 6,
    url: "ad/%{id}",
    method: "DELETE",
  },
};
