export default {
  bbshop_list: {
    name: "bbshop_list order list api",
    version: 5,
    url: "bbshop/merchant/order",
    method: "GET",
  },
  listExecl: {
    name: "order export list api",
    version: 4,
    url: "order/excels",
    method: "GET",
  },
  accept: {
    name: "order accept api",
    version: 4,
    url: "order/%{orderId}/accept",
    method: "POST",
  },
  cancel: {
    name: "order cancel api",
    version: 4,
    url: "order/%{orderId}/reject",
    method: "POST",
  },
  send: {
    name: "order send api",
    version: 4,
    url: "order/%{orderId}/take",
    method: "POST",
  },
  sendChange: {
    name: "order send change api",
    version: 4,
    url: "order/%{orderId}/update-tracking",
    method: "POST",
  },
  import: {
    name: "import excel api",
    version: 4,
    url: "order/import-excel",
    method: "POST",
  },
  export: {
    name: "order list export api",
    version: 4,
    url: "order/export-excel",
    method: "POST",
  },
  importDetail: {
    name: "get single xls detail api",
    version: 4,
    url: "order/import-excel/%{id}",
    method: "GET",
  },
  print: {
    name: "print order",
    version: 2,
    url: "order/%{orderId}/print",
    method: "POST",
  },
  calculate: {
    name: "calculate order",
    version: 5,
    url: "bbshop/merchant/calculate-commission/%{orderId}",
    method: "POST",
  },
  refund: {
    name: "refund order",
    version: 6,
    url: "order-refund/%{orderId}",
    method: "POST",
  },
  new: {
    name: "check new  order",
    version: 6,
    url: "new-orders",
    method: "GET",
  },
  refundCheck: {
    name: "refund check",
    version: 6,
    url: "order-refund-check/%{orderId}",
    method: "POST",
  },
};
