export default {
  list: {
    name: "property-group list api",
    version: 6,
    url: "property-group",
    method: "GET",
  },
  add: {
    name: "property-groupd add api",
    version: 6,
    url: "property-group",
    method: "POST",
  },
  update: {
    name: "update property-group api",
    version: 6,
    url: "property-group/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete property-group api",
    version: 6,
    url: "property-group/%{id}",
    method: "DELETE",
  },
  addChild: {
    name: "property add api",
    version: 6,
    url: "property",
    method: "POST",
  },
  updateChild: {
    name: "update property api",
    version: 6,
    url: "property/%{id}",
    method: "PUT",
  },
  deleteChild: {
    name: "delete property api",
    version: 6,
    url: "property/%{id}",
    method: "DELETE",
  },
};
