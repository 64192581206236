import { ACTIONS } from "../actions/Medias";

const initialState = [];

export const medias = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.ADD_MEDIA: {
      let media = action.media;

      media.id = `${state.length}`;
      state.unshift(media);

      return [].concat(state);
    }
    case ACTIONS.REMOVE_MEDIA: {
      let medias = action.medias;

      let items = state;
      for (var i = 0; i < medias.length; i++) {
        let index = -1;

        for (var j = 0; j < items.length; j++) {
          if (medias[i].id === items[j].id) {
            index = j;
          }
        }

        if (index >= 0) {
          items.splice(index, 1);
        }
      }

      return [].concat(items);
    }
    default: {
      return state;
    }
  }
};

export const getMedias = (state) => {
  return state.medias;
};
