export default {
  list: {
    name: "red-packet list api",
    version: 6,
    url: "group-red-packet",
    method: "GET",
  },
  add: {
    name: "add red-packet api",
    version: 6,
    url: "group-red-packet",
    method: "POST",
  },
  update: {
    name: "update red-packet api",
    version: 6,
    url: "group-red-packet/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete red-packet api",
    version: 6,
    url: "group-red-packet/%{id}",
    method: "DELETE",
  },
  retry: {
    name: "delete red-packet api",
    version: 6,
    url: "group-red-packet/%{id}/generate",
    method: "POST",
  },
};
