export default {
  list: {
    name: "navigator list api",
    version: 6,
    url: "navigator",
    method: "GET",
  },
  add: {
    name: "add navigator api",
    version: 6,
    url: "navigator",
    method: "POST",
  },
  update: {
    name: "update navigator api",
    version: 6,
    url: "navigator/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete navigator api",
    version: 6,
    url: "navigator/%{id}",
    method: "DELETE",
  },
};
