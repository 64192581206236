import React, { useEffect } from "react";
import {
  Modal,
  List,
  Avatar,
  InputNumber,
  Form,
  Button,
  Timeline,
  Divider,
  Col,
} from "antd";
import { Locale } from "../../utils";
import TextArea from "antd/lib/input/TextArea";

const i18n = Locale.getInstance();

function getMaxRefundAmount(total, list) {
  if (Array.isArray(list) && list.length > 0) {
    const alreadyRefundCount = list.reduce(
      (sum, item) => (sum += item.refund_amount),
      0
    );
    return total - alreadyRefundCount;
  }
  return total;
}

function OrderRefundModal({ details, onCancel, onFinish }) {
  const [refundForm] = Form.useForm();
  useEffect(() => {
    return () => {
      refundForm.resetFields();
    };
  });
  return (
    <Modal
      visible={details.length > 0}
      onCancel={onCancel}
      footer={null}
      title={i18n.t("order.refund")}
      width={800}
      forceRender
    >
      <Form form={refundForm} onFinish={onFinish}>
        <List
          className="order-refund-modal-list"
          dataSource={details}
          renderItem={(item) => (
            <List.Item>
              <Col span={14}>
                <List.Item.Meta
                  avatar={<Avatar src={item.product.images[0]} size="large" />}
                  title={i18n.getPropNameByLocale(item.product, "name")}
                  description={i18n.t("order.refundMax", {
                    max: getMaxRefundAmount(item.amount, item.refund),
                  })}
                />
                {Array.isArray(item.refund) && item.refund.length > 0 && (
                  <Timeline mode={i18n.isLocaleRTL() ? "right" : "left"}>
                    {item.refund.map((i) => (
                      <Timeline.Item key={i.id} style={{ marginTop: "16px" }}>
                        {i.created_at}
                        {i.refund_label}
                        <Divider type="vertical" />
                        {i18n.t("order.refundAmount", {
                          amount: i.refund_amount,
                        })}
                        <Divider type="vertical" />
                        {i18n.t("order.refundPrice", {
                          price: i.refund_price / 100,
                        })}
                      </Timeline.Item>
                    ))}
                  </Timeline>
                )}
              </Col>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name={`${item.product_id}`}
                    label={i18n.t("order.refundCount")}
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                    initialValue={0}
                    wrapperCol={{ span: 10 }}
                    extra={i18n.t("financeAccount.ballanceInfoValue", {
                      amount:
                        (item.price * getFieldValue(`${item.product_id}`)) /
                        100,
                    })}
                  >
                    <InputNumber
                      max={getMaxRefundAmount(item.amount, item.refund)}
                      min={0}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </List.Item>
          )}
        />
        <Form.Item
          name="reason"
          rules={[
            { required: true, message: i18n.t("order.refundReasonWarning") },
          ]}
        >
          <TextArea
            placeholder={i18n.t("order.refundReason")}
            dir={i18n.isLocaleRTL() ? "rtl" : "ltr"}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {i18n.t("order.refund")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default OrderRefundModal;
