import { Table, Input, Tag, Avatar, Space, Button, Switch } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ApiManager, Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";

import Base from "../Base";
import SelectManagerList from "./SelectManagerList";

const base = Base.getInstance();
const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const { Search } = Input;

function LotteryManagers({ location }) {
  const { lotteryId } = location.state;
  const getData = useRef();
  const user = useSelector((state) => getUserInfo(state));
  const [listData, setListData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  getData.current = async (page = 1, keyword = "") => {
    if (user.token) {
      const apiUrl = api.managers.list;
      const apiConfig = {
        token: user.token,
        query: { lottery_id: lotteryId, page, keyword, count: 10 },
      };
      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setListData(res.data);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    }
  };

  function onDeleteItem(id) {
    base.showModal(
      i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const apiUrl = api.managers.delete;
        const { token } = user;
        try {
          if (token) {
            const apiConfig = {
              token,
              path: {
                id,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              getData.current(listData.current_page);
            } else {
              base.showMessage(res.message, Base.Message.warning);
            }
          }
        } catch (error) {
          base.handleApiError(api, error);
        }
      }
    );
  }

  async function onChangeStatus(record) {
    if (user.token) {
      const apiUrl = api.managers.update;
      const apiConfig = {
        token: user.token,
        path: { id: record.id },
        data: { status: record.status },
      };
      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          base.showMessage(res.message, Base.Message.success);
          getData.current(listData.current_page);
        } else {
          setLoading(false);
          base.showModal(res.message, Base.Modal.warning);
        }
      } catch (error) {
        setLoading(false);
        base.handleApiError(apiUrl, error);
      }
    }
  }

  useEffect(() => {
    getData.current();
  }, [getData]);

  return (
    <>
      <Space direction="horizontal" style={{ marginBottom: 16 }}>
        <Search
          onSearch={(keyword) => getData.current(1, keyword)}
          placeholder={i18n.t("users.searchPlace")}
          style={{ width: 300 }}
          enterButton
        />
        <Button
          type="primary"
          onClick={() => {
            setShowList(true);
          }}
        >
          {i18n.t("lottery.selectManager")}
        </Button>
      </Space>
      <Table
        loading={loading}
        dataSource={listData.data}
        rowKey={(record) => `${record.id}`}
        pagination={{
          current: listData.current_page,
          total: listData.total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        columns={[
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => (
              <Avatar
                size="large"
                shape="circle"
                src={record.user.avatar}
                alt={record.user.nickname}
              />
            ),
          },
          {
            title: i18n.t("users.name"),
            dataIndex: "nickname",
            key: "nickname",
            render: (text, record) => record.user.nickname,
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            align: "center",
            render: (text, record) => {
              if (record.user) {
                return record.user.mobile;
              }
              return null;
            },
          },
          {
            title: i18n.t("commissionList.status"),
            key: "tags",
            align: "center",
            render: (text, record) => {
              if (record.status === 1) {
                return <Tag color="green">{i18n.t("lottery.opened")}</Tag>;
              }
              return null;
            },
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "created_at",
            align: "center",
          },
          {
            title: i18n.t("users.actions"),
            key: "actions",
            align: "center",
            render: (text, record) => (
              <Space direction="horizontal">
                <Switch
                  checked={record.status === 1}
                  onChange={(e) => {
                    onChangeStatus({ ...record, status: e ? 1 : 0 });
                  }}
                />
                <Button
                  type="link"
                  danger
                  onClick={() => {
                    onDeleteItem(record.id);
                  }}
                >
                  {i18n.t("product.productItem.actionDelete")}
                </Button>
              </Space>
            ),
          },
        ]}
      />
      <SelectManagerList
        visible={showList}
        onCancel={() => {
          setShowList(false);
        }}
        lotteryId={lotteryId}
        getListData={getData.current}
      />
    </>
  );
}

export default LotteryManagers;
