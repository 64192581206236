import React from "react";

import {
  Layout,
  Row,
  Col,
  Divider,
  Radio,
  DatePicker,
  Spin,
  Pagination,
} from "antd";

import Base from "../Base";

import ReviewItem from "../../components/review/ReviewItem";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

class ReviewList extends Base {
  static Status = [
    { key: "-1", name: "review.status.all" },
    { key: "1", name: "review.status.reviewed" },
    { key: "0", name: "review.status.unReviewed" },
  ];

  static Satisfaction = [
    { key: "all", name: "review.satisfaction.all" },
    { key: "best", name: "review.satisfaction.best" },
    { key: "normal", name: "review.satisfaction.normal" },
    { key: "worst", name: "review.satisfaction.worst" },
  ];

  static Content = [
    { key: "0", name: "review.content.all" },
    { key: "1", name: "review.content.noContent" },
  ];

  /* lifecycle mehotds */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      status: this.getRouterQueryItemByKey(ReviewList.Status, "status"),
      satisfaction: this.getRouterQueryItemByKey(
        ReviewList.Satisfaction,
        "satisfaction"
      ),
      content: this.getRouterQueryItemByKey(ReviewList.Content, "content"),
      rangeTime: this.getDefaultTimeRange(),
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(),

      data: null,
      loadingData: false,
      replyingReview: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(this.i18n.t("review.loadingData"), Base.Message.loading);
      this.loadReviewList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      prevState.status.key !== this.state.status.key ||
      prevState.satisfaction.key !== this.state.satisfaction.key ||
      prevState.content.key !== this.state.content.key ||
      prevState.rangeTime[0].diff(this.state.rangeTime[0]) !== 0 ||
      prevState.rangeTime[1].diff(this.state.rangeTime[1]) !== 0 ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.currentPageSize !== this.state.currentPageSize
    ) {
      this.showMessage(
        this.i18n.t("review.updatingData"),
        Base.Message.loading
      );
      this.loadReviewList();

      this.setRouterQuery({
        status: this.state.status.key,
        satisfaction: this.state.satisfaction.key,
        content: this.state.content.key,
        timeRange: `${this.state.rangeTime[0].format(
          "YYYY-MM-DD"
        )}~${this.state.rangeTime[1].format("YYYY-MM-DD")}`,
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
      });
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-review-list">
        {this._renderHeaderStatistics()}
        {this._renderHeaderFilters()}
        {this._renderListTitle()}
        {this._renderListItems()}
        {this._renderListPagination()}
        {this._renderModals()}
      </Layout>
    );
  }

  _renderHeaderStatistics() {
    let data = this.state.data;
    let statistics = data ? data.statistics : null;
    let avg_score = statistics ? statistics.avg_score : 0;
    let satisfactionRatio = ((avg_score / 5.0) * 100).toFixed(0);

    return (
      <Row className="header-statistics">
        <Col span={20}>
          <span className="title">{this.i18n.t("review.headerTitle")}</span>
          <span>
            {this.i18n.t("review.headerMerchant")}
            <strong>{avg_score}</strong>
          </span>
          {/* <span>口味评分<strong>4.2</strong></span>
					<Divider type="vertical" /> */}
          {/* <span>包装评分<strong>4.2</strong></span>
					<Divider type="vertical" /> */}
          <Divider type="vertical" />
          <span>
            {this.i18n.t("review.headerClient")}
            <strong>{`${satisfactionRatio}%`}</strong>
          </span>
        </Col>
        <Col span={4}>
          {/* <a onClick={this.onHeaderDetailClick}>查看评价统计详情 ></a> */}
        </Col>
      </Row>
    );
  }

  _renderHeaderFilters() {
    return (
      <Col className="filter-container">
        {this._renderFiltersStatus()}
        {this._renderFiltersSatisfaction()}
        {this._renderFiltersContent()}
        {this._renderFiltersRangeTime()}
      </Col>
    );
  }

  _renderFiltersStatus() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("review.filterStatus")}</span>
        <Radio.Group
          value={this.state.status.key}
          onChange={this.handleFilterStatusChange}
        >
          {ReviewList.Status.map((item, index) => {
            return (
              <Radio key={`status-filter-${index}`} value={item.key}>
                {this.i18n.t(item.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersSatisfaction() {
    return (
      <div className="filter-row">
        <span className="label">
          {this.i18n.t("review.filterSatisfaction")}
        </span>
        <Radio.Group
          value={this.state.satisfaction.key}
          onChange={this.handleFilterSatisfactionChange}
        >
          {ReviewList.Satisfaction.map((item, index) => {
            return (
              <Radio key={`satisfaction-filter-${index}`} value={item.key}>
                {this.i18n.t(item.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersContent() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("review.filterContent")}</span>
        <Radio.Group
          value={this.state.content.key}
          onChange={this.handleFilterContentChange}
        >
          {ReviewList.Content.map((item, index) => {
            return (
              <Radio key={`satisfaction-filter-${index}`} value={item.key}>
                {this.i18n.t(item.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersRangeTime() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("review.filterDate")}</span>
        <DatePicker.RangePicker
          defaultValue={this.state.rangeTime}
          format="YYYY-MM-DD"
          allowClear={false}
          onChange={this.handleFiltersTimeChange}
        />
      </div>
    );
  }

  _renderListTitle() {
    let data = this.state.data;
    let reviews = data ? data.reviews : null;
    let count = reviews ? reviews.total : 0;
    let title = this.i18n.t("review.listTitle", { count: count });

    return <div className="list-title">{title}</div>;
  }

  _renderListItems() {
    let data = this.state.data;
    if (data) {
      let reviews = data ? data.reviews : null;
      let reviewsData = reviews ? reviews.data : null;

      if (reviewsData && reviewsData.length > 0) {
        return (
          <Spin spinning={this.state.loadingData || this.state.replyingReview}>
            {reviewsData.map((review, index) => {
              return (
                <ReviewItem
                  key={`review-${index}`}
                  review={review}
                  actionClick={(action, data) =>
                    this.onReviewItemActionClick(review, action, data)
                  }
                />
              );
            })}
          </Spin>
        );
      } else {
        return (
          <div className="list-message">
            {this.i18n.t("review.loadingDataEpmty")}
          </div>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <div className="list-message">
            {this.i18n.t("review.loadingData")}
          </div>
        );
      }

      return (
        <div className="list-message">
          {this.i18n.t("review.loadingDataEpmty")}
        </div>
      );
    }
  }

  _renderListPagination() {
    let data = this.state.data;
    let reviews = data ? data.reviews : null;
    if (reviews && reviews.data && reviews.data.length > 0) {
      return (
        <div className="list-pagination">
          <Pagination
            total={reviews.total}
            current={this.state.currentPage}
            showSizeChanger={true}
            pageSizeOptions={["10", "20", "30"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
            showQuickJumper
          />
        </div>
      );
    } else {
      return null;
    }
  }

  _renderModals() {
    let items = [];

    items.push(this.renderImagePreviewModal());

    return items;
  }

  /* user methods */

  onHeaderDetailClick = () => {};

  handleFilterStatusChange = (e) => {
    let status = ReviewList.Status.find(
      (status) => status.key === e.target.value
    );
    this.setState({ status: status, currentPage: 1 });
  };

  handleFilterSatisfactionChange = (e) => {
    let satisfaction = ReviewList.Satisfaction.find(
      (status) => status.key === e.target.value
    );
    this.setState({ satisfaction: satisfaction, currentPage: 1 });
  };

  handleFilterContentChange = (e) => {
    let content = ReviewList.Content.find(
      (status) => status.key === e.target.value
    );
    this.setState({ content: content, currentPage: 1 });
  };

  handleFiltersTimeChange = (dates) => {
    this.setState({ rangeTime: dates, currentPage: 1 });
  };

  onReviewItemActionClick = (review, action, data) => {
    if (!review) return;

    if (action === "image") {
      data.images = data.images.map((image) => {
        return { url: image };
      });
      this.setState({
        previewImages: data.images,
        previewImageIndex: data.index,
      });
    } else if (action === "reply") {
      if (!this.util.isValidText(data)) {
        this.showMessage(
          this.i18n.t("review.replyValidWarning"),
          Base.Message.warning
        );
        return;
      }

      if (data.length > 300) {
        this.showMessage(
          this.i18n.t("review.replyLengthWarning"),
          Base.Message.warning
        );
        return;
      }

      this.showMessage(
        this.i18n.t("review.replyCommitting"),
        Base.Message.loading
      );
      this.replyToReview(review, data);
    }
  };

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  /* server methods */

  async loadReviewList() {
    if (this.state.loadingData) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.review.list;
    let apiConfig = {
      token: token,
      query: {
        "filter[replied]": this.state.status.key,
        "filter[type]": this.state.satisfaction.key,
        "filter[has_content]": this.state.content.key,
        "filter[start_date]": this.state.rangeTime[0].format("YYYY-MM-DD"),
        "filter[end_date]": this.state.rangeTime[1].format("YYYY-MM-DD"),
        page: this.state.currentPage,
        count: this.state.currentPageSize,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ data: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ loadingData: false });
    }
  }

  async replyToReview(review, data) {
    if (!review || !this.util.isValidText(data)) return;

    if (this.state.replyingReview) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.review.reply;
    let apiConfig = {
      token: token,
      path: {
        reviewId: review.id,
      },
      data: {
        content: data,
      },
    };

    this.setState({ replyingReview: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("review.replyCommittingSuccess"),
          Base.Message.loading
        );
        this.loadReviewList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ replyingReview: false });
    }
  }

  /* custom methods */

  getDefaultTimeRange() {
    let timeRange = [this.moment().subtract(30, "days"), this.moment()];

    let queryTime = this.getRouterQueryTimeRange();
    if (queryTime) {
      timeRange = queryTime;
    }

    return timeRange;
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
}, {})(ReviewList);
