export default {
  list: {
    name: "article list api",
    version: 6,
    url: "article",
    method: "GET",
  },
  add: {
    name: "add article api",
    version: 6,
    url: "article",
    method: "POST",
  },
  update: {
    name: "update article api",
    version: 6,
    url: "article/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete article api",
    version: 6,
    url: "article/%{id}",
    method: "DELETE",
  },
  catList: {
    name: "article-category list api",
    version: 6,
    url: "article-category",
    method: "GET",
  },
  catAdd: {
    name: "add article-category api",
    version: 6,
    url: "article-category",
    method: "POST",
  },
  catUpdate: {
    name: "update article-category api",
    version: 6,
    url: "article-category/%{id}",
    method: "PUT",
  },
  catDelete: {
    name: "delete article-category api",
    version: 6,
    url: "article-category/%{id}",
    method: "DELETE",
  },
};
