export default {
    show: {
        name: "get applyment info",
        version: 6,
        url: "wechat/applyment/show",
        method: "GET",
    },
    apply: {
        name: "apply api",
        version: 6,
        url: "wechat/applyment/apply",
        method: "GET",
    },
    query: {
        name: "query api",
        version: 6,
        url: "wechat/applyment/query",
        method: "GET",
    },
};
