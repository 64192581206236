export default {
  topupList: {
    name: "topup list api",
    version: 6,
    url: "balance/topup-list",
    method: "GET",
  },
  spendList: {
    name: "spend list api",
    version: 6,
    url: "balance/spend-list",
    method: "GET",
  },
  topup: {
    name: "topup api",
    version: 6,
    url: "balance/topup",
    method: "POST",
  },
  recall: {
    name: "recall api",
    version: 6,
    url: "balance/recall",
    method: "POST",
  },
};
