import {
  Image,
  Table,
  message,
  Tag,
  Space,
  Button,
  Layout,
  Divider,
  Breadcrumb,
  Popconfirm,
  InputNumber,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { ApiManager, Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import {
  LeftOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const base = Base.getInstance();

const LotteryPrize = ({ router }) => {
  const { state } = router.location;
  const onGetPrizeList = useRef();
  const [prizeList, setPrizeList] = useState([]);
  const [increase, setIncrease] = useState(0);
  const [decrease, setDecrease] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => getUserInfo(state));

  const onIncrease = async (id) => {
    if (user.token) {
      const apiUrl = api.lottery.prizeIncrease;
      const apiConfig = {
        token: user.token,
        data: {
          quantity: increase,
        },
        path: { id: id },
      };

      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          message.success(res.message);
          setPrizeList(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    } else {
      base.showModal(i18n.t("lottery.empty"), Base.Modal.warning);
    }
  };

  const onDecrease = async (id) => {
    if (user.token) {
      const apiUrl = api.lottery.prizeDecrease;
      const apiConfig = {
        token: user.token,
        data: {
          quantity: decrease,
        },
        path: { id: id },
      };

      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          message.success(res.message);
          setPrizeList(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    } else {
      base.showModal(i18n.t("lottery.empty"), Base.Modal.warning);
    }
  };

  const columns = [
    {
      title: i18n.t("lottery.zhAwardImg"),
      dataIndex: "zh_image",
      key: "zh_image",
      render: (img, record) => (
        <div>
          <div>
            <Image width={100} src={img} />
          </div>
          <div>
            <a>{record.zh_name}</a>
          </div>
        </div>
      ),
    },
    {
      title: i18n.t("lottery.ugAwardImg"),
      dataIndex: "ug_image",
      key: "ug_image",
      render: (img, record) => (
        <div>
          <div>
            <Image width={100} src={img} />
          </div>
          <div>
            <a>{record.ug_name}</a>
          </div>
        </div>
      ),
    },
    {
      title: i18n.t("commissionList.ship"),
      dataIndex: "ship_price",
      key: "ship_price",
    },
    {
      title: i18n.t("lottery.itemType"),
      dataIndex: "type",
      key: "type",
      render: (type) => {
        let color = type === 0 ? "geekblue" : type === 1 ? "volcano" : "green";

        return (
          <Tag color={color} key={type}>
            {type === 0
              ? i18n.t("lottery.product")
              : type === 1
              ? i18n.t("lottery.red")
              : i18n.t("lottery.thanks")}
          </Tag>
        );
      },
    },
    {
      title: i18n.t("redPacket.price"),
      dataIndex: "lucky_money_amount",
      key: "lucky_money_amount",
    },
    {
      title: i18n.t("order.printView.amount"),
      dataIndex: "total",
      key: "total",
      render: (text, record) => (
        <Popconfirm
          title={
            <InputNumber
              min={0}
              value={increase}
              onChange={(e) => setIncrease(e)}
            />
          }
          onConfirm={() => onIncrease(record.id)}
          okText={i18n.t("proxy.editOk")}
          cancelText={i18n.t("proxy.editCancel")}
        >
          <Space>
            <a>{text}</a>

            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size={"small"}
            />
          </Space>
        </Popconfirm>
      ),
    },
    {
      title: i18n.t("lottery.left"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Popconfirm
          title={
            <InputNumber
              min={0}
              max={text}
              value={decrease}
              onChange={(e) => setDecrease(e)}
            />
          }
          onConfirm={() => onDecrease(record.id)}
          okText={i18n.t("proxy.editOk")}
          cancelText={i18n.t("proxy.editCancel")}
        >
          <Space>
            <a>{text}</a>
            {text > 0 ? (
              <Button
                type="primary"
                icon={<MinusCircleOutlined />}
                size={"small"}
              />
            ) : null}
          </Space>
        </Popconfirm>
      ),
    },
    {
      title: i18n.t("lottery.prePrize"),
      dataIndex: "id",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() =>
              router.push({
                pathname: "/lottery-pre-list",
                state: {
                  prizeId: text,
                  name: {
                    zh: record.zh_name
                      ? record.zh_name
                      : i18n.t("lottery.prize"),
                    ug: record.ug_name
                      ? record.ug_name
                      : i18n.t("lottery.prize"),
                  },
                  prename: { zh: state.name.zh, ug: state.name.ug },
                },
              })
            }
            type="primary"
          >
            {i18n.t("lottery.prePrize")}
          </Button>
        </Space>
      ),
    },
  ];

  onGetPrizeList.current = debounce(async (page = 1) => {
    let prizeApi = api.lottery.prizeList;
    if (user && user.token) {
      const query = {
        page,
        count: 10,
      };
      const apiConfig = {
        token: user.token,
        query,
      };
      if (state.lotteryId) {
        apiConfig.path = { id: state.lotteryId };
      }
      try {
        const res = await prizeApi.run(apiConfig);
        if (res.result) {
          setPrizeList(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        console.log("loadProducts -> error", error);
      } finally {
      }
    }
  }, 500);

  useEffect(() => {
    onGetPrizeList.current();
  }, []);

  return (
    <>
      <Layout id="route-product-edit">
        <Layout.Header className="edit-header">
          <Button
            type="link"
            className="back"
            onClick={() => {
              router.goBack();
            }}
          >
            <LeftOutlined />
            {i18n.t("productEdit.headerBack")}
          </Button>

          <Divider type="vertical" />

          <Breadcrumb className="back">
            <Breadcrumb.Item href="/lottery/list">
              {i18n.t("main.sideMenus.lotteryList")}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="">{state.name.zh}</Breadcrumb.Item>
          </Breadcrumb>
        </Layout.Header>
      </Layout>
      <Table columns={columns} dataSource={prizeList} />
    </>
  );
};

export default LotteryPrize;
